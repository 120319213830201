<template>
  <div>
    <div class="d-flex justify-center">
      <v-alert
        dense
        text
        class="v-alert"
        :type="alertType"
        :value="alert"
        transition="slide-y-transition"
        >{{ alertText.text }}</v-alert
      >
    </div>
    <div v-if="IsORGAdmin || (IsORGViewer && awaitingUsersCount)">
      <div class="page-sub-sec">
        <h2 class="pa-1">Awaiting Validation</h2>
      </div>
      <div style="background-color: #e2f6ff;" class="pa-2 mb-4">
        <v-data-table
          :items="awaiting_users"
          :headers="awaitngHeader"
          :loading="this.$apollo.loading"
          loading-text="Loading... Please wait"
          class="dark--text"
          :no-data-text="`No user available`"
          :hide-default-footer="
            awaitingUsersCount < 10 ? true : false
          "
          :page-count="awaiting_pageSize"
          :page="awaiting_page"
          :options.sync="awaiting_options"
          :server-items-length="awaitingUsersCount"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50, 100, 500]
          }"
        >
          <template v-slot:[`item.EmployeeID`]="{ item }">
            {{ item.EmployeeID }}</template
          >
          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }}</template
          >
          <template v-slot:[`item.productName`]="{ item }">
            {{ item.productName }}
          </template>
          <template v-slot:[`item.dateCalculated`]="{ item }">
            {{ item.dateCalculated }}
          </template>
          <template v-slot:[`item.isUserRegistered`]="{ item }">
            <div v-if="item.isUserRegistered" class="success--text">
              True
            </div>
            <div v-else class="warning--text">
              False
            </div>
          </template>
          <template v-slot:[`item.isBlocked`]="{ item }">
            <div v-if="item.isBlocked" class="warning--text">
              Inactive
            </div>
            <div v-else class="success--text">
              Active
            </div>
          </template>
          <template v-slot:[`item.validationSlot`]="{ item }">
            <div class="ml-2">
              <v-btn
                v-if="item.isBlocked"
                class="ml-2"
                color="grey"
                icon
                small
              >
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on"
                      >mdi-check</v-icon
                    >
                  </template>
                  <span>{{ 'User is Blocked or Inactive ' }}</span>
                </v-tooltip>
              </v-btn>
              <v-btn
                v-else
                class="ml-2"
                color="green"
                icon
                small
                @click="approveValidation(item)"
              >
                <v-icon title="Approve">mdi-check</v-icon></v-btn
              >
              <v-btn
                class="ml-3"
                color="red"
                icon
                small
                :disabled="item.isBlocked"
                @click="rejectValidation(item)"
                ><v-icon title="Reject">mdi-close</v-icon></v-btn
              >
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
    <div class="page-sub-sec">
      <v-text-field
        v-model="searchEnterpriseLearner"
        append-icon="mdi-magnify"
        label="Search"
        outlined
        dense
        hide-details
        class="shrink"
        @input="usersData"
      />
      <div class="mr-2">
        <v-btn
          color="primary"
          class="success mr-2"
          :loading="gradeReportloading"
          v-if="IsORGAdmin"
          @click="generateLearnerReport"
          :disabled="!isShowLearnersReport"
        >
          <v-icon class="mr-2">mdi-file-excel</v-icon>Export Learner
          Report</v-btn
        >
        <v-btn
          v-if="
            false && (IsDementiaAdmin || IsORGAdmin || IsORGViewer)
          "
          color="primary"
          :loading="reportloading"
          @click="generateReport"
        >
          <v-icon class="mr-2">mdi-file-excel</v-icon>Generate
          Report</v-btn
        >
      </div>
    </div>
    <v-data-table
      :items="users"
      :headers="computedUserHeaders"
      :search="searchEnterpriseLearner"
      :hide-default-footer="usersCount < 10 ? true : false"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="dark--text"
      :no-data-text="`No user available`"
      :page-count="pageSize"
      :page="page"
      :options.sync="options"
      :server-items-length="usersCount"
      :footer-props="{
        'items-per-page-options': [50, 100, 500]
      }"
    >
      <template v-slot:[`item.EmployeeID`]="{ item }">
        {{
          item.EmployeeIDType == 'AlphaNumeric' ||
          item.EmployeeIDType == 'EmailAddress'
            ? item.EmployeeID
            : ''
        }}</template
      >
      <template v-slot:[`item.name`]="{ item }">
        <router-link :to="`/users/${item.id}`" class="blue--text">
          {{ item.name }}</router-link
        >
      </template>
      <template v-slot:[`item.email`]="{ item }">
        {{ item.email }}
      </template>

      <template v-slot:[`item.role.text`]="{ item }">
        {{ item.role.text }}
      </template>
      <template v-slot:[`item.orgName`]="{ item }">
        {{ item.orgName }}
      </template>
      <template v-slot:[`item.userActive`]="{ item }">
        <div v-if="!item.userActive" class="warning--text">
          Inactive
        </div>
        <div v-else class="success--text">
          Active
        </div>
      </template>
      <template v-slot:[`item.subscription`]="{ item }">
        {{ item.subscription }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div
          v-if="getLoginedUserId != item.id"
          class="lessons-actions-wrap"
        >
          <!-- <v-btn class="info" rounded small disabled>
          Reset Password
        </v-btn> -->
          <v-btn
            v-if="!item.userActive"
            color="green"
            icon
            small
            @click="enableOrDisableUser(item.id, true)"
            ><v-icon title="Enable">mdi-eye</v-icon></v-btn
          >
          <v-btn
            v-else
            color="orange"
            icon
            small
            @click="enableOrDisableUser(item.id, false)"
            ><v-icon title="Disable">mdi-eye-off</v-icon></v-btn
          >

          <router-link
            :to="`/users/${item.id}`"
            class="v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--small"
          >
            <v-icon title="Edit" class="blue--text"
              >mdi-pencil</v-icon
            >
          </router-link>

          <v-btn
            v-if="hideDelete == 'false'"
            icon
            color="red"
            small
            @click="LoadConfirmDialogForDeleteOrg(item.id)"
          >
            <v-icon title="Delete">mdi-trash-can-outline</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td
          :colspan="computedUserHeaders.length"
          style="background: white;padding: 0px;"
        >
          <v-simple-table
            v-if="item.products && item.products.length > 0"
          >
            <template v-slot:default>
              <thead>
                <tr style="background: #E5E5E5;">
                  <th class="text-left">
                    Subscription Name
                  </th>
                  <!-- <th class="text-left">
                    Purchase Date
                  </th> -->
                  <!-- <th class="text-left">
                    Expiry Date
                  </th> -->
                  <!-- <th class="text-left">
                    Auto Renewal
                  </th> -->
                  <th class="text-left">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody v-if="item.products && item.products.length > 0">
                <tr
                  v-for="items in item.products"
                  :key="items.productId"
                >
                  <td>{{ items.product }}</td>
                  <!-- <td>{{ loadDate(items.purchaseDate) }}</td>
                  <td>{{ loadDate(items.expirationDate) }}</td>
                  <td>{{ items.autoRenewal }}</td> -->
                  <td>{{ items.productActive }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td
                    colspan="5"
                    class="text-center"
                    style="color:#ababab;font-size: .875rem;"
                  >
                    {{ 'No data available' }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
    </v-data-table>

    <v-dialog v-model="confirmdialog.show" persistent max-width="450">
      <v-card>
        <v-card-title class="headline">{{
          confirmdialog.HeaderText
        }}</v-card-title>
        <v-card-text v-if="!confirmdialog.reject"
          >want to Approve
          <span class="success--text">{{
            confirmdialog.ContentText
          }}</span>
          validation</v-card-text
        >
        <v-card-text v-if="confirmdialog.reject"
          >want to Reject
          <span class="warning--text">{{
            confirmdialog.ContentText
          }}</span>
          validation</v-card-text
        >
        <div v-if="errors.length > 0">
          <v-icon
            style="color:red"
            class="pa-1 mt-4"
            @click="errors = []"
            >mdi-close</v-icon
          >
          <div
            v-for="(items, index) in errors"
            :key="index"
            class="pa-2"
          >
            <v-row>
              <v-col>
                <span style="color:red"
                  >{{ errors.length > 1 ? index + 1 + '.' : '' }}
                  {{ items.message }}</span
                >
              </v-col>
            </v-row>
          </div>
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="
              confirmdialog.show = false;
              errors = [];
            "
            >Cancel</v-btn
          >
          <v-btn
            color="red darken-1"
            text
            @click="
              confirmdialog.ButtonConfirmAction(
                confirmdialog.customObject
              )
            "
            >{{ confirmdialog.ButtonConfirmText }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmdialogUserdelete"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">Are you sure</v-card-title>
        <v-card-text
          >Please confirm you wish to remove the selected
          user</v-card-text
        >
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="confirmdialogUserdelete = false"
            >Cancel</v-btn
          >
          <v-btn
            color="red darken-1"
            text
            @click="deleteUser(userdeleteId)"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';
import { Constant } from '../../../config/constants';
import * as saveAs from 'file-saver';
import { AUTH_TOKEN } from '../../../vue-apollo';
import moment from 'moment';

// const INVITE_NEW_USER = gql`
//   mutation(
//     $email: String!
//     $roleName: String!
//     $orgId: ID
//     $firstName: String!
//     $lastName: String
//   ) {
//     customRegister(
//       input: {
//         email: $email
//         role: $roleName
//         orgId: $orgId
//         firstName: $firstName
//         lastName: $lastName
//       }
//     ) {
//       user {
//         id
//         username
//         email
//         firstName
//         lastName
//         confirmed
//         blocked
//         role {
//           id
//           name
//         }
//         memberOf {
//           id
//           name
//         }
//         adminOf {
//           id
//           name
//         }
//         viewerOf {
//           id
//           name
//         }
//       }
//     }
//   }
// `;
const RE_INVITE_USER = gql`
  mutation($userId: ID!) {
    reinviteUser(id: $userId) {
      user {
        id
      }
    }
  }
`;
const ENABLE_DISABLE_USER_MUTATION = gql`
  mutation($userId: ID!, $isDisabled: Boolean!) {
    customUpdateUser(
      input: {
        where: { id: $userId }
        data: { blocked: $isDisabled }
      }
    ) {
      user {
        id
        username
        email
        confirmed
        blocked
        role {
          id
          name
        }
        memberOf {
          id
          name
        }
      }
    }
  }
`;

// const USER_QUERY_COUNT = gql`
//   query Users($where: JSON) {
//     users(where: $where) {
//       id
//       role {
//         name
//       }
//       username
//     }
//   }
// `;
const AWAITING_USER_QUERY_COUNT = gql`
  query GetAwaitingLearnersTotalCount {
    getAwaitingLearnersTotalCount {
      isSuccess
      data
    }
  }
`;

// const USER_QUERY = gql`
//   query {
//     users {
//       id
//       username
//       firstName
//       lastName
//       mobile
//       email
//       confirmed
//       blocked
//       role {
//         id
//         name
//       }
//       viewerOf {
//         id
//         name
//       }
//       adminOf {
//         id
//         name
//       }
//       memberOf {
//         id
//         name
//       }
//     }
//   }
// `;
const USER_QUERY = gql`
  query GetEnterpriseUsers(
    $pageNumber: Int!
    $pageSize: Int!
    $search: String
    $sortBy: String
    $sortOrder: String
  ) {
    getEnterpriseUsers(
      pageNumber: $pageNumber
      pageSize: $pageSize
      search: $search
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      totalItemCount
      statusMessage
      statusCode
      data {
        firstName
        lastName
        email
        id
        orgName
        userActive
        roleName
        EmployeeIDType
        EmployeeID
        products {
          userId
          productId
          product
          expirationDate
          purchaseDate
          autoRenewal
          productActive
        }
      }
    }
  }
`;

const ORG_QUERY = gql`
  query {
    orgs {
      id
      name
    }
  }
`;
const ROLE_QUERY = gql`
  query {
    roles {
      id
      name
    }
  }
`;
const DELETE_USER = gql`
  mutation($userId: ID!) {
    deleteUser(input: { where: { id: $userId } }) {
      user {
        id
      }
    }
  }
`;

const USER_QUERY_AWAITING_LEARNERS = gql`
  query GetEnterpriseAwaitingLearners(
    $pageNumber: Int!
    $pageSize: Int!
  ) {
    getEnterpriseAwaitingLearners(
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      statusMessage
      statusCode
      data {
        firstName
        lastName
        email
        userProductId
        orgName
        purchaseDate
        productName
        email
        isUserRegistered
        isBlocked
        EmployeeIDType
        EmployeeID
      }
    }
  }
`;

const UPDATE_VALIDATION = gql`
  mutation SaveAwaitingLearnerProductApproval(
    $userProductId: ID!
    $isApproved: Boolean!
  ) {
    saveAwaitingLearnerProductApproval(
      userProductId: $userProductId
      isApproved: $isApproved
    ) {
      isSuccess
      statusCode
      statusMessage
      error {
        id
        code
        message
      }
    }
  }
`;

export default {
  name: 'EnterpriseLearners',
  data: () => ({
    system_roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER'
    },
    users: [],
    pageSize: 50,
    expanded: [],
    page: 1,
    options: {},
    orgId: 0,
    confirmdialogUserdelete: false,

    confirmdialog: {
      show: false,
      HeaderText: '',
      ContentText: '',
      ButtonConfirmText: '',
      ButtonConfirmAction: '',
      customObject: null
    },
    hideDelete: process.env.VUE_APP_HIDEDELETE_STATUS,
    userdeleteId: '',
    alert: false,
    alertText: { text: '' },
    alertType: 'success',
    showNewDialog: false,
    loading: true,
    newCourseName: '',
    searchEnterpriseLearner: '',
    headers: [
      { text: 'Employee ID', value: 'EmployeeID' },
      { text: 'Name', value: 'name' },
      { text: 'Email', value: 'email' },
      // { text: 'Role', value: 'role.text' },
      { text: 'Organisation', value: 'orgName' },
      { text: 'Status ', value: 'userActive' },
      // { text: 'Subscription ', value: 'subscription' },
      { text: 'Action1', value: 'action', sortable: false },
      { text: '', value: 'data-table-expand' }
    ],
    errors: [],
    validationHeaders: [
      { text: 'Employee ID', value: 'EmployeeID' },
      { text: 'Name', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: 'Subscription Name', value: 'productName' },
      { text: 'Time Awaiting Validation', value: 'dateCalculated' },
      { text: 'User Registered', value: 'isUserRegistered' },
      { text: 'User Status', value: 'isBlocked' },
      { text: 'Validation Approval ', value: 'validationSlot' }
    ],
    awaiting_pageSize: 10,
    awaiting_page: 0,
    awaiting_options: {},
    awaiting_users: [],
    error: null,
    reportloading: false,
    allRoles: [],
    usersCount: 0,
    allOrgs: [],
    forms: {
      newEmail: null,
      newRoleName: null,
      newOrgId: null,
      newFirstName: null,
      newLastName: null,
      newPhoneNum: null
    },
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    gradeReportloading: false,
    isShowLearnersReport: false
  }),
  apollo: {
    awaitingUsersCount() {
      return {
        query: AWAITING_USER_QUERY_COUNT,
        fetchPolicy: 'cache-and-network',
        update: data => {
          return data?.getAwaitingLearnersTotalCount?.data;
        },
        skip() {
          return (
            !this.isAuthenticated ||
            !this.IsORGAdmin ||
            !this.IsORGViewer
          );
        }
      };
    },
    orgs: {
      query: ORG_QUERY,
      skip() {
        return !this.isAuthenticated;
      }
    },
    roles: {
      query: ROLE_QUERY,
      skip() {
        return !this.isAuthenticated;
      }
    }
  },
  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating'
    ]),
    orgEmployeeIDType() {
      if (
        this.getUser?.user?.memberOf?.[0] &&
        this.getUser?.user?.memberOf?.[0].EmployeeIDType
      )
        return this.getUser?.user?.memberOf?.[0].EmployeeIDType;
      else return '';
    },
    awaitngHeader() {
      if (this.IsORGViewer || this.IsORGAdmin) {
        const orgEmployeeIDType = this.orgEmployeeIDType;
        return this.validationHeaders.filter(t =>
          this.IsORGViewer
            ? t.value != 'validationSlot'
            : true &&
              (orgEmployeeIDType !== 'AlphaNumeric' &&
              orgEmployeeIDType !== 'EmailAddress'
                ? t.text != 'Employee ID'
                : true)
        );
      } else {
        return this.validationHeaders;
      }
    },

    computedUserHeaders() {
      if (this.IsORGViewer || this.IsORGAdmin) {
        const orgEmployeeIDType = this.orgEmployeeIDType;
        return this.headers.filter(
          t =>
            t.text != 'Action' &&
            t.text != 'Organisation' &&
            (orgEmployeeIDType !== 'AlphaNumeric' &&
            orgEmployeeIDType !== 'EmailAddress'
              ? t.text != 'Employee ID'
              : true)
        );
      } else return this.headers;
    },

    IsDementiaAdmin() {
      if (
        this.getUser?.user?.role?.name ===
        this.system_roles.DEMENTIA_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsOrglevelRole() {
      const organizationRequiredRoles = [
        this.system_roles.ORG_ADMIN,
        this.system_roles.ORG_VIEWER,
        'LEARNER'
      ];
      if (
        this.forms.newRoleName &&
        organizationRequiredRoles.includes(this.forms.newRoleName)
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsCMSAdmin() {
      if (
        this.getUser?.user?.role?.name === this.system_roles.CMS_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsORGAdmin() {
      if (
        this.getUser?.user?.role?.name === this.system_roles.ORG_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsORGViewer() {
      if (
        this.getUser?.user?.role?.name ===
        this.system_roles.ORG_VIEWER
      ) {
        return true;
      } else {
        return false;
      }
    },

    getLoginedUserId() {
      return this.getUser?.user?.id;
    },
    getRolesByRole() {
      if (!this.roles) return [];
      if (
        this.getUser?.user?.role?.name === this.system_roles.ORG_ADMIN
      ) {
        var adminroles = this.roles
          .filter(function(r) {
            //return r.id != 1 && r.id != 2 && r.id != 3 && r.id != 6;
            return (
              r.name != Constant.roles.AUTHENTICATED &&
              r.name != Constant.roles.PUBLIC &&
              r.name != Constant.roles.DEMENTIA_ADMIN &&
              r.name != Constant.roles.CMS_ADMIN
            );
          })
          .map(item => ({
            ...item,
            text: this.getRoleDisplayName(item.name)
          }));
        return adminroles;
      } else if (
        this.getUser?.user?.role?.name ===
        this.system_roles.DEMENTIA_ADMIN
      ) {
        var dadminroles = this.roles
          .filter(function(r) {
            //return r.id != 1 && r.id != 2;
            return (
              r.name != Constant.roles.AUTHENTICATED &&
              r.name != Constant.roles.PUBLIC
            );
          })
          .map(item => {
            // let rolText = item.name;
            // if (rolText === Constant.roles.DEMENTIA_ADMIN)
            //   rolText = Constant.roles.DEMENTIA_ADMIN_VIEW;
            return {
              ...item,
              text: this.getRoleDisplayName(item.name)
            };
          });
        return dadminroles;
      } else return [];
    },

    getUsersByRole() {
      if (!this.users) return [];
      if (
        this.getUser?.user?.role?.name ===
          this.system_roles.ORG_ADMIN ||
        this.getUser?.user?.role?.name ===
          this.system_roles.ORG_VIEWER
      ) {
        const orgId = this.getUser?.user?.memberOf[0].id.toString();
        var orgusers = this.users.filter(function(e) {
          return (
            (e.adminOf.length != 0 && e.adminOf[0].id === orgId) ||
            (e.viewerOf.length != 0 && e.viewerOf[0].id === orgId) ||
            (e.memberOf.length != 0 && e.memberOf[0].id === orgId)
          );
        });
        return orgusers;
      } else {
        return this.users;
      }
    }
  },
  watch: {
    options: {
      handler() {
        this.usersData();
      }
    },
    awaiting_options: {
      handler() {
        this.usersDataAwaitng();
      }
    },
    deep: true
  },
  created() {
    this.Constant = Constant;
  },
  methods: {
    getRoleDisplayName(roleName) {
      let displayRole = '';
      switch (roleName) {
        case Constant.roles.DEMENTIA_ADMIN:
          displayRole = Constant.roles.DEMENTIA_ADMIN_VIEW;
          break;
        case Constant.roles.CMS_ADMIN:
          displayRole = Constant.roles.CMS_ADMIN_VIEW;
          break;
        case Constant.roles.ORG_ADMIN:
          displayRole = Constant.roles.ORG_ADMIN_VIEW;
          break;
        case Constant.roles.ORG_VIEWER:
          displayRole = Constant.roles.ORG_VIEWER_VIEW;
          break;
        case Constant.roles.LEARNER:
          displayRole = Constant.roles.LEARNER_VIEW;
          break;
      }
      return displayRole;
    },
    loadDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    usersDataAwaitng(arg = false) {
      const { page, itemsPerPage } = this.awaiting_options;
      let page_nuber = page ? page : this.awaiting_page;
      if (arg) {
        page_nuber = 0;
      }
      this.$apollo
        .query({
          query: USER_QUERY_AWAITING_LEARNERS,
          variables: {
            pageNumber: page_nuber,
            pageSize: itemsPerPage
              ? itemsPerPage
              : this.awaiting_pageSize
          },
          fetchPolicy: 'network-only',
          skip() {
            return !this.isAuthenticated;
          }
        })
        .then(response => {
          let nwdata = response.data.getEnterpriseAwaitingLearners.data.map(
            usr => {
              // usr.organisationName = this.getOrganisationName(usr);
              usr.role = {
                ...usr.role,
                text: null
              };
              usr.name = usr.firstName.concat(
                ' ',
                usr.lastName ? usr.lastName : ''
              );
              const dateFormatted = moment(usr.purchaseDate);
              usr.dateCalculated = dateFormatted.fromNow();

              return usr;
            }
          );
          this.awaiting_users = nwdata;
        });
    },

    approveValidation(item) {
      this.confirmdialog.HeaderText = 'Are you sure?';
      this.confirmdialog.reject = false;
      this.confirmdialog.ContentText = item.name;
      this.confirmdialog.ButtonConfirmText = 'Yes Approve';
      this.confirmdialog.show = true;
      this.confirmdialog.customObject = {
        userProductId: item.userProductId,
        isApproved: true
      };
      this.confirmdialog.ButtonConfirmAction = this.updateUserValidation;
    },
    rejectValidation(item) {
      this.confirmdialog.HeaderText = 'Are you sure?';
      this.confirmdialog.reject = true;
      this.confirmdialog.ContentText = item.name;
      this.confirmdialog.ButtonConfirmText = 'Yes Reject';
      this.confirmdialog.show = true;
      this.confirmdialog.customObject = {
        userProductId: item.userProductId,
        isApproved: false
      };
      this.confirmdialog.ButtonConfirmAction = this.updateUserValidation;
    },
    updateUserValidation(item) {
      this.$apollo
        .mutate({
          mutation: UPDATE_VALIDATION,
          variables: {
            userProductId: item.userProductId,
            isApproved: item.isApproved
          },
          update: (
            store,
            { data: { saveAwaitingLearnerProductApproval } }
          ) => {
            if (!saveAwaitingLearnerProductApproval.error) {
              this.confirmdialog.show = false;
              this.alert = true;
              this.alertType = 'success';
              this.alertText.text = 'Action was Successfull';
              this.usersDataAwaitng(true);
              this.usersData(true);
              setTimeout(() => {
                this.alert = false;
                this.alertText.text = '';
              }, 3000);
            } else {
              this.errors = saveAwaitingLearnerProductApproval.error;
            }
          },
          skip() {
            return !this.isAuthenticated;
          }
        })
        .catch(e => {
          this.alertType = 'error';
          this.alert = true;
          this.alertText.text = JSON.stringify(
            e.graphQLErrors[0].extensions.exception.data.message[0]
              .messages[0].message
          ).replace(/['"]+/g, '');
          setTimeout(() => {
            this.alert = false;
            this.alertText.text = '';
            this.alertType = 'success';
          }, 13000);
        });
    },
    setActiveTab(id) {
      this.selectedTab = id;
    },
    generateReport() {
      var datetime = new Date();
      this.reportloading = true;
      var month = datetime.getMonth() + 1;
      //custom file name
      var sheetname =
        'DA_EnterpriseLearnersData_' +
        datetime.getDate() +
        '_' +
        month +
        '_' +
        datetime.getFullYear() +
        '_' +
        datetime.toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        }) +
        '.xlsx';
      const downloadUrl =
        process.env.VUE_APP_BASE_API + '/generateReportEnterprise';
      fetch(downloadUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN)
        }
      })
        .then(response => response.blob())
        .then(blob => {
          saveAs(blob, sheetname);
          this.reportloading = false;
        })
        .catch(err => {
          this.reportloading = false;
          // todo: handle server failure
          console.log(err);
        });
    },
    enterpriseLearnerPaginationStatus() {
      if (this.getUsersByRole && this.getUsersByRole.length > 0)
        return false;
      else return true;
    },
    enableOrDisableUser(userId, isDisabled) {
      this.$apollo.mutate({
        mutation: ENABLE_DISABLE_USER_MUTATION,
        variables: {
          userId: userId,
          isDisabled: !isDisabled
        },
        update: (store, { data: { customUpdateUser } }) => {
          // Only update the store if there's a new course created
          if (!customUpdateUser.user) return;
          // Get the current store state
          // Push the new users onto the cache
          const _user = this.users.find(link => link.id === userId);
          _user.userActive = isDisabled;

          // // Write the list back into the cache
          // store.writeQuery({ query: USER_QUERY, _user });
        }
      });
    },
    LoadConfirmDialogForDeleteOrg(id) {
      this.userdeleteId = id;
      this.confirmdialogUserdelete = true;
    },

    deleteUser(userId) {
      this.$apollo.mutate({
        mutation: DELETE_USER,
        variables: {
          userId: userId
        },
        update: (store, { data: { deleteUser } }) => {
          if (!deleteUser) return;
          this.alert = true;
          this.alertText.text = 'User Removed!';
          setTimeout(() => {
            this.alert = false;
            this.alertText.text = '';
          }, 3000);
          // Pop the user from the cache
          this.usersData(true);
        }
      });
      this.confirmdialogUserdelete = false;
    },
    reInviteUser(userId, username) {
      this.$apollo.mutate({
        mutation: RE_INVITE_USER,
        variables: {
          userId: userId,
          username: username
        },
        update: () => {
          this.alert = true;
          this.alertText.text = 'Invitation sent!';
          setTimeout(() => {
            this.alert = false;
            this.alertText.text = '';
          }, 3000);
        }
      });
    },
    cancel() {
      this.$refs.form.reset();
      this.showNewDialog = false;
    },
    getOrganisationName(user) {
      return user.role.name === this.system_roles.ORG_ADMIN
        ? user.adminOf
          ? user.adminOf[0]
            ? user.adminOf[0].name
            : '-'
          : '-'
        : user.role.name === this.system_roles.ORG_VIEWER
        ? user.viewerOf
          ? user.viewerOf[0]
            ? user.viewerOf[0].name
            : '-'
          : '-'
        : user.memberOf
        ? user.memberOf[0]
          ? user.memberOf[0].name
          : '-'
        : '-';
    },
    usersData(flag = false) {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let sortDirection = null;
      let sort_var = null;
      this.loading = true;
      let page_nuber = flag ? 0 : page ? page : this.page;
      if (sortBy[0]) {
        sortDirection = sortDesc[0] ? 'desc' : 'asc';
        sort_var = sortBy[0];
      }
      this.$apollo
        .query({
          query: USER_QUERY,
          variables: {
            pageNumber: page_nuber,
            pageSize: itemsPerPage ? itemsPerPage : this.pageSize,
            search: this.searchEnterpriseLearner,
            sortBy: sort_var,
            sortOrder: sortDirection
          },
          fetchPolicy: 'network-only',
          skip() {
            return !this.isAuthenticated;
          }
        })
        .then(response => {
          this.loading = false;
          let data = response?.data?.getEnterpriseUsers?.data;
          if (response?.data?.getEnterpriseUsers?.totalItemCount) {
            this.usersCount = parseInt(
              response?.data?.getEnterpriseUsers?.totalItemCount
            );
          }
          let neDtata = data?.map(usr => {
            usr.role = {
              // ...usr.role,
              text: usr.roleName
            };
            usr.name = usr.firstName.concat(
              ' ',
              usr.lastName ? usr.lastName : ''
            );
            usr.subscription = usr.products ? usr.products.length : 0;
            return usr;
          });
          this.users = neDtata;
          if (
            this.users.length > 0 &&
            this.isShowLearnersReport === false
          ) {
            this.isShowLearnersReport = true;
          }
        });
    },
    reportNameGenerator(report) {
      var datetime = new Date();
      var month = datetime.getMonth() + 1;
      return `${report}_${datetime.getDate()}_${month}_${datetime.getFullYear()}_${datetime.toLocaleString(
        'en-US',
        {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        }
      )}.xlsx`;
    },
    async generateLearnerReport() {
      const self = this;
      self.gradeReportloading = true;
      var sheetname = await self.reportNameGenerator(
        'LearnerReport_'
      );
      const downloadUrl =
        process.env.VUE_APP_BASE_API + '/generateLearnerReport';
      fetch(downloadUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN)
        }
      })
        .then(response => response.blob())
        .then(blob => {
          saveAs(blob, sheetname);
          self.gradeReportloading = false;
        })
        .catch(err => {
          self.gradeReportloading = false;
          console.log(err);
        });
    }
  }
};
</script>
