import Vue from 'vue';
import VueRouter from 'vue-router';
//import Dashboard from '../pages/Dashboard';
import CourseList from '../pages/Course/Modules/CourseList';
import CourseDetail from '../pages/Course/Modules/CourseDetail';
import Lesson from '../pages/Course/Lessons/Lesson';
import Activity from '../pages/Course/Activities/Activity';
import OrgList from '../pages/Organization/OrgList';
import OrgDetail from '../pages/Organization/OrgDetail';
import OrgLearner from '../pages/Users/OrgLearner';
import OrgAdmin from '../pages/Users/OrgAdmin';
import UserList from '../pages/Users/UserList';
import UserDetail from '../pages/Users/UserDetail';
import LoginHome from '../pages/Login/LoginHome';
import GetReport from '../pages/Users/GetReport';
import LicenseList from '../pages/Organization/License/LicenseList';
import LearnerDetails from '../pages/Organization/Learners/LearnerDetails';
import { Constant } from '../config/constants';
import store from '../store';
import PrimaryCourseList from '../pages/Course/PrimaryCourseList';
import PrimaryCourseDetail from '../pages/Course/PrimaryCourseDetail';
import SubscriptionsList from '../pages/Subscriptions/SubscriptionsList';
import SubscriptionsDetails from '../pages/Subscriptions/Details/SubscriptionsDetails';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/login'
  },
  // {
  //   path: '/dashboard',
  //   name: 'Dashboard',
  //   component: Dashboard
  // },
  {
    path: '/courses',
    name: 'PrimaryCourseList',
    component: PrimaryCourseList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/courses/:courseId',
    name: 'PrimaryCourseDetail',
    component: PrimaryCourseDetail,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/modules',
    name: 'CourseList',
    component: CourseList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/courses/:courseListId/:courseId',
    name: 'CourseModuleList',
    component: CourseDetail,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/modules/:courseId',
    name: 'CourseDetail',
    component: CourseDetail,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/lesson/:courseId/:lessonId',
    name: 'Lesson',
    component: Lesson,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/activity/:courseId/:lessonId/:activityId',
    name: 'Activity',
    component: Activity,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/organisations/Users/:orgId/:subscriptionId',
    name: 'LicenseList',
    component: LicenseList,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/organisations/Learners/:orgId/:userId',
    name: 'LearnerDetails',
    component: LearnerDetails,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/organisations/:orgId/:subscriptionId',
    name: 'OrgSubscriptionList',
    component: SubscriptionsDetails,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/organisations',
    name: 'OrgList',
    component: OrgList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/organisations/:orgId',
    name: 'OrgDetail',
    component: OrgDetail,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/learner/:orgId/:learnerId',
    name: 'OrgLearner',
    component: OrgLearner,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/:orgId/:userId',
    name: 'OrgAdmin',
    component: OrgAdmin,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users',
    name: 'UsersList',
    component: UserList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/subscriptions',
    name: 'SubscriptionsList',
    component: SubscriptionsList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/subscriptions/:subscriptionId',
    name: 'SubscriptionsDetails',
    component: SubscriptionsDetails,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users/:userId',
    name: 'UserDetail',
    component: UserDetail,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user/getReport',
    name: 'GetReport',
    meta: { layout: 'ReportLayout' },
    component: GetReport
  },
  {
    path: '/login',
    name: 'LoginHome',
    meta: { public: true },
    component: LoginHome
  }
];

const router = new VueRouter({
  routes
});

// router.beforeEach((to, from, next) => {
//   if (to.meta.public || store.getters['auth/isAuthenticated']) {
//     next();
//   } else {
//     console.log('redirected from router-not authenticated');
//     next({
//       name: 'LoginHome',
//       params: { ...to.params, redirect: to.name },
//       query: to.query
//     });
//   }
// });

router.beforeEach((to, from, next) => {
  if (to.name == 'LoginHome') {
    next();
    return;
  }
  if (to.name == 'GetReport') {
    next();
    return;
  }
  if (
    to.matched.some(record => record.meta.requiresAuth) &&
    store.getters['auth/isAuthenticated']
  ) {
    //Authorization check
    let roleName = store.getters['auth/getUser']?.user?.role?.name;
    if (
      to.name == 'PrimaryCourseList' &&
      (roleName == Constant.roles.CMS_ADMIN ||
        roleName == Constant.roles.DEMENTIA_ADMIN)
    ) {
      next();
      return;
    }

    if (
      to.name == 'CourseList' &&
      (roleName == Constant.roles.CMS_ADMIN ||
        roleName == Constant.roles.DEMENTIA_ADMIN)
    ) {
      next();
      return;
    }
    if (
      to.name == 'OrgList' &&
      roleName == Constant.roles.DEMENTIA_ADMIN
    ) {
      next();
      return;
    }
    if (
      to.name == 'OrgDetail' &&
      (roleName == Constant.roles.DEMENTIA_ADMIN ||
        roleName == Constant.roles.ORG_ADMIN ||
        roleName == Constant.roles.ORG_VIEWER)
    ) {
      next();
      return;
    }
    if (
      to.name == 'OrgLearner' &&
      (roleName == Constant.roles.DEMENTIA_ADMIN ||
        roleName == Constant.roles.ORG_ADMIN ||
        roleName == Constant.roles.ORG_VIEWER)
    ) {
      next();
      return;
    }
    if (
      to.name == 'OrgAdmin' &&
      (roleName == Constant.roles.DEMENTIA_ADMIN ||
        roleName == Constant.roles.ORG_ADMIN ||
        roleName == Constant.roles.ORG_VIEWER)
    ) {
      next();
      return;
    }
    if (
      to.name == 'UsersList' &&
      (roleName == Constant.roles.DEMENTIA_ADMIN ||
        roleName == Constant.roles.ORG_ADMIN ||
        roleName == Constant.roles.ORG_VIEWER)
    ) {
      next();
      return;
    }
    if (
      to.name == 'SubscriptionsList' &&
      (roleName == Constant.roles.DEMENTIA_ADMIN ||
        roleName == Constant.roles.CMS_ADMIN)
    ) {
      next();
      return;
    }
    if (
      to.name == 'SubscriptionsDetails' &&
      (roleName == Constant.roles.DEMENTIA_ADMIN ||
        roleName == Constant.roles.CMS_ADMIN)
    ) {
      next();
      return;
    }
    if (
      to.name == 'UserDetail' &&
      (roleName == Constant.roles.DEMENTIA_ADMIN ||
        roleName == Constant.roles.ORG_ADMIN ||
        roleName == Constant.roles.ORG_VIEWER)
    ) {
      next();
      return;
    } else {
      //TODO
      //Unauthorized users also redirect to login page or should we direct them to access denied page??
      next('/login');
    }

    return;
  } else if (store.getters['auth/isAuthenticated']) {
    next();
    return;
  }
  next('/login');
  return;
});

export default router;
