<template>
  <div class="overflow-y-scroll">
    <div v-if="loading">
      <div class="text-center">
        <v-progress-linear indeterminate />
      </div>
    </div>
    <v-container fluid class="grey lighten-5">
      <v-alert
        dense
        text
        :type="alert.type"
        :value="alert.show"
        transition="slide-y-transition"
        >{{ alert.text }}</v-alert
      >
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
          <h1>{{ course.title }}</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card outlined>
            <v-form ref="form" v-model="validCourse" lazy-validation>
              <v-card-text>
                <div class="section-devider no-bg">
                  <h2>Title</h2>
                  <v-text-field
                    v-model="local.title"
                    :counter="50"
                    :rules="nameRules"
                    prepend-icon="mdi-pencil"
                    required
                  />
                </div>
                <div v-if="IsCMSAdmin && !loading">
                  <div class="section-devider">
                    <h2>Text Color</h2>
                    <v-color-picker
                      v-model="textcolor"
                      hide-canvas
                      hide-mode-switch
                      mode="hexa"
                      show-swatches
                      swatches-max-height="300"
                    />
                  </div>
                  <div class="section-devider">
                    <h2>SubText Color</h2>
                    <v-color-picker
                      v-model="subtextColour"
                      hide-canvas
                      hide-mode-switch
                      mode="hexa"
                      show-swatches
                      swatches-max-height="300"
                    />
                  </div>
                  <div class="section-devider">
                    <h2>Background Color</h2>
                    <v-color-picker
                      v-model="tilecolor"
                      hide-canvas
                      hide-mode-switch
                      mode="hexa"
                      show-swatches
                      swatches-max-height="300"
                    />
                  </div>

                  <div class="section-devider">
                    <h2>Progress Fill color</h2>
                    <v-color-picker
                      v-model="progressfillColour"
                      hide-canvas
                      hide-mode-switch
                      mode="hexa"
                      show-swatches
                      swatches-max-height="300"
                    />
                  </div>
                </div>
                <div
                  v-if="IsDementiaAdmin && !loading"
                  class="section-devider"
                >
                  <h2>Color Picker</h2>
                  <v-row
                    justify="space-around"
                    class="colorDisplayRow"
                  >
                    <v-col>
                      <v-row class="colorDisplayRow">
                        <h4>Text Color</h4>
                      </v-row>
                      <v-row class="colorDisplayRow">
                        <div
                          class="text-Color-Picker"
                          :style="{ 'background-color': textcolor }"
                        ></div>
                        <div class="text-display">
                          {{ textcolor }}
                        </div>
                      </v-row>
                    </v-col>
                    <v-col>
                      <v-row>
                        <h4>Subtext Color</h4>
                      </v-row>
                      <v-row>
                        <div
                          class="text-Color-Picker"
                          :style="{
                            'background-color': subtextColour
                          }"
                        ></div>
                        <div class="text-display">
                          {{ subtextColour }}
                        </div>
                      </v-row>
                    </v-col>
                    <v-col>
                      <v-row>
                        <h4>Background Color</h4>
                      </v-row>
                      <v-row>
                        <div
                          class="text-Color-Picker"
                          :style="{ 'background-color': tilecolor }"
                        ></div>
                        <div class="text-display">
                          {{ tilecolor }}
                        </div>
                      </v-row>
                    </v-col>
                    <v-col>
                      <v-row>
                        <h4>Progress Fill Color</h4>
                      </v-row>
                      <v-row>
                        <div
                          class="text-Color-Picker"
                          :style="{
                            'background-color': progressfillColour
                          }"
                        ></div>
                        <div class="text-display">
                          {{ progressfillColour }}
                        </div>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
                <!-- <div class="section-devider">
                  <h2>Text Color Picker</h2>
                  <v-color-picker
                    v-model="textcolor"
                    hide-canvas
                    hide-mode-switch
                    mode="hexa"
                    show-swatches
                    swatches-max-height="300"
                  />
                </div> -->
                <div class="section-devider">
                  <h2>Intro Header</h2>
                  <app-content-header-editor
                    v-model="local.headerContent"
                  />
                </div>
                <div class="section-devider">
                  <h2>Description</h2>
                  <app-content-list-editor v-model="local.desc" />
                </div>
                <!-- <div class="section-devider">
                  <h2>Tile Color Picker</h2>
                  <v-color-picker
                    v-model="tilecolor"
                    hide-canvas
                    hide-mode-switch
                    mode="hexa"
                    show-swatches
                    swatches-max-height="300"
                  />
                </div> -->
                <!-- <div class="section-devider">
                <h2>Public</h2>
                <v-checkbox
                  v-model="local.public"
                  :label="`Make course visible to everyone`"
                />
              </div> -->
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-btn
                  v-if="IsCMSAdmin && !loading"
                  color="primary"
                  x-large
                  @click="validateCourse"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card outlined class="mb-5">
            <v-card-title class="d-flex justify-space-between">
              <span class="header">Module Details</span>
            </v-card-title>
            <v-form
              ref="moduleform"
              v-model="validCourse"
              lazy-validation
            >
              <div class="details_header">
                <span class="details_sub_header"
                  >Appears in course...</span
                >
                <v-row cols="12">
                  <v-col sm="6 ">
                    <v-autocomplete
                      v-model="moduleForm.primary_courses"
                      :items="primaryCourseListForModule"
                      :disabled="!IsCMSAdmin"
                      label="Select Course"
                      :rules="
                        IsCMSAdmin
                          ? [v => !!v || 'Select a Course']
                          : []
                      "
                      required
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>
                            <span style="color:#0d3349;font-size:12px"
                              >{{ data.item.value }} -
                            </span>
                            {{ data.item.text }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="pl-2 pb-2">
                  <div class="d-inline">
                    <span class="pa-1 mb-2 heading-payment"
                      >Payment Status
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            class="pl-1"
                            color="#113247"
                            v-on="on"
                            >info</v-icon
                          >
                        </template>
                        <span
                          >Is this module available for free to users,
                          or is it locked and requiring payment</span
                        >
                      </v-tooltip></span
                    >
                    <v-row class="pl-4 pb-2">
                      <v-autocomplete
                        v-model="moduleForm.isPaid"
                        :items="paymentStatus"
                        prepend-icon="mdi-currency-usd-off"
                        :disabled="!IsCMSAdmin"
                        :rules="
                          IsCMSAdmin
                            ? [
                                v =>
                                  v !== null ||
                                  'Select a Payment Status'
                              ]
                            : []
                        "
                        dense
                        required
                      />
                    </v-row>
                  </div>
                  <div class="d-inline  mx-lg-auto">
                    <span class="pa-1 heading-payment"
                      >Visibility
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            class="pl-1"
                            color="#113247"
                            v-on="on"
                            >info</v-icon
                          >
                        </template>
                        <span
                          >Whether or not this module is visible
                          within the mobile app</span
                        >
                      </v-tooltip></span
                    >
                    <v-switch
                      v-model="moduleForm.isDisabled"
                      style="font-size:10px;padding-left: 10px;"
                      inset
                      color="green"
                      :disabled="!IsCMSAdmin"
                      :label="
                        `${
                          moduleForm.isDisabled ? 'Visible' : 'Hidden'
                        }`
                      "
                    />
                  </div>
                </v-row>
                <div>
                  <span class="pa-1 heading-payment">Notes</span>
                  <v-textarea
                    v-model="moduleForm.description"
                    :disabled="!IsCMSAdmin"
                    outlined
                    dense
                    style="padding:10px;background: #FFFFFF;padding-left: 4px;"
                  />
                </div>
                <div
                  v-if="!loading && IsCMSAdmin"
                  class="text-end pt-2 pr-2"
                  @click="updateModuleDetails()"
                >
                  <v-btn color="primary">
                    Save
                  </v-btn>
                </div>
              </div>
            </v-form>
          </v-card>
          <v-card outlined class="mb-5">
            <v-card-title class="d-flex justify-space-between">
              <h2>Learning objective</h2>
              <v-btn
                v-if="IsCMSAdmin"
                color="primary"
                rounded
                small
                @click="LoadCreateObjective"
                >CREATE OBJECTIVE</v-btn
              >
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="searchobjective"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    dense
                    hide-details
                    class="shrink"
                  />
                </v-col>
              </v-row>
              <v-data-table
                :items="learningobjectives"
                :headers="computedObjectiveheaders"
                :search="searchobjective"
                :no-data-text="`No objective available`"
                :hide-default-footer="
                  learningobjectivePaginationStatus()
                "
              >
                <template v-slot:[`item.id`]="{ item }">
                  <span>
                    {{ getIndex(item, learningobjectives) + 1 }}</span
                  >
                </template>
                <template v-slot:[`item.title`]="{ item }">
                  {{ item.title }}
                </template>
                <template v-slot:[`item.lessons`]="{ item }">
                  <span v-if="item.lessons">{{
                    item.lessons.length
                  }}</span>
                  <span v-else>0</span>
                </template>
                <template v-slot:[`item.isDisabled`]="{ item }">
                  <span v-if="item.isDisabled"> Hidden </span>
                  <span v-else>Visible</span>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <div class="lessons-actions-wrap">
                    <v-btn
                      color="blue"
                      icon
                      small
                      @click="LoadLearningObjective(item)"
                      ><v-icon title="Edit">mdi-pencil</v-icon></v-btn
                    >
                    <v-btn
                      v-if="item.isDisabled"
                      color="green"
                      icon
                      small
                      @click="
                        LoadConfirmDialogForDisableOrEnableObjective(
                          item,
                          false
                        )
                      "
                      ><v-icon title="Enable">mdi-eye</v-icon></v-btn
                    >
                    <v-btn
                      v-else
                      color="orange"
                      icon
                      small
                      @click="
                        LoadConfirmDialogForDisableOrEnableObjective(
                          item,
                          true
                        )
                      "
                      ><v-icon title="Disable"
                        >mdi-eye-off</v-icon
                      ></v-btn
                    >
                    <v-btn
                      v-if="hideDelete == 'false'"
                      color="red"
                      icon
                      small
                      @click="
                        LoadConfirmDialogForDeleteObjective(item)
                      "
                    >
                      <v-icon title="Delete"
                        >mdi-trash-can-outline</v-icon
                      ></v-btn
                    >
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <v-card outlined>
            <v-card-title class="d-flex justify-space-between">
              <h2>Lessons</h2>
              <v-btn
                v-if="IsCMSAdmin"
                color="primary"
                small
                rounded
                @click="LoadCreateLesson"
                >CREATE LESSON</v-btn
              >
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="searchlesson"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    dense
                    hide-details
                  />
                </v-col>
              </v-row>
              <v-data-table
                :items="orderedLessons"
                :headers="computedLessonheaders"
                :search="searchlesson"
                :no-data-text="`No lesson available`"
                :hide-default-footer="lessonPaginationStatus()"
              >
                <template
                  v-for="h in computedLessonheaders"
                  v-slot:[`header.${h.value}`]="{ header }"
                >
                  <v-tooltip
                    v-if="headerTooltips[h.value]"
                    :key="h.value"
                    bottom
                  >
                    <template v-slot:activator="{ on }">
                      <span v-on="on"
                        >{{ header.text
                        }}<v-icon class="pl-1" color="#113247"
                          >mdi-information</v-icon
                        ></span
                      >
                    </template>
                    <span>{{ headerTooltips[header.value] }}</span>
                  </v-tooltip>
                  <span v-else :key="h.value">{{ header.text }}</span>
                </template>
                <template v-slot:[`item.id`]="{ item }">
                  <span>
                    {{ getIndex(item, orderedLessons) + 1 }}</span
                  >
                </template>
                <template v-slot:[`item.title`]="{ item }">
                  <router-link
                    :to="`/lesson/${courseId}/${item.id}`"
                    class="blue--text"
                    >{{ item.title }}</router-link
                  >
                </template>
                <template
                  v-slot:[`item.learningobjective`]="{ item }"
                >
                  <span v-if="item.learningobjective">{{
                    item.learningobjective.title
                  }}</span>
                  <span v-else>-</span>
                </template>
                <template v-slot:[`item.isDisabled`]="{ item }">
                  <span v-if="item.isDisabled"> Hidden </span>
                  <span v-else>Visible</span>
                </template>
                <template v-slot:[`item.sort`]="{ item }">
                  <div class="lessons-actions-wrap">
                    <v-btn
                      icon
                      small
                      :disabled="getIndex(item, orderedLessons) < 1"
                      @click="
                        moveLesson(
                          getIndex(item, orderedLessons),
                          getIndex(item, orderedLessons) - 1
                        )
                      "
                      ><v-icon>mdi-arrow-up</v-icon></v-btn
                    >
                    <v-btn
                      icon
                      small
                      :disabled="
                        getIndex(item, orderedLessons) >
                          orderedLessons.length - 2
                      "
                      @click="
                        moveLesson(
                          getIndex(item, orderedLessons),
                          getIndex(item, orderedLessons) + 1
                        )
                      "
                      ><v-icon>mdi-arrow-down</v-icon></v-btn
                    >
                  </div>
                </template>
                <template v-slot:[`item.isPublished`]="{ item }">
                  <span v-if="item.isPublished"
                    ><v-icon color="#757575">mdi-file-table</v-icon>
                    Published
                  </span>
                  <span v-else
                    ><v-icon color="#757575"
                      >mdi-file-document-edit-outline</v-icon
                    >
                    Draft</span
                  >
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <div class="lessons-actions-wrap">
                    <!-- <router-link
                      :to="`/lesson/${courseId}/${item.id}`"
                      class="v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--small"
                    >
                      <v-icon class="blue--text">mdi-pencil</v-icon>
                    </router-link> -->
                    <v-btn
                      v-if="item.isDisabled"
                      icon
                      color="green"
                      small
                      @click="DisableOREnalbeLesson(item, false)"
                      ><v-icon title="Enable">mdi-eye</v-icon></v-btn
                    >
                    <v-btn
                      v-else
                      icon
                      color="orange"
                      small
                      @click="DisableOREnalbeLesson(item, true)"
                      ><v-icon title="Disable"
                        >mdi-eye-off</v-icon
                      ></v-btn
                    >
                    <v-btn
                      v-if="hideDelete == 'false'"
                      icon
                      color="red"
                      small
                      @click="LoadConfirmDialogForDeleteLesson(item)"
                    >
                      <v-icon title="Delete"
                        >mdi-trash-can-outline</v-icon
                      ></v-btn
                    >
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- dialogs -->
    <v-dialog v-model="showLBDialog" persistent max-width="400px">
      <v-card>
        <v-form
          ref="Objectiveform"
          v-model="validObjective"
          lazy-validation
        >
          <v-card-title class="headline"
            >Learning Objective</v-card-title
          >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="forms.createLearningObjective.title"
                    :counter="50"
                    :rules="nameRules"
                    label="Title*"
                    required
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small class="info--text"
              >*indicates required field</small
            >
            <v-spacer />
            <v-btn depressed text @click="showLBDialog = false">
              Cancel
            </v-btn>
            <v-btn
              v-if="forms.createLearningObjective.id > 0"
              color="primary"
              @click="updateLearningObjective"
            >
              Update
            </v-btn>
            <v-btn
              v-else
              color="primary"
              @click="validateLearningObjective"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showAddModuleToCourse"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-form
          ref="AddModuleToCourseform"
          v-model="validLesson"
          lazy-validation
        >
          <v-card-title class="headline"
            >Add module to course</v-card-title
          >
          <v-card-text>
            <v-container>
              <v-row>
                <v-autocomplete
                  v-model="forms.addModuleToCourse.id"
                  :items="primaryCourseList"
                  :rules="[v => !!v || 'Course is required']"
                  label="Select course*"
                  dense
                  required
                />
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              depressed
              text
              @click="showAddModuleToCourse = false"
            >
              Cancel
            </v-btn>
            <v-btn color="primary" @click="AddModuleToCourse">
              Add module
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Lesson -->

    <v-dialog v-model="showLessonDialog" persistent max-width="800px">
      <v-card>
        <v-form
          ref="Lessonform"
          v-model="validLesson"
          lazy-validation
        >
          <v-card-title class="headline">Lesson</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="forms.createLesson.title"
                    :counter="50"
                    :rules="nameRules"
                    label="Title*"
                    required
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="
                      forms.createLesson.createLearningObjective.id
                    "
                    :items="availableLearingObjectivesForCourse"
                    label="Learning Objective*"
                    required
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small class="info--text"
              >*Please ensure each mandatory field marked with an
              asterisk * is completed</small
            >
            <v-spacer />
            <v-btn depressed text @click="showLessonDialog = false">
              Cancel
            </v-btn>
            <v-btn
              v-if="forms.createLesson.id > 0"
              color="primary"
              @click="updateLearningObjective"
            >
              Update
            </v-btn>
            <v-btn v-else color="primary" @click="validateLesson">
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- alertconfirm -->
    <v-dialog v-model="confirmdialog.show" persistent max-width="450">
      <!-- <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Open Dialog
          </v-btn>
        </template> -->
      <v-card>
        <v-card-title class="headline">{{
          confirmdialog.HeaderText
        }}</v-card-title>
        <v-card-text>{{ confirmdialog.ContentText }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="confirmdialog.show = false"
            >Cancel</v-btn
          >
          <v-btn
            color="red darken-1"
            text
            @click="
              confirmdialog.ButtonConfirmAction(
                confirmdialog.customObject
              )
            "
            >{{ confirmdialog.ButtonConfirmText }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="coursevalidationalert"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-text>
          <br />
          <br />
          <br />
          <h4 v-if="!coursevalidationalertMsg">
            Intro header and Description are required fields
          </h4>
          <h4 v-else>
            {{ coursevalidationalertMsg }}
          </h4>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="coursevalidationalert = false"
            >Got it</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import AppContentListEditor from '@/components/editors/AppContentListEditor';
import AppContentHeaderEditor from '@/components/editors/AppContentHeaderEditor';
import Content from '@/components/content-edit';
import { mapGetters, mapActions } from 'vuex';
import CourseStoreTypes from '../../../store/modules/course/CourseStoreTypes';

const COURSE_QUERY = gql`
  query($courseId: ID!) {
    course(id: $courseId) {
      id
      title
      tileColour
      titleTextColour
      subTextColour
      progressFillColour
      isDisabled
      isPaid
      description
      primary_courses
      {
       id
       Title
       isDisabled
       DisabledCourses
       ModuleOrder
      }
      desc {
        __typename
        ${Content.names}
      }
      headerContent {
        __typename
        ${Content.names}
      }
      public
      lessons
      {
         id
         title
         isDisabled
         isPublished
         learningobjective
         {
          id
          title
          isDisabled
          lessons
          {
           id
          }
         }
      }
      lessonOrder
      learningobjectives {
        id
        title
        isDisabled
        lessons{
           id
          }
      }
    }
  }
  ${Content.strings}
`;
const PRIMARY_COURSE_QUERY = gql`
  query($courseId: ID!) {
    primaryCourse(id: $courseId) {
      id
      Title
    }
  }
`;
// const PRIMARY_COURSE_QUERY = gql`
//   query($courseId: ID!) {
//     primaryCourse(id: $courseId) {
//       id
//       Title
//     }
//   }
// `;

const UPDATE_COURSE_MUTATION = gql`
  mutation(
    $courseId: ID!
    $courseTitle: String!
    $tileColour: String!
    $titleTextColour: String!
    $progressFillColour:String!
    $subTextColour:String!
    $courseHeaderContent: [CourseHeaderContentDynamicZoneInput!]
    $courseDesc: [CourseDescDynamicZoneInput!]
    $coursePublic: Boolean!
  ) {
    updateCourse(
      input: {
        where: { id: $courseId }
        data: {
          title: $courseTitle
          tileColour: $tileColour
          titleTextColour: $titleTextColour
          subTextColour: $subTextColour
          progressFillColour: $progressFillColour
          headerContent: $courseHeaderContent
          desc: $courseDesc
          public: $coursePublic
        }
      }
    ) {
      course {
        id
      title
      tileColour
      titleTextColour
      isDisabled
      primary_courses
      {
       id
       Title
       isDisabled
       DisabledCourses
       ModuleOrder
      }
      desc {
        __typename
        ${Content.names}
      }
      headerContent {
        __typename
        ${Content.names}
      }
      public
        lessons
      {
         id
         title
         isDisabled
         learningobjective
         {
          id
          title
          isDisabled
          lessons
          {
           id
          }
         }
      }
      lessonOrder
      learningobjectives {
        id
        title
        isDisabled
        lessons{
           id
          }
      }
      }
    }
  }
  ${Content.strings}
`;

const UPDATE_COURSE_MUTATION_DA = gql`
  mutation(
    $courseId: ID!
    $isDisabled: Boolean!
    $isPaid: Boolean!
    $description: String
    $primary_courses: [ID]
  ) {
    updateCourse(
      input: {
        where: { id: $courseId }
        data: {
          isDisabled: $isDisabled
          isPaid: $isPaid
          description: $description
          primary_courses: $primary_courses
        }
      }
    ) {
      course {
        id
        title
      }
    }
  }
`;

// To populate lesson select
// const ALL_LESSONS_QUERY = gql`
//   query {
//     lessons {
//       id
//       title
//       isDisabled
//       learningobjective {
//         id
//         title
//         isDisabled
//         lessons {
//           id
//         }
//       }
//     }
//   }
// `;

// To make sure all lesson queries are consistent
const LESSON_PARTS = gql`
  fragment LessonParts on Lesson {
    id
    title
    isDisabled
    isPublished
    learningobjective {
      id
      title
      isDisabled
      lessons {
        id
      }
    }
  }
`;

const SET_LESSONS_MUTATION = gql`
  mutation($courseId: ID!, $lessonIds: [ID!]!) {
    updateCourse(
      input: {
        where: { id: $courseId }
        data: { lessons: $lessonIds }
      }
    ) {
      course {
        id
        lessonOrder
        lessons {
          ...LessonParts
        }
      }
    }
  }
  ${LESSON_PARTS}
`;

const DELETE_LESSON_MUTATION = gql`
  mutation($lessonId: ID!) {
    deleteLesson(input: { where: { id: $lessonId } }) {
      lesson {
        ...LessonParts
      }
    }
  }
  ${LESSON_PARTS}
`;

const CREATE_LESSON_MUTATION = gql`
  mutation(
    $courseId: ID!
    $lessonTitle: String!
    $learningobjectiveId: ID!
  ) {
    createLesson(
      input: {
        data: {
          title: $lessonTitle
          courses: [$courseId]
          learningobjective: $learningobjectiveId
        }
      }
    ) {
      lesson {
        ...LessonParts
      }
    }
  }
  ${LESSON_PARTS}
`;

const CREATE_LEARNING_OBJECTIVE_MUTATION = gql`
  mutation($courseId: ID!, $objectiveTitle: String!) {
    createLearningobjective(
      input: { data: { title: $objectiveTitle, course: $courseId } }
    ) {
      learningobjective {
        id
        title
        isDisabled
        lessons {
          id
        }
      }
    }
  }
`;

const UPDATE_LEARNING_OBJECTIVE_MUTATION = gql`
  mutation($objectiveId: ID!, $objectiveTitle: String!) {
    updateLearningobjective(
      input: {
        where: { id: $objectiveId }
        data: { title: $objectiveTitle }
      }
    ) {
      learningobjective {
        id
        title
        isDisabled
        lessons {
          id
        }
      }
    }
  }
`;

const DELETE_LEARNING_OBJECTIVE_MUTATION = gql`
  mutation($objectiveId: ID!) {
    deleteLearningobjective(input: { where: { id: $objectiveId } }) {
      learningobjective {
        id
        title
        isDisabled
        lessons {
          id
        }
      }
    }
  }
`;

const DISABLE_ENABLE_LEARNING_OBJECTIVE_MUTATION = gql`
  mutation($objectiveId: ID!, $isDisabled: Boolean) {
    disableOrEnableLearningObjectve(
      input: {
        where: { id: $objectiveId }
        data: { isDisabled: $isDisabled }
      }
    ) {
      learningobjective {
        id
        title
        isDisabled
        lessons {
          id
        }
      }
    }
  }
`;

const COURSE_ALL_LEARNIGN_OBJECTIVES_QUERY = gql`
  query($courseId: ID!) {
    learningobjectives(where: { course: $courseId }) {
      id
      title
      isDisabled
      lessons {
        id
      }
    }
  }
`;

const DISABLE_ENABLE_LESSON = gql`
  mutation($lessonId: ID!, $isDisabled: Boolean) {
    disableOrEnableLesson(
      input: {
        where: { id: $lessonId }
        data: { isDisabled: $isDisabled }
      }
    ) {
      lesson {
        ...LessonParts
      }
    }
  }
  ${LESSON_PARTS}
`;

const ALL_PRIMARY_COURSES_QUERY = gql`
  query {
    primaryCourses {
      id
      Title
      isDisabled
      DisabledCourses
      ModuleOrder
    }
  }
`;

const ADD_MODULE_TO_COURSE_MUTATION = gql`
  mutation($courseId: ID!, $primarycourses: [ID]) {
    updateCourse(
      input: {
        where: { id: $courseId }
        data: { primary_courses: $primarycourses }
      }
    ) {
      course {
        id
        title
        tileColour
        titleTextColour
        isDisabled
        primary_courses {
          id
          Title
          isDisabled
          DisabledCourses
          ModuleOrder
          courses {
            id
            lessons {
              id
            }
          }
        }
      }
    }
  }
`;

// const ENABLE_DISABLE_MODULE_FOR_PRIMARYCOURSE_MUTATION = gql`
//   mutation($courseId: ID!, $modules: JSON) {
//     updatePrimaryCourse(
//       input: {
//         where: { id: $courseId }
//         data: { DisabledCourses: $modules }
//       }
//     ) {
//       primaryCourse {
//         Title
//         id
//         isDisabled
//         tileColour
//         titleTextColour
//         DisabledCourses
//         ModuleOrder
//         courses {
//           id
//           title
//           isDisabled
//           lessons {
//             title
//           }
//         }
//       }
//     }
//   }
// `;

const UPDATE_MODULEORDER_PRIMARYCOURSE_MUTATION = gql`
  mutation($courseId: ID!, $moduleOrder: JSON) {
    updatePrimaryCourse(
      input: {
        where: { id: $courseId }
        data: { ModuleOrder: $moduleOrder }
      }
    ) {
      primaryCourse {
        Title
        id
        isDisabled
        tileColour
        titleTextColour
        DisabledCourses
        ModuleOrder
        courses {
          id
          title
          isDisabled
          lessons {
            id
          }
        }
      }
    }
  }
`;

const UPDATE_LESSON_ORDER_MUTATION = gql`
  mutation($courseId: ID!, $lessonorder: JSON) {
    updateCourse(
      input: {
        where: { id: $courseId }
        data: { lessonOrder: $lessonorder }
      }
    ) {
      course {
        id
        lessonOrder
        lessons {
          ...LessonParts
        }
      }
    }
  }
  ${LESSON_PARTS}
`;
export default {
  name: 'CourseDetail',
  components: {
    AppContentListEditor,
    AppContentHeaderEditor,
    ...Content.types
  },
  data: () => ({
    alert: {
      type: 'success',
      show: false,
      text: ''
    },
    loading: false,
    coursevalidationalert: false,
    coursevalidationalertMsg: null,
    validObjective: true,
    validCourse: true,
    validLesson: true,
    nameRules: [
      v => !!v || 'Title is required',
      v =>
        (v && v.length <= 50) ||
        'Title must be less than 50 characters'
    ],
    roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER'
    },
    confirmdialog: {
      show: false,
      HeaderText: '',
      ContentText: '',
      ButtonConfirmText: '',
      ButtonConfirmAction: '',
      customObject: null
    },
    textcolor: '',
    tilecolor: '',
    subtextColour: '',
    progressfillColour: '',
    mainCourseName: '',
    searchprimarycourse: '',
    searchobjective: '',
    searchlesson: '',
    showLBDialog: false,
    showAddModuleToCourse: false,
    showLessonDialog: false,
    paymentStatus: [
      { text: 'Paid', value: true },
      { text: 'Free', value: false }
    ],
    hideDelete: process.env.VUE_APP_HIDEDELETE_STATUS,
    primaryCourseHeaders: [
      { text: 'Title', value: 'Title' },
      // { text: 'Modules', value: 'courses' },
      // { text: 'Lessons', value: 'lessons' },
      // { text: 'Activities', value: 'activities' },
      { text: 'Course status', value: 'isDisabled' },
      { text: 'Module status', value: 'courses' },
      { text: 'Action', value: 'action', sortable: false }
    ],

    learningObjectiveheaders: [
      { text: 'ID', value: 'id', width: '1%' },
      { text: 'Title', value: 'title', width: '1%' },
      { text: 'Lessons', value: 'lessons', width: '1%' },
      { text: 'Status', value: 'isDisabled', width: '1%' },
      {
        text: 'Action',
        value: 'action',
        sortable: false,
        width: '1%'
      }
    ],
    lessonheaders: [
      { text: 'ID', value: 'id', width: '1%' },
      { text: 'Title', value: 'title', width: '1%' },
      { text: 'Objective', value: 'learningobjective', width: '1%' },
      { text: 'Status', value: 'isDisabled', width: '1%' },
      { text: 'Sort', value: 'sort', sortable: false, width: '1%' },
      {
        text: 'Publication Status',
        value: 'isPublished',
        width: '1%'
      },
      {
        text: 'Action',
        value: 'action',
        sortable: false,
        width: '1%'
      }
    ],
    headerTooltips: {
      isDisabled:
        'Whether or not this course is visible within the mobile app'
    },
    course: {
      title: ''
    },
    local: {
      title: '',
      description: '',
      headerContent: [],
      desc: [],
      public: false
    },
    forms: {
      addModuleToCourse: {
        id: 0
      },
      addLesson: {
        id: ''
      },
      createLesson: {
        id: 0,
        title: '',
        createLearningObjective: {}
      },
      createLearningObjective: {
        id: 0,
        title: ''
      }
    },
    moduleForm: {
      primary_courses: null,
      isDisabled: false,
      isPaid: false,
      description: null
    },
    lessons: [],
    learningobjectives: [],
    primarycourses: [],
    moduleId: 0,
    type: 'hex',
    hex: '#003349'
  }),
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const prevOrg =
        from.name === 'OrgDetail'
          ? from.path
          : from.name === 'CourseList'
          ? ''
          : vm.getOrgPath;
      vm.updateCourseFromOrg(prevOrg);
    });
  },
  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating'
    ]),
    ...mapGetters('course', ['getOrgPath']),
    IsDementiaAdmin() {
      if (
        this.getUser?.user?.role?.name === this.roles.DEMENTIA_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsCMSAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.CMS_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGViewer() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_VIEWER) {
        return true;
      } else {
        return false;
      }
    },
    courseId() {
      return this.$route.params.courseId;
    },
    orderedLessons() {
      if (!this.course.lessonOrder) {
        return this.course.lessons;
      }

      if (
        this.course.lessonOrder?.length != this.course.lessons?.length
      ) {
        return this.course.lessons;
      }
      // Create index of lessons
      let lessons = this.course.lessons.reduce((acc, i) => {
        acc[i.id] = i;
        return acc;
      }, {});

      // First add lessons with order
      let orderedLessons = [];
      this.course.lessonOrder.forEach(l => {
        if (lessons[l]) {
          orderedLessons.push(lessons[l]);
          delete lessons[l];
        }
      });

      // Then add unordered lessons
      orderedLessons.push(...Object.values(lessons));

      return orderedLessons;
    },

    computedPrimaryCourseheaders() {
      if (!this.IsCMSAdmin)
        return this.primaryCourseHeaders.filter(
          t => t.text != 'Action'
        );
      else return this.primaryCourseHeaders;
    },

    computedObjectiveheaders() {
      if (!this.IsCMSAdmin)
        return this.learningObjectiveheaders.filter(
          t => t.text != 'Action'
        );
      else return this.learningObjectiveheaders;
    },
    computedLessonheaders() {
      if (!this.IsCMSAdmin)
        return this.lessonheaders.filter(
          t => t.text != 'Action' && t.text != 'Sort'
        );
      else return this.lessonheaders;
    },
    // Lessons that aren't already in the Course
    // availableLessons() {
    //   return this.lessons
    //     .filter(i => !this.course?.lessons?.some(j => j.id == i.id))
    //     .map(i => ({ text: i.title, value: i.id }));
    // },
    availableLearingObjectivesForCourse() {
      return this.learningobjectives.map(i => ({
        text: i.title,
        value: i.id
      }));
    },
    breadcrumbs() {
      if (this.$route.params.courseListId) {
        let moduleName = this.course;
        return [
          {
            text: this.mainCourseName,
            href: `#/courses/${this.$route.params.courseListId}`
          },
          {
            text: moduleName.title,
            disabled: true
          }
        ];
      } else {
        return [{ text: 'Back', href: `#/modules` }];
      }
    },

    primaryCourseList() {
      return this.primaryCourses
        ?.filter(
          f => !this.primarycourses.some(item => item.id === f.id)
        )
        .map(mod => ({
          text: mod.Title,
          value: mod.id
        }));
    },
    primaryCourseListForModule() {
      return this.primaryCourses?.map(mod => ({
        text: mod.Title,
        value: mod.id
      }));
    }
  },
  methods: {
    ...mapActions('course', [
      CourseStoreTypes.Actions.UPDATE_COURSE_FROM_ORG
    ]),
    // getLessonsCount(courses) {
    //   var totallength = 0;
    //   if (!courses) return 0;

    //   courses.forEach(l => {
    //     if (l.lessons) totallength = totallength + l.lessons.length;
    //   });
    //   return totallength;
    // },
    // getActivitiesCount(courses) {
    //   var totallength = 0;
    //   courses.forEach(function(c) {
    //     c.lessons.forEach(function(l) {
    //       if (l.activities)
    //         totallength = totallength + l.activities.length;
    //     });
    //   });
    //   return totallength;
    // },
    learningobjectivePaginationStatus() {
      if (
        this.learningobjectives &&
        this.learningobjectives.length > 0
      )
        return false;
      else return true;
    },
    lessonPaginationStatus() {
      if (this.orderedLessons && this.orderedLessons.length > 0)
        return false;
      else return true;
    },
    primaryCoursePaginationStatus() {
      if (this.primarycourses && this.primarycourses.length > 0)
        return false;
      else return true;
    },
    getIndex(item, items) {
      if (!items) return 0;
      if (!item) return 0;
      return items
        .filter(o => o != 'undefined')
        .map(function(x) {
          if (x) return x.id;
        })
        .indexOf(item.id);
    },
    isModuleDisabledForCourse(item, courseid) {
      return item.DisabledCourses?.some(function(id) {
        return id === courseid;
      });
    },
    validateCourse() {
      //valdate
      this.coursevalidationalertMsg = null;
      if (!this.$refs.form.validate()) return;
      if (this.local.headerContent.length == 0) {
        this.coursevalidationalert = true;
        return;
      }
      if (this.local.desc.length == 0) {
        this.coursevalidationalert = true;
        return;
      }

      if (this.local.desc.length > 0) {
        let validStats = false;
        this.local.desc.forEach(val => {
          if (
            val.__typename == 'ComponentContentListHeading' &&
            val.text?.length > 250
          ) {
            validStats = true;
            return false;
          }
        });
        if (validStats) {
          this.coursevalidationalert = true;
          this.coursevalidationalertMsg =
            'Header text must be less than 250 characters';
          return;
        }
      }
      this.updateCourse();
    },
    updateCourse() {
      if (!this.$refs.form.validate()) return;

      this.$apollo.mutate({
        mutation: UPDATE_COURSE_MUTATION,
        variables: {
          courseId: this.courseId,
          courseTitle: this.local.title,
          tileColour: this.tilecolor,
          titleTextColour: this.textcolor,
          subTextColour: this.subtextColour,
          progressFillColour: this.progressfillColour,
          courseHeaderContent: this.local.headerContent,
          courseDesc: this.local.desc,
          coursePublic: this.local.public
        },
        update: (store, { data: { updateCourse } }) => {
          if (!updateCourse.course) return;
          this.$apollo.queries.course.refresh();
          // Get the current store state
          // const data = store.readQuery({
          //   query: COURSE_QUERY,
          //   variables: {
          //     courseId: this.courseId
          //   }
          // });
          // data.course = updateCourse.course;
          // // Write the list back into the cache
          // store.writeQuery({
          //   query: COURSE_QUERY,
          //   variables: {
          //     courseId: this.courseId
          //   },
          //   data
          // });
          this.alert.show = true;
          this.alert.text = 'Module Details Saved Successfully';
          this.alert.type = 'success';

          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        }
      });
    },
    updateModuleDetails() {
      if (!this.$refs.moduleform.validate()) return;

      this.$apollo.mutate({
        mutation: UPDATE_COURSE_MUTATION_DA,
        variables: {
          courseId: this.courseId,
          primary_courses: this.moduleForm.primary_courses,
          isDisabled: !this.moduleForm.isDisabled,
          isPaid: this.moduleForm.isPaid,
          description: this.moduleForm.description
        },
        update: () => {
          this.$apollo.queries.course.refresh();
          this.alert.show = true;
          this.alert.text = 'Module Details Saved Successfully';
          this.alert.type = 'success';

          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        }
      });
    },
    LoadAddModuleToCourse() {
      this.showAddModuleToCourse = true;
    },
    AddModuleToCourse() {
      //Add current module to the specified primarycourse
      if (!this.$refs.AddModuleToCourseform.validate()) return;
      //push current module id into assocated module in that primary course
      let primarycourseids = [];
      if (this.primarycourses && this.primarycourses.length > 0) {
        primarycourseids = this.primarycourses.map(c => c.id);
        primarycourseids.push(this.forms.addModuleToCourse.id);
      } else {
        primarycourseids.push(this.forms.addModuleToCourse.id);
      }
      this.updateModulePrimaryCourses(primarycourseids);

      //update primary course module order as well
      this.updateModuleOrderInPrimaryCourse(
        this.courseId,
        this.forms.addModuleToCourse.id,
        false
      );

      this.showAddModuleToCourse = false;
    },

    updateModulePrimaryCourses(primarycourseids) {
      this.$apollo.mutate({
        mutation: ADD_MODULE_TO_COURSE_MUTATION,
        variables: {
          courseId: this.courseId, //module id
          primarycourses: primarycourseids
        },
        update: (store, { data: { updateCourse } }) => {
          if (!updateCourse.course) return;
          // Get the current store state
          const data = store.readQuery({
            query: COURSE_QUERY,
            variables: {
              courseId: this.courseId
            }
          });
          data.primary_courses = updateCourse.course.primary_courses;
          // Write the list back into the cache

          store.writeQuery({
            query: COURSE_QUERY,
            variables: {
              courseId: this.courseId
            },
            data
          });
          this.alert.show = true;
          this.alert.text = 'Successfully Updated';
          this.alert.type = 'success';

          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        }
      });
    },

    updateModuleOrderInPrimaryCourse(
      moduleid,
      primarycourseid,
      isDelete
    ) {
      //Get module order of this primary course
      var moduleorder = [];

      moduleorder = this.primarycourses.filter(function(pcourse) {
        if (pcourse === primarycourseid) {
          return pcourse.ModuleOrder;
        }
      });
      if (isDelete) {
        //remove module from module order
        moduleorder = moduleorder.filter(function(moduleid) {
          if (moduleid != moduleid) {
            return moduleid;
          }
        });
      } else {
        moduleorder.push(moduleid);
      }

      this.$apollo.mutate({
        mutation: UPDATE_MODULEORDER_PRIMARYCOURSE_MUTATION,
        variables: {
          courseId: primarycourseid,
          moduleOrder: moduleorder
        },
        update: (store, { data: { updatePrimaryCourse } }) => {
          if (!updatePrimaryCourse.primaryCourse) return;
          // Get the current store state
        }
      });
    },

    LoadConfirmDialogForDeleteCourse(item) {
      this.confirmdialog.HeaderText = 'Are you sure?';
      this.confirmdialog.ContentText =
        'This will delete current module from the selected course';
      this.confirmdialog.ButtonConfirmText = "Yes I'm sure";
      this.confirmdialog.show = true;
      this.confirmdialog.customObject = item;
      this.confirmdialog.ButtonConfirmAction = this.DeleteModuleFromCourse;
    },
    DeleteModuleFromCourse(item) {
      //filter selectd primary course and update the state
      let primarycourseids = [];
      primarycourseids = this.primarycourses.map(c => c.id);
      var filteredprimarycourseids = primarycourseids.filter(function(
        courseid
      ) {
        if (courseid != item.id) {
          return courseid;
        }
      });

      this.updateModulePrimaryCourses(filteredprimarycourseids);
      //delete moduleid from module order
      this.updateModuleOrderInPrimaryCourse(
        this.courseId,
        item.id,
        true
      );
      this.confirmdialog.show = false;
    },

    LoadCreateObjective() {
      this.forms.createLearningObjective.id = 0;
      this.forms.createLearningObjective.title = '';
      this.showLBDialog = true;
    },
    validateLearningObjective() {
      if (!this.$refs.Objectiveform.validate()) return;
      this.createLearningObjective();
    },
    createLearningObjective() {
      if (!this.forms.createLearningObjective.title) return;
      this.$apollo.mutate({
        mutation: CREATE_LEARNING_OBJECTIVE_MUTATION,
        variables: {
          courseId: this.courseId,
          objectiveTitle: this.forms.createLearningObjective.title
        },
        update: (store, { data: { createLearningobjective } }) => {
          if (!createLearningobjective.learningobjective) return;
          // Get the current store state
          const data = store.readQuery({
            query: COURSE_QUERY,
            variables: {
              courseId: this.courseId
            }
          });
          data.course.learningobjectives.push(
            createLearningobjective.learningobjective
          );
          // Write the list back into the cache
          store.writeQuery({
            query: COURSE_QUERY,
            variables: {
              courseId: this.courseId
            },
            data
          });
          this.alert.show = true;
          this.alert.text = 'Lesson Objective Created Successfully';
          this.alert.type = 'success';

          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
          this.forms.createLearningObjective.title = '';
          this.showLBDialog = false;
        }
      });
    },
    LoadLearningObjective(item) {
      //id is populated for edit purpose
      this.forms.createLearningObjective.id = item.id;
      this.forms.createLearningObjective.title = item.title;
      this.showLBDialog = true;
    },
    updateLearningObjective() {
      if (!this.forms.createLearningObjective.id > 0) return;
      if (!this.forms.createLearningObjective.title) return;
      this.$apollo.mutate({
        mutation: UPDATE_LEARNING_OBJECTIVE_MUTATION,
        variables: {
          objectiveId: this.forms.createLearningObjective.id,
          objectiveTitle: this.forms.createLearningObjective.title
        },
        update: (store, { data: { updateLearningobjective } }) => {
          if (!updateLearningobjective.learningobjective) return;
          // Get the current store state
          const data = store.readQuery({
            query: COURSE_QUERY,
            variables: {
              courseId: this.courseId
            }
          });

          var learningobjective = data.course.learningobjectives.filter(
            function(learningobjective) {
              if (
                learningobjective.id ==
                updateLearningobjective.learningobjective.id
              ) {
                return learningobjective;
              }
            }
          );
          if (learningobjective.length > 0) {
            learningobjective[0] =
              updateLearningobjective.learningobjective;
          }

          // Write the list back into the cache
          store.writeQuery({
            query: COURSE_QUERY,
            variables: {
              courseId: this.courseId
            },
            data
          });
          this.alert.show = true;
          this.alert.text = 'Lesson Objective Upadated Successfully';
          this.alert.type = 'success';

          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
          this.forms.createLearningObjective.title = '';
          this.showLBDialog = false;
        }
      });
    },
    LoadConfirmDialogForDeleteObjective(item) {
      this.confirmdialog.HeaderText = 'Are you sure?';
      this.confirmdialog.ContentText =
        'Deleting a learning objective will remove all lessons and activities';
      this.confirmdialog.ButtonConfirmText = "Yes I'm sure";
      this.confirmdialog.show = true;
      this.confirmdialog.customObject = item;
      this.confirmdialog.ButtonConfirmAction = this.DeleteLearningObjective;
    },
    LoadConfirmDialogForDisableOrEnableObjective(item, status) {
      this.confirmdialog.HeaderText = 'Are you sure?';
      this.confirmdialog.ContentText = status
        ? `You have changed the Learning Objective ${item.title} from visible to hidden.
       All associated lessons and activities will also be made invisible`
        : `You have changed the Learning Objective ${item.title} from hidden to visible.
       All associated lessons and activities will also be made visible`;
      this.confirmdialog.ButtonConfirmText = "Yes I'm sure";
      this.confirmdialog.show = true;
      this.confirmdialog.customObject = { item, status };
      this.confirmdialog.ButtonConfirmAction = this.DisableOREnalbeLearningObjective;
    },
    DeleteLearningObjective(item) {
      if (!item) return;
      this.$apollo.mutate({
        mutation: DELETE_LEARNING_OBJECTIVE_MUTATION,
        variables: {
          objectiveId: item.id
        },
        update: (store, { data: { deleteLearningobjective } }) => {
          if (!deleteLearningobjective.learningobjective) return;
          // Get the current store state
          const data = store.readQuery({
            query: COURSE_QUERY,
            variables: {
              courseId: this.courseId
            }
          });

          if (item.lessons && item.lessons.length > 0) {
            //remove all lesson under this learning objective
            var lessonstoremove = item.lessons.map(item => item.id);
            if (lessonstoremove && lessonstoremove.length > 0)
              var filteredlessons = data.course.lessons.filter(
                x => !lessonstoremove.includes(x.id)
              );
            data.course.lessons = filteredlessons;
          }
          // remove objectives using filter
          var filteredlearningobjectives = data.course.learningobjectives.filter(
            function(learningobjective) {
              if (learningobjective.id != item.id) {
                return learningobjective;
              }
            }
          );
          data.course.learningobjectives = filteredlearningobjectives;
          // Write the list back into the cache
          store.writeQuery({
            query: COURSE_QUERY,
            variables: {
              courseId: this.courseId
            },
            data
          });
          this.alert.show = true;
          this.alert.text = 'Lesson Objective deleted Successfully';
          this.alert.type = 'success';

          setTimeout(() => {
            this.alert.show = false;
          }, 3000);

          this.confirmdialog.show = false;
        }
      });
    },
    DisableOREnalbeLearningObjective({ item, status }) {
      if (!item) return;
      this.$apollo.mutate({
        mutation: DISABLE_ENABLE_LEARNING_OBJECTIVE_MUTATION,
        variables: {
          objectiveId: item.id,
          isDisabled: status
        },
        update: (
          store,
          { data: { disableOrEnableLearningObjectve } }
        ) => {
          if (!disableOrEnableLearningObjectve.learningobjective)
            return;
          // Get the current store state
          const data = store.readQuery({
            query: COURSE_QUERY,
            variables: {
              courseId: this.courseId
            }
          });

          var learningobjective = data.course.learningobjectives.filter(
            function(learningobjective) {
              if (learningobjective.id == item.id) {
                return learningobjective;
              }
            }
          );
          if (learningobjective.length > 0) {
            learningobjective[0].isDisabled =
              disableOrEnableLearningObjectve.learningobjective.isDisabled;
          }
          //disable module in the store as well
          data.course.lessons.filter(function(lesson) {
            if (
              lesson.learningobjective?.id ===
              disableOrEnableLearningObjectve.learningobjective?.id
            ) {
              lesson.isDisabled = status;
            }
          });

          // Write the list back into the cache
          store.writeQuery({
            query: COURSE_QUERY,
            variables: {
              courseId: this.courseId
            },
            data
          });
          this.alert.show = true;
          this.alert.text = 'Action was Successfull';
          this.alert.type = 'success';

          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
          this.confirmdialog.show = false;
        }
      });
    },
    LoadCreateLesson() {
      this.showLessonDialog = true;
      this.forms.createLesson.id = 0;
      this.forms.createLesson.title = '';
      this.forms.createLesson.createLearningObjective.id = -1;
      this.forms.createLesson.createLearningObjective.title = '';
    },
    //DisableOREnableLesson TODO MUTATION
    // DisableOREnableLesson(item, status) {
    //   if (!item) return;
    //   this.$apollo.mutate({
    //     mutation: DISABLE_ENABLE_LEARNING_OBJECTIVE_MUTATION,
    //     variables: {
    //       objectiveId: item.id,
    //       isDisabled: status
    //     },
    //     update: (
    //       store,
    //       { data: { disableOrEnableLearningObjectve } }
    //     ) => {
    //       if (!disableOrEnableLearningObjectve.learningobjective)
    //         return;
    //       // Get the current store state
    //       const data = store.readQuery({
    //         query: COURSE_QUERY,
    //         variables: {
    //           courseId: this.courseId
    //         }
    //       });

    //       var learningobjective = data.course.learningobjectives.filter(
    //         function(learningobjective) {
    //           if (learningobjective.id == item.id) {
    //             return learningobjective;
    //           }
    //         }
    //       );
    //       if (learningobjective.length > 0) {
    //         learningobjective[0].isDisabled =
    //           disableOrEnableLearningObjectve.learningobjective.isDisabled;
    //       }

    //       // Write the list back into the cache
    //       store.writeQuery({
    //         query: COURSE_QUERY,
    //         variables: {
    //           courseId: this.courseId
    //         },
    //         data
    //       });
    //       this.alert.show = true;
    //       this.alert.text = 'Success';
    //       this.alert.type = 'success';

    //       setTimeout(() => {
    //         this.alert.show = false;
    //       }, 3000);
    //     }
    //   });
    // },
    validateLesson() {
      if (!this.$refs.Lessonform.validate()) return;
      this.createLesson();
    },
    createLesson() {
      if (!this.forms.createLesson.title) return;
      if (this.forms.createLesson.createLearningObjective.id === -1)
        return;
      // todo: remove from lessonOrder
      this.$apollo.mutate({
        mutation: CREATE_LESSON_MUTATION,
        variables: {
          courseId: this.courseId,
          lessonTitle: this.forms.createLesson.title,
          learningobjectiveId: this.forms.createLesson
            .createLearningObjective.id
        },
        update: (store, { data: { createLesson } }) => {
          if (!createLesson.lesson) return;
          // Get the current store state
          const data = store.readQuery({
            query: COURSE_QUERY,
            variables: {
              courseId: this.courseId
            }
          });
          data.course.lessons.push(createLesson.lesson);
          // Write the list back into the cache

          store.writeQuery({
            query: COURSE_QUERY,
            variables: {
              courseId: this.courseId
            },
            data
          });
          //update lesson order in module
          this.updateLessonOrder(createLesson.lesson.id, false);

          this.alert.show = true;
          this.alert.text = 'Lesson updated Successfully';
          this.alert.type = 'success';

          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
          this.forms.createLesson.title = '';
          this.forms.createLesson.id = 0;
          this.forms.createLesson.createLearningObjective.id = 0;
          this.showLessonDialog = false;
        }
      });
    },
    updateLessonOrder(lessonid, isDelete) {
      if (isDelete) {
        this.course.lessonOrder = this.course.lessonOrder.filter(
          lessonid => lessonid != lessonid
        );
      } else {
        if (
          this.course.lessonOrder &&
          this.course.lessonOrder.length > 0
        ) {
          this.course.lessonOrder.push(lessonid);
        } else {
          this.course.lessonOrder = [lessonid];
        }
      }
      this.$apollo.mutate({
        mutation: UPDATE_LESSON_ORDER_MUTATION,
        variables: {
          courseId: this.courseId,
          lessonorder: this.course.lessonOrder
        },
        update: (store, { data: { updateCourse } }) => {
          if (!updateCourse.course) return;
          // Get the current store state
          const data = store.readQuery({
            query: COURSE_QUERY,
            variables: {
              courseId: this.courseId
            }
          });
          data.course.lessonOrder = updateCourse.course.lessonOrder;
          // Write the list back into the cache
          store.writeQuery({
            query: COURSE_QUERY,
            variables: {
              courseId: this.courseId
            },
            data
          });
        }
      });
    },

    addLesson() {
      // todo: remove from lessonOrder
      if (this.forms.addLesson.id === null) return;

      // Create unique list of lessons
      const lessonIds = new Set([
        ...this.course.lessons.map(item => item.id),
        this.forms.addLesson.id
      ]);

      this.$apollo.mutate({
        mutation: SET_LESSONS_MUTATION,
        variables: {
          courseId: this.courseId,
          lessonIds: [...lessonIds]
        }
      });
    },
    moveLesson(from, to) {
      const newOrder = this.orderedLessons.map(l => l.id);
      const [item] = newOrder.splice(from, 1);
      newOrder.splice(to, 0, item);

      this.$apollo.mutate({
        mutation: SET_LESSONS_MUTATION,
        variables: {
          courseId: this.courseId,
          lessonIds: newOrder
        }
      });
    },
    LoadConfirmDialogForDeleteLesson(item) {
      this.confirmdialog.HeaderText = 'Are you sure?';
      this.confirmdialog.ContentText =
        'Deleting a lesson will remove all activities and quizzes';
      this.confirmdialog.ButtonConfirmText = "Yes I'm sure";
      this.confirmdialog.show = true;
      this.confirmdialog.customObject = item;
      this.confirmdialog.ButtonConfirmAction = this.DeleteLesson;
    },
    DeleteLesson(item) {
      if (!item) return;
      const lessonId = parseInt(item.id);
      // const lessonIds = this.course.lessons
      //   .map(item => item.id)
      //   .filter(item => item != lessonId);

      this.$apollo.mutate({
        mutation: DELETE_LESSON_MUTATION,
        variables: {
          lessonId: lessonId
        },
        update: (store, { data: { deleteLesson } }) => {
          if (!deleteLesson.lesson) return;
          // Get the current store state
          const data = store.readQuery({
            query: COURSE_QUERY,
            variables: {
              courseId: this.courseId
            }
          });

          var filteredlessons = data.course.lessons.filter(function(
            lesson
          ) {
            if (lesson.id != lessonId) {
              return lesson;
            }
          });
          if (filteredlessons.length > 0) {
            data.course.lessons = filteredlessons;
          }

          // Write the list back into the cache
          store.writeQuery({
            query: COURSE_QUERY,
            variables: {
              courseId: this.courseId
            },
            data
          });
          //Dual call need to remove
          //update lesson order in module
          this.updateLessonOrder(lessonId, true);
          this.alert.show = true;
          this.alert.text = 'Deleted Successfully';
          this.alert.type = 'success';

          setTimeout(() => {
            this.alert.show = false;
          }, 3000);

          this.confirmdialog.show = false;
        }
      });
    },
    DisableOREnalbeLesson(item, status) {
      if (!item) return;
      this.$apollo.mutate({
        mutation: DISABLE_ENABLE_LESSON,
        variables: {
          lessonId: item.id,
          isDisabled: status
        },
        update: (store, { data: { disableOrEnableLesson } }) => {
          if (!disableOrEnableLesson.lesson) return;
          // Get the current store state
          const data = store.readQuery({
            query: COURSE_QUERY,
            variables: {
              courseId: this.courseId
            }
          });

          var lesson = data.course.lessons.filter(function(l) {
            if (l.id == item.id) {
              return l;
            }
          });
          if (lesson.length > 0) {
            lesson[0].isDisabled =
              disableOrEnableLesson.lesson.isDisabled;
          }

          // Write the list back into the cache
          store.writeQuery({
            query: COURSE_QUERY,
            variables: {
              courseId: this.courseId
            },
            data
          });
          this.alert.show = true;
          this.alert.text = 'Updated Successfully';
          this.alert.type = 'success';

          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        }
      });
    }
    // enableOrDisableModuleForCourse(item, status, courseId) {
    //   var disabledmodulelist = item.DisabledCourses;
    //   if (status === true) {
    //     //disable the moduel for course so include in the disabled list array
    //     if (disabledmodulelist && disabledmodulelist.length > 0) {
    //       disabledmodulelist.push(courseId);
    //     } else {
    //       disabledmodulelist = [courseId];
    //     }
    //   } else {
    //     //enable the module for course so pop moduleid from disabled list
    //     if (disabledmodulelist && disabledmodulelist.length > 0) {
    //       disabledmodulelist = disabledmodulelist.filter(function(
    //         id
    //       ) {
    //         if (id != courseId) {
    //           return id;
    //         }
    //       });
    //     }
    //   }

    //   this.$apollo.mutate({
    //     mutation: ENABLE_DISABLE_MODULE_FOR_PRIMARYCOURSE_MUTATION,
    //     variables: {
    //       courseId: item.id,
    //       modules: disabledmodulelist
    //     },
    //     update: (store, { data: { updatePrimaryCourse } }) => {
    //       if (!updatePrimaryCourse.primaryCourse) return;
    //       // Get the current store state
    //       const data = store.readQuery({
    //         query: COURSE_QUERY,
    //         variables: {
    //           courseId: courseId
    //         }
    //       });
    //       var primarycourse = data.course.primary_courses.filter(
    //         function(pc) {
    //           if (pc.id == item.id) {
    //             return pc;
    //           }
    //         }
    //       );
    //       if (primarycourse) {
    //         primarycourse.DisabledCourses =
    //           updatePrimaryCourse.primaryCourse.DisabledCourses;
    //       }
    //       // Write the list back into the cache
    //       store.writeQuery({
    //         query: COURSE_QUERY,
    //         variables: {
    //           courseId: courseId
    //         },
    //         data
    //       });
    //       this.alert.show = true;
    //       this.alert.text = 'Success';
    //       this.alert.type = 'success';

    //       setTimeout(() => {
    //         this.alert.show = false;
    //       }, 3000);
    //     }
    //   });
    // }
  },
  apollo: {
    course() {
      this.loading = true;
      return {
        query: COURSE_QUERY,
        variables: {
          courseId: this.$route.params.courseId
        },
        fetchPolicy: 'network-only',
        update: data => {
          this.local.title = data.course.title || '';
          this.loading = false;
          if (data.course.headerContent) {
            this.local.headerContent = [...data.course.headerContent];
          } else {
            this.local.headerContent = [];
          }
          if (data.course.desc) {
            this.local.desc = [...data.course.desc];
          } else {
            this.local.desc = [];
          }

          this.local.public = data.course.public || false;
          this.tilecolor = data.course.tileColour || '#F4F6F8';
          this.textcolor = data.course.titleTextColour || '#002744';
          this.subtextColour = data.course.subTextColour || '#A6A6BD';
          this.progressfillColour =
            data.course.progressFillColour || '#2CD5C4';
          this.learningobjectives = data.course.learningobjectives;
          this.primarycourses = data.course.primary_courses;
          this.moduleId = data.course.id;
          this.moduleForm.isDisabled = !data.course.isDisabled;
          this.moduleForm.isPaid = data.course.isPaid;
          this.moduleForm.description = data.course.description;
          if (data.course.primary_courses[0]?.id) {
            this.moduleForm.primary_courses =
              data.course.primary_courses[0].id;
          }
          return data.course;
        },
        error() {
          this.loading = false;
        }
      };
    },
    mainCourse() {
      return {
        query: PRIMARY_COURSE_QUERY,
        variables: {
          courseId: this.$route.params.courseListId
        },
        fetchPolicy: 'network-only',
        update: data => {
          this.mainCourseName = data.primaryCourse.Title;
          return data.primaryCourse;
        },
        skip() {
          return !this.$route.params.courseListId;
        }
      };
    },
    // primaryCourse: {
    //   query: PRIMARY_COURSE_QUERY,
    //   variables: {
    //     courseId: this.forms?.addModuleToCourse?.id
    //   },
    //   loadingKey: 'loading',
    //   update(data) {
    //     return data.primaryCourse;
    //   },
    //   result(data) {
    //     console.log(JSON.stringify(data));
    //   }
    // },
    primaryCourses() {
      return {
        query: ALL_PRIMARY_COURSES_QUERY
      };
    },

    // lessons() {
    //   return {
    //     query: ALL_LESSONS_QUERY
    //   };
    // },
    learningobjective() {
      return {
        query: COURSE_ALL_LEARNIGN_OBJECTIVES_QUERY,
        variables: {
          courseId: this.courseId
        },
        update: data => data.learningobjective
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  display: flex;
  align-items: center;
  color: #000000;
}
.details_header {
  padding-left: 15px;
  margin-bottom: 15px;
}
.details_sub_header {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  display: flex;
  align-items: center;
}
.heading-payment {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  display: flex;
  align-items: center;
}
</style>
