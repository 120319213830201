<template>
  <div>
    <v-container>
      <v-row>
        <v-col sm="1">
          <h3 class="font-weight-black">
            {{ index }}
          </h3>
        </v-col>
        <v-col>
          <h2 class="indigo--text">Image Choice</h2>
          <h3>Question</h3>
          <app-rich-text-editor v-model="local.quiz.question" />
          <h3 class="mt-6 pb-1">Answers</h3>
          <input
            v-if="IsCMSAdmin"
            class="pb-1"
            color="primary"
            type="file"
            accept="image/*"
            @change="uploadImage($event)"
          />
          <table class="mb-6" style="border-spacing: 0;">
            <thead>
              <tr>
                <th></th>
                <th style="text-align: left; width: 50%;">Preview</th>
                <th
                  style="text-align: left;white-space: nowrap;"
                  class="px-2"
                >
                  Correct Answer
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(answer, indexs) in local.quiz.answers"
                :key="answer.id"
              >
                <td class="pr-4 pb-6 pt-4">{{ indexs + 1 }}</td>
                <td>
                  <img
                    class="image"
                    width="200"
                    height="200"
                    :src="answer.image"
                  />
                </td>
                <td style="text-align: center;">
                  <input v-model="answer.correct" type="checkbox" />
                </td>
                <td>
                  <v-btn
                    icon
                    color="primary"
                    @click="deleteAnswer(indexs)"
                    ><v-icon>mdi-minus-circle</v-icon></v-btn
                  >
                </td>
              </tr>
            </tbody>
            <tbody style="background-color: rgba(0,0,0,0.05);">
              <!-- <tr>
          <td></td>
          <td>
            <v-text-field v-model="forms.newAnswer.image" />
          </td>
          <td>
            <v-text-field v-model="forms.newAnswer.alt" />
          </td>
          <td style="text-align: center;">
            <input
              v-model="forms.newAnswer.correct"
              type="checkbox"
            />
          </td>
          <td><v-btn @click="addAnswer">Add</v-btn></td>
        </tr> -->
            </tbody>
          </table>
          <div class="mb-3">
            <h3 class="mt-6">Incorrect Answer Text</h3>
            <v-text-field
              v-model="local.quiz.incorrectAnswerText"
              outlined
              dense
              hide-details
              class="shrink "
            />
          </div>
          <v-btn
            v-if="IsCMSAdmin"
            color="primary"
            x-small
            title="Remove the current quiz"
            @click="$emit('removethiscomponent', local.quiz)"
            >Remove</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import AppRichTextEditor from '@/components/editors/AppRichTextEditor';
import clone from 'rfdc';
import { mapGetters } from 'vuex';
import { Constant } from '../../config/constants';

const cloner = clone();

// Quiz structure gets accessed by Activity component to
// query quiz which uses component name to access so fragment
// name *must* be component name. Component name is dynamically
// accessed by <component :is="__typename"> so must be Strapi
// component name
const QUERY_FRAGMENT = gql`
  fragment ComponentQuizImageChoice on ComponentQuizImageChoice {
    id
    question
    incorrectAnswerText
    answers {
      __typename
      ... on ComponentQuizAnswerImageAnswer {
        id
        image
        alt
        correct
        key
      }
    }
  }
`;

export default {
  name: 'ComponentQuizImageChoice',
  icon: 'mdi-check-box-multiple-outline',
  gqlQueryFragment: QUERY_FRAGMENT,
  text: 'Image Choice',
  components: {
    AppRichTextEditor
  },
  props: {
    quiz: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER'
    },
    local: {
      // * Quiz structure needs to match the GrqphQL structure
      // * Gets passed to update mutation without alteration
      quiz: {}
    },
    isUploading: false,
    forms: {
      newAnswer: {
        image: '',
        alt: '',
        correct: false,
        key: '',
        __typename: ''
      }
    }
  }),
  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating'
    ]),
    IsDementiaAdmin() {
      if (
        this.getUser?.user?.role?.name === this.roles.DEMENTIA_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsCMSAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.CMS_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGViewer() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_VIEWER) {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {
    this.$set(this.local, 'quiz', cloner(this.quiz));
  },
  methods: {
    addAnswer() {
      this.local.quiz.answers.push({
        image: this.forms.newAnswer.image,
        alt: this.forms.newAnswer.alt,
        correct: this.forms.newAnswer.correct
      });
      this.forms.newAnswer.image = '';
      this.forms.newAnswer.alt = '';
      this.forms.newAnswer.correct = false;
    },
    deleteAnswer(index) {
      this.local.quiz.answers.splice(index, 1);
    },
    uploadImage(event) {
      if (!event.target.files[0]) return;
      const uploadUrl =
        process.env.VUE_APP_BASE_API + '/media/upload/image';
      let data = new FormData();
      var tempObject = {};
      data.append('files', event.target.files[0]);
      const headerOptions = {
        Authorization:
          'Bearer ' + localStorage.getItem(Constant.AUTH_TOKEN)
      };
      fetch(uploadUrl, {
        method: 'POST',
        body: data,
        headers: headerOptions
      })
        .then(res => res.json())
        .then(res => {
          tempObject.image = res.presignedUrl;
          tempObject.alt = 'imagechoice';
          tempObject.key = res.key;
          tempObject.correct = false;
          tempObject.__typename = 'ComponentQuizAnswerImageAnswer';
          this.local.quiz.answers.push(tempObject);
          console.log(JSON.stringify(this.local.quiz.answers));
        })
        .catch(err => {
          // todo: handle server failure
          console.log(err);
        })
        .finally(() => {
          this.isUploading = false;
        });
    }
  }
};
</script>
