var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex justify-center pb-1" },
        [
          _c(
            "v-alert",
            {
              staticClass: "invite-learner v-alert",
              attrs: {
                dense: "",
                text: "",
                type: _vm.alertType,
                value: _vm.alert,
                transition: "slide-y-transition"
              }
            },
            [_vm._v(_vm._s(_vm.alertText.text))]
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "730" },
          model: {
            value: _vm.alertBox,
            callback: function($$v) {
              _vm.alertBox = $$v
            },
            expression: "alertBox"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.alertBoxType))
              ]),
              _vm.summaryCount.employeeIDNotRequiredItemsCount > 0
                ? _c("v-card-text", { staticClass: "black--text pb-0" }, [
                    _vm._v(
                      " Employee ID is not required for this organization. "
                    )
                  ])
                : _vm._e(),
              _c(
                "v-card-text",
                [
                  _vm.summaryFailedList.length > 0
                    ? _c("OrgLearnersErrorSummary", {
                        attrs: {
                          summaryFailedList: _vm.summaryFailedList,
                          summaryCount: _vm.summaryCount
                        }
                      })
                    : _vm._e(),
                  _vm.summaryCount.successCount > 0 &&
                  _vm.uploadTotalRecords === _vm.summaryCount.successCount &&
                  _vm.summaryFailedList.length === 0
                    ? _c(
                        "v-card-text",
                        {
                          staticClass:
                            "black--text pa-0 font-weight-medium pt-3"
                        },
                        [
                          _c("span", { staticClass: "green--text" }, [
                            _vm._v(_vm._s(_vm.summaryCount.successCount))
                          ]),
                          _vm._v(" learner accounts were successfully created.")
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.clearAlert }
                    },
                    [_vm._v(" Close ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "grey lighten-5", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "mr-auto", attrs: { cols: "auto" } },
                [
                  _c("v-breadcrumbs", {
                    staticClass: "pa-0",
                    attrs: { items: _vm.breadcrumbs }
                  }),
                  _c("h1", [
                    _vm._v(" Profile: "),
                    _c("span", { staticClass: "success--text" }, [
                      _vm._v(_vm._s(_vm.org.name))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-tabs",
        {
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        _vm._l(_vm.computedtabItems, function(tab) {
          return _c(
            "v-tab",
            {
              key: tab.id,
              on: {
                click: function($event) {
                  return _vm.setActiveTab(tab.id)
                }
              }
            },
            [
              tab.text == "Subscriptions"
                ? _c("v-img", {
                    staticClass: "mr-2",
                    attrs: {
                      "max-height": "24",
                      "max-width": "24",
                      src: require("../../images/SubscriptionIconTab.png")
                    }
                  })
                : _c("v-icon", { staticClass: "mr-2" }, [
                    _vm._v(_vm._s(tab.icon))
                  ]),
              _vm._v(" " + _vm._s(tab.text) + " ")
            ],
            1
          )
        }),
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        _vm._l(_vm.computedtabItems, function(tab) {
          return _c("v-tab-item", { key: tab.id }, [
            tab.text == "Subscriptions"
              ? _c(
                  "div",
                  [
                    _c("subscriptions-list", {
                      attrs: { "org-name": _vm.org.name },
                      on: { alertObj: _vm.getAlertObj }
                    })
                  ],
                  1
                )
              : _vm._e(),
            tab.text == "Details"
              ? _c(
                  "div",
                  [
                    _c(
                      "v-card",
                      { attrs: { color: "basil", flat: "" } },
                      [
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "div",
                              { staticClass: "page-sub-sec" },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-chip",
                                      {
                                        staticClass: "ma-2",
                                        attrs: { color: "primary" }
                                      },
                                      [
                                        _vm._v(
                                          " Total Viewers: " +
                                            _vm._s(_vm.local.org.noofviewers) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-chip",
                                      {
                                        staticClass: "ma-2",
                                        attrs: { color: "primary" }
                                      },
                                      [
                                        _vm._v(
                                          " Total Admins: " +
                                            _vm._s(_vm.local.org.noofadmins) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm.IsDementiaAdmin
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "warning" },
                                        on: { click: _vm.checkLicenseUpdate }
                                      },
                                      [
                                        _c("v-icon", { staticClass: "mr-2" }, [
                                          _vm._v("mdi-update")
                                        ]),
                                        _vm._v(" Update")
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "v-container",
                              { attrs: { fluid: "" } },
                              [
                                _c(
                                  "v-form",
                                  {
                                    ref: "orgform",
                                    refInFor: true,
                                    attrs: { "lazy-validation": "" },
                                    model: {
                                      value: _vm.valid,
                                      callback: function($$v) {
                                        _vm.valid = $$v
                                      },
                                      expression: "valid"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "4" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                rules: _vm.nameRules,
                                                counter: 50,
                                                label: "Name*",
                                                required: "",
                                                disabled: _vm.IsORGAdmin
                                                  ? true
                                                  : false
                                              },
                                              model: {
                                                value: _vm.local.org.newOrgName,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.local.org,
                                                    "newOrgName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "local.org.newOrgName"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "4" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Address*",
                                                rules: [
                                                  function(v) {
                                                    return (
                                                      !!v ||
                                                      "Address is required"
                                                    )
                                                  }
                                                ],
                                                required: "",
                                                disabled: _vm.IsORGAdmin
                                                  ? true
                                                  : false
                                              },
                                              model: {
                                                value: _vm.local.org.orgAddress,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.local.org,
                                                    "orgAddress",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "local.org.orgAddress"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "4" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Website*",
                                                required: "",
                                                rules: [
                                                  function(v) {
                                                    return (
                                                      !!v ||
                                                      "Domain is required"
                                                    )
                                                  }
                                                ],
                                                disabled: _vm.IsORGAdmin
                                                  ? true
                                                  : false
                                              },
                                              model: {
                                                value: _vm.local.org.orgDomain,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.local.org,
                                                    "orgDomain",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "local.org.orgDomain"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "4" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Organisation Code*",
                                                rules: [
                                                  function(v) {
                                                    return (
                                                      !!v ||
                                                      "Organisation Code is required"
                                                    )
                                                  }
                                                ],
                                                required: "",
                                                disabled: ""
                                              },
                                              model: {
                                                value: _vm.local.org.code,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.local.org,
                                                    "code",
                                                    $$v
                                                  )
                                                },
                                                expression: "local.org.code"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "4" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Country*",
                                                rules: [
                                                  function(v) {
                                                    return (
                                                      !!v ||
                                                      "Country is required"
                                                    )
                                                  }
                                                ],
                                                required: "",
                                                disabled: _vm.IsORGAdmin
                                                  ? true
                                                  : false
                                              },
                                              model: {
                                                value: _vm.local.org.orgCountry,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.local.org,
                                                    "orgCountry",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "local.org.orgCountry"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pt-1",
                                            attrs: { cols: "12", sm: "4" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "caption" },
                                              [_vm._v("Employee ID Type*")]
                                            ),
                                            _c("v-select", {
                                              staticClass: "pt-0",
                                              attrs: {
                                                items: _vm.EmployeeIDTypes,
                                                "item-value": "value",
                                                "item-text": "label",
                                                label: "Select",
                                                "persistent-hint": "",
                                                disabled: !_vm.IsDementiaAdmin,
                                                "single-line": "",
                                                rules: [
                                                  function(v) {
                                                    return (
                                                      !!v ||
                                                      "Employee ID Type is required"
                                                    )
                                                  }
                                                ]
                                              },
                                              model: {
                                                value: _vm.EmployeeIDType,
                                                callback: function($$v) {
                                                  _vm.EmployeeIDType = $$v
                                                },
                                                expression: "EmployeeIDType"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm.EmployeeIDType === "AlphaNumeric" ||
                                        _vm.EmployeeIDType === "EmailAddress"
                                          ? _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "4" }
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label:
                                                      "Mobile Help Message*",
                                                    disabled: !_vm.IsDementiaAdmin,
                                                    rules: [
                                                      function(v) {
                                                        return (
                                                          !!v ||
                                                          "Mobile help message is required"
                                                        )
                                                      }
                                                    ],
                                                    required: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.MobileHelpMessage,
                                                    callback: function($$v) {
                                                      _vm.MobileHelpMessage = $$v
                                                    },
                                                    expression:
                                                      "MobileHelpMessage"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            tab.text == "Courses"
              ? _c(
                  "div",
                  [
                    _c(
                      "v-card",
                      { attrs: { color: "basil", flat: "" } },
                      [
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "div",
                              { staticClass: "page-sub-sec" },
                              [
                                _c("v-text-field", {
                                  staticClass: "shrink",
                                  attrs: {
                                    "append-icon": "mdi-magnify",
                                    label: "Search",
                                    outlined: "",
                                    "hide-details": "",
                                    dense: ""
                                  },
                                  model: {
                                    value: _vm.courseSearch,
                                    callback: function($$v) {
                                      _vm.courseSearch = $$v
                                    },
                                    expression: "courseSearch"
                                  }
                                }),
                                _vm.IsDementiaAdmin
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "success" },
                                        on: {
                                          click: function($event) {
                                            _vm.showNewDialog = true
                                          }
                                        }
                                      },
                                      [_vm._v("Add Course")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c("v-data-table", {
                              attrs: {
                                items: _vm.orderedCourses,
                                headers: _vm.computedCourseHeaders,
                                search: _vm.courseSearch,
                                "no-data-text": "No course available",
                                "hide-default-footer": _vm.coursePaginationStatus()
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item.Title",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm.IsDementiaAdmin
                                          ? _c(
                                              "span",
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "blue--text",
                                                    attrs: {
                                                      to: "/courses/" + item.id
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(item.Title))]
                                                )
                                              ],
                                              1
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                " " + _vm._s(item.Title) + " "
                                              )
                                            ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.courses",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        item.courses
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(item.courses.length)
                                              )
                                            ])
                                          : _c("span", [_vm._v("0")])
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.isDisabled",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm.getIsCourseBlockedByORG(item)
                                          ? _c(
                                              "div",
                                              { staticClass: "warning--text" },
                                              [_vm._v(" Inactive ")]
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "success--text" },
                                              [_vm._v(" Active ")]
                                            )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.sort",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: "",
                                              small: "",
                                              disabled:
                                                _vm.getCourseIndex(item) < 1
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.moveCourse(
                                                  _vm.getCourseIndex(item),
                                                  _vm.getCourseIndex(item) - 1
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-arrow-up")
                                            ])
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: "",
                                              small: "",
                                              disabled:
                                                _vm.getCourseIndex(item) >
                                                _vm.orderedCourses.length - 2
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.moveCourse(
                                                  _vm.getCourseIndex(item),
                                                  _vm.getCourseIndex(item) + 1
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-arrow-down")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.action",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "lessons-actions-wrap"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                style: item.isDisabled
                                                  ? "cursor: pointer"
                                                  : "",
                                                attrs: {
                                                  title: item.isDisabled
                                                    ? "Disabled on all organisations"
                                                    : ""
                                                }
                                              },
                                              [
                                                _vm.getIsCourseBlockedByORG(
                                                  item
                                                )
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          color: "green",
                                                          small: "",
                                                          disabled:
                                                            item.isDisabled
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.enableOrDisableCourse(
                                                              item,
                                                              true,
                                                              _vm.org.id
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              title: "Enable"
                                                            }
                                                          },
                                                          [_vm._v("mdi-eye")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          color: "orange",
                                                          small: "",
                                                          disabled:
                                                            item.isDisabled
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.enableOrDisableCourse(
                                                              item,
                                                              false,
                                                              _vm.org.id
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              title: "Disable"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-eye-off"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                              ],
                                              1
                                            ),
                                            _vm.hideDelete == "false"
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "float-right",
                                                    attrs: {
                                                      icon: "",
                                                      color: "red",
                                                      small: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.LoadConfirmDialogForRemoveCourse(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          title: "Delete"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-trash-can-outline"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            tab.text == "Learners"
              ? _c(
                  "div",
                  [
                    _c(
                      "v-card",
                      { attrs: { color: "basil", flat: "" } },
                      [
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "div",
                              { staticClass: "page-sub-sec" },
                              [
                                _c("v-text-field", {
                                  staticClass: "shrink",
                                  attrs: {
                                    "append-icon": "mdi-magnify",
                                    label: "Search",
                                    dense: "",
                                    outlined: "",
                                    "hide-details": ""
                                  },
                                  on: { input: _vm.learnersData },
                                  model: {
                                    value: _vm.learnerSearch,
                                    callback: function($$v) {
                                      _vm.learnerSearch = $$v
                                    },
                                    expression: "learnerSearch"
                                  }
                                }),
                                _vm.IsDementiaAdmin || _vm.IsORGAdmin
                                  ? _c(
                                      "div",
                                      { staticClass: "d-flex float-right" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            class:
                                              _vm.IsDementiaAdmin ||
                                              _vm.IsORGAdmin
                                                ? "mr-5"
                                                : "",
                                            attrs: {
                                              color: "success",
                                              loading: _vm.learnerReportloader,
                                              disabled: !_vm.isShowLearnersReport
                                            },
                                            on: {
                                              click: _vm.generateLearnersReport
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-file-excel")
                                            ]),
                                            _vm._v("Export Learner Report")
                                          ],
                                          1
                                        ),
                                        _vm.IsDementiaAdmin || _vm.IsORGAdmin
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "mr-5",
                                                attrs: {
                                                  href:
                                                    _vm.defaultTemplateForLearnerUpload,
                                                  color: "success"
                                                }
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-download")
                                                ]),
                                                _vm._v("Template")
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.IsDementiaAdmin || _vm.IsORGAdmin
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "mr-0",
                                                attrs: { color: "success" },
                                                on: {
                                                  click: function($event) {
                                                    _vm.showNewDialogForUpload = true
                                                  }
                                                }
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-file-upload")
                                                ]),
                                                _vm._v(" Upload Learners")
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c("v-data-table", {
                              attrs: {
                                items: _vm.learners,
                                headers: _vm.computedLearnerHeaders,
                                loading: _vm.loadingLearner,
                                search: _vm.learnerSearch,
                                "no-data-text": "No learner available",
                                "hide-default-footer":
                                  _vm.totalLearners < 20 ? true : false,
                                "page-count": _vm.pageSize,
                                page: _vm.page,
                                options: _vm.options,
                                "server-items-length": _vm.totalLearners,
                                "footer-props": {
                                  "items-per-page-options": [
                                    20,
                                    30,
                                    40,
                                    50,
                                    100,
                                    500
                                  ]
                                }
                              },
                              on: {
                                "update:options": function($event) {
                                  _vm.options = $event
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item.EmployeeID",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(
                                          " " + _vm._s(item.EmployeeID) + " "
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.name",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "blue--text",
                                            attrs: {
                                              to:
                                                "/organisations/Learners/" +
                                                _vm.org.id +
                                                "/" +
                                                item.user_id
                                            }
                                          },
                                          [_vm._v(_vm._s(item.name))]
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.email",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(" " + _vm._s(item.email) + " ")
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.completed",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.completed) +
                                              "/" +
                                              _vm._s(item.total)
                                          )
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.action",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "lessons-actions-wrap"
                                          },
                                          [
                                            item.blocked
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      color: "green",
                                                      small: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.enableOrDisablelearner(
                                                          item.user_id,
                                                          false
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          title: "Enable"
                                                        }
                                                      },
                                                      [_vm._v("mdi-eye")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      color: "orange",
                                                      small: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.enableOrDisablelearner(
                                                          item.user_id,
                                                          true
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          title: "Disable"
                                                        }
                                                      },
                                                      [_vm._v("mdi-eye-off")]
                                                    )
                                                  ],
                                                  1
                                                ),
                                            _vm.hideDelete == "false"
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "float-right",
                                                    attrs: {
                                                      icon: "",
                                                      color: "red",
                                                      small: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.LoadConfirmDialogForRemoveLearner(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          title: "Delete"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-trash-can-outline"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            tab.text == "Administrators"
              ? _c(
                  "div",
                  [
                    _c(
                      "v-card",
                      { attrs: { color: "basil", flat: "" } },
                      [
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "div",
                              { staticClass: "page-sub-sec" },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "justify-end mr-2" },
                                  [
                                    _vm.IsDementiaAdmin || _vm.IsORGAdmin
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: { color: "success" },
                                                on: {
                                                  click: function($event) {
                                                    _vm.showNewDialogForAdmin = true
                                                  }
                                                }
                                              },
                                              [_vm._v("Invite Admin")]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            ),
                            _c("v-data-table", {
                              attrs: {
                                items: _vm.org.admins,
                                headers: _vm.adminHeaders,
                                "no-data-text": "No admin available",
                                "hide-default-footer": _vm.adminPaginationStatus()
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item.username",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "blue--text",
                                            attrs: {
                                              to:
                                                "/organisations/Learners/" +
                                                _vm.org.id +
                                                "/" +
                                                item.id
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                (item.firstName
                                                  ? item.firstName
                                                  : "") +
                                                  " " +
                                                  (item.lastName
                                                    ? item.lastName
                                                    : "")
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.email",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(" " + _vm._s(item.email) + " ")
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.mobile",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(" " + _vm._s(item.mobile) + " ")
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            tab.text == "Viewers"
              ? _c(
                  "div",
                  [
                    _c(
                      "v-card",
                      { attrs: { color: "basil", flat: "" } },
                      [
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "div",
                              { staticClass: "page-sub-sec" },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "justify-end mr-2" },
                                  [
                                    _vm.IsDementiaAdmin || _vm.IsORGAdmin
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: { color: "success" },
                                                on: {
                                                  click: function($event) {
                                                    _vm.showNewDialogForViewers = true
                                                  }
                                                }
                                              },
                                              [_vm._v("Invite Viewers")]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            ),
                            _c("v-data-table", {
                              attrs: {
                                items: _vm.org.viewers,
                                headers: _vm.adminHeaders,
                                "no-data-text": "No viewers available",
                                "hide-default-footer": _vm.viewerPaginationStatus()
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item.username",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "blue--text",
                                            attrs: {
                                              to:
                                                "/organisations/Learners/" +
                                                _vm.org.id +
                                                "/" +
                                                item.id
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                (item.firstName
                                                  ? item.firstName
                                                  : "") +
                                                  " " +
                                                  (item.lastName
                                                    ? item.lastName
                                                    : "")
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.email",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(" " + _vm._s(item.email) + " ")
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.mobile",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(" " + _vm._s(item.mobile) + " ")
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        }),
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.showNewDialog,
            callback: function($$v) {
              _vm.showNewDialog = $$v
            },
            expression: "showNewDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _vm.slectedTab == 1
                ? _c("v-card-title", { staticClass: "headline" }, [
                    _vm._v("Add Course")
                  ])
                : _vm._e(),
              _vm.slectedTab == 2
                ? _c("v-card-title", { staticClass: "headline" }, [
                    _vm._v("Invite Learner")
                  ])
                : _vm._e(),
              _vm.slectedTab == 1
                ? _c(
                    "v-form",
                    { staticClass: "pa-6" },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.availableCourses,
                          label: "Select From Available Course"
                        },
                        model: {
                          value: _vm.forms.newCourseId,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "newCourseId", $$v)
                          },
                          expression: "forms.newCourseId"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.slectedTab == 2
                ? _c(
                    "v-form",
                    { ref: "form", staticClass: "pa-6" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Email*",
                          rules: [
                            function(v) {
                              return !!v || "E-mail is required"
                            },
                            function(v) {
                              return (
                                /.+@.+\..+/.test(v) || "E-mail must be valid"
                              )
                            }
                          ],
                          required: ""
                        },
                        model: {
                          value: _vm.newLearner.email,
                          callback: function($$v) {
                            _vm.$set(_vm.newLearner, "email", $$v)
                          },
                          expression: "newLearner.email"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          rules: [
                            function(v) {
                              return !!v || "First name is required"
                            }
                          ],
                          label: "First name*",
                          required: ""
                        },
                        model: {
                          value: _vm.newLearner.firstName,
                          callback: function($$v) {
                            _vm.$set(_vm.newLearner, "firstName", $$v)
                          },
                          expression: "newLearner.firstName"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: { label: "Last name" },
                        model: {
                          value: _vm.newLearner.lastName,
                          callback: function($$v) {
                            _vm.$set(_vm.newLearner, "lastName", $$v)
                          },
                          expression: "newLearner.lastName"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-card-actions",
                [
                  _c("small", { staticClass: "info--text" }, [
                    _vm._v("*indicates required field")
                  ]),
                  _c("v-spacer"),
                  _vm.slectedTab == 2
                    ? _c(
                        "v-btn",
                        {
                          attrs: { depressed: "", text: "" },
                          on: { click: _vm.cancelInviteLearner }
                        },
                        [_vm._v(" Cancel ")]
                      )
                    : _c(
                        "v-btn",
                        {
                          attrs: { depressed: "", text: "" },
                          on: {
                            click: function($event) {
                              _vm.showNewDialog = false
                            }
                          }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                  _vm.slectedTab == 1
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.addCourse }
                        },
                        [_vm._v(" Add Course ")]
                      )
                    : _vm._e(),
                  _vm.slectedTab == 2
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.addLearner }
                        },
                        [_vm._v(" Add Learner ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.showNewDialogForAdmin,
            callback: function($$v) {
              _vm.showNewDialogForAdmin = $$v
            },
            expression: "showNewDialogForAdmin"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v("Invite Admin")
                    ])
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Email*",
                          rules: [
                            function(v) {
                              return !!v || "E-mail is required"
                            },
                            function(v) {
                              return (
                                /.+@.+\..+/.test(v) || "E-mail must be valid"
                              )
                            }
                          ],
                          required: ""
                        },
                        model: {
                          value: _vm.newAdmin.newEmail,
                          callback: function($$v) {
                            _vm.$set(_vm.newAdmin, "newEmail", $$v)
                          },
                          expression: "newAdmin.newEmail"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          rules: [
                            function(v) {
                              return !!v || "First name is required"
                            }
                          ],
                          label: "First name*",
                          required: ""
                        },
                        model: {
                          value: _vm.newAdmin.newFirstName,
                          callback: function($$v) {
                            _vm.$set(_vm.newAdmin, "newFirstName", $$v)
                          },
                          expression: "newAdmin.newFirstName"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: { label: "Last name" },
                        model: {
                          value: _vm.newAdmin.newLastName,
                          callback: function($$v) {
                            _vm.$set(_vm.newAdmin, "newLastName", $$v)
                          },
                          expression: "newAdmin.newLastName"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.newAdmin.newMobileNum
                            ? [
                                function(v) {
                                  return (
                                    /^\d+$/.test(v) ||
                                    "Number is not a valid mobile number"
                                  )
                                }
                              ]
                            : [],
                          label: "Mobile Number"
                        },
                        model: {
                          value: _vm.newAdmin.newMobileNum,
                          callback: function($$v) {
                            _vm.$set(_vm.newAdmin, "newMobileNum", $$v)
                          },
                          expression: "newAdmin.newMobileNum"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("small", { staticClass: "info--text" }, [
                        _vm._v(
                          "*Please ensure each mandatory field marked with an asterisk * is completed"
                        )
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "", text: "" },
                          on: { click: _vm.cancelInviteAdmin }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.createNewUser }
                        },
                        [_vm._v(" Invite ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.showNewDialogForViewers,
            callback: function($$v) {
              _vm.showNewDialogForViewers = $$v
            },
            expression: "showNewDialogForViewers"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v("Invite Viewers")
                    ])
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Email*",
                          rules: [
                            function(v) {
                              return !!v || "E-mail is required"
                            },
                            function(v) {
                              return (
                                /.+@.+\..+/.test(v) || "E-mail must be valid"
                              )
                            }
                          ],
                          required: ""
                        },
                        model: {
                          value: _vm.newViewer.newEmail,
                          callback: function($$v) {
                            _vm.$set(_vm.newViewer, "newEmail", $$v)
                          },
                          expression: "newViewer.newEmail"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          rules: [
                            function(v) {
                              return !!v || "First name is required"
                            }
                          ],
                          label: "First name*",
                          required: ""
                        },
                        model: {
                          value: _vm.newViewer.newFirstName,
                          callback: function($$v) {
                            _vm.$set(_vm.newViewer, "newFirstName", $$v)
                          },
                          expression: "newViewer.newFirstName"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: { label: "Last name" },
                        model: {
                          value: _vm.newViewer.newLastName,
                          callback: function($$v) {
                            _vm.$set(_vm.newViewer, "newLastName", $$v)
                          },
                          expression: "newViewer.newLastName"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.newViewer.newMobileNum
                            ? [
                                function(v) {
                                  return (
                                    /^\d+$/.test(v) ||
                                    "Number is not a valid mobile number"
                                  )
                                }
                              ]
                            : [],
                          label: "Mobile Number"
                        },
                        model: {
                          value: _vm.newViewer.newMobileNum,
                          callback: function($$v) {
                            _vm.$set(_vm.newViewer, "newMobileNum", $$v)
                          },
                          expression: "newViewer.newMobileNum"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("small", { staticClass: "info--text" }, [
                        _vm._v(
                          "*Please ensure each mandatory field marked with an asterisk * is completed"
                        )
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "", text: "" },
                          on: { click: _vm.cancelInviteViewer }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.createViewer }
                        },
                        [_vm._v(" Invite ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.showNewDialogForUpload,
            callback: function($$v) {
              _vm.showNewDialogForUpload = $$v
            },
            expression: "showNewDialogForUpload"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c("br"),
              _c("v-form", { ref: "form" }, [
                _c("input", {
                  ref: "file",
                  staticClass: "mr-5",
                  attrs: {
                    id: "inputExcelUpload",
                    accept: ".csv",
                    color: "primary",
                    type: "file"
                  }
                })
              ]),
              _c("br"),
              _vm._v(" " + _vm._s(_vm.uploadSuccessMessage) + " "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { depressed: "", color: "primary", text: "" },
                      on: { click: _vm.cancelUpload }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.loading,
                        disabled: _vm.loading,
                        color: "primary"
                      },
                      on: { click: _vm.uploadExcel }
                    },
                    [
                      _vm._v(" Upload New Learners "),
                      _c("v-icon", { attrs: { right: "", dark: "" } }, [
                        _vm._v(" mdi-cloud-upload ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "450" },
          model: {
            value: _vm.licenseconfirmdialog,
            callback: function($$v) {
              _vm.licenseconfirmdialog = $$v
            },
            expression: "licenseconfirmdialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Update Organisation license's")
              ]),
              _c("v-card-text", [
                _vm._v("Please confirm the change in number of licenses")
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.licenseconfirmdialog = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: { click: _vm.updateOrg }
                    },
                    [_vm._v("Confirm")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "450" },
          model: {
            value: _vm.editCourseDuration,
            callback: function($$v) {
              _vm.editCourseDuration = $$v
            },
            expression: "editCourseDuration"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "d-flex justify-space-between" },
                [_c("h2", [_vm._v(" Course Refresh Duration ")])]
              ),
              _c("v-slider", {
                staticClass: "mt-7 ml-5 mr-5",
                attrs: {
                  "thumb-color": "blue",
                  min: "30",
                  max: "400",
                  "thumb-label": "always"
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function() {
                      return [
                        _c("v-text-field", {
                          staticClass: "mt-0 pt-0",
                          staticStyle: { width: "60px" },
                          attrs: { min: "30", max: "10000", type: "number" },
                          model: {
                            value: _vm.courseRefresh,
                            callback: function($$v) {
                              _vm.courseRefresh = $$v
                            },
                            expression: "courseRefresh"
                          }
                        }),
                        _vm._v("Days ")
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.courseRefresh,
                  callback: function($$v) {
                    _vm.courseRefresh = $$v
                  },
                  expression: "courseRefresh"
                }
              }),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-5",
                      attrs: { color: "primary", small: "", rounded: "" },
                      on: {
                        click: function($event) {
                          _vm.editCourseDuration = false
                        }
                      }
                    },
                    [_vm._v("CANCEL")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-5",
                      attrs: { color: "primary", small: "", rounded: "" },
                      on: { click: _vm.setCourseDuration }
                    },
                    [_vm._v("SAVE")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-center pt-2" },
        [
          _vm.org.learners
            ? _c(
                "v-alert",
                {
                  attrs: {
                    value: _vm.dialoglicensealert,
                    prominent: "",
                    border: "top",
                    transition: "scale-transition",
                    "max-width": "600"
                  }
                },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { staticClass: "grow" }, [
                        _vm._v(
                          " There is a problem with you reducing the licence count as your organisation currently has " +
                            _vm._s(_vm.org.learners.length) +
                            " allocated to learners "
                        )
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "shrink" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.dialoglicensealert = false
                                }
                              }
                            },
                            [_vm._v(" OK ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "450" },
          model: {
            value: _vm.confirmdialog.show,
            callback: function($$v) {
              _vm.$set(_vm.confirmdialog, "show", $$v)
            },
            expression: "confirmdialog.show"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.confirmdialog.HeaderText))
              ]),
              _c("v-card-text", [
                _vm._v(_vm._s(_vm.confirmdialog.ContentText))
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmdialog.show = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.confirmdialog.ButtonConfirmAction(
                            _vm.confirmdialog.customObject
                          )
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.confirmdialog.ButtonConfirmText))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }