var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "black--text" },
    [
      _vm.summaryCount.employeeIDErrorCount > 0
        ? _c(
            "v-card-text",
            { staticClass: "pa-0 pb-3" },
            [
              _c("v-card-text", { staticClass: "pa-0 font-weight-bold" }, [
                _vm._v(" Missing Employee ID: ")
              ]),
              _vm._v(
                " The learners listed below had incorrect employee IDs provided. Most issues result in the learner account being created without an employee ID. Some issues prevent the learner account from being created altogether. "
              )
            ],
            1
          )
        : _c("v-card-text", { staticClass: "pa-0 pb-3" }, [
            _vm._v(
              "Some issues prevent the learner account from being created altogether."
            )
          ]),
      _vm.summaryCount.employeeIDErrorCount > 0
        ? _c("v-card-text", { staticClass: "pa-0 pb-3" }, [
            _vm._v(
              " Employee IDs may be updated individually via User Management. Alternatively, learners without an employee ID will be prompted to input this data the next time they access the Ask Annie mobile app. "
            )
          ])
        : _vm._e(),
      _vm.summaryCount.successCount > 0
        ? _c("v-card-text", { staticClass: "pa-0 font-weight-medium" }, [
            _c("span", { staticClass: "green--text" }, [
              _vm._v(_vm._s(_vm.summaryCount.successCount))
            ]),
            _vm._v(" learner accounts were successfully created. ")
          ])
        : _vm._e(),
      _vm.summaryCount.accountNotCreatedCount > 0
        ? _c("v-card-text", { staticClass: "pa-0 font-weight-medium pb-3" }, [
            _c("span", { staticClass: "red--text" }, [
              _vm._v(_vm._s(_vm.summaryCount.accountNotCreatedCount))
            ]),
            _vm._v(" learner accounts were not created. ")
          ])
        : _vm._e(),
      _c("v-simple-table", {
        attrs: { "fixed-header": "", height: "300px" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "text-left" }, [
                      _vm._v(" Learner Name ")
                    ]),
                    _c("th", { staticClass: "text-left" }, [
                      _vm._v(" Learner Email ")
                    ]),
                    _c("th", { staticClass: "text-left" }, [
                      _vm._v(" Issue Type ")
                    ]),
                    _c("th", { staticClass: "text-left" }, [
                      _vm._v(" Account Created ")
                    ])
                  ])
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.summaryFailedList, function(item, index) {
                    return _c(
                      "tr",
                      {
                        key: index,
                        staticClass: "error-list-b",
                        class: index % 2 ? "table-row-bg" : ""
                      },
                      [
                        _c("td", [
                          item.data && item.data.name
                            ? _c("span", [_vm._v(_vm._s(item.data.name))])
                            : _vm._e()
                        ]),
                        _c("td", [
                          item.data && item.data.email
                            ? _c("span", [_vm._v(_vm._s(item.data.email))])
                            : _vm._e()
                        ]),
                        _c("td", [_vm._v(_vm._s(item.message))]),
                        _c("td", [_vm._v(_vm._s(item.accountCreated))])
                      ]
                    )
                  }),
                  0
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }