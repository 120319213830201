import { render, staticRenderFns } from "./CourseDetail.vue?vue&type=template&id=20b36094&scoped=true&"
import script from "./CourseDetail.vue?vue&type=script&lang=js&"
export * from "./CourseDetail.vue?vue&type=script&lang=js&"
import style0 from "./CourseDetail.vue?vue&type=style&index=0&id=20b36094&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20b36094",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VAutocomplete,VBreadcrumbs,VBtn,VCard,VCardActions,VCardText,VCardTitle,VCol,VColorPicker,VContainer,VDataTable,VDialog,VForm,VIcon,VListItemContent,VListItemTitle,VProgressLinear,VRow,VSpacer,VSwitch,VTextField,VTextarea,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/var/app/current/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('20b36094')) {
      api.createRecord('20b36094', component.options)
    } else {
      api.reload('20b36094', component.options)
    }
    module.hot.accept("./CourseDetail.vue?vue&type=template&id=20b36094&scoped=true&", function () {
      api.rerender('20b36094', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Course/Modules/CourseDetail.vue"
export default component.exports