// Pass through model changes to parent component
// - For compound components that split an object up into
//   sub-components
// - Must have "value" prop as per v-model attribute
// - As it is an arrow function "this" will be where it is called
export const Emit = (attrName, defaults) => ({
  get() {
    return this.value[attrName];
  },
  set(value) {
    // Create new object, staring with defaults
    let newValue = Object.assign({ ...defaults }, this.value);
    newValue[attrName] = value;
    this.$emit('input', newValue);
  }
});
