<template>
  <div>
    <div class="d-flex justify-center">
      <v-alert
        dense
        text
        class="v-alert"
        :type="alertType"
        :value="alert"
        transition="slide-y-transition"
        >{{ alertText.text }}</v-alert
      >
    </div>

    <v-container fluid class="grey lighten-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <h1>User Management</h1>
        </v-col>
      </v-row>
    </v-container>
    <v-tabs v-model="tab">
      <v-tab
        v-for="tab in computedtabItems"
        :key="tab.id"
        @click="setActiveTab(tab.id)"
      >
        <v-icon class="mr-2">{{ tab.icon }}</v-icon>
        {{ tab.text }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="tab in computedtabItems" :key="tab.id">
        <div v-if="tab.text == 'Public Learners' && IsDementiaAdmin">
          <PublicLearners />
        </div>
        <div v-if="tab.text == 'Enterprise Learners'">
          <EnterpriseLearners />
        </div>
        <div v-if="tab.text == 'Admin Users'">
          <EnterpriseUsers @alertObj="getAlertObj" />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';
import { Constant } from '../../config/constants';
import PublicLearners from './UserManagement/PublicLearners';
import EnterpriseLearners from './UserManagement/EnterpriseLearners';
import EnterpriseUsers from './UserManagement/EnterpriseUsers';
const ROLE_QUERY = gql`
  query {
    roles {
      id
      name
    }
  }
`;

export default {
  name: 'UserList',
  components: {
    PublicLearners,
    EnterpriseLearners,
    EnterpriseUsers
  },
  data: () => ({
    system_roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER'
    },
    orgId: 0,
    alert: false,
    alertText: { text: '' },
    alertType: 'success',
    tab: null,
    selectedTab: 0,
    tabItems: [
      { id: 0, text: 'Public Learners', icon: 'mdi-account' },
      {
        id: 1,
        text: 'Enterprise Learners',
        icon: 'mdi-card-account-details'
      },
      {
        id: 2,
        text: 'Admin Users',
        icon: 'mdi-card-account-details'
      }
    ],
    error: null,
    allRoles: [],
    allOrgs: []
  }),
  apollo: {
    roles: {
      query: ROLE_QUERY,
      skip() {
        return !this.isAuthenticated;
      }
    }
  },
  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating'
    ]),

    computedtabItems() {
      if (this.IsORGViewer || this.IsORGAdmin)
        return this.tabItems.filter(t => t.text != 'Public Learners');
      else return this.tabItems;
    },

    IsDementiaAdmin() {
      if (
        this.getUser?.user?.role?.name ===
        this.system_roles.DEMENTIA_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsOrglevelRole() {
      const organizationRequiredRoles = [
        this.system_roles.ORG_ADMIN,
        this.system_roles.ORG_VIEWER,
        'LEARNER'
      ];
      if (
        this.forms.newRoleName &&
        organizationRequiredRoles.includes(this.forms.newRoleName)
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsCMSAdmin() {
      if (
        this.getUser?.user?.role?.name === this.system_roles.CMS_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsORGAdmin() {
      if (
        this.getUser?.user?.role?.name === this.system_roles.ORG_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsORGViewer() {
      if (
        this.getUser?.user?.role?.name ===
        this.system_roles.ORG_VIEWER
      ) {
        return true;
      } else {
        return false;
      }
    },

    getRolesByRole() {
      if (!this.roles) return [];
      if (
        this.getUser?.user?.role?.name === this.system_roles.ORG_ADMIN
      ) {
        var adminroles = this.roles
          .filter(function(r) {
            //return r.id != 1 && r.id != 2 && r.id != 3 && r.id != 6;
            return (
              r.name != Constant.roles.AUTHENTICATED &&
              r.name != Constant.roles.PUBLIC &&
              r.name != Constant.roles.DEMENTIA_ADMIN &&
              r.name != Constant.roles.CMS_ADMIN
            );
          })
          .map(item => ({
            ...item,
            text: this.getRoleDisplayName(item.name)
          }));
        return adminroles;
      } else if (
        this.getUser?.user?.role?.name ===
        this.system_roles.DEMENTIA_ADMIN
      ) {
        var dadminroles = this.roles
          .filter(function(r) {
            //return r.id != 1 && r.id != 2;
            return (
              r.name != Constant.roles.AUTHENTICATED &&
              r.name != Constant.roles.PUBLIC
            );
          })
          .map(item => {
            // let rolText = item.name;
            // if (rolText === Constant.roles.DEMENTIA_ADMIN)
            //   rolText = Constant.roles.DEMENTIA_ADMIN_VIEW;
            return {
              ...item,
              text: this.getRoleDisplayName(item.name)
            };
          });
        return dadminroles;
      } else return [];
    },

    getUsersByRole() {
      if (!this.users) return [];
      if (
        this.getUser?.user?.role?.name ===
          this.system_roles.ORG_ADMIN ||
        this.getUser?.user?.role?.name ===
          this.system_roles.ORG_VIEWER
      ) {
        const orgId = this.getUser?.user?.memberOf[0].id.toString();
        var orgusers = this.users.filter(function(e) {
          return (
            (e.adminOf.length != 0 && e.adminOf[0].id === orgId) ||
            (e.viewerOf.length != 0 && e.viewerOf[0].id === orgId) ||
            (e.memberOf.length != 0 && e.memberOf[0].id === orgId)
          );
        });
        return orgusers;
      } else {
        return this.users;
      }
    }
  },

  created() {
    this.Constant = Constant;
  },
  methods: {
    getRoleDisplayName(roleName) {
      let displayRole = '';
      switch (roleName) {
        case Constant.roles.DEMENTIA_ADMIN:
          displayRole = Constant.roles.DEMENTIA_ADMIN_VIEW;
          break;
        case Constant.roles.CMS_ADMIN:
          displayRole = Constant.roles.CMS_ADMIN_VIEW;
          break;
        case Constant.roles.ORG_ADMIN:
          displayRole = Constant.roles.ORG_ADMIN_VIEW;
          break;
        case Constant.roles.ORG_VIEWER:
          displayRole = Constant.roles.ORG_VIEWER_VIEW;
          break;
        case Constant.roles.LEARNER:
          displayRole = Constant.roles.LEARNER_VIEW;
          break;
      }
      return displayRole;
    },
    setActiveTab(id) {
      this.selectedTab = id;
    },

    userPaginationStatus() {
      if (this.getUsersByRole && this.getUsersByRole.length > 0)
        return false;
      else return true;
    },
    getAlertObj(value) {
      const self = this;
      self.alert = value.alert;
      self.alertType = value.alertType;
      self.alertText = value.alertText;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-alert {
  left: unset !important;
}
</style>
