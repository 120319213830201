var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "grey lighten-5", attrs: { fluid: "" } },
        [
          _c(
            "v-alert",
            {
              attrs: {
                dense: "",
                text: "",
                type: _vm.alert.type,
                value: _vm.alert.show,
                transition: "slide-y-transition"
              }
            },
            [_vm._v(_vm._s(_vm.alert.text))]
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c("v-col", { staticClass: "mr-auto", attrs: { cols: "auto" } }, [
                _c("h1", [_vm._v("Modules")])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "page-sub-sec" },
        [
          _c("v-text-field", {
            staticClass: "shrink",
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Search",
              outlined: "",
              dense: "",
              "hide-details": ""
            },
            on: { input: _vm.modulesData },
            model: {
              value: _vm.searchCourse,
              callback: function($$v) {
                _vm.searchCourse = $$v
              },
              expression: "searchCourse"
            }
          }),
          _vm.IsCMSAdmin
            ? _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      _vm.showNewDialog = true
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-plus")]), _vm._v(" New Module")],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "dark--text",
        attrs: {
          headers: _vm.computedCourseHeaders,
          items: _vm.modules,
          search: _vm.searchCourse,
          "no-data-text": "No module available",
          "hide-default-footer": _vm.totalCourses < 20 ? true : false,
          loading: _vm.loading,
          "loading-text": "Loading... Please wait",
          "page-count": _vm.pageSize,
          page: _vm.page,
          options: _vm.options,
          "server-items-length": _vm.totalCourses,
          "footer-props": {
            "items-per-page-options": [20, 30, 40, 50, 100, 500]
          }
        },
        on: {
          "update:options": function($event) {
            _vm.options = $event
          }
        },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.computedCourseHeaders, function(h) {
              return {
                key: "header." + h.value,
                fn: function(ref) {
                  var header = ref.header
                  return [
                    _vm.headerTooltips[h.value]
                      ? _c(
                          "v-tooltip",
                          {
                            key: h.value,
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "span",
                                        _vm._g({}, on),
                                        [
                                          _vm._v(_vm._s(header.text)),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "pl-1",
                                              attrs: { color: "#113247" }
                                            },
                                            [_vm._v("mdi-information")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.headerTooltips[header.value]))
                            ])
                          ]
                        )
                      : _c("span", { key: h.value }, [
                          _vm._v(_vm._s(header.text))
                        ])
                  ]
                }
              }
            }),
            {
              key: "item.title",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "router-link",
                    {
                      staticClass: "blue--text",
                      attrs: { to: "/modules/" + item.id }
                    },
                    [_vm._v(_vm._s(item.title))]
                  )
                ]
              }
            },
            {
              key: "item.id",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "router-link",
                    {
                      staticClass: "blue--text",
                      attrs: { to: "/modules/" + item.id }
                    },
                    [_vm._v(_vm._s(item.id))]
                  )
                ]
              }
            },
            {
              key: "item.lessons",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.lessons) + " ")]
              }
            },
            {
              key: "item.activities",
              fn: function(ref) {
                var item = ref.item
                return [_c("span", [_vm._v(_vm._s(item.activities))])]
              }
            },
            {
              key: "item.course",
              fn: function(ref) {
                var item = ref.item
                return [_c("span", [_vm._v(_vm._s(item.course))])]
              }
            },
            {
              key: "item.isPaid",
              fn: function(ref) {
                var item = ref.item
                return [
                  !item.isPaid
                    ? _c(
                        "v-btn",
                        { attrs: { color: "green", icon: "", small: "" } },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "#757575", title: "Enable" } },
                            [_vm._v("mdi-currency-usd-off")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  item.isPaid
                    ? _c(
                        "v-btn",
                        { attrs: { color: "red", icon: "", small: "" } },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "#757575", title: "Enable" } },
                            [_vm._v("mdi-currency-usd")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("span", [
                    _vm._v(" " + _vm._s(!item.isPaid ? " Free" : " Paid"))
                  ])
                ]
              }
            },
            {
              key: "item.isDisabled",
              fn: function(ref) {
                var item = ref.item
                return [
                  !item.isDisabled && _vm.IsCMSAdmin
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "green", icon: "", small: "" },
                          on: {
                            click: function($event) {
                              return _vm.enableOrDisableCourse(item.id, true)
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { title: "Enable" } }, [
                            _vm._v("mdi-eye")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  item.isDisabled && _vm.IsCMSAdmin
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "red", icon: "", small: "" },
                          on: {
                            click: function($event) {
                              return _vm.enableOrDisableCourse(item.id, false)
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { title: "Enable" } }, [
                            _vm._v("mdi-eye-off")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  !item.isDisabled && !_vm.IsCMSAdmin
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "grey",
                            icon: "",
                            small: "",
                            disabled: ""
                          }
                        },
                        [
                          _c("v-icon", { attrs: { title: "Enable" } }, [
                            _vm._v("mdi-eye")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  item.isDisabled && !_vm.IsCMSAdmin
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "grey",
                            icon: "",
                            small: "",
                            disabled: ""
                          }
                        },
                        [
                          _c("v-icon", { attrs: { title: "Enable" } }, [
                            _vm._v("mdi-eye-off")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(
                    " " +
                      _vm._s(!item.isDisabled ? " Visible" : " Hidden") +
                      " "
                  )
                ]
              }
            },
            {
              key: "item.action",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "div",
                    { staticClass: "lessons-actions-wrap" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", small: "" },
                          on: {
                            click: function($event) {
                              return _vm.LoadDialogForModuleDuplication(item)
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { title: "copy" } }, [
                            _vm._v("mdi-content-copy")
                          ])
                        ],
                        1
                      ),
                      _vm.hideDelete == "false"
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "float-right",
                              attrs: { icon: "", color: "red", small: "" },
                              on: {
                                click: function($event) {
                                  return _vm.LoadConfirmDialogForDeleteCourse(
                                    item.id
                                  )
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { title: "Delete" } }, [
                                _vm._v("mdi-trash-can-outline")
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.showNewDialog,
            callback: function($$v) {
              _vm.showNewDialog = $$v
            },
            expression: "showNewDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v("New Module")
                    ])
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-text-field", {
                        attrs: {
                          counter: 50,
                          rules: _vm.nameRules,
                          label: "Name*",
                          required: ""
                        },
                        model: {
                          value: _vm.newCourseName,
                          callback: function($$v) {
                            _vm.newCourseName = $$v
                          },
                          expression: "newCourseName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("small", { staticClass: "info--text" }, [
                        _vm._v("*indicates required field")
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "", text: "" },
                          on: {
                            click: function($event) {
                              _vm.showNewDialog = false
                            }
                          }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.validateCourse }
                        },
                        [_vm._v(" New module ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "450" },
          model: {
            value: _vm.confirmdialogCoursedelete,
            callback: function($$v) {
              _vm.confirmdialogCoursedelete = $$v
            },
            expression: "confirmdialogCoursedelete"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Are you sure")
              ]),
              _c("v-card-text", [_vm._v("Remove Module?")]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmdialogCoursedelete = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.deleteCourse(_vm.coursedeleteId)
                        }
                      }
                    },
                    [_vm._v("Confirm")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.showCopyDialog,
            callback: function($$v) {
              _vm.showCopyDialog = $$v
            },
            expression: "showCopyDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.copyvalid,
                    callback: function($$v) {
                      _vm.copyvalid = $$v
                    },
                    expression: "copyvalid"
                  }
                },
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v("Duplicate Module")
                    ])
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-text-field", {
                        attrs: {
                          counter: 50,
                          rules: _vm.nameRules,
                          label: "Name*",
                          required: ""
                        },
                        model: {
                          value: _vm.copyModuleName,
                          callback: function($$v) {
                            _vm.copyModuleName = $$v
                          },
                          expression: "copyModuleName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.primaryCourseList,
                          label: "Select Course*",
                          rules: [
                            function(v) {
                              return !!v || "Course is required"
                            }
                          ],
                          dense: "",
                          required: ""
                        },
                        model: {
                          value: _vm.copyToCourseId,
                          callback: function($$v) {
                            _vm.copyToCourseId = $$v
                          },
                          expression: "copyToCourseId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("small", { staticClass: "info--text" }, [
                        _vm._v(
                          "Please ensure each mandatory field marked with an asterisk * is completed"
                        )
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "", text: "" },
                          on: {
                            click: function($event) {
                              _vm.showCopyDialog = false
                            }
                          }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", loading: _vm.copyLoading },
                          on: {
                            click: function($event) {
                              return _vm.validateDuplicateModule()
                            }
                          }
                        },
                        [_vm._v(" Duplicate Module ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }