<template>
  <div>
    <div class="page-sub-sec">
      <v-text-field
        v-model="searchEnterpriseUser"
        append-icon="mdi-magnify"
        label="Search"
        outlined
        dense
        hide-details
        class="shrink"
        @input="usersData"
      />
      <div>
        <!-- <v-btn
          v-if="IsORGAdmin || IsDementiaAdmin"
          color="primary"
          @click="generateReport"
        >
          <v-icon class="mr-2">mdi-file-excel</v-icon>Generate
          Report</v-btn
        > -->
        <v-btn
          v-if="IsDementiaAdmin || IsORGAdmin"
          class="ml-5"
          color="primary"
          @click="showNewDialog = true"
        >
          <v-icon class="mr-2">mdi-account-plus</v-icon>Invite
          user</v-btn
        >
      </div>
    </div>
    <v-data-table
      :items="users"
      :headers="computedUserHeaders"
      :search="searchEnterpriseUser"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="dark--text"
      :no-data-text="`No user available`"
      :hide-default-footer="usersCount < 10 ? true : false"
      :page-count="pageSize"
      :page="page"
      :options.sync="options"
      :server-items-length="usersCount"
      :footer-props="{
        'items-per-page-options': [50, 100, 500]
      }"
    >
      <template v-slot:[`item.name`]="{ item }">
        <router-link :to="`/users/${item.id}`" class="blue--text">
          {{ item.name }}</router-link
        >
      </template>
      <template v-slot:[`item.email`]="{ item }">
        {{ item.email }}
      </template>
      <template v-slot:[`item.role`]="{ item }">
        {{ item.role }}
      </template>
      <template v-slot:[`item.orgName`]="{ item }">
        {{ item.orgName }}
      </template>
      <template v-slot:[`item.userActive`]="{ item }">
        <div v-if="!item.userActive" class="warning--text">
          Inactive
        </div>
        <div v-else class="success--text">
          Active
        </div>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div
          v-if="getLoginedUserId != item.id"
          class="lessons-actions-wrap"
        >
          <!-- <v-btn class="info" rounded small disabled>
          Reset Password
        </v-btn> -->
          <v-btn
            v-if="item.userActive"
            color="green"
            icon
            small
            @click="enableOrDisableUser(item.id, false)"
            ><v-icon title="Enable">mdi-eye</v-icon></v-btn
          >
          <v-btn
            v-else
            color="orange"
            icon
            small
            @click="enableOrDisableUser(item.id, true)"
            ><v-icon title="Disable">mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            v-if="IsDementiaAdmin || IsORGAdmin"
            icon
            color="light-blue"
            small
            @click="reInviteUser(item.id, item.username)"
          >
            <v-icon title="Reinvite">mdi-account-reactivate</v-icon>
          </v-btn>

          <router-link
            :to="`/users/${item.id}`"
            class="v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--small"
          >
            <v-icon title="Edit" class="blue--text"
              >mdi-pencil</v-icon
            >
          </router-link>

          <v-btn
            v-if="hideDelete == 'false'"
            icon
            color="red"
            small
            @click="LoadConfirmDialogForDeleteOrg(item.id)"
          >
            <v-icon title="Delete">mdi-trash-can-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="showNewDialog" persistent max-width="600px">
      <v-card>
        <v-form ref="form">
          <v-card-title>
            <span class="headline">Invite user</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="forms.newEmail"
              label="Email*"
              :rules="emailRules"
              required
            />

            <v-text-field
              v-model="forms.newFirstName"
              :rules="[v => !!v || 'First name is required']"
              label="First name*"
              required
            />

            <v-text-field
              v-model="forms.newLastName"
              label="Last name"
            />

            <v-text-field
              v-model="forms.newPhoneNum"
              label="Phone Number"
            />

            <v-autocomplete
              v-model="forms.newRoleName"
              :items="getRolesByRole"
              :rules="[v => !!v || 'Role is required']"
              item-text="text"
              item-value="name"
              label="Select Role*"
              required
            />

            <v-autocomplete
              v-if="IsDementiaAdmin && IsOrglevelRole"
              v-model="forms.newOrgId"
              :items="orgs"
              item-text="name"
              item-value="id"
              label="Select Organisation*"
              :rules="[v => !!v || 'Organization is required']"
              required
            />

            <!-- <v-form v-else>
            <v-text-field
              v-model="forms.newOrgId"
              hidden
            />
          </v-form> -->
          </v-card-text>
          <v-card-actions>
            <small class="info--text"
              >*indicates required field</small
            >
            <v-spacer />
            <v-btn depressed text @click="cancel">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="createNewUser">
              Invite
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmdialogUserdelete"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">Are you sure</v-card-title>
        <v-card-text
          >Please confirm you wish to remove the selected
          user</v-card-text
        >
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="confirmdialogUserdelete = false"
            >Cancel</v-btn
          >
          <v-btn
            color="red darken-1"
            text
            @click="deleteUser(userdeleteId)"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';
import { Constant } from '../../../config/constants';
import * as saveAs from 'file-saver';
import { AUTH_TOKEN } from '../../../vue-apollo';

const INVITE_NEW_USER = gql`
  mutation(
    $email: String!
    $roleName: String!
    $orgId: ID
    $firstName: String!
    $lastName: String
  ) {
    customRegister(
      input: {
        email: $email
        role: $roleName
        orgId: $orgId
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      user {
        id
        username
        email
        firstName
        lastName
        confirmed
        blocked
        role {
          id
          name
        }
        memberOf {
          id
          name
        }
        adminOf {
          id
          name
        }
        viewerOf {
          id
          name
        }
      }
    }
  }
`;
const RE_INVITE_USER = gql`
  mutation($userId: ID!) {
    reinviteUser(id: $userId) {
      user {
        id
      }
    }
  }
`;
const ENABLE_DISABLE_USER_MUTATION = gql`
  mutation($userId: ID!, $isDisabled: Boolean!) {
    customUpdateUser(
      input: {
        where: { id: $userId }
        data: { blocked: $isDisabled }
      }
    ) {
      user {
        id
        username
        email
        confirmed
        blocked
        role {
          id
          name
        }
        memberOf {
          id
          name
        }
      }
    }
  }
`;
// const USER_QUERY_COUNT = gql`
//   query Users($where: JSON) {
//     users(where: $where) {
//       id
//       username
//       firstName
//       lastName
//       mobile
//       email
//       confirmed
//       blocked
//       role {
//         id
//         name
//       }
//       viewerOf {
//         id
//         name
//       }
//       adminOf {
//         id
//         name
//       }
//       memberOf {
//         id
//         name
//       }
//     }
//   }
// `;

// const USER_QUERY = gql`
//   query Users($limit: Int, $first: Int, $sort: String, $where: JSON) {
//     users(limit: $limit, start: $first, sort: $sort, where: $where) {
//       id
//       username
//       firstName
//       lastName
//       mobile
//       email
//       confirmed
//       blocked
//       role {
//         id
//         name
//       }
//       viewerOf {
//         id
//         name
//       }
//       adminOf {
//         id
//         name
//       }
//       memberOf {
//         id
//         name
//       }
//     }
//   }
// `;
const USER_QUERY = gql`
  query GetAdminUsers(
    $first: Int!
    $limit: Int!
    $search: String
    $sortBy: String
    $sortOrder: String
  ) {
    getAdminUsers(
      pageNumber: $first
      pageSize: $limit
      sortBy: $sortBy
      sortOrder: $sortOrder
      search: $search
    ) {
      totalItemCount
      statusMessage
      statusCode
      data {
        id
        firstName
        lastName
        email
        userActive
        orgName
        roleName
      }
    }
  }
`;

const ORG_QUERY = gql`
  query {
    orgs {
      id
      name
    }
  }
`;
const ROLE_QUERY = gql`
  query {
    roles {
      id
      name
    }
  }
`;
const DELETE_USER = gql`
  mutation($userId: ID!) {
    deleteUser(input: { where: { id: $userId } }) {
      user {
        id
      }
    }
  }
`;

export default {
  name: 'EnterpriseUsers',
  data: () => ({
    system_roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER'
    },
    system_roles_name: {
      DEMINTIA_ADMIN: 'Dementia Admin',
      CMS_ADMIN: 'CMS Admin',
      ORG_ADMIN: 'ORG Admin',
      ORG_VIEWER: 'ORG Viewer'
    },
    orgId: 0,
    confirmdialogUserdelete: false,
    userdeleteId: '',
    alert: false,
    alertText: { text: '' },
    alertType: 'success',
    showNewDialog: false,
    newCourseName: '',
    searchEnterpriseUser: '',
    validationSlot: '',
    hideDelete: process.env.VUE_APP_HIDEDELETE_STATUS,
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: 'Role', value: 'role' },
      { text: 'Organisation', value: 'orgName' },
      { text: 'Status ', value: 'userActive' },
      { text: 'Action', value: 'action', sortable: false }
    ],
    validationHeaders: [
      { text: 'Name', value: 'name' },
      { text: 'Subscription Name', value: 'role.text' },
      { text: 'Email', value: 'email' },
      { text: 'Time Awaiting Validation', value: 'orgName' },
      { text: 'Validation Approval ', value: 'validationSlot' }
    ],
    pageSize: 50,
    page: 1,
    options: {},
    awaiting_pageSize: 10,
    awaiting_page: 0,
    awaiting_options: {},

    error: null,
    allRoles: [],
    allOrgs: [],
    forms: {
      newEmail: null,
      newRoleName: null,
      newOrgId: null,
      newFirstName: null,
      newLastName: null,
      newPhoneNum: null
    },
    loading: false,
    usersCount: 0,
    users: [],
    awaiting_users: [],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ]
  }),
  apollo: {
    orgs: {
      query: ORG_QUERY,
      skip() {
        return !this.isAuthenticated;
      }
    },
    roles: {
      query: ROLE_QUERY,
      skip() {
        return !this.isAuthenticated;
      }
    }
  },
  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating'
    ]),

    computedUserHeaders() {
      if (this.IsORGViewer || this.IsORGAdmin)
        return this.headers.filter(
          t => t.text != 'Action' && t.text != 'Organisation'
        );
      else return this.headers;
    },

    IsDementiaAdmin() {
      if (
        this.getUser?.user?.role?.name ===
        this.system_roles.DEMENTIA_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsOrglevelRole() {
      const organizationRequiredRoles = [
        this.system_roles.ORG_ADMIN,
        this.system_roles.ORG_VIEWER,
        'LEARNER'
      ];
      if (
        this.forms.newRoleName &&
        organizationRequiredRoles.includes(this.forms.newRoleName)
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsCMSAdmin() {
      if (
        this.getUser?.user?.role?.name === this.system_roles.CMS_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsORGAdmin() {
      if (
        this.getUser?.user?.role?.name === this.system_roles.ORG_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsORGViewer() {
      if (
        this.getUser?.user?.role?.name ===
        this.system_roles.ORG_VIEWER
      ) {
        return true;
      } else {
        return false;
      }
    },

    getLoginedUserId() {
      return this.getUser?.user?.id;
    },
    getRolesByRole() {
      if (!this.roles) return [];
      if (
        this.getUser?.user?.role?.name === this.system_roles.ORG_ADMIN
      ) {
        var adminroles = this.roles
          .filter(function(r) {
            console.log();
            //return r.id != 1 && r.id != 2 && r.id != 3 && r.id != 6;
            return (
              r.name != Constant.roles.AUTHENTICATED &&
              r.name != Constant.roles.PUBLIC &&
              r.name != Constant.roles.DEMENTIA_ADMIN &&
              r.name != Constant.roles.CMS_ADMIN &&
              r.name != Constant.roles.LEARNER
            );
          })
          .map(item => ({
            ...item,
            text: this.getRoleDisplayName(item.name)
          }));
        return adminroles;
      } else if (
        this.getUser?.user?.role?.name ===
        this.system_roles.DEMENTIA_ADMIN
      ) {
        var dadminroles = this.roles
          .filter(function(r) {
            //return r.id != 1 && r.id != 2;
            return (
              r.name != Constant.roles.AUTHENTICATED &&
              r.name != Constant.roles.PUBLIC &&
              r.name != Constant.roles.LEARNER
            );
          })
          .map(item => {
            // let rolText = item.name;
            // if (rolText === Constant.roles.DEMENTIA_ADMIN)
            //   rolText = Constant.roles.DEMENTIA_ADMIN_VIEW;
            return {
              ...item,
              text: this.getRoleDisplayName(item.name)
            };
          });
        return dadminroles;
      } else return [];
    },

    getUsersByRole() {
      if (!this.users) return [];
      if (
        this.getUser?.user?.role?.name ===
          this.system_roles.ORG_ADMIN ||
        this.getUser?.user?.role?.name ===
          this.system_roles.ORG_VIEWER
      ) {
        const orgId = this.getUser?.user?.memberOf[0].id.toString();
        var orgusers = this.users.filter(function(e) {
          return (
            (e.adminOf.length != 0 && e.adminOf[0].id === orgId) ||
            (e.viewerOf.length != 0 && e.viewerOf[0].id === orgId) ||
            (e.memberOf.length != 0 && e.memberOf[0].id === orgId)
          );
        });
        return orgusers;
      } else {
        return this.users;
      }
    }
  },
  watch: {
    options: {
      handler() {
        this.usersData();
      }
    },
    awaiting_options: {
      handler() {
        this.usersDataAwaitng();
      }
    },
    deep: true,
    alert: function() {
      const self = this;
      self.$emit('alertObj', {
        alert: self.alert,
        alertType: self.alertType,
        alertText: self.alertText
      });
    }
  },
  created() {
    this.Constant = Constant;
  },
  methods: {
    usersData(flag = false) {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let payload = {};
      payload.first = flag ? 0 : page ? page : this.page;
      payload.limit = itemsPerPage ? itemsPerPage : this.pageSize;
      if (sortBy[0]) {
        payload.first = page;
        let sortDirection = sortDesc[0] ? 'desc' : 'asc';
        payload.sort = sortBy[0];
        payload.sortOrder = sortDirection;
      }
      if (this.searchEnterpriseUser) {
        payload.first = 0;
        payload.search = this.searchEnterpriseUser;
      }
      this.loading = true;
      this.$apollo
        .query({
          query: USER_QUERY,
          fetchPolicy: 'network-only',
          variables: payload,
          skip() {
            return !this.isAuthenticated;
          }
        })
        .then(response => {
          this.loading = false;
          if (response?.data?.getAdminUsers?.totalItemCount) {
            this.usersCount = parseInt(
              response?.data?.getAdminUsers?.totalItemCount
            );
          }
          this.users = response.data.getAdminUsers.data.map(usr => {
            usr.role = this.system_roles_name?.[usr.roleName]
              ? this.system_roles_name?.[usr.roleName]
              : usr.roleName;
            usr.name = usr.firstName.concat(
              ' ',
              usr.lastName ? usr.lastName : ''
            );
            return usr;
          });
        });

      //   .query( {
      //   query: USER_QUERY,
      //   variables: payload,
      //   fetchPolicy: 'cache-and-network',
      //   update: data => {
      //     this.users = data.users.map(usr => {
      //       usr.orgName = this.getorgName(usr);
      //       usr.role = {
      //         ...usr.role,
      //         text: this.getRoleDisplayName(usr.role.name)
      //       };
      //       usr.fullName = usr.firstName.concat(
      //         ' ',
      //         usr.lastName ? usr.lastName : ''
      //       );
      //       return usr;
      //     });
      //   },
      //   skip() {
      //     return !this.isAuthenticated;
      //   }
      // });
    },

    generateReport() {
      var datetime = new Date();
      var month = datetime.getMonth() + 1;
      //custom file name
      var sheetname =
        'DA_LearnerData_' +
        datetime.getDate() +
        '_' +
        month +
        '_' +
        datetime.getFullYear() +
        '_' +
        datetime.toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        }) +
        '.xlsx';
      const downloadUrl =
        process.env.VUE_APP_BASE_API + '/generateReportEnterprise';
      fetch(downloadUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN)
        }
      })
        .then(response => response.blob())
        .then(blob => saveAs(blob, sheetname))
        .catch(err => {
          // todo: handle server failure
          console.log(err);
        });
    },
    getRoleDisplayName(roleName) {
      let displayRole = '';
      switch (roleName) {
        case Constant.roles.DEMENTIA_ADMIN:
          displayRole = Constant.roles.DEMENTIA_ADMIN_VIEW;
          break;
        case Constant.roles.CMS_ADMIN:
          displayRole = Constant.roles.CMS_ADMIN_VIEW;
          break;
        case Constant.roles.ORG_ADMIN:
          displayRole = Constant.roles.ORG_ADMIN_VIEW;
          break;
        case Constant.roles.ORG_VIEWER:
          displayRole = Constant.roles.ORG_VIEWER_VIEW;
          break;
        case Constant.roles.LEARNER:
          displayRole = Constant.roles.LEARNER_VIEW;
          break;
      }
      return displayRole;
    },
    setActiveTab(id) {
      this.selectedTab = id;
    },
    awaitingUserPaginationStatus() {
      if (this.getUsersByRole && this.getUsersByRole.length > 0)
        return false;
      else return true;
    },

    enterpriseUserPaginationStatus() {
      if (this.getUsersByRole && this.getUsersByRole.length > 0)
        return false;
      else return true;
    },
    enableOrDisableUser(userId, isDisabled) {
      this.$apollo.mutate({
        mutation: ENABLE_DISABLE_USER_MUTATION,
        variables: {
          userId: userId,
          isDisabled: !isDisabled
        },
        update: (store, { data: { customUpdateUser } }) => {
          // Only update the store if there's a new course created
          if (!customUpdateUser.user) return;
          // Get the current store state
          // const { page, itemsPerPage } = this.options;
          // const data = store.readQuery({
          //   query: USER_QUERY,
          //   variables: {
          //     first: page ? page : this.page,
          //     limit: itemsPerPage ? itemsPerPage : this.pageSize,
          //   }
          // });
          // Push the new users onto the cache
          const _user = this.users.find(link => link.id === userId);
          _user.userActive = isDisabled;

          // Write the list back into the cache
          // store.writeQuery({ query: USER_QUERY, data });
        }
      });
    },
    LoadConfirmDialogForDeleteOrg(id) {
      this.userdeleteId = id;
      this.confirmdialogUserdelete = true;
    },
    createNewUser() {
      if (!this.$refs.form.validate()) return;

      var orgId;
      if (this.IsORGAdmin) {
        orgId = this.getUser?.user?.memberOf[0].id;
      } else if (this.IsOrglevelRole) {
        if (!this.forms.newOrgId) return;
      }

      this.$apollo
        .mutate({
          mutation: INVITE_NEW_USER,
          variables: {
            email: this.forms.newEmail,
            roleName: this.forms.newRoleName,
            orgId: this.IsORGAdmin ? orgId : this.forms.newOrgId,
            firstName: this.forms.newFirstName,
            lastName: this.forms.newLastName
          },
          update: (store, { data: { customRegister } }) => {
            // Only update the store if there's a new user created
            if (!customRegister.user) return;
            this.usersData(true);
            // Get the current store state
            // const data = store.readQuery({
            //   query: USER_QUERY
            // });
            // customRegister.user.orgName = this.getOrganisationName(
            //   customRegister.user
            // );
            // data.users.push(customRegister.user);
            // // Write the list back into the cache
            // store.writeQuery({ query: USER_QUERY, data });
            this.alertType = 'success';
            this.alert = true;
            this.alertText.text = 'Invitation Sent Successfully';
            this.$refs.form.reset();
            setTimeout(() => {
              this.alert = false;
              this.alertText.text = '';
            }, 3000);
          }
        })
        .catch(e => {
          this.alertType = 'error';
          this.alert = true;
          this.alertText.text = JSON.stringify(
            e.graphQLErrors[0].extensions.exception.data.message[0]
              .messages[0].message
          ).replace(/['"]+/g, '');
          setTimeout(() => {
            this.alert = false;
            this.alertText.text = '';
            this.alertType = 'success';
          }, 13000);
        });

      this.showNewDialog = false;
    },
    deleteUser(userId) {
      this.$apollo.mutate({
        mutation: DELETE_USER,
        variables: {
          userId: userId
        },
        update: (store, { data: { deleteUser } }) => {
          if (!deleteUser) return;
          // Get the current store state
          // const data = store.readQuery({
          //   query: USER_QUERY
          // });
          this.confirmdialogUserdelete = false;
          this.alert = true;
          this.alertText.text = 'User Removed!';
          setTimeout(() => {
            this.alert = false;
            this.alertText.text = '';
          }, 3000);
          // Pop the user from the cache
          this.usersData(true);
          // Write the list back into the cache
          // store.writeQuery({ query: USER_QUERY, data });
        },
        error() {
          this.confirmdialogUserdelete = false;
        }
      });
    },
    reInviteUser(userId, username) {
      this.$apollo.mutate({
        mutation: RE_INVITE_USER,
        variables: {
          userId: userId,
          username: username
        },
        update: () => {
          this.alert = true;
          this.alertText.text = 'Invitation sent!';
          setTimeout(() => {
            this.alert = false;
            this.alertText.text = '';
          }, 3000);
        }
      });
    },
    cancel() {
      this.$refs.form.reset();
      this.showNewDialog = false;
    },
    getOrganisationName(user) {
      return user.role.name === this.system_roles.ORG_ADMIN
        ? user.adminOf
          ? user.adminOf[0]
            ? user.adminOf[0].name
            : '-'
          : '-'
        : user.role.name === this.system_roles.ORG_VIEWER
        ? user.viewerOf
          ? user.viewerOf[0]
            ? user.viewerOf[0].name
            : '-'
          : '-'
        : user.memberOf
        ? user.memberOf[0]
          ? user.memberOf[0].name
          : '-'
        : '-';
    }
  }
};
</script>
