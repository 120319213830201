<template>
  <div>
    <div class="d-flex justify-center">
      <v-alert
        dense
        text
        class="v-alert"
        :type="alert.type"
        :value="alert.show"
        transition="slide-y-transition"
        >{{ alert.text }}</v-alert
      >
    </div>
    <v-container fluid class="grey lighten-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <h1>Subscriptions</h1>
        </v-col>
      </v-row>
    </v-container>
    <div class="page-sub-sec">
      <v-text-field
        v-model="searchUser"
        append-icon="mdi-magnify"
        label="Search"
        outlined
        dense
        hide-details
        class="shrink"
        @input="subscriptionData"
      />
      <v-btn
        v-if="IsCMSAdmin"
        color="primary"
        @click="showNewDialog = true"
      >
        <v-icon class="mr-2">mdi-plus</v-icon>Add Subscription</v-btn
      >
    </div>
    <v-data-table
      :items="subscriptions"
      :headers="computedUserHeaders"
      :search="searchUser"
      class="dark--text"
      :no-data-text="`No data available`"
      :loading="this.$apollo.loading"
      loading-text="Loading... Please wait"
      :hide-default-footer="courseCurrentCount < 10 ? true : false"
      :page-count="pageSize"
      :page="page"
      :options.sync="options"
      :server-items-length="courseCurrentCount"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50, 100, 500]
      }"
    >
      <template
        v-for="h in computedUserHeaders"
        v-slot:[`header.${h.value}`]="{ header }"
      >
        <v-tooltip
          v-if="headerTooltips[h.value]"
          :key="h.value"
          bottom
        >
          <template v-slot:activator="{ on }">
            <span v-on="on"
              >{{ header.text
              }}<v-icon class="pl-1" color="#113247"
                >mdi-information</v-icon
              ></span
            >
          </template>
          <span>{{ headerTooltips[header.value] }}</span>
        </v-tooltip>
        <span v-else :key="h.value">{{ header.text }}</span>
      </template>
      <template v-slot:[`item.productName`]="{ item }">
        <router-link
          :to="`/subscriptions/${item.productId}`"
          class="blue--text"
          >{{ item.productName }}</router-link
        >
      </template>
      <template v-slot:[`item.productId`]="{ item }">
        {{ item.productId }}
      </template>
      <template v-slot:[`item.inAppMonthlyProductId`]="{ item }">
        {{ item.inAppMonthlyProductId }}
      </template>
      <template v-slot:[`item.inAppYearlyProductId`]="{ item }">
        {{ item.inAppYearlyProductId }}
      </template>
      <template v-slot:[`item.courseCount`]="{ item }">
        {{ item.courseCount }}
      </template>

      <template v-slot:[`item.productAvailabilityName`]="{ item }">
        {{
          item.productAvailabilityName
            ? item.productAvailabilityName
            : 'Unavailable'
        }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div
          v-if="getLoginedUserId != item.productId"
          class="lessons-actions-wrap"
        >
          <!-- <v-btn class="info" rounded small disabled>
          Reset Password
        </v-btn> -->
          <v-btn
            v-if="!item.isDisabled"
            color="green"
            icon
            small
            :disabled="!IsDementiaAdmin"
            @click="changeVisibility(item)"
            ><v-icon title="Enable">mdi-eye</v-icon></v-btn
          >
          <v-btn
            v-else
            color="orange"
            icon
            small
            :disabled="!IsDementiaAdmin"
            @click="changeVisibility(item)"
            ><v-icon title="Disable">mdi-eye-off</v-icon></v-btn
          >
          <v-btn icon color="light-blue" small>
            <v-icon title="Reinvite">mdi-account-reactivate</v-icon>
          </v-btn>

          <router-link
            :to="`/users/${item.productId}`"
            class="v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--small"
          >
            <v-icon title="Edit" class="blue--text"
              >mdi-pencil</v-icon
            >
          </router-link>

          <v-btn
            v-if="hideDelete == 'false'"
            icon
            color="red"
            small
            @click="LoadConfirmDialogForDeleteOrg(item.productId)"
          >
            <v-icon title="Delete">mdi-trash-can-outline</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:[`item.isDisabled`]="{ item }">
        <v-btn
          v-if="!item.isDisabled"
          color="green"
          icon
          small
          :disabled="!IsDementiaAdmin"
          @click="changeVisibility(item)"
          ><v-icon title="Enable">mdi-eye</v-icon></v-btn
        >
        <v-btn
          v-if="item.isDisabled"
          color="red"
          icon
          small
          :disabled="!IsDementiaAdmin"
          @click="changeVisibility(item)"
          ><v-icon title="Enable">mdi-eye-off</v-icon></v-btn
        >
        {{ !item.isDisabled ? ' Visible' : ' Hidden' }}
      </template>
    </v-data-table>
    <v-dialog v-model="showNewDialog" persistent max-width="600px">
      <v-card>
        <v-form ref="form" @submit="createProduct">
          <v-card-title>
            <span class="headline">Add Subscription</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="forms.name"
              label="Name*"
              :rules="[v => !!v || 'Name is required']"
              required
            />
          </v-card-text>
          <v-card-actions>
            <small class="info--text"
              >*indicates required field</small
            >
            <v-spacer />
            <v-btn depressed text @click="cancel">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="createProduct">
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Constant } from '../../config/constants';
import gql from 'graphql-tag';

const SUBSCRIPTION_NEW_QUERY = gql`
  query GetAllSubscriptions(
    $pageNumber: Int!
    $pageSize: Int!
    $search: String
    $sortBy: String
    $sortOrder: String
  ) {
    getAllSubscriptions(
      pageNumber: $pageNumber
      pageSize: $pageSize
      search: $search
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      isSuccess
      totalItemCount
      data {
        productId
        productName
        inAppYearlyProductId
        isDisabled
        inAppProductId
        inAppMonthlyProductId
        productAvailabilityName
        productAvailablityId
        courseCount
      }
    }
  }
`;

const SUBSCRIPTION_QUERY_COUNT = gql`
  query {
    products {
      id
    }
  }
`;

const CREATE_SUBSCRIPTION_MUTATION = gql`
  mutation($subscriptionName: String!) {
    createSubscription(name: $subscriptionName) {
      statusCode
      statusMessage
      isSuccess
      data {
        name
      }
    }
  }
`;

const UPDATE_SUBSCRIPTION_DETAILS = gql`
  mutation($subId: ID!, $isDisabled: Boolean!) {
    updateSubscriptionDetail(
      input: { id: $subId, isDisabled: $isDisabled }
    ) {
      statusCode
      isSuccess
      statusMessage
    }
  }
`;

export default {
  name: 'SubscriptionsList',
  data: () => ({
    system_roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER'
    },
    subscriptions: [],
    courseCurrentCount: 0,
    pageSize: 10,
    page: 1,
    options: {},
    imageFile: [],
    confirmdialogUserdelete: false,
    userdeleteId: '',
    alert: {
      type: 'success',
      show: false,
      text: ''
    },
    hideDelete: process.env.VUE_APP_HIDEDELETE_STATUS,
    alertText: { text: '' },
    name: '',
    alertType: 'success',
    showNewDialog: false,
    searchUser: '',
    loading: false,
    headers: [
      { text: 'Subscription Name', value: 'productName' },
      { text: 'Subscription ID', value: 'productId' },
      // {
      //   text: 'In-App Product ID - Monthly',
      //   value: 'inAppMonthlyProductId'
      // },
      {
        text: 'In-App Product ID - Yearly',
        value: 'inAppYearlyProductId'
      },
      {
        text: 'No of Courses',
        value: 'courseCount',
        sortable: false
      },
      { text: 'Availability', value: 'productAvailabilityName' },
      { text: 'Visibility', value: 'isDisabled' }
    ],
    headerTooltips: {
      isDisabled:
        'Whether or not this subscription is visible within the mobile app'
    },
    forms: {
      name: null
    }
  }),

  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating'
    ]),

    computedUserHeaders() {
      if (this.IsCMSAdmin)
        return this.headers.filter(t => t.value != 'visibility');
      else return this.headers;
    },
    IsDementiaAdmin() {
      if (
        this.getUser?.user?.role?.name ===
        this.system_roles.DEMENTIA_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsOrglevelRole() {
      const organizationRequiredRoles = [
        this.system_roles.ORG_ADMIN,
        this.system_roles.ORG_VIEWER,
        'LEARNER'
      ];
      if (
        this.forms.newRoleName &&
        organizationRequiredRoles.includes(this.forms.newRoleName)
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsCMSAdmin() {
      if (
        this.getUser?.user?.role?.name === this.system_roles.CMS_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsORGAdmin() {
      if (
        this.getUser?.user?.role?.name === this.system_roles.ORG_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsORGViewer() {
      if (
        this.getUser?.user?.role?.name ===
        this.system_roles.ORG_VIEWER
      ) {
        return true;
      } else {
        return false;
      }
    },
    getLoginedUserId() {
      return this.getUser?.user?.id;
    }
  },

  watch: {
    options: {
      handler() {
        this.subscriptionData();
      }
    },
    deep: true
  },
  created() {
    this.Constant = Constant;
  },
  methods: {
    getRoleDisplayName(roleName) {
      let displayRole = '';
      switch (roleName) {
        case Constant.roles.DEMENTIA_ADMIN:
          displayRole = Constant.roles.DEMENTIA_ADMIN_VIEW;
          break;
        case Constant.roles.CMS_ADMIN:
          displayRole = Constant.roles.CMS_ADMIN_VIEW;
          break;
        case Constant.roles.ORG_ADMIN:
          displayRole = Constant.roles.ORG_ADMIN_VIEW;
          break;
        case Constant.roles.ORG_VIEWER:
          displayRole = Constant.roles.ORG_VIEWER_VIEW;
          break;
        case Constant.roles.LEARNER:
          displayRole = Constant.roles.LEARNER_VIEW;
          break;
      }
      return displayRole;
    },
    createProduct() {
      if (!this.$refs.form.validate()) return;

      const subscriptionName = this.forms.name;
      this.$apollo
        .mutate({
          mutation: CREATE_SUBSCRIPTION_MUTATION,
          variables: {
            subscriptionName
          },
          // Why is query not updating!!!
          // This shouldn't be necessary!!!
          update: () => {
            this.subscriptionData(true);
            this.alert.show = true;
            this.alert.text = 'Successfully Created';
            this.alert.type = 'success';
            this.showNewDialog = false;
            this.$refs.form.resetValidation();
            this.$refs.form.reset();
            setTimeout(() => {
              this.alert.show = false;
            }, 3000);
          }
        })
        .catch(error => {
          this.alert.show = true;
          this.alert.text = error;
          this.alert.type = 'error';
          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        });
    },
    getFile() {
      let fileUpload = document.getElementById('fileUpload');
      if (fileUpload != null) {
        fileUpload.click();
      }
    },
    LoadConfirmDialogForDeleteOrg(id) {
      this.userdeleteId = id;
      this.confirmdialogUserdelete = true;
    },
    cancel() {
      this.$refs.form.reset();
      this.imageUrl = null;
      this.showNewDialog = false;
    },
    changeVisibility(item) {
      if (!this.IsDementiaAdmin) {
        return false;
      }
      this.$apollo
        .mutate({
          mutation: UPDATE_SUBSCRIPTION_DETAILS,
          variables: {
            subId: item.productId,
            isDisabled: !item.isDisabled
          },
          // Why is query not updating!!!
          // This shouldn't be necessary!!!
          update: (store, { data: { updateSubscriptionDetail } }) => {
            // this.subscriptionData();
            if (!updateSubscriptionDetail) return;
            const _subscriptions = this.subscriptions.find(
              link => link.productId === item.productId
            );
            if (_subscriptions.productId) {
              _subscriptions.isDisabled = !item.isDisabled;
              // [...[this.subscriptions], ..._subscriptions];
            }
            this.alert.show = true;
            this.alert.text = 'Success';
            this.alert.type = 'success';
            this.editName = false;
            setTimeout(() => {
              this.alert.show = false;
            }, 3000);
          }
        })
        .catch(error => {
          this.alert.show = true;
          this.alert.text = error;
          this.alert.type = 'error';
          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        });
    },
    subscriptionData(pageNum = false) {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let sortDirection = null;
      let sort_var = null;
      let page_nuber = pageNum ? 1 : page ? page : this.page;

      this.loading = true;
      if (sortBy[0]) {
        sortDirection = sortDesc[0] ? 'desc' : 'asc';
        sort_var = sortBy[0];
      }
      this.$apollo
        .query({
          query: SUBSCRIPTION_NEW_QUERY,
          fetchPolicy: 'network-only',
          variables: {
            pageNumber: page_nuber,
            pageSize: itemsPerPage ? itemsPerPage : this.pageSize,
            search: this.searchUser,
            sortBy: sort_var,
            sortOrder: sortDirection
          },
          skip() {
            return !this.isAuthenticated;
          }
        })
        .then(response => {
          if (response.data.getAllSubscriptions.isSuccess) {
            this.loading = false;

            let newProducts = [];
            let data = response.data.getAllSubscriptions.data;
            this.courseCurrentCount =
              response.data.getAllSubscriptions.totalItemCount;

            data.map(items => {
              items.productAvailabilityName = items.productAvailabilityName
                ? items.productAvailabilityName
                : 'Unavailable';
              return newProducts.push(items);
            });
            this.subscriptions = data;
          } else {
            this.alert.show = true;
            this.alert.text = 'No subscription for this user';
            this.alert.type = 'error';
            setTimeout(() => {
              this.alert.show = false;
            }, 3000);
          }
        });
    }
  },
  apollo: {
    subsCount() {
      return {
        query: SUBSCRIPTION_QUERY_COUNT,
        fetchPolicy: 'cache-and-network',
        update: data => {
          return data.products.length;
        },
        error() {
          this.loading = false;
        },
        skip() {
          return !this.isAuthenticated;
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.v-alert {
  left: unset !important;
}
</style>
