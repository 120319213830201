<template>
  <div>
    <v-container>
      <v-row>
        <v-col sm="1">
          <h3 class="font-weight-black">
            {{ index }}
          </h3>
        </v-col>
        <v-col>
          <h2 class="indigo--text">Single Choice</h2>
          <h3>Question</h3>
          <app-rich-text-editor v-model="local.quiz.question" />
          <h3 class="mt-6">Answers</h3>
          <table class="mb-6" style="border-spacing: 0;">
            <thead>
              <tr>
                <th></th>
                <th style="text-align: left; width: 100%;">
                  Text
                </th>
                <th
                  style="text-align: left;white-space: nowrap;"
                  class="px-2"
                >
                  Correct Answer
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(answer, _index) in local.quiz.answers"
                :key="answer.id"
              >
                <td class="pr-4 pb-6 pt-4">{{ _index + 1 }}</td>
                <td><v-text-field v-model="answer.text" /></td>
                <td style="text-align: center;">
                  <input
                    :checked="answer.correct"
                    type="radio"
                    :name="'singelchoice' + index"
                    :value="answer.text"
                    @click="makeThisCorrect(_index)"
                  />
                </td>
                <td>
                  <v-btn
                    color="primary"
                    icon
                    @click="deleteAnswer(_index)"
                    ><v-icon>mdi-minus-circle</v-icon></v-btn
                  >
                </td>
              </tr>
            </tbody>
            <tbody style="background-color: rgba(0,0,0,0.05);">
              <tr>
                <td></td>
                <td>
                  <v-text-field v-model="forms.newAnswer.text" />
                </td>
                <td style="text-align: center;"></td>
                <td>
                  <v-btn
                    v-if="IsCMSAdmin"
                    icon
                    color="primary"
                    @click="addAnswer"
                    ><v-icon>mdi-plus-circle</v-icon></v-btn
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mb-3">
            <h3 class="mt-6">Incorrect Answer Text</h3>
            <v-text-field
              v-model="local.quiz.incorrectAnswerText"
              outlined
              dense
              hide-details
              class="shrink "
            />
          </div>
          <v-btn
            v-if="IsCMSAdmin"
            color="primary"
            x-small
            title="Remove the current quiz"
            @click="$emit('removethiscomponent', local.quiz)"
            >Remove</v-btn
          >
          <v-dialog
            v-model="singleChoiceValidation"
            persistent
            max-width="450"
          >
            <v-card>
              <v-card-text>
                <br />
                <br />
                <br />
                <h4>Question and answers are required fields</h4>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="red darken-1"
                  text
                  @click="singleChoiceValidation = false"
                  >Got it</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import AppRichTextEditor from '@/components/editors/AppRichTextEditor';
import clone from 'rfdc';
import { mapGetters } from 'vuex';

const cloner = clone();

// Quiz structure gets accessed by Activity component to
// query quiz which uses component name to access so fragment
// name *must* be component name. Component name is dynamically
// accessed by <component :is="__typename"> so must be Strapi
// component name
const QUERY_FRAGMENT = gql`
  fragment ComponentQuizSingleChoice on ComponentQuizSingleChoice {
    id
    question
    incorrectAnswerText
    answers {
      __typename
      ... on ComponentQuizAnswerTextAnswer {
        id
        text
        correct
      }
    }
  }
`;

export default {
  name: 'ComponentQuizSingleChoice',
  icon: 'mdi-check-box-multiple-outline',
  gqlQueryFragment: QUERY_FRAGMENT,
  text: 'Single Choice',
  components: {
    AppRichTextEditor
  },
  props: {
    quiz: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    rgroup: [],
    selectedValue: 1,
    singleChoiceValidation: false,
    roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER'
    },
    local: {
      // * Quiz structure needs to match the GrqphQL structure
      // * Gets passed to update mutation without alteration
      quiz: {}
    },
    forms: {
      newAnswer: {
        text: '',
        correct: false
      }
    }
  }),
  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating'
    ]),
    IsDementiaAdmin() {
      if (
        this.getUser?.user?.role?.name === this.roles.DEMENTIA_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsCMSAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.CMS_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGViewer() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_VIEWER) {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {
    this.$set(this.local, 'quiz', cloner(this.quiz));
  },
  methods: {
    makeThisCorrect(index) {
      if (index != undefined) {
        for (var i in this.local.quiz.answers) {
          if (i == index) {
            this.local.quiz.answers[i].correct = true;
          } else {
            this.local.quiz.answers[i].correct = false;
          }
        }
      }
      console.log(JSON.stringify(this.local.quiz.answers));
    },
    addAnswer() {
      if (!this.local.quiz.question || !this.forms.newAnswer.text) {
        this.singleChoiceValidation = true;
        return;
      }
      this.local.quiz.answers.push({
        text: this.forms.newAnswer.text,
        correct: this.forms.newAnswer.correct
      });
      this.forms.newAnswer.text = '';
      this.forms.newAnswer.correct = false;
    },
    deleteAnswer(index) {
      this.local.quiz.answers.splice(index, 1);
    }
  }
};
</script>
