var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.hasChrome && _vm.isAuthenticated && !this.$route.meta.layout
        ? _c(
            "v-navigation-drawer",
            {
              staticClass: "primary side-drawer",
              attrs: { app: "" },
              model: {
                value: _vm.drawerState,
                callback: function($$v) {
                  _vm.drawerState = $$v
                },
                expression: "drawerState"
              }
            },
            [
              _c(
                "v-list",
                { staticClass: "secondary--text pa-0" },
                [
                  _c(
                    "v-list-item",
                    { attrs: { link: "", to: "/" } },
                    [
                      _c("v-list-item-content", { staticClass: "logo-sec" }, [
                        _c("img", {
                          attrs: { src: require("./images/logo.svg") }
                        })
                      ])
                    ],
                    1
                  ),
                  _vm.IsDementiaAdmin || _vm.IsORGAdmin || _vm.IsORGViewer
                    ? _c(
                        "v-list-item",
                        { attrs: { link: "", to: "/users/" } },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v("mdi-account-multiple")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v("User Management "),
                                _vm.IsORGAdmin && _vm.usersCount
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "e-badge  pr-1 pl-1 e-badge-success e-badge-notification"
                                      },
                                      [_vm._v(_vm._s(_vm.usersCount))]
                                    )
                                  : _vm._e()
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.IsORGAdmin || _vm.IsORGViewer
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            link: "",
                            to: "/organisations/" + _vm.OrgInfoId
                          }
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v("mdi-domain")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Profile")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.IsDementiaAdmin
                    ? _c(
                        "v-list-item",
                        { attrs: { link: "", to: "/organisations/" } },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v("mdi-office-building")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [_vm._v("Organisations")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.IsCMSAdmin || _vm.IsDementiaAdmin
                    ? _c(
                        "v-list-item",
                        { attrs: { link: "", to: "/subscriptions/" } },
                        [
                          _c(
                            "v-list-item-action",
                            [
                              _c("v-img", {
                                attrs: {
                                  "max-height": "30",
                                  "max-width": "25",
                                  src: require("./images/SubscriptionIcon.png")
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [_vm._v("Subscriptions")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.IsCMSAdmin || _vm.IsDementiaAdmin
                    ? _c(
                        "v-list-item",
                        { attrs: { link: "", to: "/courses/" } },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v("mdi-notebook-multiple")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Courses")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.IsCMSAdmin || _vm.IsDementiaAdmin
                    ? _c(
                        "v-list-item",
                        { attrs: { link: "", to: "/modules/" } },
                        [
                          _c(
                            "v-list-item-action",
                            [
                              _c("v-icon", [
                                _vm._v("mdi-book-open-page-variant")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Modules")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("v-spacer")
            ],
            1
          )
        : _vm._e(),
      _vm.hasChrome && !this.$route.meta.layout
        ? _c(
            "v-app-bar",
            {
              class: _vm.isAuthenticated
                ? "primary light--text"
                : "container light--text",
              attrs: { app: "" }
            },
            [
              _vm.hasChrome && _vm.isAuthenticated
                ? _c("v-app-bar-nav-icon", {
                    staticClass: "light--text",
                    on: { click: _vm.toggleDrawer }
                  })
                : _vm._e(),
              _vm.hasChrome && _vm.isAuthenticated
                ? _c("v-toolbar-title", [_vm._v("LMS")])
                : _vm._e(),
              _vm.hasChrome && _vm.isAuthenticated ? _c("v-spacer") : _vm._e(),
              _c("drawer-user-list")
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-main",
        { staticClass: "grey lighten-5 primary--text" },
        [_c("v-container", { attrs: { fluid: "" } }, [_c("router-view")], 1)],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }