import { render, staticRenderFns } from "./UserList.vue?vue&type=template&id=5fdc6af4&scoped=true&"
import script from "./UserList.vue?vue&type=script&lang=js&"
export * from "./UserList.vue?vue&type=script&lang=js&"
import style0 from "./UserList.vue?vue&type=style&index=0&id=5fdc6af4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fdc6af4",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VAlert,VCol,VContainer,VIcon,VRow,VTab,VTabItem,VTabs,VTabsItems})


/* hot reload */
if (module.hot) {
  var api = require("/var/app/current/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5fdc6af4')) {
      api.createRecord('5fdc6af4', component.options)
    } else {
      api.reload('5fdc6af4', component.options)
    }
    module.hot.accept("./UserList.vue?vue&type=template&id=5fdc6af4&scoped=true&", function () {
      api.rerender('5fdc6af4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Users/UserList.vue"
export default component.exports