import CourseTypes from './CourseStoreTypes';
const { Mutations } = CourseTypes;

export default {
  namespaced: true,
  state: {
    courseFromOrg: ''
  },
  getters: {
    getOrgPath(state) {
      return state.courseFromOrg;
    }
  },
  mutations: {
    setCourseFromOrg(state, orgPath) {
      state.courseFromOrg = orgPath;
    }
  },
  actions: {
    updateCourseFromOrg({ commit }, data) {
      commit(Mutations.SET_COURSE_FROM_ORG, data);
    }
  }
};
