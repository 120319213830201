import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { apolloProvider } from './vue-apollo';
import './scss/main.scss';
// todo: get from environment
Vue.config.productionTip = false;

// Rich Text Editor
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import 'tiptap-vuetify/dist/main.css';
import DatetimePicker from 'vuetify-datetime-picker';

Vue.use(TiptapVuetifyPlugin, { vuetify });
Vue.use(DatetimePicker);

window.App = new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  render: h => h(App)
}).$mount('#app');

// Provide the store to Cypress for tests
if (window.Cypress) window.store = store;
