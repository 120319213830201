var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      this.$apollo.loading
        ? _c("div", [
            _c(
              "div",
              { staticClass: "text-center" },
              [_c("v-progress-linear", { attrs: { indeterminate: "" } })],
              1
            )
          ])
        : _vm._e(),
      _c(
        "v-container",
        { staticClass: "grey lighten-5", attrs: { fluid: "" } },
        [
          _c(
            "v-alert",
            {
              attrs: {
                dense: "",
                text: "",
                type: _vm.alert.type,
                value: _vm.alert.show,
                transition: "slide-y-transition"
              }
            },
            [_vm._v(_vm._s(_vm.alert.text))]
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "mr-auto", attrs: { cols: "auto" } },
                [
                  _c("v-breadcrumbs", {
                    staticClass: "pa-0",
                    attrs: { items: _vm.breadcrumbs }
                  }),
                  _c("h1", [
                    _vm._v(" Activity: "),
                    _c("span", { staticClass: "success--text" }, [
                      _vm._v(_vm._s(_vm.activity.title))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", [
                _c(
                  "div",
                  { staticClass: "page-sub-sec" },
                  [
                    _vm.activity.quiz
                      ? _c("v-chip", { staticClass: "ma-2" }, [
                          _vm._v(
                            " Total Number of Quizzes: " +
                              _vm._s(_vm.activity.quiz.length) +
                              " "
                          )
                        ])
                      : _c("v-chip", { staticClass: "ma-2" }, [
                          _vm._v(" Total Number of Quizzes: 0 ")
                        ])
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    { attrs: { outlined: "" } },
                    [
                      _c("v-card-title", [_c("h2", [_vm._v("Quiz")])]),
                      _c("v-card-text", [
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              _vm._l(_vm.activity.quiz, function(quiz, index) {
                                return _c(quiz.__typename, {
                                  key:
                                    quiz.__typename +
                                    (quiz.id ? quiz.id : Math.random()),
                                  ref: "quizzes",
                                  refInFor: true,
                                  tag: "component",
                                  attrs: {
                                    quiz: quiz,
                                    index: index + 1 + ". "
                                  },
                                  on: {
                                    removethiscomponent: _vm.removeComponent
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm.IsCMSAdmin
                          ? _c(
                              "div",
                              _vm._l(_vm.quizTypes, function(quizType, name) {
                                return _c(
                                  "v-btn",
                                  {
                                    key: name,
                                    staticClass: "mr-2",
                                    on: {
                                      click: function($event) {
                                        return _vm.addQuiz(quizType.name)
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", { staticClass: "mr-2" }, [
                                      _vm._v(_vm._s(quizType.icon))
                                    ]),
                                    _vm._v(" " + _vm._s(quizType.text) + " ")
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          : _vm._e()
                      ]),
                      _c(
                        "v-card-actions",
                        [
                          _vm.IsCMSAdmin
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", "x-large": "" },
                                  on: { click: _vm.saveQuiz }
                                },
                                [_vm._v("Save")]
                              )
                            : _vm._e(),
                          _c("v-spacer")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "450" },
          model: {
            value: _vm.quizvalidationalert,
            callback: function($$v) {
              _vm.quizvalidationalert = $$v
            },
            expression: "quizvalidationalert"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", [
                _c("br"),
                _c("br"),
                _c("br"),
                _c("h4", [_vm._v("Question and answers are required fields")])
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.quizvalidationalert = false
                        }
                      }
                    },
                    [_vm._v("Got it")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }