var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editor-heading" },
    [
      _c(_vm.headingLevel, { tag: "component" }, [_vm._v(_vm._s(_vm.text))]),
      _c(
        "div",
        { staticClass: "editing-panel mt-4" },
        [
          _c("v-text-field", {
            attrs: { label: "Text" },
            model: {
              value: _vm.text,
              callback: function($$v) {
                _vm.text = $$v
              },
              expression: "text"
            }
          }),
          _c("v-text-field", {
            attrs: { label: "Level" },
            model: {
              value: _vm.level,
              callback: function($$v) {
                _vm.level = $$v
              },
              expression: "level"
            }
          }),
          _c("v-text-field", {
            attrs: { label: "Anchor" },
            model: {
              value: _vm.anchor,
              callback: function($$v) {
                _vm.anchor = $$v
              },
              expression: "anchor"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }