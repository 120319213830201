var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { sm: "1" } }, [
                _c("h3", { staticClass: "font-weight-black" }, [
                  _vm._v(" " + _vm._s(_vm.index) + " ")
                ])
              ]),
              _c(
                "v-col",
                [
                  _c("h2", { staticClass: "indigo--text" }, [
                    _vm._v("Single Choice")
                  ]),
                  _c("h3", [_vm._v("Question")]),
                  _c("app-rich-text-editor", {
                    model: {
                      value: _vm.local.quiz.question,
                      callback: function($$v) {
                        _vm.$set(_vm.local.quiz, "question", $$v)
                      },
                      expression: "local.quiz.question"
                    }
                  }),
                  _c("h3", { staticClass: "mt-6" }, [_vm._v("Answers")]),
                  _c(
                    "table",
                    {
                      staticClass: "mb-6",
                      staticStyle: { "border-spacing": "0" }
                    },
                    [
                      _c("thead", [
                        _c("tr", [
                          _c("th"),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                "text-align": "left",
                                width: "100%"
                              }
                            },
                            [_vm._v(" Text ")]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "px-2",
                              staticStyle: {
                                "text-align": "left",
                                "white-space": "nowrap"
                              }
                            },
                            [_vm._v(" Correct Answer ")]
                          ),
                          _c("th", [_vm._v("Action")])
                        ])
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.local.quiz.answers, function(
                          answer,
                          _index
                        ) {
                          return _c("tr", { key: answer.id }, [
                            _c("td", { staticClass: "pr-4 pb-6 pt-4" }, [
                              _vm._v(_vm._s(_index + 1))
                            ]),
                            _c(
                              "td",
                              [
                                _c("v-text-field", {
                                  model: {
                                    value: answer.text,
                                    callback: function($$v) {
                                      _vm.$set(answer, "text", $$v)
                                    },
                                    expression: "answer.text"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "td",
                              { staticStyle: { "text-align": "center" } },
                              [
                                _c("input", {
                                  attrs: {
                                    type: "radio",
                                    name: "singelchoice" + _vm.index
                                  },
                                  domProps: {
                                    checked: answer.correct,
                                    value: answer.text
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.makeThisCorrect(_index)
                                    }
                                  }
                                })
                              ]
                            ),
                            _c(
                              "td",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary", icon: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteAnswer(_index)
                                      }
                                    }
                                  },
                                  [_c("v-icon", [_vm._v("mdi-minus-circle")])],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        }),
                        0
                      ),
                      _c(
                        "tbody",
                        {
                          staticStyle: {
                            "background-color": "rgba(0,0,0,0.05)"
                          }
                        },
                        [
                          _c("tr", [
                            _c("td"),
                            _c(
                              "td",
                              [
                                _c("v-text-field", {
                                  model: {
                                    value: _vm.forms.newAnswer.text,
                                    callback: function($$v) {
                                      _vm.$set(_vm.forms.newAnswer, "text", $$v)
                                    },
                                    expression: "forms.newAnswer.text"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("td", {
                              staticStyle: { "text-align": "center" }
                            }),
                            _c(
                              "td",
                              [
                                _vm.IsCMSAdmin
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: { icon: "", color: "primary" },
                                        on: { click: _vm.addAnswer }
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-plus-circle")
                                        ])
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("h3", { staticClass: "mt-6" }, [
                        _vm._v("Incorrect Answer Text")
                      ]),
                      _c("v-text-field", {
                        staticClass: "shrink ",
                        attrs: { outlined: "", dense: "", "hide-details": "" },
                        model: {
                          value: _vm.local.quiz.incorrectAnswerText,
                          callback: function($$v) {
                            _vm.$set(_vm.local.quiz, "incorrectAnswerText", $$v)
                          },
                          expression: "local.quiz.incorrectAnswerText"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.IsCMSAdmin
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            "x-small": "",
                            title: "Remove the current quiz"
                          },
                          on: {
                            click: function($event) {
                              return _vm.$emit(
                                "removethiscomponent",
                                _vm.local.quiz
                              )
                            }
                          }
                        },
                        [_vm._v("Remove")]
                      )
                    : _vm._e(),
                  _c(
                    "v-dialog",
                    {
                      attrs: { persistent: "", "max-width": "450" },
                      model: {
                        value: _vm.singleChoiceValidation,
                        callback: function($$v) {
                          _vm.singleChoiceValidation = $$v
                        },
                        expression: "singleChoiceValidation"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-text", [
                            _c("br"),
                            _c("br"),
                            _c("br"),
                            _c("h4", [
                              _vm._v("Question and answers are required fields")
                            ])
                          ]),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "red darken-1", text: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.singleChoiceValidation = false
                                    }
                                  }
                                },
                                [_vm._v("Got it")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }