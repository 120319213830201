var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex justify-center" },
        [
          _c(
            "v-alert",
            {
              staticClass: "v-alert",
              attrs: {
                dense: "",
                text: "",
                type: _vm.alertType,
                value: _vm.alert,
                transition: "slide-y-transition"
              }
            },
            [_vm._v(_vm._s(_vm.alertText.text))]
          )
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "grey lighten-5", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c("v-col", { staticClass: "mr-auto", attrs: { cols: "auto" } }, [
                _c("h1", [_vm._v("User Management")])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-tabs",
        {
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        _vm._l(_vm.computedtabItems, function(tab) {
          return _c(
            "v-tab",
            {
              key: tab.id,
              on: {
                click: function($event) {
                  return _vm.setActiveTab(tab.id)
                }
              }
            },
            [
              _c("v-icon", { staticClass: "mr-2" }, [_vm._v(_vm._s(tab.icon))]),
              _vm._v(" " + _vm._s(tab.text) + " ")
            ],
            1
          )
        }),
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        _vm._l(_vm.computedtabItems, function(tab) {
          return _c("v-tab-item", { key: tab.id }, [
            tab.text == "Public Learners" && _vm.IsDementiaAdmin
              ? _c("div", [_c("PublicLearners")], 1)
              : _vm._e(),
            tab.text == "Enterprise Learners"
              ? _c("div", [_c("EnterpriseLearners")], 1)
              : _vm._e(),
            tab.text == "Admin Users"
              ? _c(
                  "div",
                  [
                    _c("EnterpriseUsers", { on: { alertObj: _vm.getAlertObj } })
                  ],
                  1
                )
              : _vm._e()
          ])
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }