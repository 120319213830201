var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("div", [
            _c(
              "div",
              { staticClass: "text-center" },
              [_c("v-progress-linear", { attrs: { indeterminate: "" } })],
              1
            )
          ])
        : _vm._e(),
      _c(
        "v-container",
        { staticClass: "grey lighten-5", attrs: { fluid: "" } },
        [
          _c(
            "v-alert",
            {
              attrs: {
                dense: "",
                text: "",
                type: _vm.alert.type,
                value: _vm.alert.show,
                transition: "slide-y-transition"
              }
            },
            [_vm._v(_vm._s(_vm.alert.text))]
          ),
          !_vm.errors
            ? _c(
                "v-row",
                { attrs: { "no-gutters": "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mr-auto", attrs: { cols: "auto" } },
                    [
                      _c("v-breadcrumbs", {
                        staticClass: "pa-1",
                        attrs: { items: _vm.breadcrumbs }
                      }),
                      _c("h2", { staticClass: "pa-1" }, [
                        _vm._v(" Lesson: "),
                        _c("span", { staticClass: "success--text" }, [
                          _vm._v(_vm._s(_vm.lesson.title))
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.errors
            ? _c(
                "v-row",
                [
                  _c("v-col", [
                    _c(
                      "div",
                      [
                        _c(
                          "v-card",
                          { attrs: { color: "basil", flat: "" } },
                          [
                            _c(
                              "v-card-title",
                              { staticClass: "headline" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-3",
                                    attrs: { color: "primary" }
                                  },
                                  [_vm._v("mdi-book-open-page-variant")]
                                ),
                                _vm._v(" Lesson Details")
                              ],
                              1
                            ),
                            _c(
                              "v-form",
                              { ref: "form" },
                              [
                                _c("v-card-text", [
                                  _c(
                                    "div",
                                    { staticClass: "section-devider" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "5" } },
                                            [
                                              _c("h3", [_vm._v("Title")]),
                                              _c("v-text-field", {
                                                staticClass: "mt-5",
                                                attrs: {
                                                  "prepend-icon": "mdi-pencil",
                                                  required: "",
                                                  counter: 50,
                                                  rules: [
                                                    function(v) {
                                                      return (
                                                        !!v ||
                                                        "Title is required"
                                                      )
                                                    },
                                                    function(v) {
                                                      return (
                                                        (v && v.length <= 50) ||
                                                        "Title must be less than 50 characters"
                                                      )
                                                    }
                                                  ]
                                                },
                                                model: {
                                                  value: _vm.local.title,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.local,
                                                      "title",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "local.title"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm.IsCMSAdmin
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "ml-1",
                                                  attrs: { cols: "2" }
                                                },
                                                [
                                                  _c("h3", [
                                                    _vm._v(" Visibility ")
                                                  ]),
                                                  _c("v-switch", {
                                                    staticClass: "mt-9 pa-1",
                                                    attrs: {
                                                      inset: "",
                                                      label:
                                                        "" +
                                                        (_vm.lessonEnabled
                                                          ? "Visible"
                                                          : "Hidden"),
                                                      color: "success"
                                                    },
                                                    model: {
                                                      value: _vm.lessonEnabled,
                                                      callback: function($$v) {
                                                        _vm.lessonEnabled = $$v
                                                      },
                                                      expression:
                                                        "lessonEnabled"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "ml-10",
                                              attrs: { cols: "4" }
                                            },
                                            [
                                              _c(
                                                "h3",
                                                { staticClass: "mb-9" },
                                                [_vm._v(" Publication State ")]
                                              ),
                                              !_vm.loading
                                                ? _c(
                                                    "span",
                                                    { attrs: { cols: "4" } },
                                                    [
                                                      _vm.lessonStatus
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-file-document"
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      !_vm.lessonStatus
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-file-document-edit-outline"
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.lessonStatus ===
                                                              "Published"
                                                              ? "Published"
                                                              : "Draft"
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4" } },
                                            [
                                              _c("h3", [
                                                _vm._v(" Learning Minutes ")
                                              ]),
                                              _c("v-text-field", {
                                                attrs: {
                                                  "prepend-icon": "mdi-pencil"
                                                },
                                                model: {
                                                  value:
                                                    _vm.learningMinuteDuration,
                                                  callback: function($$v) {
                                                    _vm.learningMinuteDuration = $$v
                                                  },
                                                  expression:
                                                    "learningMinuteDuration"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "section-devider" },
                                    [
                                      _c("h2", [_vm._v("Learning Objective")]),
                                      _c("v-autocomplete", {
                                        attrs: {
                                          items:
                                            _vm.availableLearingObjectivesForCourse,
                                          label: "Learning Objective*",
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.local.LearningObjectiveid,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.local,
                                              "LearningObjectiveid",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "local.LearningObjectiveid"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "section-devider" },
                                    [
                                      _c("h2", [_vm._v("Lesson Card Image")]),
                                      _c(
                                        "h5",
                                        { staticClass: "warning--text mt-3" },
                                        [
                                          _vm._v(
                                            " The image should be landscape (16:9 pixel) for better visibility in mobile view. Slightly larger frames for images to be seen completley "
                                          )
                                        ]
                                      ),
                                      _c("div", [
                                        _vm.isUploading || _vm.imageUrl
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "lesson-card-container"
                                              },
                                              [
                                                _vm.isUploading
                                                  ? _c("v-progress-circular", {
                                                      staticStyle: {
                                                        top: "50px"
                                                      },
                                                      attrs: {
                                                        small: "",
                                                        size: 20,
                                                        indeterminate: ""
                                                      }
                                                    })
                                                  : _vm._e(),
                                                !_vm.isUploading && _vm.imageUrl
                                                  ? _c("img", {
                                                      staticStyle: {
                                                        width: "100%",
                                                        height: "100%"
                                                      },
                                                      attrs: {
                                                        alt: "Avatar",
                                                        src: _vm.imageUrl
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "pa-1 ma-1" },
                                          [
                                            _c("v-file-input", {
                                              attrs: {
                                                id: "fileUpload",
                                                accept: "image/*",
                                                "truncate-length": "31",
                                                rules: [
                                                  function(value) {
                                                    return (
                                                      !value ||
                                                      value.size < 500000 ||
                                                      "Image size should be less than 500 KB!"
                                                    )
                                                  }
                                                ],
                                                disabled: !_vm.IsCMSAdmin,
                                                filled: "",
                                                "prepend-icon": "mdi-plus"
                                              },
                                              on: { change: _vm.onFilePicked }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "section-devider" },
                                    [
                                      _c("h2", [_vm._v("Lesson Image")]),
                                      _c("app-content-header-editor", {
                                        model: {
                                          value: _vm.local.introHeaderContent,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.local,
                                              "introHeaderContent",
                                              $$v
                                            )
                                          },
                                          expression: "local.introHeaderContent"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "section-devider" },
                                    [
                                      _c("h2", [
                                        _vm._v("Introduction Content")
                                      ]),
                                      _c("app-content-list-editor", {
                                        model: {
                                          value: _vm.local.intro,
                                          callback: function($$v) {
                                            _vm.$set(_vm.local, "intro", $$v)
                                          },
                                          expression: "local.intro"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "section-devider" },
                                    [
                                      _c("h2", [_vm._v("Call To Action")]),
                                      _c(
                                        "div",
                                        { staticClass: "row" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "3" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label:
                                                    "Introduction/Pre learning material access Text",
                                                  required: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.local.callToAction
                                                      .introAttemptQuizText,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.local.callToAction,
                                                      "introAttemptQuizText",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n                          local.callToAction.introAttemptQuizText\n                        "
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "6" } },
                                            [
                                              _vm.local.callToAction
                                                .introAttemptQuizStatus
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "mr-2 success",
                                                      attrs: {
                                                        rounded: "",
                                                        disabled: !_vm.IsCMSAdmin,
                                                        small: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.local.callToAction.introAttemptQuizStatus = false
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("Disable")]
                                                  )
                                                : _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "mr-2 warning",
                                                      attrs: {
                                                        rounded: "",
                                                        small: "",
                                                        disabled: !_vm.IsCMSAdmin
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.local.callToAction.introAttemptQuizStatus = true
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("Enable")]
                                                  )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "row" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "3" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label:
                                                    "Post learning access Text",
                                                  required: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.local.callToAction
                                                      .materialAttemptQuizText,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.local.callToAction,
                                                      "materialAttemptQuizText",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n                          local.callToAction.materialAttemptQuizText\n                        "
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "6" } },
                                            [
                                              _vm.local.callToAction
                                                .materialAttemptQuizStatus
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "mr-2 success",
                                                      attrs: {
                                                        rounded: "",
                                                        disabled: !_vm.IsCMSAdmin,
                                                        small: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.local.callToAction.materialAttemptQuizStatus = false
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("Disable")]
                                                  )
                                                : _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "mr-2 warning",
                                                      attrs: {
                                                        rounded: "",
                                                        small: "",
                                                        disabled: !_vm.IsCMSAdmin
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.local.callToAction.materialAttemptQuizStatus = true
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("Enable")]
                                                  )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]),
                                _c(
                                  "v-card-actions",
                                  { staticClass: "pt-0" },
                                  [
                                    _vm.IsCMSAdmin &&
                                    !_vm.lessonPublished &&
                                    !_vm.lessonDraftSaved
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "primary",
                                              "x-large": ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.updateLesson(0)
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                "mdi-file-document-edit-outline"
                                              )
                                            ]),
                                            _vm._v("Save As Draft ")
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.IsCMSAdmin &&
                                    (_vm.lessonPublished ||
                                      _vm.lessonDraftSaved)
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "primary",
                                              "x-large": ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.updateLesson(1)
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-content-save")
                                            ]),
                                            _vm._v("Save ")
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.IsCMSAdmin && !_vm.lessonPublished
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "primary",
                                              "x-large": ""
                                            },
                                            on: { click: _vm.showPublishDialog }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-file-document")
                                            ]),
                                            _vm._v("Publish ")
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "v-col",
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "v-card",
                            { attrs: { color: "basil", flat: "" } },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "headline" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-3",
                                      attrs: { color: "primary" }
                                    },
                                    [_vm._v("mdi-bookshelf")]
                                  ),
                                  _vm._v(" Learning Materials")
                                ],
                                1
                              ),
                              _c("v-card-text", [
                                _c(
                                  "div",
                                  { staticClass: "section-devider mt-4" },
                                  [
                                    _c("h2", [
                                      _vm._v("Learning Material Header")
                                    ]),
                                    _c("app-content-header-editor", {
                                      model: {
                                        value:
                                          _vm.local.materialintroHeaderContent,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.local,
                                            "materialintroHeaderContent",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "local.materialintroHeaderContent"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "section-devider" },
                                  [
                                    _c("h2", [
                                      _vm._v("Learning Material Content")
                                    ]),
                                    _c("app-content-list-editor", {
                                      model: {
                                        value: _vm.local.materialContent,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.local,
                                            "materialContent",
                                            $$v
                                          )
                                        },
                                        expression: "local.materialContent"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card",
                        {
                          staticClass: "mt-3",
                          attrs: { color: "basil", flat: "" }
                        },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-tabs",
                                {
                                  attrs: { replace: "" },
                                  model: {
                                    value: _vm.tab,
                                    callback: function($$v) {
                                      _vm.tab = $$v
                                    },
                                    expression: "tab"
                                  }
                                },
                                _vm._l(_vm.tabItems, function(tab, i) {
                                  return _c(
                                    "v-tab",
                                    {
                                      key: i,
                                      on: {
                                        click: function($event) {
                                          return _vm.setActiveTab(i)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", { staticClass: "mr-2" }, [
                                        _vm._v(_vm._s(tab.icon))
                                      ]),
                                      _vm._v(" " + _vm._s(tab.text) + " ")
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                              _c(
                                "v-tabs-items",
                                {
                                  model: {
                                    value: _vm.tab,
                                    callback: function($$v) {
                                      _vm.tab = $$v
                                    },
                                    expression: "tab"
                                  }
                                },
                                [
                                  _c("v-tab-item", { key: 0 }, [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            attrs: { color: "basil", flat: "" }
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "page-sub-sec"
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "shrink",
                                                      attrs: {
                                                        "append-icon":
                                                          "mdi-magnify",
                                                        label: "Search",
                                                        outlined: "",
                                                        dense: "",
                                                        "hide-details": ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.searchActivity,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.searchActivity = $$v
                                                        },
                                                        expression:
                                                          "searchActivity"
                                                      }
                                                    }),
                                                    _vm.IsCMSAdmin
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              color: "primary"
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.LoadCreateActivity
                                                            }
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v("mdi-plus")
                                                            ]),
                                                            _vm._v(
                                                              " Create Activity"
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                ),
                                                _c("v-data-table", {
                                                  attrs: {
                                                    items:
                                                      _vm.orderedActivities,
                                                    headers:
                                                      _vm.computedActivityheaders,
                                                    loading: _vm.loading,
                                                    search: _vm.searchActivity,
                                                    "no-data-text":
                                                      "No activity available",
                                                    "hide-default-footer": _vm.activityPaginationStatus()
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "item.id",
                                                        fn: function(ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.getIndex(
                                                                      item,
                                                                      _vm.orderedActivities
                                                                    ) + 1
                                                                  )
                                                              )
                                                            ])
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "item.title",
                                                        fn: function(ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c(
                                                              "router-link",
                                                              {
                                                                staticClass:
                                                                  "blue--text",
                                                                attrs: {
                                                                  to:
                                                                    "/activity/" +
                                                                    _vm.courseId +
                                                                    "/" +
                                                                    _vm.lessonId +
                                                                    "/" +
                                                                    item.id
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.title
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "item.quiz",
                                                        fn: function(ref) {
                                                          var item = ref.item
                                                          return [
                                                            item.quiz
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item
                                                                          .quiz
                                                                          .length
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ])
                                                              : _c("span", [
                                                                  _vm._v("0")
                                                                ])
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "item.action",
                                                        fn: function(ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "lessons-actions-wrap"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "blue",
                                                                      icon: "",
                                                                      small: ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.LoadActivity(
                                                                          item
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          title:
                                                                            "Edit"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-pencil"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm.hideDelete ==
                                                                "false"
                                                                  ? _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "red",
                                                                          icon:
                                                                            "",
                                                                          small:
                                                                            ""
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.LoadConfirmDialogForDeleteActivity(
                                                                              item
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs: {
                                                                              title:
                                                                                "Delete"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-trash-can-outline"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("v-tab-item", { key: 1 }, [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            attrs: { color: "basil", flat: "" }
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "page-sub-sec"
                                                  },
                                                  [
                                                    _vm.IsCMSAdmin
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "ml-auto",
                                                            attrs: {
                                                              color: "primary"
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.LoadCreateSearchKeyword
                                                            }
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v("mdi-plus")
                                                            ]),
                                                            _vm._v(
                                                              " Create Keyword"
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "4"
                                                        }
                                                      },
                                                      [
                                                        _c("v-autocomplete", {
                                                          attrs: {
                                                            items:
                                                              _vm.local
                                                                .searchKeywords,
                                                            label: "Select",
                                                            multiple: "",
                                                            chips: "",
                                                            hint:
                                                              "Lesson keywords for search",
                                                            "persistent-hint":
                                                              ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.local
                                                                .searchKeywords,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.local,
                                                                "searchKeywords",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "local.searchKeywords"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-card-actions",
                                              { staticClass: "pt-0" },
                                              [
                                                _vm.IsCMSAdmin
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                          "x-large": ""
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.createSearchKeyword
                                                        }
                                                      },
                                                      [_vm._v("Save")]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("v-tab-item", { key: 2 }, [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            attrs: { color: "basil", flat: "" }
                                          },
                                          [
                                            _c(
                                              "v-form",
                                              { ref: "form" },
                                              [
                                                _c(
                                                  "v-card-text",
                                                  [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              sm: "6"
                                                            }
                                                          },
                                                          [
                                                            _c("v-card-title", [
                                                              _vm._v(
                                                                "100% Completion"
                                                              )
                                                            ]),
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                label: "Header",
                                                                readonly: !_vm.IsCMSAdmin,
                                                                rules: [
                                                                  function(v) {
                                                                    return (
                                                                      !!v ||
                                                                      "full completion header is required"
                                                                    )
                                                                  }
                                                                ],
                                                                required: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.courseCompletionFullHeader,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.courseCompletionFullHeader = $$v
                                                                },
                                                                expression:
                                                                  "courseCompletionFullHeader"
                                                              }
                                                            }),
                                                            _c(
                                                              "v-card-text",
                                                              {
                                                                staticClass:
                                                                  "header-body"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Description"
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "app-rich-text-editor",
                                                              {
                                                                model: {
                                                                  value:
                                                                    _vm.courseCompletionFull,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.courseCompletionFull = $$v
                                                                  },
                                                                  expression:
                                                                    "courseCompletionFull"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              sm: "6"
                                                            }
                                                          },
                                                          [
                                                            _c("v-card-title", [
                                                              _vm._v(
                                                                "Partial Completion"
                                                              )
                                                            ]),
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                label: "Header",
                                                                rules: [
                                                                  function(v) {
                                                                    return (
                                                                      !!v ||
                                                                      "partial completion header is required"
                                                                    )
                                                                  }
                                                                ],
                                                                readonly: !_vm.IsCMSAdmin,
                                                                required: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.courseCompletionPartialHeader,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.courseCompletionPartialHeader = $$v
                                                                },
                                                                expression:
                                                                  "\n                                courseCompletionPartialHeader\n                              "
                                                              }
                                                            }),
                                                            _c(
                                                              "v-card-text",
                                                              {
                                                                staticClass:
                                                                  "header-body"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Description"
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "app-rich-text-editor",
                                                              {
                                                                model: {
                                                                  value:
                                                                    _vm.courseCompletionPartial,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.courseCompletionPartial = $$v
                                                                  },
                                                                  expression:
                                                                    "courseCompletionPartial"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-card-actions",
                                                  [
                                                    _vm.IsCMSAdmin
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "primary",
                                                            attrs: {
                                                              "x-large": ""
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.saveQuizCompletion
                                                            }
                                                          },
                                                          [_vm._v("Save")]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.confirmdialogPublish,
            callback: function($$v) {
              _vm.confirmdialogPublish = $$v
            },
            expression: "confirmdialogPublish"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Publish Lesson")
              ]),
              _c("v-card-text", [
                _vm._v("Are you sure you want to publish the lesson "),
                _c("span", { staticStyle: { color: "green" } }, [
                  _vm._v(" " + _vm._s(_vm.lesson.title))
                ]),
                _vm._v("? "),
                _c("br"),
                _c("span", { staticStyle: { "font-weight": "bold" } }, [
                  _vm._v(
                    "Once a lesson has been published it cannot be unpublished."
                  )
                ])
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "#113247 darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmdialogPublish = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "publishButton",
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          return _vm.updateLesson(2)
                        }
                      }
                    },
                    [_vm._v("PUBLISH")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "400px" },
          model: {
            value: _vm.showActivityDialog,
            callback: function($$v) {
              _vm.showActivityDialog = $$v
            },
            expression: "showActivityDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Activity")
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Title*", required: "" },
                                model: {
                                  value: _vm.forms.createActivity.title,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.forms.createActivity,
                                      "title",
                                      $$v
                                    )
                                  },
                                  expression: "forms.createActivity.title"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { depressed: "", text: "" },
                      on: {
                        click: function($event) {
                          _vm.showActivityDialog = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _vm.forms.createActivity.id > 0
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.UpdateActivity }
                        },
                        [_vm._v(" Update ")]
                      )
                    : _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.createActivity }
                        },
                        [_vm._v(" Save ")]
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "400px" },
          model: {
            value: _vm.showSearchKeywordDialog,
            callback: function($$v) {
              _vm.showSearchKeywordDialog = $$v
            },
            expression: "showSearchKeywordDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Search Keyword")
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Keyword*", required: "" },
                                model: {
                                  value: _vm.local.keyword,
                                  callback: function($$v) {
                                    _vm.$set(_vm.local, "keyword", $$v)
                                  },
                                  expression: "local.keyword"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { depressed: "", text: "" },
                      on: {
                        click: function($event) {
                          _vm.showSearchKeywordDialog = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.addSearchKeyword }
                    },
                    [_vm._v(" Save ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "450" },
          model: {
            value: _vm.confirmdialog.show,
            callback: function($$v) {
              _vm.$set(_vm.confirmdialog, "show", $$v)
            },
            expression: "confirmdialog.show"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.confirmdialog.HeaderText))
              ]),
              _c("v-card-text", [
                _vm._v(_vm._s(_vm.confirmdialog.ContentText))
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmdialog.show = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.confirmdialog.ButtonConfirmAction(
                            _vm.confirmdialog.customObject
                          )
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.confirmdialog.ButtonConfirmText))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }