<template>
  <report-layout>
    <div>
      <v-container class=" mainContainer ml-2 pt-0">
        <v-row>
          <div>
            <img
              style="height: 45px;"
              src="../../images/DementiaPdfLogo.png"
            />
          </div>
        </v-row>
        <v-col>
          <v-row class="mt-7">
            <span style="font-size:14px ; font-family: 'filsonBold';"
              >Ask Annie</span
            >
          </v-row>

          <v-row>
            <span style="font-size:26px; font-family: 'filsonBold';"
              >Lesson Completion History</span
            >
          </v-row>
          <div v-if="!showError" class="mt-5 pa-0">
            <v-row>
              <span style="font-size:11px;font-family: 'filsonBook' "
                >The following document was generated for
                {{
                  generateReportData.firstName
                    ? generateReportData.firstName
                    : ''
                }}
                {{
                  generateReportData.lastName
                    ? generateReportData.lastName
                    : ''
                }}
                on the
                {{ generatedDate ? loadDate(generatedDate) : '' }}.
              </span>
            </v-row>

            <div
              v-for="subscription in generateReportData.subscription"
              :key="subscription.id"
            >
              <div v-if="subscription.courses">
                <v-row class="mt-4">
                  <span
                    style="font-size:22px; font-family: 'filsonBold';"
                    >{{ subscription.subscriptionname }}</span
                  >
                </v-row>
                <div
                  v-for="course in subscription.courses"
                  :key="course.id"
                >
                  <div v-if="course.modules" class="mt-2">
                    <v-row>
                      <span
                        style="font-size:18px; font-family: 'filsonBold';"
                        >{{ course.courseName }}
                      </span>
                    </v-row>
                    <div
                      v-for="module in course.modules"
                      :key="module.id"
                    >
                      <div v-if="module.lessons" class="mt-2">
                        <v-row>
                          <span
                            style="font-size:12px; font-family: 'filsonBold';"
                            >{{
                              module.moduleName
                                ? module.moduleName
                                : ''
                            }}
                          </span>
                        </v-row>
                        <v-row style="font-size:10px" class="mt-1">
                          <p>
                            <span style="font-family: 'filsonMedium'">
                              Learning objectives:&nbsp;&nbsp; </span
                            ><span style="font-family: 'filsonBook'">
                              {{ module.objectivies }}
                            </span>
                          </p>
                        </v-row>
                        <v-row>
                          <v-simple-table>
                            <thead>
                              <tr
                                style="background: #000000;color: white; font-family: 'filsonBold';width:100%;"
                              >
                                <th
                                  style="color: white;font-size:8px;width:20%"
                                >
                                  Date of last completion
                                </th>
                                <th
                                  style="color: white;font-size:8px;width:40% !important"
                                >
                                  Lesson
                                </th>
                                <th
                                  style="color: white;font-size:8px;width:20%"
                                >
                                  Learning Minutes
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="lesson in module.lessons"
                                :key="lesson.id"
                                style="font-family: 'filsonBook';width:100%;"
                              >
                                <td style="font-size:9px;width:20%">
                                  {{
                                    lesson
                                      ? loadDate(
                                          lesson.lastCompletionDate
                                        )
                                      : ''
                                  }}
                                </td>
                                <td
                                  style="font-size:9px;width:40% !important"
                                >
                                  {{ lesson.lessonName }}
                                </td>
                                <td style="font-size:9px;width:20%">
                                  {{ lesson.lessonDuration }}
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <span
              style="color:red;font-size:18px; font-family: 'filsonBold';"
              >No Data for this User Report</span
            >
          </div>
        </v-col>
      </v-container>
    </div>
  </report-layout>
</template>
<script>
import { mapGetters } from 'vuex';
import ReportLayout from '@/components/layouts/ReportLayout';
import moment from 'moment';
import gql from 'graphql-tag';
const ROLESQUERY = gql`
  query Roles {
    roles {
      name
    }
  }
`;
export default {
  generatedDate: '',
  name: 'GetReport',
  components: {
    ReportLayout
  },
  data: () => ({
    generateReportData: {},
    userToken: '',
    showError: false
  }),
  computed: {
    ...mapGetters('auth', ['isAuthenticated', 'isInit'])
  },

  methods: {
    getCurrentDate: function() {
      var currentDate = new Date();
      this.generatedDate = currentDate;
      return this.generatedDate;
    },
    loadDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    async getReportData() {
      this.userToken = localStorage.getItem('apollo-token');
      const headerOptions = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.userToken
      };
      const bodyData = JSON.stringify({
        operationName: 'LessonCompletionReport',
        query: `
        query LessonCompletionReport {
        lessonCompletionReport {
         data {
        firstName
        lastName
        subscription {
          subscriptionname
          courses {
            courseName
            modules {
              moduleName
              objectivies
              lessons {
                lessonName
                lessonDuration
                lastCompletionDate
              }
            }
          }
        }
      }
      }
    }
    `,
        variables: {}
      });
      const apiurl = process.env.VUE_APP_BASE_API + '/graphql';
      fetch(apiurl, {
        method: 'POST',
        headers: headerOptions,
        body: bodyData
      })
        .then(res => res.json())
        .then(result => {
          if (result.data.lessonCompletionReport.data != null) {
            this.generateReportData =
              result.data.lessonCompletionReport.data;
          } else {
            this.showError = true;
          }
        })
        .catch(error => {
          this.showError = true;

          console.log('No Data: ' + error);
        });
    }
  },
  apollo: {
    getRoles() {
      this.getReportData();
      this.getCurrentDate();
      return {
        query: ROLESQUERY,
        fetchPolicy: 'cache-and-network',

        skip() {
          return !this.Learner;
        }
      };
    }
  }
};
</script>
<style lang="scss" scoped>
div {
  width: 100%;
}

.mainContainer {
  -webkit-print-color-adjust: exact;
  font-family: 'filson';
  max-width: 100%;
}
@font-face {
  font-family: 'filsonBold';
  src: url('/FilsonProBold.ttf');
}
@font-face {
  font-family: 'filsonMedium';
  src: url('/FilsonProMedium.ttf');
}
@font-face {
  font-family: 'filsonBook';
  src: url('/FilsonProBook.ttf');
}
</style>
