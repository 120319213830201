var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("report-layout", [
    _c(
      "div",
      [
        _c(
          "v-container",
          { staticClass: " mainContainer ml-2 pt-0" },
          [
            _c("v-row", [
              _c("div", [
                _c("img", {
                  staticStyle: { height: "45px" },
                  attrs: { src: require("../../images/DementiaPdfLogo.png") }
                })
              ])
            ]),
            _c(
              "v-col",
              [
                _c("v-row", { staticClass: "mt-7" }, [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        "font-family": "'filsonBold'"
                      }
                    },
                    [_vm._v("Ask Annie")]
                  )
                ]),
                _c("v-row", [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "26px",
                        "font-family": "'filsonBold'"
                      }
                    },
                    [_vm._v("Lesson Completion History")]
                  )
                ]),
                !_vm.showError
                  ? _c(
                      "div",
                      { staticClass: "mt-5 pa-0" },
                      [
                        _c("v-row", [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "11px",
                                "font-family": "'filsonBook'"
                              }
                            },
                            [
                              _vm._v(
                                "The following document was generated for " +
                                  _vm._s(
                                    _vm.generateReportData.firstName
                                      ? _vm.generateReportData.firstName
                                      : ""
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.generateReportData.lastName
                                      ? _vm.generateReportData.lastName
                                      : ""
                                  ) +
                                  " on the " +
                                  _vm._s(
                                    _vm.generatedDate
                                      ? _vm.loadDate(_vm.generatedDate)
                                      : ""
                                  ) +
                                  ". "
                              )
                            ]
                          )
                        ]),
                        _vm._l(_vm.generateReportData.subscription, function(
                          subscription
                        ) {
                          return _c("div", { key: subscription.id }, [
                            subscription.courses
                              ? _c(
                                  "div",
                                  [
                                    _c("v-row", { staticClass: "mt-4" }, [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "22px",
                                            "font-family": "'filsonBold'"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              subscription.subscriptionname
                                            )
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._l(subscription.courses, function(
                                      course
                                    ) {
                                      return _c("div", { key: course.id }, [
                                        course.modules
                                          ? _c(
                                              "div",
                                              { staticClass: "mt-2" },
                                              [
                                                _c("v-row", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "18px",
                                                        "font-family":
                                                          "'filsonBold'"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          course.courseName
                                                        ) + " "
                                                      )
                                                    ]
                                                  )
                                                ]),
                                                _vm._l(course.modules, function(
                                                  module
                                                ) {
                                                  return _c(
                                                    "div",
                                                    { key: module.id },
                                                    [
                                                      module.lessons
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mt-2"
                                                            },
                                                            [
                                                              _c("v-row", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle: {
                                                                      "font-size":
                                                                        "12px",
                                                                      "font-family":
                                                                        "'filsonBold'"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        module.moduleName
                                                                          ? module.moduleName
                                                                          : ""
                                                                      ) + " "
                                                                    )
                                                                  ]
                                                                )
                                                              ]),
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "mt-1",
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "10px"
                                                                  }
                                                                },
                                                                [
                                                                  _c("p", [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle: {
                                                                          "font-family":
                                                                            "'filsonMedium'"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Learning objectives: "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle: {
                                                                          "font-family":
                                                                            "'filsonBook'"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              module.objectivies
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-row",
                                                                [
                                                                  _c(
                                                                    "v-simple-table",
                                                                    [
                                                                      _c(
                                                                        "thead",
                                                                        [
                                                                          _c(
                                                                            "tr",
                                                                            {
                                                                              staticStyle: {
                                                                                background:
                                                                                  "#000000",
                                                                                color:
                                                                                  "white",
                                                                                "font-family":
                                                                                  "'filsonBold'",
                                                                                width:
                                                                                  "100%"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "th",
                                                                                {
                                                                                  staticStyle: {
                                                                                    color:
                                                                                      "white",
                                                                                    "font-size":
                                                                                      "8px",
                                                                                    width:
                                                                                      "20%"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Date of last completion "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "th",
                                                                                {
                                                                                  staticStyle: {
                                                                                    color:
                                                                                      "white",
                                                                                    "font-size":
                                                                                      "8px",
                                                                                    width:
                                                                                      "40% !important"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Lesson "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "th",
                                                                                {
                                                                                  staticStyle: {
                                                                                    color:
                                                                                      "white",
                                                                                    "font-size":
                                                                                      "8px",
                                                                                    width:
                                                                                      "20%"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Learning Minutes "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "tbody",
                                                                        _vm._l(
                                                                          module.lessons,
                                                                          function(
                                                                            lesson
                                                                          ) {
                                                                            return _c(
                                                                              "tr",
                                                                              {
                                                                                key:
                                                                                  lesson.id,
                                                                                staticStyle: {
                                                                                  "font-family":
                                                                                    "'filsonBook'",
                                                                                  width:
                                                                                    "100%"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "td",
                                                                                  {
                                                                                    staticStyle: {
                                                                                      "font-size":
                                                                                        "9px",
                                                                                      width:
                                                                                        "20%"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          lesson
                                                                                            ? _vm.loadDate(
                                                                                                lesson.lastCompletionDate
                                                                                              )
                                                                                            : ""
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "td",
                                                                                  {
                                                                                    staticStyle: {
                                                                                      "font-size":
                                                                                        "9px",
                                                                                      width:
                                                                                        "40% !important"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          lesson.lessonName
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "td",
                                                                                  {
                                                                                    staticStyle: {
                                                                                      "font-size":
                                                                                        "9px",
                                                                                      width:
                                                                                        "20%"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          lesson.lessonDuration
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          }
                                                                        ),
                                                                        0
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                })
                                              ],
                                              2
                                            )
                                          : _vm._e()
                                      ])
                                    })
                                  ],
                                  2
                                )
                              : _vm._e()
                          ])
                        })
                      ],
                      2
                    )
                  : _c("div", [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "red",
                            "font-size": "18px",
                            "font-family": "'filsonBold'"
                          }
                        },
                        [_vm._v("No Data for this User Report")]
                      )
                    ])
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }