<template>
  <div>
    <v-container fluid class="grey lighten-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
          <h1>
            Profile:<span class="success--text">{{
              user.firstName
            }}</span>
          </h1>
          <h3 class="info--text">{{ org.name }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card color="basil" flat>
            <v-card-title>
              <h3>Details</h3>
            </v-card-title>
            <v-card-text>
              <dl>
                <dt>Email</dt>
                <dd>{{ user.email }}</dd>
                <div v-if="user.firstName">
                  <dt>First name</dt>
                  <dd>{{ user.firstName }}</dd>
                </div>
                <div v-if="user.lastName">
                  <dt>Last name</dt>
                  <dd>{{ user.lastName }}</dd>
                </div>
                <div v-if="user.mobile">
                  <dt>Mobile</dt>
                  <dd>{{ user.mobile }}</dd>
                </div>
              </dl>
            </v-card-text>
          </v-card>
          <v-card color="basil" class="mt-5" flat>
            <v-card-title>
              <h3>Lessons Attempted</h3>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :items="lessonResults"
                :headers="lessonHeaders"
                :no-data-text="`No attempted lessons are available`"
                :hide-default-footer="lessonsPaginationStatus()"
              >
                <!-- <template v-slot:[`item.title`]="{ item }">
                  <div
                    :title="
                      item.notpresent
                        ? 'This lesson is no longer associated with the learner\'s organization'
                        : ''
                    "
                  >
                    {{ item.title }}
                    <span
                      v-if="item.notpresent"
                      class="red--text font-weight-bold font-weight-black"
                      >{{ item.notpresent }}</span
                    >
                  </div>
                </template> -->
                <template v-slot:[`item.title`]="{ item }">
                  {{ item.title }}
                </template>
                <template v-slot:[`item.completed`]="{ item }">
                  {{ item.completed ? 'Completed' : 'In Progress' }}
                </template>
                <template v-slot:[`item.success`]="{ item }">
                  {{ item.success + '/' + item.activities }}
                </template>
                <template v-slot:[`item.at`]="{ item }">
                  {{ item.at }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import clone from 'rfdc';

const cloner = clone();

const USER_QUERY = gql`
  query($userId: ID!) {
    user(id: $userId) {
      id
      username
      email
      firstName
      lastName
      mobile
      results {
        id
        at
        lesson {
          id
          title
        }
        completed
        evaluation
      }
    }
  }
`;

const ORG_QUERY = gql`
  query($orgId: ID!) {
    org(id: $orgId) {
      id
      name
      courses {
        id
        lessons {
          id
        }
      }
    }
  }
`;

export default {
  name: 'OrgLearner',
  data: () => ({
    user: {},
    org: {},
    local: {
      user: {}
    },
    lessonHeaders: [
      { text: 'Title', value: 'title' },
      { text: 'Lesson Status', value: 'completed' },
      {
        text: 'Last Attempted Quiz Score',
        value: 'success',
        align: 'center',
        sortable: false
      },
      { text: 'Attempted At', value: 'at', align: 'center' }
    ]
  }),
  computed: {
    learnerId() {
      return this.$route.params.learnerId;
    },
    orgId() {
      return this.$route.params.orgId;
    },
    breadcrumbs() {
      const bct = [];

      if (!this.org) {
        bct.push({ text: 'Org', disabled: true });
      } else {
        bct.push({
          text: this.org.name,
          href: `#/organisations/${this.org.id}`
        });
      }

      if (!this.user) {
        bct.push({ text: 'User', disabled: true });
      } else {
        bct.push({ text: this.user.username });
      }

      return bct;
    },
    lessonResults() {
      if (!this.user || !this.user.results || !this.org.courses)
        return [];

      const lessonResults = {};

      const userResult = cloner(this.user.results);
      const lessonIds = this.org.courses
        .flatMap(_course => _course.lessons)
        .flatMap(_lesson => _lesson.id);

      userResult.sort((a, b) => new Date(a.at) - new Date(b.at));

      for (const result of userResult) {
        if (result.lesson) {
          const success = Object.values(result.evaluation).filter(
            r => r
          ).length;
          const activities = Object.values(result.evaluation).length;

          lessonResults[result.lesson.id] = {
            id: result.lesson.id,
            title: result.lesson.title,
            at: result.at,
            success,
            activities,
            completed: result.completed
          };
        }
      }

      return Object.values(lessonResults).map(lr => {
        if (!lessonIds.includes(lr.id)) {
          lr.notpresent = '*';
        }
        return lr;
      });
    }
  },
  methods: {
    lessonsPaginationStatus() {
      if (this.lessonResults && this.lessonResults.length > 0)
        return false;
      else return true;
    }
  },
  apollo: {
    org() {
      return {
        query: ORG_QUERY,
        variables: {
          orgId: this.orgId
        }
      };
    },
    user() {
      return {
        query: USER_QUERY,
        variables: {
          userId: this.learnerId
        }
      };
    }
  }
};
</script>
