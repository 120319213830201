import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';

// A la carte mode
import Vuetify, {
  VApp,
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VContainer,
  VDataTable,
  VDialog,
  VForm,
  VIcon,
  VSkeletonLoader,
  VSpacer,
  VTextField
} from 'vuetify/lib';

// import minifyTheme from 'minify-css-string';
import 'vuetify/dist/vuetify.min.css';

// The Vue CLI service will perform tree-shaking automagically
// But Storybook will not, so you need to register all of the
// Vuetify components/directives that components mounted in
// Storybook use.
Vue.use(Vuetify, {
  components: {
    VApp,
    VBtn,
    VCard,
    VCardActions,
    VCardTitle,
    VCardText,
    VContainer,
    VDataTable,
    VDialog,
    VForm,
    VIcon,
    VSkeletonLoader,
    VSpacer,
    VTextField
  }
});

let vuetify = new Vuetify({
  theme: {
    // dark: true,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#003349',
        secondary: '#E0E721',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        light: '#fff',
        dark: '#000'
      }
    }
  },
  icons: {
    iconfont: 'md'
  }
});

export default vuetify;
