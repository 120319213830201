export default Object.freeze({
  Mutations: {
    SET_USER: 'setUser',
    SET_AUTH_STATE: 'setAuthState',
    SET_APOLLO: 'setApollo'
  },
  Actions: {
    INIT: 'init',
    LOGIN: 'login',
    AUTHENTICATE: 'authenticate',
    LOGOUT: 'logout',
    CANCEL_LOGIN: 'cancelLogin'
  },
  AuthState: {
    INIT: 'Initialising',
    UNAUTHENTICATED: 'Unauthenticated',
    AUTHENTICATING: 'Authenticating',
    UNAUTHENTICATING: 'Unauthenticating',
    AUTHENTICATED: 'Authenticated',
    AUTH_ERROR: 'Authentication Error',
    LOGIN_FAILED: 'Loginfailed'
  }
});
