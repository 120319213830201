<template>
  <div class="info-head">
    <v-img v-if="user.avatar" :src="user.avatar" />
    <v-btn v-if="!user.avatar" color="secondary" outlined rounded>
      <v-icon class="mr-2">mdi-account</v-icon>
      <span>{{ user.firstName }}</span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'DrawerUserItem',
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss">
.drawer-user-item {
  .v-avatar {
    background-color: black;
  }
}
</style>
