export const Constant = {
  roles: {
    DEMENTIA_ADMIN: 'DEMINTIA_ADMIN', //3
    CMS_ADMIN: 'CMS_ADMIN', //6
    ORG_ADMIN: 'ORG_ADMIN', //4
    ORG_VIEWER: 'ORG_VIEWER', //7
    LEARNER: 'LEARNER', //5
    AUTHENTICATED: 'Authenticated', //1
    PUBLIC: 'Public', //2

    //This role is only for view purpse since we can not show misspelt DEMINTIA_ADMIN
    DEMENTIA_ADMIN_VIEW: 'Dementia Admin',
    CMS_ADMIN_VIEW: 'CMS Admin',
    ORG_ADMIN_VIEW: 'Org Admin',
    ORG_VIEWER_VIEW: 'Org Viewer',
    LEARNER_VIEW: 'Learner'
  },
  AUTH_TOKEN: 'apollo-token' // Name of the localStorage item
};
