<template>
  <v-container fluid class="grey lighten-5">
    <v-row no-gutters align="center">
      <v-col cols="auto" class="mr-auto">
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
        <h1>
          Profile:<span class="success--text">{{
            user.firstName
          }}</span>
        </h1>
        <h3 class="info--text">{{ org.name }}</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card color="basil" flat>
          <v-card-title>
            <h3>Details</h3>
          </v-card-title>
          <v-card-text>
            <dl>
              <dt>Email</dt>
              <dd>{{ user.email }}</dd>
              <div v-if="user.firstName">
                <dt>First name</dt>
                <dd>{{ user.firstName }}</dd>
              </div>
              <div v-if="user.lastName">
                <dt>Last name</dt>
                <dd>{{ user.lastName }}</dd>
              </div>
              <div v-if="user.mobile">
                <dt>Mobile</dt>
                <dd>{{ user.mobile }}</dd>
              </div>
            </dl>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import gql from 'graphql-tag';

const USER_QUERY = gql`
  query($userId: ID!) {
    user(id: $userId) {
      id
      username
      email
      firstName
      lastName
      mobile
    }
  }
`;

const ORG_QUERY = gql`
  query($orgId: ID!) {
    org(id: $orgId) {
      id
      name
      courses {
        id
        title
      }
    }
  }
`;

export default {
  name: 'OrgAdmin',
  data: () => ({
    user: {},
    org: {}
  }),
  computed: {
    userId() {
      return this.$route.params.userId;
    },
    orgId() {
      return this.$route.params.orgId;
    },
    breadcrumbs() {
      const bct = [];

      if (!this.org) {
        bct.push({ text: 'Org', disabled: true });
      } else {
        bct.push({
          text: this.org.name,
          href: `#/organisations/${this.org.id}`
        });
      }

      if (!this.user) {
        bct.push({ text: 'User', disabled: true });
      } else {
        bct.push({ text: this.user.username });
      }

      return bct;
    }
  },
  apollo: {
    user() {
      return {
        query: USER_QUERY,
        variables: {
          userId: this.userId
        }
      };
    },
    org() {
      return {
        query: ORG_QUERY,
        variables: {
          orgId: this.orgId
        }
      };
    }
  }
};
</script>
