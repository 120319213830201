<template>
  <div>
    <v-container fluid class="grey lighten-5">
      <v-alert
        dense
        text
        :type="alert.type"
        :value="alert.show"
        transition="slide-y-transition"
        >{{ alert.text }}</v-alert
      >
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <h1>Courses</h1>
        </v-col>
      </v-row>
    </v-container>
    <div class="page-sub-sec">
      <v-text-field
        v-model="searchCourse"
        append-icon="mdi-magnify"
        label="Search"
        outlined
        dense
        hide-details
        class="shrink"
        @input="courseData"
      />
      <v-btn
        v-if="IsCMSAdmin"
        color="primary"
        @click="showNewDialog = true"
      >
        <v-icon>mdi-plus</v-icon> New Course</v-btn
      >
    </div>
    <v-data-table
      :items="courses"
      :headers="computedCourseHeaders"
      :search="searchCourse"
      class="dark--text"
      :no-data-text="`No course available`"
      :hide-default-footer="courseCount < 20 ? true : false"
      :loading="loading"
      loading-text="Loading... Please wait"
      :page-count="pageSize"
      :page="page"
      :options.sync="options"
      :server-items-length="courseCount"
      :footer-props="{
        'items-per-page-options': [20, 30, 40, 50, 100, 500]
      }"
    >
      <template
        v-for="h in computedCourseHeaders"
        v-slot:[`header.${h.value}`]="{ header }"
      >
        <v-tooltip
          v-if="headerTooltips[h.value]"
          :key="h.value"
          bottom
        >
          <template v-slot:activator="{ on }">
            <span v-on="on"
              >{{ header.text
              }}<v-icon class="pl-1" color="#113247"
                >mdi-information</v-icon
              ></span
            >
          </template>
          <span>{{ headerTooltips[header.value] }}</span>
        </v-tooltip>
        <span v-else :key="h.value">{{ header.text }}</span>
      </template>

      <template v-slot:[`item.primaryCourse`]="{ item }">
        <router-link
          :to="`/courses/${item.primaryCourseId}`"
          class="blue--text"
          >{{ item.primaryCourse }}</router-link
        >
      </template>
      <template v-slot:[`item.primaryCourseId`]="{ item }">
        {{ item.primaryCourseId }}
      </template>
      <template v-slot:[`item.product_availability`]="{ item }">
        {{ item.product_availability }}
      </template>
      <template v-slot:[`item.courses`]="{ item }">
        {{ item.courses }}
      </template>
      <template v-slot:[`item.lessons`]="{ item }">
        <span>{{ item.lessons }}</span>
      </template>
      <template v-slot:[`item.activities`]="{ item }">
        <span>{{ item.activities }}</span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div v-if="!item.status" class="warning--text">
          Inactive
        </div>
        <div v-else class="success--text">
          Active
        </div>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="lessons-actions-wrap">
          <v-btn
            icon
            small
            @click="loadDialogForCourseDuplication(item)"
            ><v-icon title="copy">mdi-content-copy</v-icon></v-btn
          >
          <v-btn
            v-if="!item.status"
            color="green"
            icon
            small
            @click="
              enableOrDisableCourse(item.primaryCourseId, false)
            "
            ><v-icon title="Enable">mdi-eye</v-icon></v-btn
          >
          <v-btn
            v-else
            color="orange"
            icon
            small
            @click="enableOrDisableCourse(item.primaryCourseId, true)"
            ><v-icon title="Disable">mdi-eye-off</v-icon></v-btn
          >

          <v-btn
            v-if="hideDelete == 'false'"
            icon
            color="red"
            class="float-right"
            small
            @click="
              LoadConfirmDialogForDeleteCourse(item.primaryCourseId)
            "
          >
            <v-icon title="Delete">mdi-trash-can-outline</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:[`item.visibility`]="{ item }">
        <v-btn v-if="item.isDisabled" color="grey" icon small
          ><v-icon title="Disabled">mdi-eye-off</v-icon></v-btn
        >
        <v-btn v-else color="grey" icon small
          ><v-icon title="Enabled">mdi-eye</v-icon></v-btn
        >
        {{ !item.isDisabled ? ' Visible' : ' Hidden' }}
      </template>
    </v-data-table>
    <v-dialog v-model="showNewDialog" persistent max-width="600px">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title>
            <span class="headline">New Course</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="newCourseName"
              :counter="50"
              :rules="nameRules"
              label="Name*"
              required
            />
          </v-card-text>
          <v-card-actions>
            <small class="info--text"
              >Please ensure each mandatory field marked with an
              asterisk * is completed</small
            >
            <v-spacer />
            <v-btn depressed text @click="showNewDialog = false">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="validateCourse">
              New Course
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCopyDialog" persistent max-width="600px">
      <v-card>
        <!-- <div v-if="!copyLoading">
          <div class="text-center">
            <v-progress-linear indeterminate />
          </div>
        </div> -->
        <v-form ref="form" v-model="copyvalid" lazy-validation>
          <v-card-title>
            <span class="headline">Duplicate Course</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="copyCourseName"
              :counter="50"
              :rules="nameRules"
              label="Name*"
              required
            />
          </v-card-text>
          <v-card-actions>
            <small class="info--text"
              >Please ensure each mandatory field marked with an
              asterisk * is completed</small
            >
            <v-spacer />
            <v-btn depressed text @click="showCopyDialog = false">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="validateDuplicateCourse()">
              <v-progress-circular
                v-if="copyLoading"
                small
                :size="20"
                indeterminate
                color="white"
              />
              Duplicate Course
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmdialogCoursedelete"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">Are you sure </v-card-title>
        <v-card-text>Remove Course?</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="confirmdialogCoursedelete = false"
            >Cancel</v-btn
          >
          <v-btn
            color="red darken-1"
            text
            @click="deleteCourse(coursedeleteId)"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';

const ENABLE_DISABLE_PRIMARY_COURSE_MUTATION = gql`
  mutation($courseId: ID!, $isDisabled: Boolean!) {
    updatePrimaryCourse(
      input: {
        where: { id: $courseId }
        data: { isDisabled: $isDisabled }
      }
    ) {
      primaryCourse {
        Title
        id
        isDisabled
        product {
          id
          product_availability {
            id
            name
          }
        }
        courses {
          title
          id
          lessons {
            title
            activities {
              id
            }
          }
        }
      }
    }
  }
`;
// const COURSES_QUERY = gql`
//   query($limit: Int, $first: Int, $sort: String, $where: JSON) {
//     primaryCourses(
//       limit: $limit
//       start: $first
//       sort: $sort
//       where: $where
//     ) {
//       Title
//       id
//       isDisabled
//       product {
//         id
//         product_availability {
//           id
//           name
//         }
//       }
//       courses {
//         title
//         id
//         lessons {
//           title
//           activities {
//             id
//           }
//         }
//       }
//     }
//   }
// `;

const COURSES_QUERY_NEW = gql`
  query GetPrimaryCourseList(
    $pageNumber: Int!
    $pageSize: Int!
    $search: String
    $sortBy: String
    $sortOrder: String
  ) {
    getPrimaryCourseList(
      pageNumber: $pageNumber
      pageSize: $pageSize
      search: $search
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      isSuccess
      statusCode
      totalItemCount
      statusMessage
      data {
        primaryCourseId
        primaryCourse
        status
        availability
        modules
        lessons
        activities
      }
      error {
        id
        code
        message
      }
    }
  }
`;

const CREATE_PRIMARY_COURSE_MUTATION = gql`
  mutation($newCourseName: String!) {
    createPrimaryCourse(input: { data: { Title: $newCourseName , isDisabled: ${false}} }) {
      primaryCourse {
        Title
        id
        isDisabled
        product {
        id
        product_availability {
          id
          name
        }
      }
        courses {
        title
        id
        lessons {
          title
          activities {
            id
          }
        }
      }
      }
    }
  }
`;

const CREATE_SOFTCOPY_PRIMARY_COURSE_MUTATION = gql`
  mutation($courseID: ID!, $courseName: String!) {
    deepCopyPrimaryCourses(
      courseID: $courseID
      courseName: $courseName
    ) {
      isSuccess
      data {
        created_at
        updated_at
        Title
      }
    }
  }
`;

const DELETE_PRIMARY_COURSE_MUTATION = gql`
  mutation($courseId: ID!) {
    deletePrimaryCourse(input: { where: { id: $courseId } }) {
      primaryCourse {
        id
      }
    }
  }
`;

export default {
  name: 'PrimaryCourseList',
  data: () => ({
    alert: {
      type: 'success',
      show: false,
      text: ''
    },
    copyLoading: false,
    loading: false,
    courses: [],
    pageSize: 20,
    page: 1,
    options: {},
    courseCount: 0,
    valid: true,
    copyvalid: true,
    nameRules: [
      v => !!v || 'Name is required',
      v =>
        (v && v.length <= 50) ||
        'Name must be less than 50 characters'
    ],
    roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER'
    },
    hideDelete: process.env.VUE_APP_HIDEDELETE_STATUS,
    coursedeleteId: '',
    confirmdialogCoursedelete: false,
    searchCourse: '',
    showNewDialog: false,
    showCopyDialog: false,
    newCourseName: '',
    copyCourseName: '',
    copyCourseId: 0,
    availability: '',

    headers: [
      { text: 'Title', value: 'primaryCourse' },
      { text: 'Course ID', value: 'primaryCourseId' },
      { text: 'Availability', value: 'availability' },
      { text: 'Modules', value: 'modules', sortable: false },
      { text: 'Lessons', value: 'lessons', sortable: false },
      { text: 'Activities', value: 'activities', sortable: false },
      { text: 'Status', value: 'status' },
      { text: 'Visibility', value: 'visibility', sortable: false },
      { text: 'Action', value: 'action', sortable: false }
    ],
    headerTooltips: {
      visibility:
        'Whether or not this course is visible within the mobile app'
    }
  }),
  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating'
    ]),
    IsDementiaAdmin() {
      if (
        this.getUser?.user?.role?.name === this.roles.DEMENTIA_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsCMSAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.CMS_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGViewer() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_VIEWER) {
        return true;
      } else {
        return false;
      }
    },
    computedCourseHeaders() {
      if (!this.IsCMSAdmin) {
        return this.headers.filter(t => t.text != 'Action');
      } else if (this.IsCMSAdmin) {
        return this.headers.filter(t => t.text != 'Visibility');
      } else return this.headers;
    }
  },

  watch: {
    options: {
      handler() {
        this.courseData();
      }
    },
    deep: true
  },
  methods: {
    validateCourse() {
      if (!this.$refs.form.validate()) return;
      //if validation true call update user
      this.createNewCourse();
    },
    createNewCourse() {
      if (!this.newCourseName) return;
      const newCourseName = this.newCourseName;
      this.newCourseName = '';
      this.$apollo.mutate({
        mutation: CREATE_PRIMARY_COURSE_MUTATION,
        variables: {
          newCourseName
        },
        update: (store, { data: { createPrimaryCourse } }) => {
          // Only update the store if there's a new course created
          if (!createPrimaryCourse.primaryCourse) return;
          this.courseData(true);
          // Get the current store state
          // const data = store.readQuery({
          //   query: COURSES_QUERY
          // });
          // // Push the new course onto the cache
          // data.primaryCourses.push(createPrimaryCourse.primaryCourse);
          // // Write the list back into the cache
          // store.writeQuery({ query: COURSES_QUERY, data });
          this.alert.show = true;
          this.alert.text = 'Course Created Successfully';
          this.alert.type = 'success';

          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        }
      });

      this.showNewDialog = false;
    },
    coursePaginationStatus() {
      if (this.primaryCourses && this.primaryCourses.length > 0)
        return false;
      else return true;
    },
    getLessonsCount(courses) {
      var totallength = 0;
      if (!courses) return 0;

      courses.forEach(l => {
        if (l.lessons) totallength = totallength + l.lessons.length;
      });
      return totallength;
    },
    getActivitiesCount(courses) {
      var totallength = 0;
      courses.forEach(function(c) {
        c.lessons.forEach(function(l) {
          if (l.activities)
            totallength = totallength + l.activities.length;
        });
      });
      return totallength;
    },
    loadDialogForCourseDuplication(item) {
      this.copyCourseName = 'Copy of ' + item.primaryCourse;
      this.copyCourseId = item.primaryCourseId;
      this.showCopyDialog = true;
    },
    validateDuplicateCourse() {
      if (!this.$refs.form.validate()) return;
      this.duplicateCourse();
    },
    duplicateCourse() {
      this.copyLoading = true;
      this.$apollo.mutate({
        mutation: CREATE_SOFTCOPY_PRIMARY_COURSE_MUTATION,
        variables: {
          courseID: this.copyCourseId,
          courseName: this.copyCourseName
        },
        context: {
          fetchOptions: {
            onProgress: progress => {
              console.log(progress);
            }
          }
        },
        update: (store, { data: { deepCopyPrimaryCourses } }) => {
          this.copyLoading = false;
          // Only update the store if there's a new course created
          if (!deepCopyPrimaryCourses) return;
          this.courseData(true);
          // Get the current store state
          // const data = store.readQuery({
          //   query: COURSES_QUERY
          // });
          // // Push the new course onto the cache
          // data.primaryCourses.push(
          //   softCopyPrimaryCourse.primaryCourse
          // );
          // // Write the list back into the cache
          // store.writeQuery({ query: COURSES_QUERY, data });
          this.alert.show = true;
          this.alert.text = 'Successfully Saved';
          this.alert.type = 'success';

          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
          this.showCopyDialog = false;
        },
        error() {
          this.copyLoading = false;
          this.showCopyDialog = false;
        }
      });
    },
    LoadConfirmDialogForDeleteCourse(id) {
      this.coursedeleteId = id;
      this.confirmdialogCoursedelete = true;
    },
    deleteCourse: function(courseId) {
      this.$apollo.mutate({
        mutation: DELETE_PRIMARY_COURSE_MUTATION,
        variables: {
          courseId: courseId
        },
        update: (store, { data: { deletePrimaryCourse } }) => {
          if (!deletePrimaryCourse) return;
          this.courseData(true);
          this.alert.show = true;
          this.alert.text = 'Successfully Deleted';
          this.alert.type = 'success';

          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
          // Get the current store state
          // const data = store.readQuery({
          //   query: COURSES_QUERY
          // });
          // // Pop the primarycourses from the cache
          // data.primaryCourses = data.primaryCourses.filter(function(
          //   e
          // ) {
          //   return e.id != deletePrimaryCourse.primaryCourse.id;
          // });
          // //data.courses.pop(deleteCourse);
          // // Write the list back into the cache
          // store.writeQuery({ query: COURSES_QUERY, data });
        }
      });
      this.confirmdialogCoursedelete = false;
    },
    enableOrDisableCourse(courseId, isDisabled) {
      this.$apollo.mutate({
        mutation: ENABLE_DISABLE_PRIMARY_COURSE_MUTATION,
        variables: {
          courseId: courseId,
          isDisabled: isDisabled,
          visibility: isDisabled
        },
        update: (store, { data: { updatePrimaryCourse } }) => {
          // Only update the store if there's a new course created
          if (!updatePrimaryCourse.primaryCourse) return;
          const _course = this.courses.find(
            link => link.primaryCourseId === courseId
          );
          if (_course.primaryCourseId) {
            _course.status = !isDisabled;
          }
        }
      });
    },
    courseData(pageNum = false) {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let sortDirection = null;
      let sort_var = null;
      let page_nuber = pageNum ? 1 : page ? page : this.page;
      this.loading = true;
      if (sortBy[0]) {
        sortDirection = sortDesc[0] ? 'desc' : 'asc';
        sort_var = sortBy[0];
      }
      this.$apollo
        .query({
          query: COURSES_QUERY_NEW,
          fetchPolicy: 'network-only',
          variables: {
            pageNumber: page_nuber,
            pageSize: itemsPerPage ? itemsPerPage : this.pageSize,
            search: this.searchCourse,
            sortBy: sort_var,
            sortOrder: sortDirection
          },
          skip() {
            return !this.isAuthenticated;
          }
        })
        .then(response => {
          this.loading = false;
          let data = response.data.getPrimaryCourseList.data;
          this.courseCount = parseInt(
            response.data.getPrimaryCourseList.totalItemCount
          );

          let newProducts = [];
          data.map(items => {
            return newProducts.push(items);
          });
          this.courses = newProducts;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>
