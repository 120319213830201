var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { sm: "1" } }, [
                _c("h3", { staticClass: "font-weight-black" }, [
                  _vm._v(" " + _vm._s(_vm.index) + " ")
                ])
              ]),
              _c(
                "v-col",
                [
                  _c("h2", { staticClass: "indigo--text" }, [
                    _vm._v("Drag & Drop")
                  ]),
                  _c("app-rich-text-editor", {
                    model: {
                      value: _vm.local.quiz.question,
                      callback: function($$v) {
                        _vm.$set(_vm.local.quiz, "question", $$v)
                      },
                      expression: "local.quiz.question"
                    }
                  }),
                  _c("h3", { staticClass: "mt-6" }, [_vm._v("Answers")]),
                  _c(
                    "table",
                    {
                      staticClass: "mb-6",
                      staticStyle: { "border-spacing": "0" }
                    },
                    [
                      _c("thead", [
                        _c("tr", [
                          _c("th"),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                "text-align": "left",
                                width: "50%"
                              }
                            },
                            [_vm._v(" Source ")]
                          ),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                "text-align": "left",
                                width: "50%"
                              }
                            },
                            [_vm._v("Target")]
                          ),
                          _c("th", [_vm._v("Action")])
                        ])
                      ]),
                      _vm.local.quiz
                        ? _c(
                            "tbody",
                            _vm._l(_vm.local.quiz.answers, function(
                              answer,
                              indexs
                            ) {
                              return _c("tr", { key: answer.id }, [
                                _c("td", { staticClass: "pr-4 pb-6 pt-4" }, [
                                  _vm._v(_vm._s(indexs + 1))
                                ]),
                                _c(
                                  "td",
                                  [
                                    _c("v-text-field", {
                                      model: {
                                        value: answer.source,
                                        callback: function($$v) {
                                          _vm.$set(answer, "source", $$v)
                                        },
                                        expression: "answer.source"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  [
                                    _c("v-text-field", {
                                      model: {
                                        value: answer.target,
                                        callback: function($$v) {
                                          _vm.$set(answer, "target", $$v)
                                        },
                                        expression: "answer.target"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { icon: "", color: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteAnswer(indexs)
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-minus-circle")
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ])
                            }),
                            0
                          )
                        : _vm._e(),
                      _c(
                        "tbody",
                        {
                          staticStyle: {
                            "background-color": "rgba(0,0,0,0.05)"
                          }
                        },
                        [
                          _c("tr", [
                            _c("td"),
                            _c(
                              "td",
                              [
                                _c("v-text-field", {
                                  model: {
                                    value: _vm.forms.newAnswer.source,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.forms.newAnswer,
                                        "source",
                                        $$v
                                      )
                                    },
                                    expression: "forms.newAnswer.source"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "td",
                              [
                                _c("v-text-field", {
                                  model: {
                                    value: _vm.forms.newAnswer.target,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.forms.newAnswer,
                                        "target",
                                        $$v
                                      )
                                    },
                                    expression: "forms.newAnswer.target"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "td",
                              [
                                _vm.IsCMSAdmin
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: { icon: "", color: "primary" },
                                        on: { click: _vm.addAnswer }
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-plus-circle")
                                        ])
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("h3", { staticClass: "mt-6" }, [
                        _vm._v("Incorrect Answer Text")
                      ]),
                      _c("v-text-field", {
                        staticClass: "shrink ",
                        attrs: { outlined: "", dense: "", "hide-details": "" },
                        model: {
                          value: _vm.local.quiz.incorrectAnswerText,
                          callback: function($$v) {
                            _vm.$set(_vm.local.quiz, "incorrectAnswerText", $$v)
                          },
                          expression: "local.quiz.incorrectAnswerText"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.IsCMSAdmin
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            "x-small": "",
                            title: "Remove the current quiz"
                          },
                          on: {
                            click: function($event) {
                              return _vm.$emit(
                                "removethiscomponent",
                                _vm.local.quiz
                              )
                            }
                          }
                        },
                        [_vm._v("Remove")]
                      )
                    : _vm._e(),
                  _c(
                    "v-dialog",
                    {
                      attrs: { persistent: "", "max-width": "450" },
                      model: {
                        value: _vm.dragDropValidation,
                        callback: function($$v) {
                          _vm.dragDropValidation = $$v
                        },
                        expression: "dragDropValidation"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-text", [
                            _c("br"),
                            _c("br"),
                            _c("br"),
                            _c("h4", [
                              _vm._v("Question and answers are required fields")
                            ])
                          ]),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "red darken-1", text: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.dragDropValidation = false
                                    }
                                  }
                                },
                                [_vm._v("Got it")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }