var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tiptap-vuetify", {
    staticClass: "app-rte-tiptap",
    attrs: {
      extensions: _vm.tiptapExtensions,
      "card-props": { flat: true, outlined: true },
      "toolbar-attributes": { class: "app-rte-toolbar" }
    },
    model: {
      value: _vm.contentLocal,
      callback: function($$v) {
        _vm.contentLocal = $$v
      },
      expression: "contentLocal"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }