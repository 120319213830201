var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("div", [
            _c(
              "div",
              { staticClass: "text-center" },
              [_c("v-progress-linear", { attrs: { indeterminate: "" } })],
              1
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "d-flex justify-center" },
        [
          _c(
            "v-alert",
            {
              staticClass: "v-alert",
              attrs: {
                dense: "",
                text: "",
                type: _vm.alert.type,
                value: _vm.alert.show,
                transition: "slide-y-transition"
              }
            },
            [_vm._v(_vm._s(_vm.alert.text))]
          )
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "grey lighten-5", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "mr-auto", attrs: { cols: "auto" } },
                [
                  _c("v-breadcrumbs", {
                    staticClass: "pa-0",
                    attrs: { items: _vm.breadcrumbs }
                  }),
                  _c("v-row", [
                    _c("h1", { staticClass: "pa-2" }, [_vm._v("Licenses:")]),
                    _c("h1", { staticClass: "success--text pa-2" }, [
                      _vm._v(" " + _vm._s(_vm.subscriptionName) + " ")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "page-sub-sec" },
        [
          _c("v-text-field", {
            staticClass: "shrink",
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Search",
              outlined: "",
              dense: "",
              "hide-details": ""
            },
            model: {
              value: _vm.licenseSearch,
              callback: function($$v) {
                _vm.licenseSearch = $$v
              },
              expression: "licenseSearch"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        [
          _c(
            "v-row",
            { staticClass: "d-flex " },
            _vm._l(_vm.userLicenses, function(lic, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c(
                    "v-col",
                    [
                      _c("v-data-table", {
                        attrs: {
                          items: lic.userDetail,
                          headers: _vm.computedHeaders,
                          search: _vm.licenseSearch,
                          "no-data-text":
                            "No Users available for this Subscription",
                          "hide-default-footer": "",
                          "disable-pagination": ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.id",
                              fn: function(ref) {
                                var item = ref.item
                                return [_vm._v(" " + _vm._s(item.id) + " ")]
                              }
                            },
                            {
                              key: "item.firstName",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "div",
                                    {
                                      class: !item.userProduct
                                        ? "available"
                                        : ""
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.firstName
                                              ? item.firstName
                                              : "Unknown User"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.action",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item.userProduct
                                    ? _c(
                                        "div",
                                        { staticClass: "lessons-actions-wrap" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "red ",
                                                icon: "",
                                                small: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.LoadConfirmDialogForDeleteUser(
                                                    item.firstName,
                                                    item.userProduct
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { title: "Delete" } },
                                                [
                                                  _vm._v(
                                                    "mdi-trash-can-outline"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        { staticClass: "lessons-actions-wrap" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "red ",
                                                icon: "",
                                                small: "",
                                                disabled: ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { title: "Delete" } },
                                                [
                                                  _vm._v(
                                                    "mdi-trash-can-outline"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.confirmdialogUserdelete,
            callback: function($$v) {
              _vm.confirmdialogUserdelete = $$v
            },
            expression: "confirmdialogUserdelete"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Remove User From Subscription?")
              ]),
              _c("v-card-text", [
                _vm._v(
                  "Are you sure you want to remove " +
                    _vm._s(_vm.LearnerName) +
                    " from the subscription " +
                    _vm._s(_vm.subscriptionName) +
                    "?"
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "#113247 darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmdialogUserdelete = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "actionButton",
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          return _vm.deleteUser(_vm.UserProductId)
                        }
                      }
                    },
                    [_vm._v("REMOVE USER")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }