<template>
  <div>
    <div class="d-flex justify-center">
      <v-alert
        dense
        text
        class="v-alert"
        :type="alertType"
        :value="alert"
        transition="slide-y-transition"
        >{{ alertText.text }}</v-alert
      >
    </div>
    <div class="page-sub-sec">
      <v-text-field
        v-model="searchPublicLearner"
        append-icon="mdi-magnify"
        label="Search"
        outlined
        dense
        hide-details
        class="shrink"
        @input="usersData"
      />
      <div class="mr-2">
        <v-btn
          v-if="IsDementiaAdmin"
          color="primary"
          class="mr-1"
          :loading="paymentreportloading"
          @click="generatePaymentReport"
        >
          <v-icon class="mr-2">mdi-file-excel</v-icon>Generate Payment
          Report</v-btn
        >
        <v-btn
          v-if="false && IsDementiaAdmin"
          color="primary"
          :loading="reportloading"
          @click="generateReport"
        >
          <v-icon class="mr-2">mdi-file-excel</v-icon>Generate
          Report</v-btn
        >
      </div>
    </div>
    <v-data-table
      :items="users"
      :headers="headers"
      :search="searchPublicLearner"
      class="dark--text"
      :no-data-text="`No user available`"
      :hide-default-footer="usersCount < 10 ? true : false"
      :loading="this.$apollo.loading"
      loading-text="Loading... Please wait"
      :expanded.sync="expanded"
      show-expand
      single-expand
      item-key="id"
      :page-count="pageSize"
      :page="page"
      :options.sync="options"
      :server-items-length="usersCount"
      :footer-props="{
        'items-per-page-options': [50, 100, 500]
      }"
    >
      <template v-slot:[`item.name`]="{ item }">
        <router-link :to="`/users/${item.id}`" class="blue--text">{{
          item.name
        }}</router-link>
      </template>
      <template v-slot:[`item.email`]="{ item }">
        {{ item.email }}
      </template>

      <template v-slot:[`item.role.text`]="{ item }">
        {{ item.role.text }}
      </template>
      <template v-slot:[`item.blocked`]="{ item }">
        <div v-if="item.blocked" class="warning--text">
          Inactive
        </div>
        <div v-else class="success--text">
          Active
        </div>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div
          v-if="getLoginedUserId != item.id"
          class="lessons-actions-wrap"
        >
          <v-btn
            v-if="item.blocked"
            color="green"
            icon
            small
            @click="enableOrDisableUser(item.id, false)"
            ><v-icon title="Enable">mdi-eye</v-icon></v-btn
          >
          <v-btn
            v-else
            color="orange"
            icon
            small
            @click="enableOrDisableUser(item.id, true)"
            ><v-icon title="Disable">mdi-eye-off</v-icon></v-btn
          >

          <router-link
            :to="`/users/${item.id}`"
            class="v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--small"
          >
            <v-icon title="Edit" class="blue--text"
              >mdi-pencil</v-icon
            >
          </router-link>

          <v-btn
            v-if="hideDelete == 'false'"
            icon
            color="red"
            small
            @click="LoadConfirmDialogForDeleteOrg(item.id)"
          >
            <v-icon title="Delete">mdi-trash-can-outline</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:[`item.subscription`]="{ item }">
        {{ item.subscription }}
      </template>
      <template v-slot:expanded-item="{ item }">
        <td
          :colspan="headers.length"
          style="background: white;padding: 0px;"
        >
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr style="background: #E5E5E5;">
                  <th class="text-left">
                    Subscription Name
                  </th>
                  <th class="text-left">
                    Purchase Date
                  </th>
                  <th class="text-left">
                    Expiry Date
                  </th>
                  <th class="text-left">
                    Auto Renewal
                  </th>
                  <th class="text-left">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody v-if="item.products && item.products.length > 0">
                <tr
                  v-for="items in item.products"
                  :key="items.productId"
                >
                  <td>{{ items.product }}</td>
                  <td>
                    {{
                      items.purchaseDate &&
                        loadDate(items.purchaseDate)
                    }}
                  </td>
                  <td>
                    {{
                      items.expirationDate &&
                        loadDate(items.expirationDate)
                    }}
                  </td>

                  <td>
                    <div
                      v-if="items.autoRenewal"
                      class="warning--text"
                    >
                      Yes
                    </div>
                    <div v-else class="success--text">
                      No
                    </div>
                  </td>
                  <td>
                    <div
                      v-if="items.productActive"
                      class="success--text"
                    >
                      Active
                    </div>
                    <div v-else class="warning--text">
                      Inactive
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td
                    colspan="5"
                    class="text-center"
                    style="color:#ababab;font-size: .875rem;"
                  >
                    {{ 'No data available' }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';
import { Constant } from '../../../config/constants';
import * as saveAs from 'file-saver';
import { AUTH_TOKEN } from '../../../vue-apollo';
import moment from 'moment';

const INVITE_NEW_USER = gql`
  mutation(
    $email: String!
    $roleName: String!
    $orgId: ID
    $firstName: String!
    $lastName: String
  ) {
    customRegister(
      input: {
        email: $email
        role: $roleName
        orgId: $orgId
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      user {
        id
        username
        email
        firstName
        lastName
        confirmed
        blocked
        role {
          id
          name
        }
        memberOf {
          id
          name
        }
        adminOf {
          id
          name
        }
        viewerOf {
          id
          name
        }
      }
    }
  }
`;
const RE_INVITE_USER = gql`
  mutation($userId: ID!) {
    reinviteUser(id: $userId) {
      user {
        id
      }
    }
  }
`;
const ENABLE_DISABLE_USER_MUTATION = gql`
  mutation($userId: ID!, $isDisabled: Boolean!) {
    customUpdateUser(
      input: {
        where: { id: $userId }
        data: { blocked: $isDisabled }
      }
    ) {
      user {
        id
        username
        email
        confirmed
        blocked
        role {
          id
          name
        }
        memberOf {
          id
          name
        }
      }
    }
  }
`;

const USER_QUERY = gql`
  query GetPublicLearners(
    $pageNumber: Int!
    $pageSize: Int!
    $search: String
    $sortBy: String
  ) {
    getPublicLearners(
      pageNumber: $pageNumber
      pageSize: $pageSize
      search: $search
      sortBy: $sortBy
    ) {
      statusMessage
      statusCode
      totalItemCount
      data {
        firstName
        lastName
        email
        id
        orgName
        userActive
        roleName
        products {
          userId
          productId
          product
          expirationDate
          purchaseDate
          autoRenewal
          productActive
        }
      }
    }
  }
`;
// const ORG_QUERY = gql`
//   query {
//     orgs {
//       id
//       name
//     }
//   }
// `;
const ROLE_QUERY = gql`
  query {
    roles {
      id
      name
    }
  }
`;
const DELETE_USER = gql`
  mutation($userId: ID!) {
    deleteUser(input: { where: { id: $userId } }) {
      user {
        id
      }
    }
  }
`;

export default {
  name: 'PublicLearners',

  data: () => ({
    system_roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER'
    },
    reportloading: false,
    paymentreportloading: false,
    orgId: 0,
    users: [],
    usersCount: 0,
    pageSize: 50,
    page: 1,
    options: {},
    confirmdialogUserdelete: false,
    userdeleteId: '',
    alert: false,
    alertText: { text: '' },
    alertType: 'success',
    showNewDialog: false,
    newCourseName: '',
    searchPublicLearner: '',
    subscription: '',
    hideDelete: process.env.VUE_APP_HIDEDELETE_STATUS,
    expanded: [],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: 'Status ', value: 'blocked' },
      {
        text: 'Subscription ',
        value: 'subscription',
        sortable: false
      },
      { text: '', value: 'data-table-expand' }
    ],
    error: null,
    allRoles: [],
    allOrgs: [],
    forms: {
      newEmail: null,
      newRoleName: null,
      newOrgId: null,
      newFirstName: null,
      newLastName: null,
      newPhoneNum: null
    },
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ]
  }),
  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating'
    ]),

    IsDementiaAdmin() {
      if (
        this.getUser?.user?.role?.name ===
        this.system_roles.DEMENTIA_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsOrglevelRole() {
      const organizationRequiredRoles = [
        this.system_roles.ORG_ADMIN,
        this.system_roles.ORG_VIEWER,
        'LEARNER'
      ];
      if (
        this.forms.newRoleName &&
        organizationRequiredRoles.includes(this.forms.newRoleName)
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsCMSAdmin() {
      if (
        this.getUser?.user?.role?.name === this.system_roles.CMS_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsORGAdmin() {
      if (
        this.getUser?.user?.role?.name === this.system_roles.ORG_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsORGViewer() {
      if (
        this.getUser?.user?.role?.name ===
        this.system_roles.ORG_VIEWER
      ) {
        return true;
      } else {
        return false;
      }
    },

    getLoginedUserId() {
      return this.getUser?.user?.id;
    },
    getRolesByRole() {
      if (!this.roles) return [];
      if (
        this.getUser?.user?.role?.name === this.system_roles.ORG_ADMIN
      ) {
        var adminroles = this.roles
          .filter(function(r) {
            //return r.id != 1 && r.id != 2 && r.id != 3 && r.id != 6;
            return (
              r.name != Constant.roles.AUTHENTICATED &&
              r.name != Constant.roles.PUBLIC &&
              r.name != Constant.roles.DEMENTIA_ADMIN &&
              r.name != Constant.roles.CMS_ADMIN
            );
          })
          .map(item => ({
            ...item,
            text: this.getRoleDisplayName(item.name)
          }));
        return adminroles;
      } else if (
        this.getUser?.user?.role?.name ===
        this.system_roles.DEMENTIA_ADMIN
      ) {
        var dadminroles = this.roles
          .filter(function(r) {
            //return r.id != 1 && r.id != 2;
            return (
              r.name != Constant.roles.AUTHENTICATED &&
              r.name != Constant.roles.PUBLIC
            );
          })
          .map(item => {
            // let rolText = item.name;
            // if (rolText === Constant.roles.DEMENTIA_ADMIN)
            //   rolText = Constant.roles.DEMENTIA_ADMIN_VIEW;
            return {
              ...item,
              text: this.getRoleDisplayName(item.name)
            };
          });
        return dadminroles;
      } else return [];
    },

    getUsersByRole() {
      if (!this.users) return [];
      if (
        this.getUser?.user?.role?.name ===
          this.system_roles.ORG_ADMIN ||
        this.getUser?.user?.role?.name ===
          this.system_roles.ORG_VIEWER
      ) {
        const orgId = this.getUser?.user?.memberOf[0].id.toString();
        var orgusers = this.users?.filter(function(e) {
          return (
            (e.adminOf.length != 0 && e.adminOf[0].id === orgId) ||
            (e.viewerOf.length != 0 && e.viewerOf[0].id === orgId) ||
            (e.memberOf.length != 0 && e.memberOf[0].id === orgId)
          );
        });
        return orgusers;
      } else {
        return this.users;
      }
    }
  },
  watch: {
    options: {
      handler() {
        this.usersData();
      }
    },
    deep: true
  },
  created() {
    this.Constant = Constant;
  },
  mounted() {
    this.usersData();
  },
  methods: {
    getRoleDisplayName(roleName) {
      let displayRole = '';
      switch (roleName) {
        case Constant.roles.DEMENTIA_ADMIN:
          displayRole = Constant.roles.DEMENTIA_ADMIN_VIEW;
          break;
        case Constant.roles.CMS_ADMIN:
          displayRole = Constant.roles.CMS_ADMIN_VIEW;
          break;
        case Constant.roles.ORG_ADMIN:
          displayRole = Constant.roles.ORG_ADMIN_VIEW;
          break;
        case Constant.roles.ORG_VIEWER:
          displayRole = Constant.roles.ORG_VIEWER_VIEW;
          break;
        case Constant.roles.LEARNER:
          displayRole = Constant.roles.LEARNER_VIEW;
          break;
      }
      return displayRole;
    },
    setActiveTab(id) {
      this.selectedTab = id;
    },
    loadDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },

    publicLearnerPaginationStatus() {
      if (this.getUsersByRole && this.getUsersByRole.length > 0)
        return false;
      else return true;
    },
    enableOrDisableUser(userId, isDisabled) {
      this.$apollo.mutate({
        mutation: ENABLE_DISABLE_USER_MUTATION,
        variables: {
          userId: userId,
          isDisabled: isDisabled
        },
        update: (store, { data: { customUpdateUser } }) => {
          // Only update the store if there's a new course created
          if (!customUpdateUser.user) return;
          this.usersData(true);
          // Get the current store state
          // const data = store.readQuery({
          //   query: USER_QUERY
          // });
          // // Push the new users onto the cache
          // const _user = data.users.find(link => link.id === userId);
          // _user.blocked = isDisabled;

          // // Write the list back into the cache
          // store.writeQuery({ query: USER_QUERY, data });
        }
      });
    },
    LoadConfirmDialogForDeleteOrg(id) {
      this.userdeleteId = id;
      this.confirmdialogUserdelete = true;
    },
    generatePaymentReport() {
      this.paymentreportloading = true;
      var datetime = new Date();
      var month = datetime.getMonth() + 1;
      //custom file name
      var sheetname =
        'DA_PublicLearnersPaymentReport_' +
        datetime.getDate() +
        '_' +
        month +
        '_' +
        datetime.getFullYear() +
        '_' +
        datetime.toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        }) +
        '.xlsx';
      const downloadUrl =
        process.env.VUE_APP_BASE_API +
        '/in-app-purchase/transactionHistory';
      fetch(downloadUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN)
        }
      })
        .then(response => response.blob())
        .then(blob => {
          saveAs(blob, sheetname);
          this.paymentreportloading = false;
        })
        .catch(err => {
          // todo: handle server failure
          this.paymentreportloading = false;
          console.log(err);
        });
    },
    generateReport() {
      this.reportloading = true;
      var datetime = new Date();
      var month = datetime.getMonth() + 1;
      //custom file name
      var sheetname =
        'DA_PublicLearnersData_' +
        datetime.getDate() +
        '_' +
        month +
        '_' +
        datetime.getFullYear() +
        '_' +
        datetime.toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        }) +
        '.xlsx';
      const downloadUrl =
        process.env.VUE_APP_BASE_API + '/generateReportPublic';
      fetch(downloadUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN)
        }
      })
        .then(response => response.blob())
        .then(blob => {
          saveAs(blob, sheetname);
          this.reportloading = false;
        })
        .catch(err => {
          // todo: handle server failure
          this.reportloading = false;
          console.log(err);
        });
    },
    createNewUser() {
      if (!this.$refs.form.validate()) return;

      var orgId;
      if (this.IsORGAdmin) {
        orgId = this.getUser?.user?.memberOf[0].id;
      } else if (this.IsOrglevelRole) {
        if (!this.forms.newOrgId) return;
      }

      this.$apollo
        .mutate({
          mutation: INVITE_NEW_USER,
          variables: {
            email: this.forms.newEmail,
            roleName: this.forms.newRoleName,
            orgId: this.IsORGAdmin ? orgId : this.forms.newOrgId,
            firstName: this.forms.newFirstName,
            lastName: this.forms.newLastName
          },
          update: (store, { data: { customRegister } }) => {
            // Only update the store if there's a new user created
            if (!customRegister.user) return;
            this.usersData(true);
            this.alertType = 'success';
            this.alert = true;
            this.alertText.text = 'Invitation Sent Successfully';
            this.$refs.form.reset();
            setTimeout(() => {
              this.alert = false;
              this.alertText.text = '';
            }, 3000);
          }
        })
        .catch(e => {
          this.alertType = 'error';
          this.alert = true;
          this.alertText.text = JSON.stringify(
            e.graphQLErrors[0].extensions.exception.data.message[0]
              .messages[0].message
          ).replace(/['"]+/g, '');
          setTimeout(() => {
            this.alert = false;
            this.alertText.text = '';
            this.alertType = 'success';
          }, 13000);
        });

      this.showNewDialog = false;
    },
    deleteUser(userId) {
      this.$apollo.mutate({
        mutation: DELETE_USER,
        variables: {
          userId: userId
        },
        update: (store, { data: { deleteUser } }) => {
          if (!deleteUser) return;
          // Get the current store state
          const data = store.readQuery({
            query: USER_QUERY
          });
          this.alert = true;
          this.alertText.text = 'User Removed!';
          setTimeout(() => {
            this.alert = false;
            this.alertText.text = '';
          }, 3000);
          // Pop the user from the cache
          data.users = data.users.filter(function(e) {
            return e.id != deleteUser.user.id;
          });
          // Write the list back into the cache
          store.writeQuery({ query: USER_QUERY, data });
        }
      });
      this.confirmdialogUserdelete = false;
    },
    reInviteUser(userId, username) {
      this.$apollo.mutate({
        mutation: RE_INVITE_USER,
        variables: {
          userId: userId,
          username: username
        },
        update: () => {
          this.alert = true;
          this.alertText.text = 'Invitation sent!';
          setTimeout(() => {
            this.alert = false;
            this.alertText.text = '';
          }, 3000);
        }
      });
    },
    cancel() {
      this.$refs.form.reset();
      this.showNewDialog = false;
    },
    usersData(pageNum = null) {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let sortDirection = null;
      let sort_var = null;
      let page_nuber = pageNum ? 0 : page ? page : this.page;
      if (sortBy[0]) {
        sortDirection = sortDesc[0] ? 'desc' : 'asc';
        sort_var = sortBy[0];
      }
      this.$apollo
        .query({
          query: USER_QUERY,
          fetchPolicy: 'network-only',
          variables: {
            pageNumber: page_nuber,
            pageSize: itemsPerPage ? itemsPerPage : this.pageSize,
            search: this.searchPublicLearner,
            sortBy: sort_var,
            sortOrder: sortDirection
          },
          skip() {
            return !this.isAuthenticated;
          }
        })
        .then(response => {
          if (response?.data?.getPublicLearners?.totalItemCount) {
            this.usersCount = parseInt(
              response?.data?.getPublicLearners?.totalItemCount
            );
          }
          let data = response.data.getPublicLearners.data;

          let neDtata = data?.map(usr => {
            usr.role = {
              // ...usr.role,
              text: usr.roleName
            };
            usr.subscription = usr.products.length;
            usr.name = usr.firstName.concat(
              ' ',
              usr.lastName ? usr.lastName : ''
            );
            return usr;
          });
          this.users = neDtata;
        });
    }
  },
  apollo: {
    roles: {
      query: ROLE_QUERY,
      skip() {
        return !this.isAuthenticated;
      }
    }
  }
};
</script>
