<template>
  <div class="overflow-y-scroll">
    <div v-if="loading">
      <div class="text-center">
        <v-progress-linear indeterminate />
      </div>
    </div>
    <v-container fluid class="grey lighten-5">
      <v-alert
        dense
        text
        :type="alert.type"
        :value="alert.show"
        transition="slide-y-transition"
        >{{ alert.text }}</v-alert
      >
      <div>
        <v-row no-gutters align="center">
          <v-col cols="auto" class="mr-auto">
            <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
            <h1 v-if="!editName">
              {{ subscriptionDetails.name }}
              <v-icon
                v-if="IsCMSAdmin"
                @click="editSubscriptionName()"
                >mdi-pencil</v-icon
              >
            </h1>
            <v-row v-if="editName">
              <v-text-field
                v-model="subscriptionName"
                autofocus
                style="font-size: 2em;font-weight: bold;"
                hide-details
                :value="subscriptionDetails.name"
                class="shrink pa-3"
              />
              <v-icon
                color="primary"
                class="pa-1"
                @click="updateSubscriptionName()"
                >mdi-check</v-icon
              >
              <v-icon
                color="red"
                class="pa-1"
                @click="editSubscriptionName()"
                >mdi-close</v-icon
              >
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <available-courses />
          <v-col cols="6">
            <v-card outlined class="mb-5">
              <!-- <v-card-title class="d-flex justify-space-between">
              
            </v-card-title> -->
              <div class="pa-5 ma-0">
                <div class="section-devider no-bg">
                  <h3>Subscription Details</h3>
                </div>
                <h5>This subscription is available to</h5>
                <v-radio-group
                  v-model="availability"
                  :disabled="IsDementiaAdmin"
                  row
                  class="pt-1"
                >
                  <!-- <v-radio
                  v-for="item in productAvailabilities"
                  :key="item.id"
                  :value="item.id"
                >
                  <template v-slot:label>
                    <div>
                      <strong>{{ item.name }}</strong>
                    </div>
                  </template>
                </v-radio> -->
                  <v-radio
                    :value="2"
                    :disabled="
                      subscriptionDetails.product_availability == 2
                    "
                    @click="showModelEveryone"
                  >
                    <template v-slot:label>
                      <div>
                        <strong>Everyone</strong>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio
                    :value="1"
                    :disabled="
                      subscriptionDetails.product_availability == 2 ||
                        subscriptionDetails.product_availability == 3
                    "
                  >
                    <template v-slot:label>
                      <div>
                        <strong>Public</strong>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio
                    :value="3"
                    :disabled="
                      subscriptionDetails.product_availability == 2 ||
                        subscriptionDetails.product_availability == 1
                    "
                    @click="showModel"
                  >
                    <template v-slot:label>
                      <div>
                        <strong>Enterprise</strong>
                      </div>
                    </template>
                  </v-radio>
                  <!-- <div
                    v-if="
                      subscriptionDetails.product_availability == 2
                    "
                    class="tooltipDiv"
                  > -->
                  <v-tooltip
                    v-if="
                      subscriptionDetails.product_availability == 2
                    "
                    right
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        class="pl-1"
                        color="#113247"
                        v-on="on"
                        >mdi-currency-usd</v-icon
                      >
                    </template>
                    <span
                      >Contact Suyati support to change the
                      availability of this subscription.</span
                    >
                  </v-tooltip>

                  <!-- </div> -->
                </v-radio-group>

                <v-row class="pl-2 pb-2">
                  <div class="d-inline">
                    <h4 class="pa-1">Subscription Icon</h4>
                    <v-row class="pl-3 pt-3">
                      <v-avatar
                        rounded
                        size="29px"
                        class="ma-1"
                        style="border: 1px solid #E0E0E0;"
                      >
                        <img
                          v-if="imageUrl"
                          alt="Avatar"
                          :src="imageUrl"
                        />
                      </v-avatar>
                      <v-btn
                        small
                        class="pa-3 ma-1 primary"
                        style="backgroundColor:#113247"
                        :loading="isUploading"
                        :disabled="IsDementiaAdmin"
                        @click="getFile"
                      >
                        <v-file-input
                          id="fileUpload"
                          hide-input
                          :disabled="IsDementiaAdmin"
                          label="File input"
                          filled
                          prepend-icon=""
                          @change="onFilePicked"
                        />
                        Upload Icon
                      </v-btn>
                    </v-row>
                  </div>
                  <div class="d-inline  mx-lg-auto">
                    <h4 class="pa-1">Visibility</h4>
                    <v-switch
                      v-model="visibility"
                      style="font-size:10px;padding-left: 10px;"
                      inset
                      color="green"
                      :disabled="IsDementiaAdmin"
                      :label="`${visibility ? 'Visible' : 'Hidden'}`"
                    />
                  </div>
                </v-row>
                <h4 class="pa-1">Notes</h4>
                <v-textarea
                  v-model="notes"
                  :value="subscriptionDetails.notes"
                  :disabled="IsDementiaAdmin"
                  outlined
                  dense
                  style="padding:10px;background: #FFFFFF;"
                />
              </div>
            </v-card>
            <!-- checkAvailability -->
            <v-card v-if="availability != 3" outlined class="mb-5">
              <div class="pa-5 ma-0">
                <div class="section-devider no-bg">
                  <h3>In-App Advertising</h3>
                </div>
                <!-- AppContentHeaderEditor 
                  v-model="local.headerContent"-->
                <app-content-header-editor v-model="advertisements" />
                <!-- <div class="mb-1">
                <h4>
                  Advertisement
                </h4>
                <v-row>
                  <div class="pl-3">
                    <div
                      style="height:162px;width:288px;background: #FFFFFF;border: 1px solid #E0E0E0;box-sizing: border-box;border-radius: 4px;"
                    ></div>
                  </div>
                  <v-col>
                    <div class="d-block">
                      <v-btn
                        small
                        color="pa-3 ma-1 primary"
                        @click="getFile"
                      >
                        <v-file-input
                          id="fileUpload"
                          hide-input
                          label="File input"
                          filled
                          prepend-icon=""
                          @change="onFilePicked"
                        />
                        Upload Video
                      </v-btn>
                    </div>
                    <div class="d-block">
                      <v-btn
                        small
                        color="pa-3 ma-1 primary"
                        @click="getFile"
                      >
                        <v-file-input
                          id="fileUpload"
                          hide-input
                          label="File input"
                          filled
                          prepend-icon=""
                          @change="onFilePicked"
                        />
                        Upload Image
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </div> -->

                <div class="mb-5">
                  <h4>
                    Advertisement
                  </h4>
                  <v-textarea
                    v-model="blurb"
                    :value="subscriptionDetails.blurb"
                    :disabled="IsDementiaAdmin"
                    outlined
                    dense
                    style="padding:10px;"
                  />
                </div>
                <!-- <div class="mb-5 mt-5">
                  <h4>
                    In-App Duration* (days)
                  </h4>
                  <v-autocomplete
                    v-model="in_app_duration"
                    :items="inAppDurations"
                    item-text="duration"
                    item-value="id"
                    required
                    style="font-size: 12px;width:200px"
                  /> -->
                <!-- <v-text-field
                    v-model="in_app_duration"
                    :disabled="IsDementiaAdmin"
                    type="number"
                    min="0"
                    style="font-size: 12px;margin-top:-15px;width:200px"
                    hide-details
                    :rules="[
                      v => !!v || 'Enter In-App Duration',
                      v => v >= 0 || 'Please Enter Valid Price'
                    ]"
                    :value="subscriptionDetails.in_app_duration"
                    width="50px"
                  /> -->
                <!-- </div> -->
                <v-row class="pl-2 pb-2">
                  <!-- <div class="d-inline">
                    <h4>
                      Monthly Product Id**
                    </h4>
                    <v-text-field
                      v-model="inAppProductId"
                      :disabled="IsDementiaAdmin"
                      min="0"
                      style="font-size: 12px;margin-top:-15px;width:200px"
                      hide-details
                      :rules="[
                        v => !!v || 'Enter In-App monthly product Id'
                      ]"
                      :value="
                        subscriptionDetails.inAppMonthlyProductId
                      "
                      width="50px"
                    />
                  </div> -->
                  <div class="d-inline ">
                    <h4>
                      Yearly Product Id*
                    </h4>
                    <v-text-field
                      v-model="price"
                      :disabled="IsDementiaAdmin"
                      style="font-size: 12px;margin-top:-15px;width:200px"
                      hide-details
                      :rules="[
                        v => !!v || 'Enter In-App yearly product Id'
                      ]"
                      :value="
                        subscriptionDetails.inAppYearlyProductId
                      "
                      width="50px"
                    />
                  </div>
                </v-row>

                <div class="mb-5 mt-5">
                  <h4 class="pa-1">In-App Auto Renew</h4>
                  <v-switch
                    v-model="isAutoRenew"
                    style="font-size:10px;padding-left: 10px;"
                    inset
                    color="green"
                    :disabled="IsDementiaAdmin"
                    :label="`${isAutoRenew ? 'Enabled' : 'Disabled'}`"
                  />
                </div>
              </div>
            </v-card>
            <div v-if="IsCMSAdmin" class="text-end pt-2">
              <v-btn
                color="primary"
                x-large
                @click="updateSubscription()"
              >
                Save
              </v-btn>
            </div>
          </v-col>
          <div v-if="errors.length > 0">
            <v-icon style="color:red" @click="errors = []"
              >mdi-close</v-icon
            >
            <div
              v-for="(items, index) in errors"
              :key="index"
              class="pa-2 ma-1"
            >
              <v-row>
                <v-col>
                  <v-alert
                    dense
                    text
                    class="invite-learner v-alert"
                    type="error"
                    :value="true"
                    transition="slide-y-transition"
                    >{{ items.message }}</v-alert
                  >
                </v-col>
              </v-row>
            </div>
          </div>
        </v-row>
      </div>
      <v-dialog v-model="showNewDialog" persistent max-width="600px">
        <v-card>
          <v-form ref="form">
            <v-card-title>
              <span class="headline"
                >Set Subscription Availability</span
              >
            </v-card-title>
            <v-card-text style="font-size:16px">
              Are you sure you want to set the availability of
              <span style="color:green">{{
                subscriptionDetails.name
              }}</span>
              to Enterprise users?
              <p class="pt-4">
                <span style="font-weight:bold">
                  Once a subscription availability is set it can not
                  be changed.</span
                >
                Reverting this change will require contacting Suyati
                support.
              </p>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn depressed text @click="changeAvailability">
                Cancel
              </v-btn>
              <v-btn color="primary" @click="showModel">
                Set Availability
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showNewDialogEveryone"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-form ref="form">
            <v-card-title>
              <span class="headline"
                >Set Subscription Availability</span
              >
            </v-card-title>
            <v-card-text style="font-size:16px">
              Are you sure you want to set the availability of
              <span style="color:green">{{
                subscriptionDetails.name
              }}</span>
              to Everyone?
              <p class="pt-4">
                <span style="font-weight:bold">
                  Once a subscription availability is set it can not
                  be changed.</span
                >
                Reverting this change will require contacting Suyati
                support.
              </p>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn depressed text @click="changeAvailability">
                Cancel
              </v-btn>
              <v-btn color="primary" @click="showModelEveryone">
                Set Availability
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-container>
    <!-- alertconfirm -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CourseStoreTypes from '../../../store/modules/course/CourseStoreTypes';
import gql from 'graphql-tag';
import AvailableCourses from './AvailableCourses.vue';
import AppContentHeaderEditor from '@/components/editors/AppContentHeaderEditorDA';
import { Constant } from '../../../config/constants';
// import Content from '@/components/content-edit';

// const PRODUCT_AVAILABILITY = gql`
//   query {
//     productAvailabilities {
//       id
//       name
//       IsAdvertisementNeeded
//     }
//   }
// `;
const ORG_QUERY = gql`
  query($orgId: ID!) {
    orgMeasures(id: $orgId) {
      id
      name
    }
  }
`;
const SUBSCRIPTION_QUERY = gql`
  query($subId: ID!) {
    findOneSubscription(id: $subId) {
      isSuccess
      statusMessage
      statusCode
      data {
        id
        name
        inAppPrice
        notes
        isDisabled
        inAppProductId
        in_app_duration
        inAppMonthlyProductId
        inAppYearlyProductId
        isAutoRenew
        product_availability
        created_at
        updated_at
        blurb
        icon {
          fileType
          id
          alt
          caption
          url
          key
        }
        advertisement {
          fileType
          id
          alt
          caption
          url
          key
        }
      }
    }
  }
`;

const UPDATE_SUBSCRIPTION_NAME = gql`
  mutation($subId: ID!, $name: String!) {
    updateSubscriptionName(id: $subId, name: $name) {
      statusCode
      isSuccess
      statusMessage
      data {
        name
      }
    }
  }
`;

const UPDATE_SUBSCRIPTION_DETAILS = gql`
  mutation(
    $subId: ID!
    $inAppPrice: String
    $notes: String
    $isDisabled: Boolean!
    $inAppProductId: String
    $isAutoRenew: Boolean
    $product_availability: Int
    $advertisement: [fileStructure]
    $blurb: String
    $icon: [fileStructure]
  ) {
    updateSubscriptionDetail(
      input: {
        id: $subId
        notes: $notes
        isDisabled: $isDisabled
        inAppMonthlyProductId: $inAppProductId
        inAppYearlyProductId: $inAppPrice
        isAutoRenew: $isAutoRenew
        product_availability: $product_availability
        blurb: $blurb
        icon: $icon
        advertisement: $advertisement
      }
    ) {
      statusCode
      isSuccess
      statusMessage
      data {
        id
        inAppPrice
        notes
        isDisabled
        in_app_duration
        inAppMonthlyProductId
        inAppYearlyProductId
        product_availability
        created_at
        updated_at
        blurb
        icon {
          fileType
          id
          alt
          caption
          url
          key
        }
        advertisement {
          fileType
          id
          alt
          caption
          url
          key
        }
      }
    }
  }
`;

// const IN_APP_DURATIONS = gql`
//   query inAppDurations {
//     inAppDurations {
//       id
//       duration
//     }
//   }
// `;

export default {
  name: 'SubscriptionsDetails',
  components: {
    AvailableCourses,
    AppContentHeaderEditor
  },
  data: () => ({
    alert: {
      type: 'success',
      show: false,
      text: ''
    },
    errors: [],
    loading: false,
    dataLoaded: false,
    local: {
      headerContent: []
    },
    orgName: '',
    advertisements: [],
    inAppProductId: null,
    editName: false,
    blurb: null,
    roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER'
    },
    showNewDialog: false,
    showNewDialogEveryone: false,
    imageName: null,
    price: null,
    in_app_duration: null,
    visibility: false,
    imageUrl: null,
    isUploading: false,
    isAutoRenew: false,
    imageFile: [],
    moduleHeaders: [
      { text: 'ID', value: 'id' },
      { text: 'Title', value: 'title' },
      { text: 'Lessons', value: 'lessons' },
      { text: 'Activities', value: 'activities' },
      { text: 'Status', value: 'isDisabled' },
      { text: 'Sort', value: 'sort', sortable: false },
      { text: 'Action', value: 'action', sortable: false }
    ],
    primaryCourse: {},
    subscriptionDetails: {},
    subscriptionName: null,
    notes: null,
    availability_data: [],
    availability: null,
    courses: [],
    modules: [],
    ModuleOrder: []
  }),
  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating'
    ]),
    ...mapGetters('course', ['getOrgPath']),
    IsDementiaAdmin() {
      if (
        this.getUser?.user?.role?.name === this.roles.DEMENTIA_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsCMSAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.CMS_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGViewer() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_VIEWER) {
        return true;
      } else {
        return false;
      }
    },
    breadcrumbs() {
      if (this.orgId) {
        let orgNames = this.orgName;
        return [
          {
            text: orgNames,
            href: `#/organisations/${this.orgId}`
          },
          {
            text: this.subscriptionDetails.name,
            disabled: true
          }
        ];
      } else {
        return [{ text: 'Back', href: `#/subscriptions` }];
      }
    },
    subId() {
      return this.$route.params.subscriptionId;
    },
    orgId() {
      return this.$route.params.orgId;
    },
    checkAvailability() {
      let obj = this.availability_data.find(
        o => o.id === this.availability
      );
      return obj?.IsAdvertisementNeeded ? true : false;
    }
  },
  methods: {
    ...mapActions('course', [
      CourseStoreTypes.Actions.UPDATE_COURSE_FROM_ORG
    ]),
    getFile() {
      let fileUpload = document.getElementById('fileUpload');
      if (fileUpload != null) {
        fileUpload.click();
      }
    },
    uploadImage(files) {
      const uploadUrl =
        process.env.VUE_APP_BASE_API + '/media/upload/image';
      let data = new FormData();
      // var tempObject = {};
      data.append('files', files);
      const headerOptions = {
        Authorization:
          'Bearer ' + localStorage.getItem(Constant.AUTH_TOKEN)
      };
      this.isUploading = true;
      fetch(uploadUrl, {
        method: 'POST',
        body: data,
        headers: headerOptions
      })
        .then(res => res.json())
        .then(res => {
          this.imageFile = [
            {
              fileType: 'picture',
              alt: files.name,
              caption: files.name,
              url: res.key,
              key: res.key
            }
          ];
        })
        .catch(() => {
          // todo: handle server failure
        })
        .finally(() => {
          this.isUploading = false;
        });
    },
    onFilePicked(e) {
      const files = e;
      if (files?.name !== undefined) {
        this.imageName = files.name;
        if (this.imageName.lastIndexOf('.') <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files);
        fr.addEventListener('load', () => {
          this.imageUrl = fr.result;
          this.uploadImage(files); // this is an image file that can be sent to server...
        });
      } else {
        this.imageName = '';
        this.imageFile = null;
        this.imageUrl = '';
      }
    },
    showModel() {
      this.showNewDialog = !this.showNewDialog;
    },
    showModelEveryone() {
      this.showNewDialogEveryone = !this.showNewDialogEveryone;
    },
    changeAvailability() {
      this.availability = this.subscriptionDetails?.product_availability;
      this.showNewDialog = false;
      this.showNewDialogEveryone = false;
    },
    editSubscriptionName() {
      this.editName = !this.editName;
    },
    updateSubscription() {
      let adImgAndVideo = [];
      if (this.advertisements.length > 0) {
        this.advertisements.forEach(items => {
          adImgAndVideo.push({
            fileType:
              items.__typename == 'ComponentContentListPicture'
                ? 'picture'
                : 'video',
            alt: items.key,
            caption: items.key,
            url: items.url,
            key: items.key
          });
        });
      }
      if (this.price < 0 || this.in_app_duration < 0) {
        return false;
      }
      let payload = {
        subId: this.subId,
        inAppPrice: this.price,
        notes: this.notes,
        isDisabled: !this.visibility,
        in_app_duration: this.in_app_duration,
        inAppProductId: this.inAppProductId,
        isAutoRenew: this.isAutoRenew,
        product_availability: this.availability,
        blurb: this.blurb,
        advertisement: adImgAndVideo
      };
      if (this.imageFile && this.imageFile.length > 0) {
        payload.icon = this.imageFile;
      }

      this.$apollo
        .mutate({
          mutation: UPDATE_SUBSCRIPTION_DETAILS,
          variables: payload,
          errorPolicy: 'all',
          // Why is query not updating!!!
          // This shouldn't be necessary!!!
          update: ({ data: { updateSubscriptionDetail } }) => {
            if (updateSubscriptionDetail?.statusCode == 'Error') {
              this.errors = updateSubscriptionDetail.error;
            } else {
              this.$apollo.queries.product.refresh();
              this.alert.show = true;
              this.alert.text =
                'Subscription details updated successfully';
              this.alert.type = 'success';
              this.editName = false;
              setTimeout(() => {
                this.alert.show = false;
              }, 3000);
            }
          }
        })
        .catch(() => {
          this.alert.show = true;
          this.alert.text = 'Please fill the required fields';
          this.alert.type = 'error';
          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        });
    },
    updateSubscriptionData(items) {
      this.subscriptionDetails = items;
      this.subscriptionName = items.name;
      this.notes = items.notes;
      this.availability = items.product_availability;
      this.blurb = items.blurb;
      this.price = items.inAppYearlyProductId;
      this.in_app_duration = items?.in_app_duration?.toString();
      this.inAppProductId = items.inAppMonthlyProductId;
      this.isAutoRenew = items.isAutoRenew;
      this.visibility = !items.isDisabled;
      // this.advertisements = [...items.advertisement];

      let adImgAndVideo = [];
      if (items.advertisement.length > 0) {
        items.advertisement.forEach((items, index) => {
          adImgAndVideo.push({
            __typename:
              items.fileType == 'picture'
                ? 'ComponentContentListPicture'
                : 'ComponentContentListVideo',
            alt: items.key,
            caption: items.key,
            url: items.url,
            key: items.key,
            _vueKey: index
          });
        });
        this.advertisements = adImgAndVideo;
      }
      this.imageUrl = items.icon?.[0]?.url
        ? items.icon[0].url
        : this.imageUrl;
      this.loading = false;
      this.dataLoaded = true;
    },
    updateSubscriptionName() {
      if (!this.subscriptionName) return;
      this.$apollo
        .mutate({
          mutation: UPDATE_SUBSCRIPTION_NAME,
          variables: {
            subId: this.subId,
            name: this.subscriptionName
          },
          // Why is query not updating!!!
          // This shouldn't be necessary!!!
          update: () => {
            this.$apollo.queries.product.refresh();
            // Only update the store if there's a new course created
            this.alert.show = true;
            this.alert.text = 'Successfully Updated';
            this.alert.type = 'success';
            this.editName = false;
            setTimeout(() => {
              this.alert.show = false;
            }, 3000);
          }
        })
        .catch(() => {
          this.alert.show = true;
          this.alert.text = 'Operation failed.';
          this.alert.type = 'error';
          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        });
    }
  },
  apollo: {
    product() {
      this.loading = true;
      return {
        query: SUBSCRIPTION_QUERY,
        variables: {
          subId: this.subId
        },
        fetchPolicy: 'network-only',
        update: data => {
          let items = data.findOneSubscription.data;
          this.updateSubscriptionData(items);
          return data.findOneSubscription.data;
        },
        error() {
          this.loading = false;
          this.dataLoaded = false;
          this.alert.show = true;
          this.alert.text =
            'Sorry, The requisition data cannot be loaded';
          this.alert.type = 'error';
          this.editName = false;
          // setTimeout(() => {
          //   this.alert.show = false;
          // }, 3000);
        }
      };
    },
    organisation() {
      return {
        query: ORG_QUERY,
        variables: {
          orgId: this.orgId
        },
        fetchPolicy: 'network-only',
        update: data => {
          this.orgName = data.orgMeasures.name;
        },
        skip() {
          return !this.orgId;
        },
        error() {
          this.loading = false;
          this.dataLoaded = false;
          this.alert.show = true;
          this.alert.text =
            'Sorry, The requisition data cannot be loaded';
          this.alert.type = 'error';
          this.editName = false;
          // setTimeout(() => {
          //   this.alert.show = false;
          // }, 3000);
        }
      };
    }
    // inAppDurations() {
    //   return {
    //     query: IN_APP_DURATIONS,
    //     fetchPolicy: 'cache-and-network',
    //     update: data => {
    //       return data.inAppDurations;
    //     },
    //     error() {
    //       this.loading = false;
    //     },
    //     skip() {
    //       return !this.isAuthenticated;
    //     }
    //   };
    // }
  }
};
</script>

<style lang="scss" scoped>
.tooltipDiv {
  height: 42px;
  margin-top: 10px;
  background: rgba(0, 0, 0, 0.87);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-weight: normal;
  padding: 0px 8px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  font-feature-settings: 'cpsp' on, 'kern' off;
  color: #ffffff;
}
</style>
