<template>
  <div>
    <h1 v-if="!isInit && isAuthenticated">
      Dementia Australia: Learning Management System
    </h1>
    <div style="position:relative; top:40px;">
      <page-user-control v-if="!isInit" />
    </div>
    <div v-if="isInit">Initialising</div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { PageUserControl } from '../../modules';

export default {
  name: 'LoginHome',
  components: { PageUserControl },
  computed: {
    ...mapGetters('auth', ['isAuthenticated', 'isInit'])
  },
  watch: {
    isAuthenticated: function(newVal) {
      if (newVal) this.redirect();
    }
  },
  created() {
    if (this.isAuthenticated && this.$route.params?.redirect) {
      this.redirect();
    }
  },
  methods: {
    redirect() {
      if (!this.$route.params?.redirect) return;
      this.$router.push({
        name: this.$route.params.redirect,
        query: this.$route.query,
        params: this.$route.params
      });
    }
  }
};
</script>
