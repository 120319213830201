import OrganizationTypes from './OrganizationStoreTypes';
const { Mutations } = OrganizationTypes;

export default {
  namespaced: true,
  state: {
    organizationFromOrg: ''
  },
  getters: {
    getOrgLicensePath(state) {
      return state.organizationFromOrg;
    }
  },
  mutations: {
    setLicenseFromOrg(state, orgPath) {
      state.organizationFromOrg = orgPath;
    }
  },
  actions: {
    updateLicenseFromOrg({ commit }, data) {
      commit(Mutations.SET_LICENSE_FROM_ORG, data);
    }
  }
};
