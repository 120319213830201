<template>
  <v-col cols="6">
    <div class="d-flex justify-center">
      <v-alert
        dense
        text
        class="v-alert"
        :type="alert.type"
        :value="alert.show"
        transition="slide-y-transition"
        >{{ alert.text }}</v-alert
      >
    </div>
    <v-card outlined>
      <v-form ref="form" lazy-validation>
        <v-card-text>
          <div class="section-devider no-bg">
            <h2>Available Courses</h2>
          </div>
          <div class="page-sub-sec">
            <v-text-field
              v-model="searchCourse"
              append-icon="mdi-magnify"
              label="Search"
              outlined
              dense
              hide-details
              class="shrink"
            />
            <v-btn
              v-if="IsCMSAdmin"
              color="primary"
              @click="showModel"
            >
              <v-icon class="mr-2">mdi-file-plus</v-icon>Add
              Course</v-btn
            >
          </div>
          <v-data-table
            :items="courses"
            :search="searchCourse"
            :headers="computedHeaders"
            :hide-default-footer="courses.length <= 10 ? true : false"
            class="dark--text"
            :loading="loadingCourses"
            loading-text="Loading... Please wait"
            :no-data-text="`No data available`"
          >
            <template v-slot:[`item.Title`]="{ item }">
              <router-link
                :to="`/courses/${item.id}`"
                class="blue--text"
                >{{ item.Title }}</router-link
              >
            </template>
            <template v-slot:[`item.id`]="{ item }">
              {{ item.id }}
            </template>
            <template v-slot:[`item.modules`]="{ item }">
              {{ item.modules }}
            </template>
            <template v-slot:[`item.lessons`]="{ item }">
              {{ item.lessons }}
            </template>
            <template v-slot:[`item.isDisabled`]="{ item }">
              <p v-if="!item.isDisabled" class="success--text">
                Active
              </p>
              <p v-if="item.isDisabled" class="warning--text">
                Inactive
              </p>
            </template>
            <template
              v-if="IsCMSAdmin"
              v-slot:[`item.sort`]="{ item }"
            >
              <v-btn
                icon
                small
                :disabled="getIndex(item, courses) < 1"
                @click="
                  moveCourse(
                    getIndex(item, courses),
                    getIndex(item, courses) - 1
                  )
                "
                ><v-icon>mdi-arrow-up</v-icon></v-btn
              >
              <v-btn
                icon
                small
                :disabled="
                  getIndex(item, courses) > courses.length - 2
                "
                @click="
                  moveCourse(
                    getIndex(item, courses),
                    getIndex(item, courses) + 1
                  )
                "
                ><v-icon>mdi-arrow-down</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-form>
    </v-card>
    <v-dialog
      v-if="IsCMSAdmin"
      v-model="showModal"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-form ref="form">
          <v-card-title>
            <span class="headline">Add Course to subscription</span>
          </v-card-title>
          <v-col cols="12" sm="8">
            <v-row>
              <v-col
                v-if="selectedCourse"
                sm="2"
                style="align-self: center;    text-align: center;"
                ><span style="color:#5F6368;font-size:10px">{{
                  selectedCourse
                }}</span></v-col
              >
              <v-col sm="9">
                <v-autocomplete
                  v-model="selectedCourse"
                  :items="primaryCourseList"
                  item-text="Title"
                  item-value="id"
                  label="Select Course*"
                  required
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>
                        <span style="color:#0d3349;font-size:12px"
                          >{{ data.item.id }} -
                        </span>
                        {{ data.item.Title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-card-actions>
            <small class="info--text"
              >*indicates required field</small
            >
            <v-spacer />
            <v-btn depressed text @click="showModel">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="addCourse">
              Add Course
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex';
import { Constant } from '../../../config/constants';
import gql from 'graphql-tag';

const COURSE_LIST_QUERY = gql`
  query($id: Int!) {
    findAvailableCourses(id: $id) {
      isSuccess
      data {
        id
        Title
        modules
        lessons
        isDisabled
        activities
      }
    }
  }
`;

const PRIMARY_COURSE_QUERY = gql`
  query {
    subscriptionCourseFilter {
      isSuccess
      data {
        id
        Title
      }
    }
  }
`;

const ADD_COURSE_TO_SUBSCRIPTION_MUTATION = gql`
  mutation(
    $id: ID!
    $primaryCourseOrder: [ID]!
    $primary_courses: [ID]!
  ) {
    addSubscriptionCourse(
      id: $id
      primaryCourseOrder: $primaryCourseOrder
      primary_courses: $primary_courses
    ) {
      statusCode
      statusMessage
      isSuccess
    }
  }
`;

export default {
  name: 'AvailableCourses',
  data: () => ({
    system_roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER'
    },
    alert: {
      type: 'success',
      show: false,
      text: ''
    },
    searchCourse: '',
    headers: [
      {
        text: 'Title',
        value: 'Title',
        align: 'left',
        width: '1%'
      },
      { text: 'Course Id', value: 'id', width: '2%' },
      { text: 'Modules', value: 'modules', width: '1%' },
      { text: 'Lessons', value: 'lessons', width: '1%' },
      { text: 'Activities', value: 'activities', width: '1%' },
      {
        text: 'Status',
        value: 'isDisabled',
        sortable: false,
        width: '1%'
      },
      {
        text: 'Sort',
        value: 'sort',
        sortable: false,
        width: '2%'
      }
    ],
    loadingCourses: false,
    courses: [],
    primaryCourseOrder: [],
    primary_courses: [],
    selectedCourse: null,
    primaryCourses: [],
    showModal: false
  }),
  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating'
    ]),

    computedHeaders() {
      if (this.IsDementiaAdmin)
        return this.headers.filter(t => t.value != 'sort');
      else return this.headers;
    },
    IsDementiaAdmin() {
      if (
        this.getUser?.user?.role?.name ===
        this.system_roles.DEMENTIA_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsOrglevelRole() {
      const organizationRequiredRoles = [
        this.system_roles.ORG_ADMIN,
        this.system_roles.ORG_VIEWER,
        'LEARNER'
      ];
      if (
        this.forms.newRoleName &&
        organizationRequiredRoles.includes(this.forms.newRoleName)
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsCMSAdmin() {
      if (
        this.getUser?.user?.role?.name === this.system_roles.CMS_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsORGAdmin() {
      if (
        this.getUser?.user?.role?.name === this.system_roles.ORG_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsORGViewer() {
      if (
        this.getUser?.user?.role?.name ===
        this.system_roles.ORG_VIEWER
      ) {
        return true;
      } else {
        return false;
      }
    },
    subId() {
      return parseInt(this.$route.params.subscriptionId);
    },
    getLoginedUserId() {
      return this.getUser?.user?.id;
    },
    getSubscriptions() {
      return [];
    }
  },
  created() {
    this.Constant = Constant;
  },
  methods: {
    getRoleDisplayName(roleName) {
      let displayRole = '';
      switch (roleName) {
        case Constant.roles.DEMENTIA_ADMIN:
          displayRole = Constant.roles.DEMENTIA_ADMIN_VIEW;
          break;
        case Constant.roles.CMS_ADMIN:
          displayRole = Constant.roles.CMS_ADMIN_VIEW;
          break;
        case Constant.roles.ORG_ADMIN:
          displayRole = Constant.roles.ORG_ADMIN_VIEW;
          break;
        case Constant.roles.ORG_VIEWER:
          displayRole = Constant.roles.ORG_VIEWER_VIEW;
          break;
        case Constant.roles.LEARNER:
          displayRole = Constant.roles.LEARNER_VIEW;
          break;
      }
      return displayRole;
    },
    showModel() {
      this.showModal = !this.showModal;
    },
    addCourse() {
      if (!this.selectedCourse) return;
      this.primaryCourseOrder.push(this.selectedCourse);
      this.primary_courses.push(this.selectedCourse);
      this.$apollo
        .mutate({
          mutation: ADD_COURSE_TO_SUBSCRIPTION_MUTATION,
          variables: {
            id: this.subId,
            primaryCourseOrder: this.primaryCourseOrder,
            primary_courses: this.primary_courses
          },
          // Why is query not updating!!!
          // This shouldn't be necessary!!!
          update: () => {
            this.$apollo.queries.courses.refresh();
            this.$apollo.queries.primaryCourseList.refresh();
            this.alert.show = true;
            this.alert.text = 'Successfully Added';
            this.alert.type = 'success';
            this.showModal = false;
            this.$refs.form.resetValidation();
            this.$refs.form.reset();
            setTimeout(() => {
              this.alert.show = false;
            }, 3000);
          }
        })
        .catch(error => {
          this.alert.show = true;
          this.alert.text = error;
          this.alert.type = 'error';
          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        });
    },
    getIndex(item, items) {
      if (!items) return 0;
      if (!item) return 0;
      return items
        .filter(o => o != 'undefined')
        .map(function(x) {
          if (x) return x.id;
        })
        .indexOf(item.id);
    },
    moveCourse(from, to) {
      const newOrder = this.courses.map(l => l.id);
      const [item] = newOrder.splice(from, 1);
      newOrder.splice(to, 0, item);

      this.$apollo.mutate({
        mutation: ADD_COURSE_TO_SUBSCRIPTION_MUTATION,
        variables: {
          id: this.subId,
          primaryCourseOrder: newOrder,
          primary_courses: this.primary_courses
        },
        update: () => {
          this.$apollo.queries.courses.refresh();
        }
      });
    }
  },
  apollo: {
    courses() {
      this.loadingCourses = true;
      return {
        query: COURSE_LIST_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          id: this.subId
        },
        update: data => {
          let items = data.findAvailableCourses.data;
          this.primaryCourseOrder = items.map(item => {
            return item.id;
          });
          this.primary_courses = items.map(item => {
            return item.id;
          });
          this.loadingCourses = false;
          return items;
        },
        error() {
          this.alert.show = true;
          this.alert.text =
            'Sorry, The requisition data cannot be loaded';
          this.alert.type = 'error';
          this.loadingCourses = false;
          // setTimeout(() => {
          //   this.alert.show = false;
          // }, 3000);
        }
      };
    },
    primaryCourseList() {
      return {
        query: PRIMARY_COURSE_QUERY,
        fetchPolicy: 'network-only',
        update: data => {
          let items = data.subscriptionCourseFilter.data;
          this.primaryCourses = items;
          return items;
        },
        skip() {
          return !this.IsCMSAdmin;
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped></style>
