var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "overflow-y-scroll" },
    [
      _vm.loading
        ? _c("div", [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("v-progress-circular", {
                  attrs: { indeterminate: "", color: "black" }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      !_vm.loading
        ? _c(
            "v-container",
            { staticClass: "grey lighten-5", attrs: { fluid: "" } },
            [
              _c(
                "v-alert",
                {
                  attrs: {
                    dense: "",
                    text: "",
                    type: _vm.alert.type,
                    value: _vm.alert.show,
                    transition: "slide-y-transition"
                  }
                },
                [_vm._v(_vm._s(_vm.alert.text))]
              ),
              _vm.dataLoaded
                ? _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "", align: "center" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mr-auto", attrs: { cols: "auto" } },
                            [
                              _c("v-breadcrumbs", {
                                staticClass: "pa-0",
                                attrs: { items: _vm.breadcrumbs }
                              }),
                              _c("h1", [_vm._v(_vm._s(_vm.course.title))])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { outlined: "" } },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      attrs: { "lazy-validation": "" },
                                      model: {
                                        value: _vm.validCourse,
                                        callback: function($$v) {
                                          _vm.validCourse = $$v
                                        },
                                        expression: "validCourse"
                                      }
                                    },
                                    [
                                      _c("v-card-text", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "section-devider no-bg"
                                          },
                                          [
                                            _c("h2", [_vm._v("Title")]),
                                            _c("v-text-field", {
                                              attrs: {
                                                counter: 50,
                                                rules: _vm.nameRules,
                                                "prepend-icon": "mdi-pencil",
                                                placeholder: "loading...",
                                                required: ""
                                              },
                                              model: {
                                                value: _vm.local.title,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.local,
                                                    "title",
                                                    $$v
                                                  )
                                                },
                                                expression: "local.title"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm.IsCMSAdmin
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "section-devider"
                                                },
                                                [
                                                  _c("h2", [
                                                    _vm._v("Text Color")
                                                  ]),
                                                  _c("v-color-picker", {
                                                    attrs: {
                                                      "hide-canvas": "",
                                                      "hide-mode-switch": "",
                                                      mode: "hexa",
                                                      "show-swatches": "",
                                                      "swatches-max-height":
                                                        "300"
                                                    },
                                                    model: {
                                                      value: _vm.textcolor,
                                                      callback: function($$v) {
                                                        _vm.textcolor = $$v
                                                      },
                                                      expression: "textcolor"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "section-devider"
                                                },
                                                [
                                                  _c("h2", [
                                                    _vm._v("SubText Color")
                                                  ]),
                                                  _c("v-color-picker", {
                                                    attrs: {
                                                      "hide-canvas": "",
                                                      "hide-mode-switch": "",
                                                      mode: "hexa",
                                                      "show-swatches": "",
                                                      "swatches-max-height":
                                                        "300"
                                                    },
                                                    model: {
                                                      value: _vm.subtextColour,
                                                      callback: function($$v) {
                                                        _vm.subtextColour = $$v
                                                      },
                                                      expression:
                                                        "subtextColour"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "section-devider"
                                                },
                                                [
                                                  _c("h2", [
                                                    _vm._v("Background Color")
                                                  ]),
                                                  _c("v-color-picker", {
                                                    attrs: {
                                                      "hide-canvas": "",
                                                      "hide-mode-switch": "",
                                                      mode: "hexa",
                                                      "show-swatches": "",
                                                      "swatches-max-height":
                                                        "300"
                                                    },
                                                    model: {
                                                      value: _vm.tilecolor,
                                                      callback: function($$v) {
                                                        _vm.tilecolor = $$v
                                                      },
                                                      expression: "tilecolor"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "section-devider"
                                                },
                                                [
                                                  _c("h2", [
                                                    _vm._v(
                                                      "Progress Fill color"
                                                    )
                                                  ]),
                                                  _c("v-color-picker", {
                                                    attrs: {
                                                      "hide-canvas": "",
                                                      "hide-mode-switch": "",
                                                      mode: "hexa",
                                                      "show-swatches": "",
                                                      "swatches-max-height":
                                                        "300"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.progressfillColour,
                                                      callback: function($$v) {
                                                        _vm.progressfillColour = $$v
                                                      },
                                                      expression:
                                                        "progressfillColour"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.IsDementiaAdmin
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "section-devider"
                                              },
                                              [
                                                _c("h2", [
                                                  _vm._v("Color Picker")
                                                ]),
                                                _c(
                                                  "v-row",
                                                  {
                                                    staticClass:
                                                      "colorDisplayRow",
                                                    attrs: {
                                                      justify: "space-around"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "colorDisplayRow"
                                                          },
                                                          [
                                                            _c("h4", [
                                                              _vm._v(
                                                                "Text Color"
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "colorDisplayRow"
                                                          },
                                                          [
                                                            _c("div", {
                                                              staticClass:
                                                                "text-Color-Picker",
                                                              style: {
                                                                "background-color":
                                                                  _vm.textcolor
                                                              }
                                                            }),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-display"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.textcolor
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      [
                                                        _c("v-row", [
                                                          _c("h4", [
                                                            _vm._v(
                                                              "Subtext Color"
                                                            )
                                                          ])
                                                        ]),
                                                        _c("v-row", [
                                                          _c("div", {
                                                            staticClass:
                                                              "text-Color-Picker",
                                                            style: {
                                                              "background-color":
                                                                _vm.subtextColour
                                                            }
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-display"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.subtextColour
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      [
                                                        _c("v-row", [
                                                          _c("h4", [
                                                            _vm._v(
                                                              "Background Color"
                                                            )
                                                          ])
                                                        ]),
                                                        _c("v-row", [
                                                          _c("div", {
                                                            staticClass:
                                                              "text-Color-Picker",
                                                            style: {
                                                              "background-color":
                                                                _vm.tilecolor
                                                            }
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-display"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.tilecolor
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      [
                                                        _c("v-row", [
                                                          _c("h4", [
                                                            _vm._v(
                                                              "Progress Fill Color"
                                                            )
                                                          ])
                                                        ]),
                                                        _c("v-row", [
                                                          _c("div", {
                                                            staticClass:
                                                              "text-Color-Picker",
                                                            style: {
                                                              "background-color":
                                                                _vm.progressfillColour
                                                            }
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-display"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.progressfillColour
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]),
                                      _c(
                                        "v-card-actions",
                                        { staticClass: "pt-0" },
                                        [
                                          _vm.IsCMSAdmin
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    "x-large": ""
                                                  },
                                                  on: {
                                                    click: _vm.validateCourse
                                                  }
                                                },
                                                [_vm._v("Save")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pa-0", attrs: { cols: "6" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "mb-5",
                                      attrs: { outlined: "" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c("v-col", [
                                                _c("h2", [_vm._v("Modules")])
                                              ]),
                                              _c("v-spacer"),
                                              _c(
                                                "v-col",
                                                { staticClass: "text-end" },
                                                [
                                                  _vm.IsCMSAdmin
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            rounded: "",
                                                            small: ""
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.LoadCreateModule
                                                          }
                                                        },
                                                        [_vm._v("NEW MODULE")]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { staticClass: "text-end" },
                                                [
                                                  _vm.IsCMSAdmin
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            rounded: "",
                                                            small: ""
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.LoadAddModule
                                                          }
                                                        },
                                                        [_vm._v("ADD MODULE")]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "shrink",
                                                    attrs: {
                                                      "append-icon":
                                                        "mdi-magnify",
                                                      label: "Search",
                                                      "single-line": "",
                                                      dense: "",
                                                      "hide-details": ""
                                                    },
                                                    model: {
                                                      value: _vm.searchmodule,
                                                      callback: function($$v) {
                                                        _vm.searchmodule = $$v
                                                      },
                                                      expression: "searchmodule"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-data-table", {
                                            attrs: {
                                              items: _vm.orderedModules,
                                              headers:
                                                _vm.computedModuleHeaders,
                                              search: _vm.searchmodule,
                                              loading: _vm.loadingCourses,
                                              "loading-text":
                                                "Loading... Please wait",
                                              "no-data-text":
                                                "No module available",
                                              "hide-default-footer": _vm.modulesPaginationStatus()
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                _vm._l(
                                                  _vm.computedModuleHeaders,
                                                  function(h) {
                                                    return {
                                                      key: "header." + h.value,
                                                      fn: function(ref) {
                                                        var header = ref.header
                                                        return [
                                                          _vm.headerTooltips[
                                                            h.value
                                                          ]
                                                            ? _c(
                                                                "v-tooltip",
                                                                {
                                                                  key: h.value,
                                                                  attrs: {
                                                                    bottom: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "span",
                                                                              _vm._g(
                                                                                {},
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    header.text
                                                                                  )
                                                                                ),
                                                                                _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    staticClass:
                                                                                      "pl-1",
                                                                                    attrs: {
                                                                                      color:
                                                                                        "#113247"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "mdi-information"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .headerTooltips[
                                                                          header
                                                                            .value
                                                                        ]
                                                                      )
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  key: h.value
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      header.text
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                        ]
                                                      }
                                                    }
                                                  }
                                                ),
                                                {
                                                  key: "item.sl",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getIndex(
                                                                item,
                                                                _vm.orderedModules
                                                              ) + 1
                                                            )
                                                        )
                                                      ])
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "item.title",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "router-link",
                                                        {
                                                          staticClass:
                                                            "blue--text",
                                                          attrs: {
                                                            to:
                                                              "/courses/" +
                                                              _vm.courseListId +
                                                              "/" +
                                                              item.id
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.title)
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "item.id",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          " " + _vm._s(item.id)
                                                        )
                                                      ])
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "item.lessons",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getLessonsCount(
                                                              item.lessons
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "item.activities",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getActivitiesCount(
                                                              item.lessons
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "item.isPaid",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      !item.isPaid
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: "grey",
                                                                icon: "",
                                                                small: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-currency-usd-off"
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      item.isPaid
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: "grey",
                                                                icon: "",
                                                                small: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-currency-usd"
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.isPaid
                                                              ? "Paid"
                                                              : "Free"
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "item.sort",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "lessons-actions-wrap"
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                icon: "",
                                                                small: "",
                                                                disabled:
                                                                  _vm.getIndex(
                                                                    item,
                                                                    _vm.orderedModules
                                                                  ) < 1
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.moveModule(
                                                                    _vm.getIndex(
                                                                      item,
                                                                      _vm.orderedModules
                                                                    ),
                                                                    _vm.getIndex(
                                                                      item,
                                                                      _vm.orderedModules
                                                                    ) - 1
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-arrow-up"
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                icon: "",
                                                                small: "",
                                                                disabled:
                                                                  _vm.getIndex(
                                                                    item,
                                                                    _vm.orderedModules
                                                                  ) >
                                                                  _vm
                                                                    .orderedModules
                                                                    .length -
                                                                    2
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.moveModule(
                                                                    _vm.getIndex(
                                                                      item,
                                                                      _vm.orderedModules
                                                                    ),
                                                                    _vm.getIndex(
                                                                      item,
                                                                      _vm.orderedModules
                                                                    ) + 1
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-arrow-down"
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "item.isDisabled",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _vm.isModuleDisabledForCourse(
                                                        item.id
                                                      ) && _vm.IsCMSAdmin
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: "green",
                                                                icon: "",
                                                                small: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.enableOrDisableModuleForCourse(
                                                                    item.id,
                                                                    false
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    title:
                                                                      "Enable"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-eye"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "text-transform":
                                                                      "none"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Visible"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      !_vm.isModuleDisabledForCourse(
                                                        item.id
                                                      ) && _vm.IsCMSAdmin
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: "orange",
                                                                icon: "",
                                                                small: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.enableOrDisableModuleForCourse(
                                                                    item.id,
                                                                    true
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    title:
                                                                      "Disable"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-eye-off"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "text-transform":
                                                                      "none"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Hidden"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.isModuleDisabledForCourse(
                                                        item.id
                                                      ) && !_vm.IsCMSAdmin
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: "grey",
                                                                icon: "",
                                                                small: "",
                                                                disabled: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    title:
                                                                      "Enable"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-eye"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "text-transform":
                                                                      "none"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Visible"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      !_vm.isModuleDisabledForCourse(
                                                        item.id
                                                      ) && !_vm.IsCMSAdmin
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: "grey",
                                                                icon: "",
                                                                small: "",
                                                                disabled: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    title:
                                                                      "Disable"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-eye-off"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "text-transform":
                                                                      "none"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Hidden"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "item.action",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "lessons-actions-wrap"
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                icon: "",
                                                                small: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.LoadDialogForModuleDuplication(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    title:
                                                                      "copy"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-content-copy"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: "red",
                                                                icon: "",
                                                                small: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.LoadConfirmDialogForDeleteModuleFromCourse(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    title:
                                                                      "Delete"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-trash-can-outline"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "v-data-table__wrapper " },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "mb-5",
                                      attrs: { outlined: "" }
                                    },
                                    [
                                      _c("v-card-title", [
                                        _c("h2", { staticClass: "pa-0" }, [
                                          _vm._v("Course Details")
                                        ])
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "lessons-actions-wrap" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "v-data-table__wrapper "
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-inline  mx-lg-auto"
                                                },
                                                [
                                                  _c(
                                                    "h4",
                                                    { staticClass: "pa-2" },
                                                    [
                                                      _vm._v(
                                                        " Available to... "
                                                      )
                                                    ]
                                                  ),
                                                  _c("v-autocomplete", {
                                                    staticClass: "pa-2",
                                                    staticStyle: {
                                                      width: "80%"
                                                    },
                                                    attrs: {
                                                      items:
                                                        _vm.getAvailableProducts,
                                                      label: _vm.selectedProduct
                                                        ? "ID-" +
                                                          _vm.selectedProduct
                                                        : "Select Subscription",
                                                      disabled: _vm.IsCMSAdmin
                                                        ? _vm.courseProductChanged
                                                          ? false
                                                          : true
                                                        : true
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.changeCourseProduct()
                                                      }
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "item",
                                                          fn: function(data) {
                                                            return [
                                                              _c(
                                                                "v-list-item-content",
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle: {
                                                                            color:
                                                                              "#0d3349",
                                                                            "font-size":
                                                                              "12px"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              data
                                                                                .item
                                                                                .value
                                                                            ) +
                                                                              " - "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            data
                                                                              .item
                                                                              .text
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      2982664205
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.selectedProduct,
                                                      callback: function($$v) {
                                                        _vm.selectedProduct = $$v
                                                      },
                                                      expression:
                                                        "selectedProduct"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "v-data-table__wrapper"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-inline  mx-sm-auto"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "lessons-actions-wrap"
                                                    },
                                                    [
                                                      _c(
                                                        "h4",
                                                        { staticClass: "pa-2" },
                                                        [_vm._v(" Visibility ")]
                                                      ),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          staticClass: "pa-2",
                                                          attrs: { right: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {},
                                                                          "v-icon",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          " mdi-information "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            false,
                                                            3439555294
                                                          )
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Whether or not this course is visible within the mobile app"
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-switch", {
                                                    staticClass: "mx-3",
                                                    attrs: {
                                                      inset: "",
                                                      disabled: !_vm.IsCMSAdmin,
                                                      label:
                                                        "" +
                                                        (_vm.courseEnabled
                                                          ? "Visible"
                                                          : "Hidden"),
                                                      color: "success"
                                                    },
                                                    model: {
                                                      value: _vm.courseEnabled,
                                                      callback: function($$v) {
                                                        _vm.courseEnabled = $$v
                                                      },
                                                      expression:
                                                        "courseEnabled"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "v-data-table__wrapper "
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-inline  mx-lg-auto"
                                            },
                                            [
                                              _c(
                                                "h4",
                                                { staticClass: "pa-1" },
                                                [_vm._v(" Notes ")]
                                              ),
                                              _c("v-textarea", {
                                                staticClass: "pa-1",
                                                attrs: {
                                                  counter: 500,
                                                  disabled: !_vm.IsCMSAdmin,
                                                  outlined: "",
                                                  dense: ""
                                                },
                                                model: {
                                                  value: _vm.courseDetailNote,
                                                  callback: function($$v) {
                                                    _vm.courseDetailNote = $$v
                                                  },
                                                  expression: "courseDetailNote"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm.IsCMSAdmin
                                            ? _c(
                                                "v-card-actions",
                                                {
                                                  staticClass:
                                                    "float-right pa-0"
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        "x-large": ""
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.validateCourseDetails
                                                      }
                                                    },
                                                    [_vm._v("Save")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.showNewModuleDialog,
            callback: function($$v) {
              _vm.showNewModuleDialog = $$v
            },
            expression: "showNewModuleDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.validModule,
                    callback: function($$v) {
                      _vm.validModule = $$v
                    },
                    expression: "validModule"
                  }
                },
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v("New Module")
                    ])
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-text-field", {
                        attrs: {
                          counter: 50,
                          rules: _vm.nameRules,
                          label: "Name*",
                          required: ""
                        },
                        model: {
                          value: _vm.newModuleName,
                          callback: function($$v) {
                            _vm.newModuleName = $$v
                          },
                          expression: "newModuleName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("small", { staticClass: "info--text" }, [
                        _vm._v(
                          "Please ensure each mandatory field marked with an asterisk * is completed"
                        )
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "", text: "" },
                          on: {
                            click: function($event) {
                              _vm.showNewModuleDialog = false
                            }
                          }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.createNewModule }
                        },
                        [_vm._v(" New module ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.showModuleAddDialog,
            callback: function($$v) {
              _vm.showModuleAddDialog = $$v
            },
            expression: "showModuleAddDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-form",
                {
                  ref: "form1",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.validModuleAdd,
                    callback: function($$v) {
                      _vm.validModuleAdd = $$v
                    },
                    expression: "validModuleAdd"
                  }
                },
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v("Add Module to this Course")
                    ])
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.getUnmappedModuleList,
                          rules: [
                            function(v) {
                              return !!v || "Module is required"
                            }
                          ],
                          label: "Select Modules*",
                          dense: "",
                          required: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function(data) {
                              return [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#0d3349",
                                            "font-size": "10px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(data.item.value) + " - "
                                          )
                                        ]
                                      ),
                                      _vm._v(" " + _vm._s(data.item.text) + " ")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.forms.addModule.id,
                          callback: function($$v) {
                            _vm.$set(_vm.forms.addModule, "id", $$v)
                          },
                          expression: "forms.addModule.id"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("small", { staticClass: "info--text" }, [
                        _vm._v(
                          "Please ensure each mandatory field marked with an asterisk * is completed"
                        )
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "", text: "" },
                          on: {
                            click: function($event) {
                              _vm.showModuleAddDialog = false
                            }
                          }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.validateNewModule()
                            }
                          }
                        },
                        [_vm._v(" Add Module ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "450" },
          model: {
            value: _vm.confirmdialog.show,
            callback: function($$v) {
              _vm.$set(_vm.confirmdialog, "show", $$v)
            },
            expression: "confirmdialog.show"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.confirmdialog.HeaderText))
              ]),
              _c("v-card-text", [
                _vm._v(_vm._s(_vm.confirmdialog.ContentText))
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmdialog.show = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.confirmdialog.ButtonConfirmAction(
                            _vm.confirmdialog.customObject
                          )
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.confirmdialog.ButtonConfirmText))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.showCopyDialog,
            callback: function($$v) {
              _vm.showCopyDialog = $$v
            },
            expression: "showCopyDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.copyvalid,
                    callback: function($$v) {
                      _vm.copyvalid = $$v
                    },
                    expression: "copyvalid"
                  }
                },
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v("Duplicate Module")
                    ])
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-text-field", {
                        attrs: {
                          counter: 50,
                          rules: _vm.nameRules,
                          label: "Name*",
                          required: ""
                        },
                        model: {
                          value: _vm.copyModuleName,
                          callback: function($$v) {
                            _vm.copyModuleName = $$v
                          },
                          expression: "copyModuleName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.primaryCourseList,
                          label: "Select Course",
                          rules: [
                            function(v) {
                              return !!v || "Course is required"
                            }
                          ],
                          dense: "",
                          required: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function(data) {
                              return [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#0d3349",
                                            "font-size": "10px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(data.item.value) + " - "
                                          )
                                        ]
                                      ),
                                      _vm._v(" " + _vm._s(data.item.text) + " ")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.copyToCourseId,
                          callback: function($$v) {
                            _vm.copyToCourseId = $$v
                          },
                          expression: "copyToCourseId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("small", { staticClass: "info--text" }, [
                        _vm._v(
                          "Please ensure each mandatory field marked with an asterisk * is completed"
                        )
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "", text: "" },
                          on: {
                            click: function($event) {
                              _vm.showCopyDialog = false
                            }
                          }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.validateDuplicateModule()
                            }
                          }
                        },
                        [_vm._v(" Duplicate Module ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }