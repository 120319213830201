<template>
  <div class="editor-video">
    <div class="preview-frame">
      <video ref="videoPreview" class="video" controls></video>
    </div>
    <div class="editing-panel">
      <!-- <div class="media-list-preview">
        <ul v-if="media">
          <li
            v-for="file in media"
            :key="file.public_id"
            :class="isSelected(urlFromId(file.public_id))"
          >
            <button @click="setVideo(file)">
              <img :src="getPosterThumbnail(file)" />
            </button>
          </li>
        </ul>
      </div> -->
      <div>
        <div class="d-flex align-center">
          <v-file-input
            ref="uploadFile"
            v-model="form.uploadFile"
            show-size
            label="Upload video"
            prepend-icon="mdi-video"
            :loading="isUploading"
            :messages="form.uploadMessages"
            :error-messages="form.uploadErrors"
            :success-messages="form.uploadSuccess"
            @change="setUploadPreview"
          />
          <v-btn
            icon
            :disabled="!form.uploadFile"
            color="primary"
            @click="uploadFile"
          >
            <v-icon>mdi-upload</v-icon>
          </v-btn>
        </div>
      </div>
      <v-text-field
        v-model="value.key"
        prepend-icon="mdi-web"
        label="Video KEY"
      />
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { Emit } from './emit.js';
import { Constant } from '../../config/constants';

const QUERY_FRAGMENT = gql`
  fragment ComponentContentListVideo on ComponentContentListVideo {
    id
    url
    key
  }
`;

const MEDIA_QUERY = gql`
  query($type: String, $tags: [String]) {
    media(type: $type, tags: $tags) {
      public_id
      secure_url
      created_at
      bytes
      version
    }
  }
`;

// const SIGN_PAYLOAD_MUTATION = gql`
//   mutation($payload: JSON!) {
//     signUpload(payload: $payload)
//   }
// `;

export default {
  name: 'ComponentContentListVideo',
  icon: 'mdi-video-outline',
  gqlQueryFragment: QUERY_FRAGMENT,
  text: 'Video',
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    form: {
      uploadFile: null,
      uploadMessages: [],
      uploadSuccess: [],
      uploadError: []
    },
    isUploading: false
  }),
  computed: {
    url: Emit('url'),
    alt: Emit('alt'),
    caption: Emit('caption'),
    key: Emit('key')
  },
  mounted() {
    this.$refs.videoPreview.src = this.url;
  },
  methods: {
    // urlFromId(id, type) {
    //   return (
    //     `https://res.cloudinary.com/` +
    //     process.env.VUE_APP_CLOUDINARY_NAME +
    //     '/video/upload/' +
    //     id +
    //     '.' +
    //     (type || 'mp4')
    //   );
    // },
    // setVideo(file) {
    //   const vid = this.$refs.videoPreview;
    //   vid.poster = this.urlFromId(file.public_id, 'jpg');
    //   this.url = vid.src = this.urlFromId(file.public_id, 'mp4');
    //   vid.load();
    // },
    setUploadPreview() {
      const vid = this.$refs.videoPreview;
      // Remove poster as local file won't have one
      vid.poster = '';
      if (this.form.uploadFile) {
        vid.src = URL.createObjectURL(this.form.uploadFile);
        this.url = URL.createObjectURL(this.form.uploadFile);
      } else {
        vid.src = '';
        this.url = '';
      }
      vid.load();
    },
    getPreview() {
      this.$refs.videoPreview.src = this.url;
      return this.$refs.videoPreview.src;
    },
    // getPosterThumbnail(file) {
    //   // Create poster thumbnail url from file object
    //   // prettier-ignore
    //   return (
    //     `https://res.cloudinary.com/` +
    //     process.env.VUE_APP_CLOUDINARY_NAME +
    //     '/video/upload/w_100,h_100,c_fill/' +
    //     file.public_id + '.jpg'
    //   );
    // },
    // isSelected(fileUrl) {
    //   return this.value.url == fileUrl ? 'selected' : '';
    // },
    // setUrl(fileUrl) {
    //   this.url = fileUrl;
    //   this.form.uploadFile = null;
    // },
    async uploadFile() {
      // todo: Set uploading and lock component
      // todo: Set status to getting sig
      // Get signature for upload direct to Cloudinary
      this.isUploading = true;

      this.form.uploadMessages = ['Getting upload signature...'];
      // let sig;
      // await this.$apollo.mutate({
      //   mutation: SIGN_PAYLOAD_MUTATION,
      //   variables: {
      //     payload: {
      //       upload_preset: process.env.VUE_APP_ENV
      //     }
      //   },
      //   update: (store, { data: { signUpload } }) => {
      //     sig = signUpload;
      //   }
      // });
      const data = new FormData();

      // todo: fail if settings absent
      // todo: set status to "uploading"
      data.append('files', this.form.uploadFile);

      this.form.uploadSuccess = this.form.uploadErrors = [];
      this.form.uploadMessages = [
        'Uploading may take several minutes depending on file size...'
      ];

      // todo: convert to XHR to enable upload progress?      const uploadUrl =
      const uploadUrl =
        process.env.VUE_APP_BASE_API + '/media/upload/video';

      const headerOptions = {
        Authorization:
          'Bearer ' + localStorage.getItem(Constant.AUTH_TOKEN)
      };

      fetch(uploadUrl, {
        method: 'POST',
        body: data,
        headers: headerOptions
      })
        .then(res => res.json())
        .then(res => {
          // todo: non-server error handling
          // Check that image created and add to gql cache
          // this.$apolloData.data.media.push({
          //   __typename: 'MediaFile',
          //   public_id: res.public_id,
          //   secure_url: res.secure_url,
          //   created_at: res.createObjectURL,
          //   bytes: res.bytes,
          //   version: res.version
          // });
          this.url = res.presignedUrl;
          this.key = res.key;
          this.form.uploadFile = undefined;
          this.form.uploadMessages = [];
          this.form.uploadSuccess = ['File uploaded'];
        })
        .catch(err => {
          // todo: handle server failure
          this.form.uploadMessages = [];
          this.form.uploadErrors = [err];
        })
        .finally(() => {
          this.isUploading = false;
        });
    }
  },
  apollo: {
    media: {
      query: MEDIA_QUERY,
      variables: {
        type: 'video'
      }
    }
  }
};
</script>

<style lang="scss">
.editor-video {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 4px;
  padding: 1rem;

  &:not(.editing) .editing-panel {
    display: none;
  }

  .editing-panel {
    margin-top: 1rem;
  }

  .preview-frame {
    background-color: #f5f5f5;
    padding-top: 100% * 9 / 16;
    position: relative;
    margin: -1rem;

    > .video,
    > .message {
      position: absolute;
      left: calc(50%);
      top: calc(50%);
      transform: translate(-50%, -50%);
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}
</style>
