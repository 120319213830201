<!--.
    | Lesson Page
    | todo:
    | - lock record when editing
    `-->
<template>
  <div>
    <div v-if="loading">
      <div class="text-center">
        <v-progress-linear indeterminate />
      </div>
    </div>
    <v-container fluid class="grey lighten-5">
      <v-alert
        dense
        text
        :type="alert.type"
        :value="alert.show"
        transition="slide-y-transition"
        >{{ alert.text }}</v-alert
      >
      <v-row v-if="!errors" no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-breadcrumbs class="pa-1" :items="breadcrumbs" />
          <h2 class="pa-1">
            Lesson:
            <span class="success--text">{{ lesson.title }}</span>
          </h2>
        </v-col>
      </v-row>
      <v-row v-if="!errors">
        <v-col>
          <div>
            <v-card color="basil" flat>
              <v-card-title class="headline"
                ><v-icon class="mr-3" color="primary"
                  >mdi-book-open-page-variant</v-icon
                >
                Lesson Details</v-card-title
              >
              <v-form ref="form">
                <v-card-text>
                  <div class="section-devider">
                    <v-row>
                      <v-col cols="5">
                        <h3>Title</h3>
                        <v-text-field
                          v-model="local.title"
                          class="mt-5"
                          prepend-icon="mdi-pencil"
                          required
                          :counter="50"
                          :rules="[
                            v => !!v || 'Title is required',
                            v =>
                              (v && v.length <= 50) ||
                              'Title must be less than 50 characters'
                          ]"
                        />
                      </v-col>
                      <v-col v-if="IsCMSAdmin" class="ml-1" cols="2">
                        <h3>
                          Visibility
                        </h3>
                        <v-switch
                          v-model="lessonEnabled"
                          inset
                          :label="
                            `${lessonEnabled ? 'Visible' : 'Hidden'}`
                          "
                          color="success"
                          class="mt-9 pa-1"
                        />
                      </v-col>
                      <v-col class="ml-10" cols="4">
                        <h3 class="mb-9">
                          Publication State
                        </h3>
                        <span v-if="!loading" cols="4">
                          <span v-if="lessonStatus">
                            <v-icon>mdi-file-document</v-icon>
                          </span>
                          <span v-if="!lessonStatus">
                            <v-icon
                              >mdi-file-document-edit-outline</v-icon
                            >
                          </span>
                          {{
                            lessonStatus === 'Published'
                              ? 'Published'
                              : 'Draft'
                          }}
                        </span>
                      </v-col>
                      <v-col cols="4">
                        <h3>
                          Learning Minutes
                        </h3>
                        <v-text-field
                          v-model="learningMinuteDuration"
                          prepend-icon="mdi-pencil"
                        />
                      </v-col>
                    </v-row>
                  </div>
                  <div class="section-devider">
                    <h2>Learning Objective</h2>
                    <v-autocomplete
                      v-model="local.LearningObjectiveid"
                      :items="availableLearingObjectivesForCourse"
                      label="Learning Objective*"
                      required
                    />
                  </div>
                  <div class="section-devider">
                    <h2>Lesson Card Image</h2>
                    <h5 class="warning--text mt-3">
                      The image should be landscape (16:9 pixel) for
                      better visibility in mobile view. Slightly
                      larger frames for images to be seen completley
                    </h5>
                    <div>
                      <div
                        v-if="isUploading || imageUrl"
                        class="lesson-card-container"
                      >
                        <!-- <v-icon
                          v-if="
                            !imageUrl && !isUploading && IsCMSAdmin
                          "
                          class="plus w-100"
                          style="top: 50px;"
                          @click="getFile"
                          >mdi-plus</v-icon
                        > -->
                        <v-progress-circular
                          v-if="isUploading"
                          small
                          style="top: 50px;"
                          :size="20"
                          indeterminate
                        />

                        <img
                          v-if="!isUploading && imageUrl"
                          style="width:100%;height: 100%;"
                          alt="Avatar"
                          :src="imageUrl"
                        />
                      </div>
                      <div class="pa-1 ma-1">
                        <v-file-input
                          id="fileUpload"
                          accept="image/*"
                          truncate-length="31"
                          :rules="[
                            value =>
                              !value ||
                              value.size < 500000 ||
                              'Image size should be less than 500 KB!'
                          ]"
                          :disabled="!IsCMSAdmin"
                          filled
                          prepend-icon="mdi-plus"
                          @change="onFilePicked"
                        />
                      </div>
                      <!-- <v-btn
                        v-if="imageUrl"
                        class="pa-1 ma-1"
                        @click="getFile"
                      >
                        <v-icon class="plus w-100">mdi-plus</v-icon
                        >Add Image
                      </v-btn> -->
                    </div>
                  </div>
                  <div class="section-devider">
                    <h2>Lesson Image</h2>
                    <app-content-header-editor
                      v-model="local.introHeaderContent"
                    />
                  </div>
                  <div class="section-devider">
                    <h2>Introduction Content</h2>
                    <app-content-list-editor v-model="local.intro" />
                  </div>
                  <div class="section-devider">
                    <h2>Call To Action</h2>
                    <div class="row">
                      <v-col cols="6" sm="3">
                        <v-text-field
                          v-model="
                            local.callToAction.introAttemptQuizText
                          "
                          label="Introduction/Pre learning material access Text"
                          required
                        />
                      </v-col>
                      <v-col cols="6" sm="6">
                        <v-btn
                          v-if="
                            local.callToAction.introAttemptQuizStatus
                          "
                          class="mr-2 success"
                          rounded
                          :disabled="!IsCMSAdmin"
                          small
                          @click="
                            local.callToAction.introAttemptQuizStatus = false
                          "
                          >Disable</v-btn
                        >
                        <v-btn
                          v-else
                          class="mr-2 warning"
                          rounded
                          small
                          :disabled="!IsCMSAdmin"
                          @click="
                            local.callToAction.introAttemptQuizStatus = true
                          "
                          >Enable</v-btn
                        >
                      </v-col>
                    </div>
                    <div class="row">
                      <v-col cols="6" sm="3">
                        <v-text-field
                          v-model="
                            local.callToAction.materialAttemptQuizText
                          "
                          label="Post learning access Text"
                          required
                        />
                      </v-col>
                      <v-col cols="6" sm="6">
                        <v-btn
                          v-if="
                            local.callToAction
                              .materialAttemptQuizStatus
                          "
                          class="mr-2 success"
                          rounded
                          :disabled="!IsCMSAdmin"
                          small
                          @click="
                            local.callToAction.materialAttemptQuizStatus = false
                          "
                          >Disable</v-btn
                        >
                        <v-btn
                          v-else
                          class="mr-2 warning"
                          rounded
                          small
                          :disabled="!IsCMSAdmin"
                          @click="
                            local.callToAction.materialAttemptQuizStatus = true
                          "
                          >Enable</v-btn
                        >
                      </v-col>
                    </div>
                  </div>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-btn
                    v-if="
                      IsCMSAdmin &&
                        !lessonPublished &&
                        !lessonDraftSaved
                    "
                    color="primary"
                    x-large
                    @click="updateLesson(0)"
                    ><v-icon>mdi-file-document-edit-outline</v-icon
                    >Save As Draft
                  </v-btn>
                  <v-btn
                    v-if="
                      IsCMSAdmin &&
                        (lessonPublished || lessonDraftSaved)
                    "
                    color="primary"
                    x-large
                    @click="updateLesson(1)"
                    ><v-icon>mdi-content-save</v-icon>Save
                  </v-btn>
                  <v-btn
                    v-if="IsCMSAdmin && !lessonPublished"
                    color="primary"
                    x-large
                    @click="showPublishDialog"
                    ><v-icon>mdi-file-document</v-icon>Publish
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </div>
        </v-col>
        <v-col>
          <div>
            <v-card color="basil" flat>
              <v-card-title class="headline"
                ><v-icon class="mr-3" color="primary"
                  >mdi-bookshelf</v-icon
                >
                Learning Materials</v-card-title
              >
              <v-card-text>
                <div class="section-devider mt-4">
                  <h2>Learning Material Header</h2>
                  <app-content-header-editor
                    v-model="local.materialintroHeaderContent"
                  />
                </div>
                <div class="section-devider">
                  <h2>Learning Material Content</h2>
                  <app-content-list-editor
                    v-model="local.materialContent"
                  />
                </div>
              </v-card-text>
            </v-card>
          </div>
          <v-card color="basil" class="mt-3" flat>
            <v-card-text>
              <v-tabs v-model="tab" replace>
                <v-tab
                  v-for="(tab, i) in tabItems"
                  :key="i"
                  @click="setActiveTab(i)"
                >
                  <v-icon class="mr-2">{{ tab.icon }}</v-icon>
                  {{ tab.text }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item :key="0">
                  <div>
                    <v-card color="basil" flat>
                      <v-card-text>
                        <div class="page-sub-sec">
                          <v-text-field
                            v-model="searchActivity"
                            append-icon="mdi-magnify"
                            label="Search"
                            outlined
                            dense
                            hide-details
                            class="shrink"
                          />
                          <v-btn
                            v-if="IsCMSAdmin"
                            color="primary"
                            @click="LoadCreateActivity"
                            ><v-icon>mdi-plus</v-icon> Create
                            Activity</v-btn
                          >
                        </div>

                        <v-data-table
                          :items="orderedActivities"
                          :headers="computedActivityheaders"
                          :loading="loading"
                          :search="searchActivity"
                          :no-data-text="`No activity available`"
                          :hide-default-footer="
                            activityPaginationStatus()
                          "
                        >
                          <template v-slot:[`item.id`]="{ item }">
                            <span>
                              {{
                                getIndex(item, orderedActivities) + 1
                              }}</span
                            >
                          </template>
                          <template v-slot:[`item.title`]="{ item }">
                            <router-link
                              :to="
                                `/activity/${courseId}/${lessonId}/${item.id}`
                              "
                              class="blue--text"
                              >{{ item.title }}</router-link
                            >
                          </template>
                          <template v-slot:[`item.quiz`]="{ item }">
                            <span v-if="item.quiz">
                              {{ item.quiz.length }}
                            </span>
                            <span v-else>0</span>
                          </template>

                          <template v-slot:[`item.action`]="{ item }">
                            <div class="lessons-actions-wrap">
                              <v-btn
                                color="blue"
                                icon
                                small
                                @click="LoadActivity(item)"
                                ><v-icon title="Edit"
                                  >mdi-pencil</v-icon
                                ></v-btn
                              >
                              <v-btn
                                v-if="hideDelete == 'false'"
                                color="red"
                                icon
                                small
                                @click="
                                  LoadConfirmDialogForDeleteActivity(
                                    item
                                  )
                                "
                              >
                                <v-icon title="Delete"
                                  >mdi-trash-can-outline</v-icon
                                ></v-btn
                              >
                            </div>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-tab-item>

                <v-tab-item :key="1">
                  <div>
                    <v-card color="basil" flat>
                      <v-card-text>
                        <div class="page-sub-sec">
                          <v-btn
                            v-if="IsCMSAdmin"
                            color="primary"
                            class="ml-auto"
                            @click="LoadCreateSearchKeyword"
                            ><v-icon>mdi-plus</v-icon> Create
                            Keyword</v-btn
                          >
                        </div>
                        <v-row>
                          <v-col cols="12" sm="4">
                            <v-autocomplete
                              v-model="local.searchKeywords"
                              :items="local.searchKeywords"
                              label="Select"
                              multiple
                              chips
                              hint="Lesson keywords for search"
                              persistent-hint
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="pt-0">
                        <v-btn
                          v-if="IsCMSAdmin"
                          color="primary"
                          x-large
                          @click="createSearchKeyword"
                          >Save</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </div>
                </v-tab-item>
                <v-tab-item :key="2">
                  <div>
                    <v-card color="basil" flat>
                      <v-form ref="form">
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" sm="6">
                              <v-card-title
                                >100% Completion</v-card-title
                              >
                              <v-text-field
                                v-model="courseCompletionFullHeader"
                                label="Header"
                                :readonly="!IsCMSAdmin"
                                :rules="[
                                  v =>
                                    !!v ||
                                    'full completion header is required'
                                ]"
                                required
                              />
                              <v-card-text class="header-body"
                                >Description</v-card-text
                              >
                              <app-rich-text-editor
                                v-model="courseCompletionFull"
                              />
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-card-title
                                >Partial Completion</v-card-title
                              >
                              <v-text-field
                                v-model="
                                  courseCompletionPartialHeader
                                "
                                label="Header"
                                :rules="[
                                  v =>
                                    !!v ||
                                    'partial completion header is required'
                                ]"
                                :readonly="!IsCMSAdmin"
                                required
                              />
                              <v-card-text class="header-body"
                                >Description</v-card-text
                              >
                              <app-rich-text-editor
                                v-model="courseCompletionPartial"
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn
                            v-if="IsCMSAdmin"
                            class="primary"
                            x-large
                            @click="saveQuizCompletion"
                            >Save</v-btn
                          >
                        </v-card-actions>
                      </v-form>
                    </v-card>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="confirmdialogPublish"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="headline">Publish Lesson</v-card-title>
        <v-card-text
          >Are you sure you want to publish the lesson
          <span style="color:green"> {{ lesson.title }}</span
          >? <br />
          <span style="font-weight:bold"
            >Once a lesson has been published it cannot be
            unpublished.</span
          ></v-card-text
        >
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#113247 darken-1"
            text
            @click="confirmdialogPublish = false"
            >Cancel</v-btn
          >
          <v-btn class="publishButton" text @click="updateLesson(2)"
            >PUBLISH</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showActivityDialog"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-title class="headline">Activity</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="forms.createActivity.title"
                  label="Title*"
                  required
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn depressed text @click="showActivityDialog = false">
            Cancel
          </v-btn>
          <v-btn
            v-if="forms.createActivity.id > 0"
            color="primary"
            @click="UpdateActivity"
          >
            Update
          </v-btn>
          <v-btn v-else color="primary" @click="createActivity">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showSearchKeywordDialog"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-title class="headline">Search Keyword</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="local.keyword"
                  label="Keyword*"
                  required
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            depressed
            text
            @click="showSearchKeywordDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn color="primary" @click="addSearchKeyword">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- alertconfirm -->
    <v-dialog v-model="confirmdialog.show" persistent max-width="450">
      <!-- <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Open Dialog
          </v-btn>
        </template> -->
      <v-card>
        <v-card-title class="headline">{{
          confirmdialog.HeaderText
        }}</v-card-title>
        <v-card-text>{{ confirmdialog.ContentText }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="confirmdialog.show = false"
            >Cancel</v-btn
          >
          <v-btn
            color="red darken-1"
            text
            @click="
              confirmdialog.ButtonConfirmAction(
                confirmdialog.customObject
              )
            "
            >{{ confirmdialog.ButtonConfirmText }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import AppContentListEditor from '@/components/editors/AppContentListEditor';
import AppContentHeaderEditor from '@/components/editors/AppContentHeaderEditor';
import AppRichTextEditor from '@/components/editors/AppRichTextEditor';
import Content from '@/components/content-edit';
import { mapGetters } from 'vuex';
import { Constant } from '../../../config/constants';

const LESSON_QUERY = gql`
  query($lessonId: ID!, $courseId: ID!) {
    lesson(id: $lessonId) {
      id
      title
      isDisabled
      searchKeywords
      intro_ButtonText
      intro_ButtonIsDisable
      material_ButtonText
      material_ButtonisDisable
      courseCompletionMessage
      courseIncompleteMessage
      courseCompletionMessageHeader
      courseIncompletetionMessageHeader
      isPublished
      learningMinutes
      lessonCardImage {
          __typename
           ${Content.images}
      }
      introHeaderContent {
        __typename
        ${Content.names}
      }
      learningobjective {
        id
        title
        isDisabled
        lessons{
           id
           }
        }
      introduction
      intro {
        __typename
        ${Content.names}
      }
      activityOrder
      courses(where: { id: $courseId }) {
        title
        learningobjectives{
        id
        title
      }
      }
      activities {
        id
        title
        quiz{
        __typename
      }
      }
      learningHeaderContent {
        __typename
        ${Content.names}
      }
      material {
        __typename
        ${Content.names}
      }
    }
  }
  ${Content.strings}
`;

const ALL_ACTIVITIES_QUERY = gql`
  query {
    activities {
      id
      title
    }
  }
`;

const LESSON_MUTATION = gql`
  mutation(
    $lessonId: ID!
    $courseId: ID!
    $title: String!
    $introHeaderContent: [LessonIntroHeaderContentDynamicZoneInput!]
    $learningobjective: ID!
    $intro: [LessonIntroDynamicZoneInput!]
    $intro_ButtonText: String!
    $intro_ButtonIsDisable: Boolean
    $material_ButtonText: String!
    $material_ButtonisDisable: Boolean
    $isDisabled: Boolean!
    $isPublished: Boolean
    $learningMinutes:Int!
    $material: [LessonMaterialDynamicZoneInput!]!
    $materialintroHeaderContent: [LessonLearningHeaderContentDynamicZoneInput!]!
    $lessonCardImage: [LessonLessonCardImageDynamicZoneInput!]
  ) {
    updateLesson(
      input: {
        where: { id: $lessonId }
        data: {
          title: $title
          introHeaderContent: $introHeaderContent
          learningobjective: $learningobjective
          intro: $intro
          intro_ButtonText: $intro_ButtonText
          intro_ButtonIsDisable: $intro_ButtonIsDisable
          material_ButtonText: $material_ButtonText
          material_ButtonisDisable: $material_ButtonisDisable
          isDisabled: $isDisabled
          learningMinutes:$learningMinutes
          isPublished: $isPublished
          material: $material
          learningHeaderContent: $materialintroHeaderContent
          lessonCardImage:$lessonCardImage
        }
      }
    ) {
      lesson {
        id
        material {
          __typename
          ${Content.names}
        }
        learningHeaderContent{
          __typename
           ${Content.names}
        }
        lessonCardImage {
          __typename
           ${Content.images}
        }
      title
      isDisabled
      searchKeywords
      intro_ButtonText
      intro_ButtonIsDisable
      material_ButtonText
      material_ButtonisDisable
      isPublished
      learningMinutes
      courseCompletionMessage
      courseIncompleteMessage
      courseCompletionMessageHeader
      courseIncompletetionMessageHeader
      introHeaderContent {
        __typename
        ${Content.names}
      }
      learningobjective {
        id
        title
        isDisabled
        lessons{
           id
           }
        }
      introduction
      intro {
        __typename
        ${Content.names}
      }
      activityOrder
      courses(where: { id: $courseId }) {
        title
      }
      activities {
        id
        title
        quiz{
        __typename
      }
      }
      learningHeaderContent {
        __typename
        ${Content.names}
      }
      material {
        __typename
        ${Content.names}
      }
      }
    }
  }
  ${Content.strings}
`;

const ADD_ACTIVITY_MUTATION = gql`
  mutation($lessonId: ID!, $activityIds: [ID!]!) {
    updateLesson(
      input: {
        where: { id: $lessonId }
        data: { activities: $activityIds }
      }
    ) {
      lesson {
        id
        activityOrder
        activities {
          id
          title
          quiz {
            __typename
          }
        }
      }
    }
  }
`;

const CREATE_ACTIVITY_MUTATION = gql`
  mutation($lessonId: ID!, $activityTitle: String!) {
    createActivity(
      input: { data: { title: $activityTitle, lessons: [$lessonId] } }
    ) {
      activity {
        id
        title
        quiz {
          __typename
        }
        lessons {
          id
          title
          activities {
            id
            title
          }
        }
      }
    }
  }
`;
const COURSE_ALL_LEARNIGN_OBJECTIVES_QUERY = gql`
  query($courseId: ID!) {
    learningobjectives(where: { course: $courseId }) {
      id
      title
      isDisabled
      lessons {
        id
      }
    }
  }
`;

const UPDATE_ACTIVITY_MUTATION = gql`
  mutation($activityId: ID!, $activityTitle: String!) {
    updateActivity(
      input: {
        where: { id: $activityId }
        data: { title: $activityTitle }
      }
    ) {
      activity {
        id
        title
        quiz {
          __typename
        }
      }
    }
  }
`;

const SAVE_SEARCH_KEYWORD_MUTATION = gql`
  mutation($lessonId: ID!, $keywords: JSON) {
    updateLesson(
      input: {
        where: { id: $lessonId }
        data: { searchKeywords: $keywords }
      }
    ) {
      lesson {
        id
        title
        searchKeywords
      }
    }
  }
`;

const SAVE_QUIZ_COMPLETION_CONTENT_MUTATION = gql`
  mutation(
    $lessonId: ID!
    $courseCompletionFull: String
    $courseCompletionPartial: String
    $courseCompletionFullHeader: String
    $courseCompletionPartialHeader: String
  ) {
    updateLesson(
      input: {
        where: { id: $lessonId }
        data: {
          courseCompletionMessage: $courseCompletionFull
          courseIncompleteMessage: $courseCompletionPartial
          courseCompletionMessageHeader: $courseCompletionFullHeader
          courseIncompletetionMessageHeader: $courseCompletionPartialHeader
        }
      }
    ) {
      lesson {
        id
        title
        searchKeywords
        courseCompletionMessage
        courseIncompleteMessage
        courseCompletionMessageHeader
        courseIncompletetionMessageHeader
      }
    }
  }
`;

export default {
  name: 'Lesson',
  components: {
    AppRichTextEditor,
    AppContentListEditor,
    AppContentHeaderEditor,
    ...Content.types
  },
  data: () => ({
    alert: {
      type: 'success',
      show: false,
      text: ''
    },
    loadedData: {},
    roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER'
    },
    hideDelete: process.env.VUE_APP_HIDEDELETE_STATUS,
    loading: false,
    isUploading: false,
    confirmdialog: {
      show: false,
      HeaderText: '',
      ContentText: '',
      ButtonConfirmText: '',
      ButtonConfirmAction: '',
      customObject: null
    },
    confirmdialogPublish: false,
    // Placeholder until Apollo returns
    searchActivity: '',
    showActivityDialog: false,
    showSearchKeywordDialog: false,
    learningMinuteDuration: 0,
    lesson: {
      activities: [],
      intro: []
    },
    lessonEnabled: false,
    lessonStatus: 'Draft',
    activitiesHeaders: [
      { text: 'Title', value: 'title' },
      { text: 'Activity Type' }
    ],
    slectedTab: 0,
    tab: null,
    tabItems: [
      // { text: 'Lesson Details', icon: 'mdi-book-open-page-variant' },
      { text: 'Activities', icon: 'mdi-format-list-checks' },
      // { text: 'Learning Material', icon: 'mdi-bookshelf' },
      { text: 'Search Keywords', icon: 'mdi-feature-search-outline' },
      { text: 'Quiz Completion Content', icon: 'mdi-head-question' }
    ],
    lessonPublished: false,
    lessonDraftSaved: false,

    imageFile: [],
    imageName: null,
    imageUrl: null,
    // 'https://www.bhaktiphotos.com/wp-content/uploads/2018/04/Mahadev-Bhagwan-Photo-for-Devotee.jpg',
    local: {
      title: '',
      objective: '',
      introduction: '',
      conclusion: '',
      activities: [],
      intro: [],
      introHeaderContent: [],
      headerContent: [],
      materialintroHeaderContent: [],
      materialContent: [],
      callToAction: {
        introAttemptQuizText: 'Attempt Quiz',
        introAttemptQuizStatus: false,
        materialAttemptQuizText: 'Repeat Quiz',
        materialAttemptQuizStatus: false
      },
      LearningObjectiveid: 0,
      searchKeywords: [],
      keyword: ''
    },
    errors: false,
    lessonActivityheaders: [
      { text: 'ID', value: 'id' },
      { text: 'Title', value: 'title' },
      { text: 'Quiz No.', value: 'quiz' },
      { text: 'Action', value: 'action', sortable: false }
    ],
    // Models for form elements
    forms: {
      addActivity: { id: null },
      createActivity: { title: '', id: 0 }
    },

    tmpComponentId: 0,
    contentTypes: Content.types,
    learningobjectives: [],
    courseCompletionFull: '',
    courseCompletionPartial: '',
    courseCompletionFullHeader: '',
    courseCompletionPartialHeader: ''
  }),
  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating'
    ]),
    IsDementiaAdmin() {
      if (
        this.getUser?.user?.role?.name === this.roles.DEMENTIA_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsCMSAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.CMS_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGViewer() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_VIEWER) {
        return true;
      } else {
        return false;
      }
    },
    computedActivityheaders() {
      if (!this.IsCMSAdmin)
        return this.lessonActivityheaders.filter(
          t => t.text != 'Action'
        );
      else return this.lessonActivityheaders;
    },
    availableLearingObjectivesForCourse() {
      if (!this.learningobjectives) return [];
      return this.learningobjectives.map(i => ({
        text: i?.title,
        value: i.id
      }));
    },
    breadcrumbs() {
      if (this.lesson?.title) {
        let course = this.lesson.courses[0];
        // prettier-ignore
        return [
          { text: course?.title, href: `#/modules/${this.courseId}` },
          { text: this.lesson?.title }
        ];
      } else {
        return [
          { text: 'Course', disabled: true },
          { text: 'Lesson', disabled: true }
        ];
      }
    },
    lessonId() {
      return this.$route.params.lessonId;
    },
    courseId() {
      return this.$route.params.courseId;
    },

    orderedActivities() {
      if (!this.lesson.activityOrder) return this.lesson.activities;

      // Create index of lessons
      let activities = this.lesson.activities.reduce((acc, i) => {
        acc[i.id] = i;
        return acc;
      }, {});

      // First add lessons with order
      let orderedActivities = [];
      this.lesson.activityOrder.forEach(a => {
        orderedActivities.push(activities[a]);
        orderedActivities.pop(activities[a] == undefined);
      });

      // Then add unordered lessons
      orderedActivities.push(...Object.values(activities));
      return orderedActivities;
    },
    availableActivities() {
      // prettier-ignore because ugly
      const availableActivities = this.activities
        ?.filter(i => !this.lesson.activities.some(j => j.id == i.id))
        ?.map(i => ({ text: i?.title, value: i.id }));
      return availableActivities || [];
    }
  },
  beforeRouteLeave(to, from, next) {
    let edited = false;
    if (this.getUser?.user?.role?.name === this.roles.CMS_ADMIN) {
      if (this.loadedData.title != this.local.title) {
        edited = true;
      }
      if (
        this.loadedData.learningMinutes != this.learningMinuteDuration
      ) {
        edited = true;
      }

      if (
        this.loadedData.isDisabled != !this.lessonEnabled ||
        this.loadedData.intro_ButtonIsDisable !=
          this.local.callToAction.introAttemptQuizStatus ||
        this.loadedData.material_ButtonisDisable !=
          this.local.callToAction.materialAttemptQuizStatus
      ) {
        edited = true;
      }

      if (
        !this.arraysEqual(
          this.loadedData.introHeaderContent,
          this.local.introHeaderContent
        ) ||
        !this.arraysEqual(this.loadedData.intro, this.local.intro)
      ) {
        edited = true;
      }
      if (this.local.materialContent.length > 0) {
        if (
          !this.objectsEqual(
            this.loadedData.material,
            this.local.materialContent
          )
        ) {
          edited = true;
        }
      }
      if (this.local.materialintroHeaderContent.length > 0) {
        if (
          !this.objectsEqual(
            this.loadedData.learningHeaderContent,
            this.local.materialintroHeaderContent
          )
        ) {
          edited = true;
        }
      }
    } else {
      edited = false;
    }
    if (edited && to.name != 'LoginHome') {
      if (
        confirm(
          'Some data are not saved, do you want to proceed without saving?'
        )
      ) {
        next();
      } else {
        return false;
      }
    } else {
      next();
    }
  },
  methods: {
    activityPaginationStatus() {
      if (this.orderedActivities && this.orderedActivities.length > 0)
        return false;
      else return true;
    },
    arraysEqual(a1, a2) {
      return (
        a1 &&
        a2 &&
        a1.length === a2.length &&
        a1.every((o, idx) => this.objectsEqual(o, a2[idx]))
      );
    },
    objectsEqual(o1, o2) {
      return o1 &&
        typeof o1 === 'object' &&
        Object.keys(o1).length > 0
        ? Object.keys(o1).length === Object.keys(o2).length &&
            Object.keys(o1).every(p =>
              this.objectsEqual(o1[p], o2[p])
            )
        : o1 === o2;
    },
    urlFromId(url) {
      return (
        `http://strapicms-env.eba-nkqspk3q.us-east-2.elasticbeanstalk.com/` +
        'media/upload/image' +
        url
      );
    },
    getFile() {
      let fileUpload = document.getElementById('fileUpload');
      if (fileUpload != null) {
        fileUpload.click();
      }
    },
    onFilePicked(e) {
      const files = e;
      if (files?.name !== undefined && files.size < 500000) {
        this.imageName = files.name;
        if (this.imageName.lastIndexOf('.') <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files);
        fr.addEventListener('load', () => {
          this.imageUrl = fr.result;
          this.uploadImage(files); // this is an image file that can be sent to server...
        });
      }
    },
    uploadImage(files) {
      const uploadUrl =
        process.env.VUE_APP_BASE_API + '/media/upload/image';
      let data = new FormData();
      // var tempObject = {};
      data.append('files', files);
      const headerOptions = {
        Authorization:
          'Bearer ' + localStorage.getItem(Constant.AUTH_TOKEN)
      };
      this.isUploading = true;
      fetch(uploadUrl, {
        method: 'POST',
        body: data,
        headers: headerOptions
      })
        .then(res => res.json())
        .then(res => {
          this.imageFile = [
            {
              __typename: 'ComponentContentListPicture',
              fileType: 'picture',
              alt: files.name,
              caption: files.name,
              url: res.key,
              key: res.key,
              _vueKey: 1
            }
          ];
        })
        .catch(() => {
          // todo: handle server failure
        })
        .finally(() => {
          this.isUploading = false;
        });
    },
    getIndex(item, items) {
      if (!items) return 0;
      return items
        .filter(o => o != 'undefined')
        .map(function(x) {
          if (x) return x.id;
        })
        .indexOf(item.id);
    },
    showPublishDialog() {
      this.confirmdialogPublish = true;
    },
    updateLesson(event) {
      if (!this.$refs.form.validate()) return;

      this.$apollo.mutate({
        mutation: LESSON_MUTATION,
        variables: {
          lessonId: this.lessonId,
          courseId: this.courseId,
          title: this.local.title,
          introHeaderContent: this.local.introHeaderContent,
          learningobjective: this.local.LearningObjectiveid,
          lessonCardImage: this.imageFile,
          learningMinutes: parseInt(this.learningMinuteDuration),
          introduction: this.local.introduction,
          intro: this.local.intro,
          isDisabled: !this.lessonEnabled,
          intro_ButtonText: this.local.callToAction
            .introAttemptQuizText,
          intro_ButtonIsDisable: this.local.callToAction
            .introAttemptQuizStatus,
          material_ButtonText: this.local.callToAction
            .materialAttemptQuizText,
          material_ButtonisDisable: this.local.callToAction
            .materialAttemptQuizStatus,
          isPublished:
            event === 0
              ? false
              : event === 2
              ? true
              : this.lessonPublished,
          material: this.local.materialContent,
          materialintroHeaderContent: this.local
            .materialintroHeaderContent
        },
        update: (store, { data: { updateLesson } }) => {
          if (!updateLesson.lesson) return;
          this.$apollo.queries.lesson.refresh();
          // Get the current store state
          // const data = store.readQuery({
          //   query: LESSON_QUERY,
          //   variables: {
          //     lessonId: this.lessonId,
          //     courseId: this.courseId
          //   }
          // });
          // data.lesson = updateLesson.lesson;
          // // Write the list back into the cache
          // store.writeQuery({
          //   query: LESSON_QUERY,
          //   variables: {
          //     lessonId: this.lessonId,
          //     courseId: this.courseId
          //   },
          //   data
          // });
          this.alert.show = true;
          this.alert.text = 'Successfully Updated';
          this.alert.type = 'success';
          // this.lessonDraftSaved = this.lessonPublished
          //   ? true
          //   : event === 0 || event === 1
          //   ? true
          //   : false;
          // this.lessonPublished = this.lessonPublished
          //   ? true
          //   : event === 2
          //   ? true
          //   : false;
          // this.imageUrl = data.lesson.lessonCardImage?.[0]?.url
          //   ? data.lesson.lessonCardImage[0].url
          //   : this.imageUrl;
          // this.lessonStatus =
          //   data.lesson.isPublished === true
          //     ? 'Published'
          //     : data.lesson.isPublished === false
          //     ? 'Saved'
          //     : 'Draft';
          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        }
      });
      this.confirmdialogPublish = false;
    },
    LoadCreateActivity() {
      this.forms.createActivity.id = 0;
      this.forms.createActivity.title = '';
      this.showActivityDialog = true;
    },
    addActivity() {
      // todo: route message to user - disable button unless selected?
      if (this.forms.addActivity.id === null) return;

      // Make sure ids are unique
      const activityIds = new Set([
        ...this.lesson.activities.map(item => item.id),
        this.forms.addActivity.id
      ]);

      this.$apollo.mutate({
        mutation: ADD_ACTIVITY_MUTATION,
        variables: {
          lessonId: this.lessonId,
          activityIds: [...activityIds]
        }
      });
    },
    LoadConfirmDialogForDeleteActivity(item) {
      this.confirmdialog.HeaderText = 'Are you sure?';
      this.confirmdialog.ContentText =
        'Deleting an activity will remove their quizzes as well!';
      this.confirmdialog.ButtonConfirmText = "Yes I'm sure";
      this.confirmdialog.show = true;
      this.confirmdialog.customObject = item;
      this.confirmdialog.ButtonConfirmAction = this.removeActivity;
    },
    removeActivity(item) {
      const activityId = parseInt(item.id);
      const activityIds = this.lesson.activities
        .map(item => item.id)
        .filter(item => item != activityId);

      this.$apollo.mutate({
        mutation: ADD_ACTIVITY_MUTATION,
        variables: {
          lessonId: this.lessonId,
          activityIds
        }
      });
      this.confirmdialog.show = false;
    },
    createActivity() {
      if (!this.forms.createActivity.title) return;
      this.$apollo.mutate({
        mutation: CREATE_ACTIVITY_MUTATION,
        variables: {
          lessonId: this.lessonId,
          activityTitle: this.forms.createActivity.title
        }
      });
      this.alert.show = true;
      this.alert.text = 'Successfully Created';
      this.alert.type = 'success';

      setTimeout(() => {
        this.alert.show = false;
      }, 3000);
      this.showActivityDialog = false;
    },
    LoadActivity(item) {
      //id is populated for edit purpose
      this.forms.createActivity.id = item.id;
      this.forms.createActivity.title = item.title;
      this.showActivityDialog = true;
    },
    UpdateActivity() {
      if (!this.forms.createActivity.title) return;
      this.$apollo.mutate({
        mutation: UPDATE_ACTIVITY_MUTATION,
        variables: {
          activityId: this.forms.createActivity.id,
          activityTitle: this.forms.createActivity.title
        }
        // todo: catch errors, update processing state
      });
      this.showActivityDialog = false;
    },
    moveActivity(from, to) {
      const newOrder = this.orderedActivities.map(i => i.id);
      const [item] = newOrder.splice(from, 1);
      newOrder.splice(to, 0, item);

      this.$apollo.mutate({
        mutation: ADD_ACTIVITY_MUTATION,
        variables: {
          lessonId: this.lessonId,
          activityIds: newOrder
        }
      });
    },
    setActiveTab(id) {
      this.slectedTab = id;
    },
    LoadCreateSearchKeyword() {
      this.showSearchKeywordDialog = true;
    },
    addSearchKeyword() {
      if (this.local.keyword) {
        this.local.searchKeywords.push(this.local.keyword);
        this.local.keyword = '';
        this.showSearchKeywordDialog = false;
      }
    },
    createSearchKeyword() {
      this.$apollo.mutate({
        mutation: SAVE_SEARCH_KEYWORD_MUTATION,
        variables: {
          lessonId: this.lessonId,
          keywords: this.local.searchKeywords
        }
      });
      this.alert.show = true;
      this.alert.text = 'Successfully Created';
      this.alert.type = 'success';

      setTimeout(() => {
        this.alert.show = false;
      }, 3000);
    },
    saveQuizCompletion() {
      if (!this.$refs.form.validate()) return;

      this.$apollo.mutate({
        mutation: SAVE_QUIZ_COMPLETION_CONTENT_MUTATION,
        variables: {
          lessonId: this.lessonId,
          courseCompletionFull: this.courseCompletionFull,
          courseCompletionPartial: this.courseCompletionPartial,
          courseCompletionFullHeader: this.courseCompletionFullHeader,
          courseCompletionPartialHeader: this
            .courseCompletionPartialHeader
        }
      });
      this.alert.show = true;
      this.alert.text = 'Successfully Saved';
      this.alert.type = 'success';

      setTimeout(() => {
        this.alert.show = false;
      }, 3000);
    }
  },
  apollo: {
    lesson() {
      this.loading = true;
      return {
        query: LESSON_QUERY,
        variables: {
          lessonId: this.lessonId,
          courseId: this.courseId
        },
        update: data => {
          this.loading = false;
          if (data.lesson) {
            this.loadedData = data.lesson;
            this.local.title = data.lesson.title || '';
            this.local.isDisabled = data.lesson.isDisabled;
            this.learningMinuteDuration =
              data.lesson.learningMinutes || 5;
            if (data.lesson.introHeaderContent) {
              this.local.introHeaderContent = [
                ...data.lesson.introHeaderContent
              ];
            }
            if (data.lesson.intro) {
              this.local.intro = [...data.lesson.intro];
            }
            if (data.lesson.learningobjective) {
              this.local.LearningObjectiveid =
                data.lesson.learningobjective.id;
            }
            this.imageUrl = data.lesson.lessonCardImage?.[0]?.url
              ? data.lesson.lessonCardImage[0].url
              : this.imageUrl;
            this.imageFile = data.lesson.lessonCardImage
              ? data.lesson.lessonCardImage
              : this.imageUrl;
            // New array as we don't want to modify raw apollo
            if (data.lesson.material) {
              this.local.materialContent = [...data.lesson.material];
            }
            if (data.lesson.learningHeaderContent) {
              this.local.materialintroHeaderContent = [
                ...data.lesson.learningHeaderContent
              ];
            }
            if (data.lesson.searchKeywords) {
              this.local.searchKeywords = data.lesson.searchKeywords;
            }
            if (data.lesson.intro_ButtonText) {
              this.local.callToAction.introAttemptQuizText =
                data.lesson.intro_ButtonText;
            }
            if (data.lesson.intro_ButtonIsDisable) {
              this.local.callToAction.introAttemptQuizStatus =
                data.lesson.intro_ButtonIsDisable;
            }
            if (data.lesson.material_ButtonText) {
              this.local.callToAction.materialAttemptQuizText =
                data.lesson.material_ButtonText;
            }
            if (data.lesson.material_ButtonisDisable) {
              this.local.callToAction.materialAttemptQuizStatus =
                data.lesson.material_ButtonisDisable;
            }
            this.courseCompletionPartial =
              data.lesson.courseIncompleteMessage;
            this.courseCompletionFull =
              data.lesson.courseCompletionMessage;
            this.courseCompletionFullHeader =
              data.lesson.courseCompletionMessageHeader;
            this.courseCompletionPartialHeader =
              data.lesson.courseIncompletetionMessageHeader;
            this.lessonEnabled = !data.lesson.isDisabled;
            // this.learningobjectives =
            //   data.lesson.courses[0].learningobjectives;
            this.lessonStatus =
              data.lesson.isPublished === true
                ? 'Published'
                : data.lesson.isPublished === false
                ? 'Saved'
                : 'Draft';
            this.lessonDraftSaved = data.lesson.isPublished === false;
            this.lessonPublished = data.lesson.isPublished === true;
            return data.lesson;
          } else {
            this.alert.show = true;
            this.alert.text =
              'Sorry, The requisition data cannot be loaded';
            this.alert.type = 'error';
            this.errors = true;
            return {};
          }
        },
        error() {
          this.loading = false;
          this.errors = true;
          this.alert.show = true;
          this.alert.text =
            'Sorry, The requisition data cannot be loaded';
          this.alert.type = 'error';
        }
      };
    },
    activities() {
      return {
        query: ALL_ACTIVITIES_QUERY
      };
    },
    learningobjectives() {
      return {
        query: COURSE_ALL_LEARNIGN_OBJECTIVES_QUERY,
        variables: {
          courseId: this.$route.params.courseId
        },
        fetchPolicy: 'network-only',
        update: data => {
          this.learningobjectives = data.learningobjectives;
          return this.learningobjectives;
        }
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.header-body {
  padding-left: 0px;
}
.lesson-card-container {
  padding: 1px;
  height: 160px;
  width: 160px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
}
</style>
