<template>
  <div class="app-content-header-editor">
    <div
      v-for="(content, index) in local.content"
      :key="content._vueKey"
      class="app-content-list-item"
    >
      <component
        :is="content.__typename"
        ref="items"
        v-model="local.content[index]"
        :class="editItem === content._vueKey ? 'editing' : ''"
        @input="update"
      />
      <!-- prettier-ignore -->
      <v-toolbar 
        v-if="IsCMSAdmin"
        flat 
        dense      
        class="app-content-list-controls .rounded-b-md">
        <v-btn icon
          :outlined="editItem == content._vueKey"
          @click="edit(content._vueKey)">
          <v-icon aria-label="Edit item settings">mdi-tools</v-icon>
        </v-btn>
        <v-btn icon :disabled="index < 1" @click="move(index, index - 1)">
          <v-icon aria-label="Move item up">mdi-arrow-up</v-icon>
        </v-btn>
        <v-btn icon :disabled="index < 1" @click="move(index, 0)">
          <v-icon aria-label="Move item to top">mdi-arrow-collapse-up</v-icon>
        </v-btn>
        <v-btn icon
          :disabled="index > local.content.length-2"
          @click="move(index, index + 1)">
          <v-icon aria-label="Move item down">mdi-arrow-down</v-icon>
        </v-btn>
        <v-btn icon
          :disabled="index > local.content.length-2"
          @click="move(index, local.content.length)">
          <v-icon aria-label="Move item to end">mdi-arrow-collapse-down</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn icon @click="del(index)">
          <v-icon aria-label="Delete item">mdi-delete-outline</v-icon>
        </v-btn>
      </v-toolbar>
    </div>
    <v-toolbar
      v-if="IsCMSAdmin"
      flat
      dense
      class="app-content-list-actions"
    >
      <v-icon class="plus">mdi-plus</v-icon>
      <v-btn
        v-for="(contentType, name) in contentTypes"
        :key="name"
        depressed
        class="mr-2"
        @click="add(contentType.name)"
      >
        <v-icon class="mr-2">{{ contentType.icon }}</v-icon>
        {{ contentType.text }}
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
import Content from '@/components/content-edit';
import { mapGetters } from 'vuex';

export default {
  name: 'AppContentHeaderEditorDA',
  // todo: limit types/strings/names to enabled list
  // todo: when local updated show dirty, enable save
  components: { ...Content.headerTypes },
  gql: Content,
  props: { value: { type: Array, default: () => [] } },
  data: () => ({
    roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER'
    },
    local: {
      content: []
    },
    tmpId: 0,
    contentTypes: Content.headerTypes,
    editItem: undefined
  }),
  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating'
    ]),
    DementiaAdmin() {
      if (
        this.getUser?.user?.role?.name === this.roles.DEMENTIA_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsCMSAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.CMS_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGViewer() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_VIEWER) {
        return true;
      } else {
        return false;
      }
    },
    contentList: {
      get() {
        return this.local.content;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    // todo: when content updated ask user to replace
    value: function() {
      // todo test that the array is still reactive
      this.local.content = [...this.value];
      this.local.content.forEach(c => {
        if (!c._vueKey) c._vueKey = c.__typename + c.id;
      });
    }
  },
  methods: {
    add(strapiComponent) {
      this.local.content.push({
        _vueKey: 't' + this.tmpId++,
        __typename: strapiComponent
      });
      this.$emit('input', this.local.content);
    },
    del(index) {
      this.local.content.splice(index, 1);
      this.$emit('input', this.local.content);
    },
    move(from, to) {
      // todo: extract 'array move' into function and unit test
      var newList = [...this.local.content];
      let [item] = newList.splice(from, 1);
      newList.splice(to, 0, item);
      this.$emit('input', newList);
    },
    update() {
      this.$emit('input', this.local.content);
    },
    edit(vueKey) {
      if (this.editItem == vueKey) this.editItem = null;
      else this.editItem = vueKey;
    }
  }
};
</script>

<style lang="scss">
// Shared styles for Picture and Video
@use 'media.scss';

.app-content-list-item {
  margin-bottom: 1rem;

  + .app-content-list-item,
  + .app-content-list-actions {
    position: relative;

    &::before {
      position: absolute;
      bottom: 100%;
      left: 50%;
      content: '';
      display: block;
      height: 1rem;
      border-right: thin solid rgba(0, 0, 0, 0.12);
    }
  }
}
.app-content-list-controls {
  // todo: get colours from css custom properties
  // todo: replace all !important with #pageid
  background-color: #f8f8f8 !important;
  border: thin solid rgba(0, 0, 0, 0.12) !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  margin-top: -4px;
  position: relative;
}
.app-content-list-actions {
  // display: none;
  background-color: #f8f8f8 !important;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px !important;
  border: thin solid rgba(0, 0, 0, 0.12) !important;
  height: auto !important;
  width: auto !important;
  margin-left: 2rem;
  margin-right: 2rem;

  &::before {
    left: 0.75rem !important;
  }

  .v-toolbar__content {
    padding: 0.5rem !important;
    padding-left: 2em !important;
  }

  .plus {
    color: white;
    position: absolute !important;
    display: block !important;
    left: -1px;
    padding-top: 0.5rem;
    height: calc(100% + 2px);
    border-radius: 4px 0 0 4px !important;
    border: thin solid rgba(0, 0, 0, 0.12) !important;
    background-color: white;
  }
}
</style>
