var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "overflow-y-scroll" },
    [
      _vm.loading
        ? _c("div", [
            _c(
              "div",
              { staticClass: "text-center" },
              [_c("v-progress-linear", { attrs: { indeterminate: "" } })],
              1
            )
          ])
        : _vm._e(),
      _c(
        "v-container",
        { staticClass: "grey lighten-5", attrs: { fluid: "" } },
        [
          _c(
            "v-alert",
            {
              attrs: {
                dense: "",
                text: "",
                type: _vm.alert.type,
                value: _vm.alert.show,
                transition: "slide-y-transition"
              }
            },
            [_vm._v(_vm._s(_vm.alert.text))]
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "mr-auto", attrs: { cols: "auto" } },
                [
                  _c("v-breadcrumbs", {
                    staticClass: "pa-0",
                    attrs: { items: _vm.breadcrumbs }
                  }),
                  _c("h1", [_vm._v(_vm._s(_vm.course.title))])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-card",
                    { attrs: { outlined: "" } },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: { "lazy-validation": "" },
                          model: {
                            value: _vm.validCourse,
                            callback: function($$v) {
                              _vm.validCourse = $$v
                            },
                            expression: "validCourse"
                          }
                        },
                        [
                          _c("v-card-text", [
                            _c(
                              "div",
                              { staticClass: "section-devider no-bg" },
                              [
                                _c("h2", [_vm._v("Title")]),
                                _c("v-text-field", {
                                  attrs: {
                                    counter: 50,
                                    rules: _vm.nameRules,
                                    "prepend-icon": "mdi-pencil",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.local.title,
                                    callback: function($$v) {
                                      _vm.$set(_vm.local, "title", $$v)
                                    },
                                    expression: "local.title"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm.IsCMSAdmin && !_vm.loading
                              ? _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "section-devider" },
                                    [
                                      _c("h2", [_vm._v("Text Color")]),
                                      _c("v-color-picker", {
                                        attrs: {
                                          "hide-canvas": "",
                                          "hide-mode-switch": "",
                                          mode: "hexa",
                                          "show-swatches": "",
                                          "swatches-max-height": "300"
                                        },
                                        model: {
                                          value: _vm.textcolor,
                                          callback: function($$v) {
                                            _vm.textcolor = $$v
                                          },
                                          expression: "textcolor"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "section-devider" },
                                    [
                                      _c("h2", [_vm._v("SubText Color")]),
                                      _c("v-color-picker", {
                                        attrs: {
                                          "hide-canvas": "",
                                          "hide-mode-switch": "",
                                          mode: "hexa",
                                          "show-swatches": "",
                                          "swatches-max-height": "300"
                                        },
                                        model: {
                                          value: _vm.subtextColour,
                                          callback: function($$v) {
                                            _vm.subtextColour = $$v
                                          },
                                          expression: "subtextColour"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "section-devider" },
                                    [
                                      _c("h2", [_vm._v("Background Color")]),
                                      _c("v-color-picker", {
                                        attrs: {
                                          "hide-canvas": "",
                                          "hide-mode-switch": "",
                                          mode: "hexa",
                                          "show-swatches": "",
                                          "swatches-max-height": "300"
                                        },
                                        model: {
                                          value: _vm.tilecolor,
                                          callback: function($$v) {
                                            _vm.tilecolor = $$v
                                          },
                                          expression: "tilecolor"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "section-devider" },
                                    [
                                      _c("h2", [_vm._v("Progress Fill color")]),
                                      _c("v-color-picker", {
                                        attrs: {
                                          "hide-canvas": "",
                                          "hide-mode-switch": "",
                                          mode: "hexa",
                                          "show-swatches": "",
                                          "swatches-max-height": "300"
                                        },
                                        model: {
                                          value: _vm.progressfillColour,
                                          callback: function($$v) {
                                            _vm.progressfillColour = $$v
                                          },
                                          expression: "progressfillColour"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e(),
                            _vm.IsDementiaAdmin && !_vm.loading
                              ? _c(
                                  "div",
                                  { staticClass: "section-devider" },
                                  [
                                    _c("h2", [_vm._v("Color Picker")]),
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "colorDisplayRow",
                                        attrs: { justify: "space-around" }
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c(
                                              "v-row",
                                              {
                                                staticClass: "colorDisplayRow"
                                              },
                                              [_c("h4", [_vm._v("Text Color")])]
                                            ),
                                            _c(
                                              "v-row",
                                              {
                                                staticClass: "colorDisplayRow"
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "text-Color-Picker",
                                                  style: {
                                                    "background-color":
                                                      _vm.textcolor
                                                  }
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "text-display"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.textcolor) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          [
                                            _c("v-row", [
                                              _c("h4", [
                                                _vm._v("Subtext Color")
                                              ])
                                            ]),
                                            _c("v-row", [
                                              _c("div", {
                                                staticClass:
                                                  "text-Color-Picker",
                                                style: {
                                                  "background-color":
                                                    _vm.subtextColour
                                                }
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "text-display" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.subtextColour
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ])
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          [
                                            _c("v-row", [
                                              _c("h4", [
                                                _vm._v("Background Color")
                                              ])
                                            ]),
                                            _c("v-row", [
                                              _c("div", {
                                                staticClass:
                                                  "text-Color-Picker",
                                                style: {
                                                  "background-color":
                                                    _vm.tilecolor
                                                }
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "text-display" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.tilecolor) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ])
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          [
                                            _c("v-row", [
                                              _c("h4", [
                                                _vm._v("Progress Fill Color")
                                              ])
                                            ]),
                                            _c("v-row", [
                                              _c("div", {
                                                staticClass:
                                                  "text-Color-Picker",
                                                style: {
                                                  "background-color":
                                                    _vm.progressfillColour
                                                }
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "text-display" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.progressfillColour
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "section-devider" },
                              [
                                _c("h2", [_vm._v("Intro Header")]),
                                _c("app-content-header-editor", {
                                  model: {
                                    value: _vm.local.headerContent,
                                    callback: function($$v) {
                                      _vm.$set(_vm.local, "headerContent", $$v)
                                    },
                                    expression: "local.headerContent"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "section-devider" },
                              [
                                _c("h2", [_vm._v("Description")]),
                                _c("app-content-list-editor", {
                                  model: {
                                    value: _vm.local.desc,
                                    callback: function($$v) {
                                      _vm.$set(_vm.local, "desc", $$v)
                                    },
                                    expression: "local.desc"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _c(
                            "v-card-actions",
                            { staticClass: "pt-0" },
                            [
                              _vm.IsCMSAdmin && !_vm.loading
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        "x-large": ""
                                      },
                                      on: { click: _vm.validateCourse }
                                    },
                                    [_vm._v("Save")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mb-5", attrs: { outlined: "" } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "d-flex justify-space-between" },
                        [
                          _c("span", { staticClass: "header" }, [
                            _vm._v("Module Details")
                          ])
                        ]
                      ),
                      _c(
                        "v-form",
                        {
                          ref: "moduleform",
                          attrs: { "lazy-validation": "" },
                          model: {
                            value: _vm.validCourse,
                            callback: function($$v) {
                              _vm.validCourse = $$v
                            },
                            expression: "validCourse"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "details_header" },
                            [
                              _c(
                                "span",
                                { staticClass: "details_sub_header" },
                                [_vm._v("Appears in course...")]
                              ),
                              _c(
                                "v-row",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "6 " } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.primaryCourseListForModule,
                                          disabled: !_vm.IsCMSAdmin,
                                          label: "Select Course",
                                          rules: _vm.IsCMSAdmin
                                            ? [
                                                function(v) {
                                                  return (
                                                    !!v || "Select a Course"
                                                  )
                                                }
                                              ]
                                            : [],
                                          required: ""
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item",
                                            fn: function(data) {
                                              return [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#0d3349",
                                                            "font-size": "12px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data.item.value
                                                            ) + " - "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.item.text
                                                          ) +
                                                          " "
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ]),
                                        model: {
                                          value: _vm.moduleForm.primary_courses,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.moduleForm,
                                              "primary_courses",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "moduleForm.primary_courses"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-row", { staticClass: "pl-2 pb-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-inline" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "pa-1 mb-2 heading-payment"
                                      },
                                      [
                                        _vm._v("Payment Status "),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { right: "" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass: "pl-1",
                                                            attrs: {
                                                              color: "#113247"
                                                            }
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [_vm._v("info")]
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Is this module available for free to users, or is it locked and requiring payment"
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "pl-4 pb-2" },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            items: _vm.paymentStatus,
                                            "prepend-icon":
                                              "mdi-currency-usd-off",
                                            disabled: !_vm.IsCMSAdmin,
                                            rules: _vm.IsCMSAdmin
                                              ? [
                                                  function(v) {
                                                    return (
                                                      v !== null ||
                                                      "Select a Payment Status"
                                                    )
                                                  }
                                                ]
                                              : [],
                                            dense: "",
                                            required: ""
                                          },
                                          model: {
                                            value: _vm.moduleForm.isPaid,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.moduleForm,
                                                "isPaid",
                                                $$v
                                              )
                                            },
                                            expression: "moduleForm.isPaid"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-inline  mx-lg-auto" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "pa-1 heading-payment" },
                                      [
                                        _vm._v("Visibility "),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass: "pl-1",
                                                            attrs: {
                                                              color: "#113247"
                                                            }
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [_vm._v("info")]
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Whether or not this module is visible within the mobile app"
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c("v-switch", {
                                      staticStyle: {
                                        "font-size": "10px",
                                        "padding-left": "10px"
                                      },
                                      attrs: {
                                        inset: "",
                                        color: "green",
                                        disabled: !_vm.IsCMSAdmin,
                                        label:
                                          "" +
                                          (_vm.moduleForm.isDisabled
                                            ? "Visible"
                                            : "Hidden")
                                      },
                                      model: {
                                        value: _vm.moduleForm.isDisabled,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.moduleForm,
                                            "isDisabled",
                                            $$v
                                          )
                                        },
                                        expression: "moduleForm.isDisabled"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _c(
                                "div",
                                [
                                  _c(
                                    "span",
                                    { staticClass: "pa-1 heading-payment" },
                                    [_vm._v("Notes")]
                                  ),
                                  _c("v-textarea", {
                                    staticStyle: {
                                      padding: "10px",
                                      background: "#FFFFFF",
                                      "padding-left": "4px"
                                    },
                                    attrs: {
                                      disabled: !_vm.IsCMSAdmin,
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value: _vm.moduleForm.description,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.moduleForm,
                                          "description",
                                          $$v
                                        )
                                      },
                                      expression: "moduleForm.description"
                                    }
                                  })
                                ],
                                1
                              ),
                              !_vm.loading && _vm.IsCMSAdmin
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "text-end pt-2 pr-2",
                                      on: {
                                        click: function($event) {
                                          return _vm.updateModuleDetails()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        { attrs: { color: "primary" } },
                                        [_vm._v(" Save ")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    { staticClass: "mb-5", attrs: { outlined: "" } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "d-flex justify-space-between" },
                        [
                          _c("h2", [_vm._v("Learning objective")]),
                          _vm.IsCMSAdmin
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    rounded: "",
                                    small: ""
                                  },
                                  on: { click: _vm.LoadCreateObjective }
                                },
                                [_vm._v("CREATE OBJECTIVE")]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    staticClass: "shrink",
                                    attrs: {
                                      "append-icon": "mdi-magnify",
                                      label: "Search",
                                      "single-line": "",
                                      dense: "",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.searchobjective,
                                      callback: function($$v) {
                                        _vm.searchobjective = $$v
                                      },
                                      expression: "searchobjective"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-data-table", {
                            attrs: {
                              items: _vm.learningobjectives,
                              headers: _vm.computedObjectiveheaders,
                              search: _vm.searchobjective,
                              "no-data-text": "No objective available",
                              "hide-default-footer": _vm.learningobjectivePaginationStatus()
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.id",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getIndex(
                                                item,
                                                _vm.learningobjectives
                                              ) + 1
                                            )
                                        )
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "item.title",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(" " + _vm._s(item.title) + " ")
                                    ]
                                  }
                                },
                                {
                                  key: "item.lessons",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      item.lessons
                                        ? _c("span", [
                                            _vm._v(_vm._s(item.lessons.length))
                                          ])
                                        : _c("span", [_vm._v("0")])
                                    ]
                                  }
                                },
                                {
                                  key: "item.isDisabled",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      item.isDisabled
                                        ? _c("span", [_vm._v(" Hidden ")])
                                        : _c("span", [_vm._v("Visible")])
                                    ]
                                  }
                                },
                                {
                                  key: "item.action",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "lessons-actions-wrap" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "blue",
                                                icon: "",
                                                small: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.LoadLearningObjective(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { title: "Edit" } },
                                                [_vm._v("mdi-pencil")]
                                              )
                                            ],
                                            1
                                          ),
                                          item.isDisabled
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "green",
                                                    icon: "",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.LoadConfirmDialogForDisableOrEnableObjective(
                                                        item,
                                                        false
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { title: "Enable" }
                                                    },
                                                    [_vm._v("mdi-eye")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "orange",
                                                    icon: "",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.LoadConfirmDialogForDisableOrEnableObjective(
                                                        item,
                                                        true
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        title: "Disable"
                                                      }
                                                    },
                                                    [_vm._v("mdi-eye-off")]
                                                  )
                                                ],
                                                1
                                              ),
                                          _vm.hideDelete == "false"
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "red",
                                                    icon: "",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.LoadConfirmDialogForDeleteObjective(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { title: "Delete" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-trash-can-outline"
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    { attrs: { outlined: "" } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "d-flex justify-space-between" },
                        [
                          _c("h2", [_vm._v("Lessons")]),
                          _vm.IsCMSAdmin
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    small: "",
                                    rounded: ""
                                  },
                                  on: { click: _vm.LoadCreateLesson }
                                },
                                [_vm._v("CREATE LESSON")]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "append-icon": "mdi-magnify",
                                      label: "Search",
                                      "single-line": "",
                                      dense: "",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.searchlesson,
                                      callback: function($$v) {
                                        _vm.searchlesson = $$v
                                      },
                                      expression: "searchlesson"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-data-table", {
                            attrs: {
                              items: _vm.orderedLessons,
                              headers: _vm.computedLessonheaders,
                              search: _vm.searchlesson,
                              "no-data-text": "No lesson available",
                              "hide-default-footer": _vm.lessonPaginationStatus()
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm._l(_vm.computedLessonheaders, function(h) {
                                  return {
                                    key: "header." + h.value,
                                    fn: function(ref) {
                                      var header = ref.header
                                      return [
                                        _vm.headerTooltips[h.value]
                                          ? _c(
                                              "v-tooltip",
                                              {
                                                key: h.value,
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "span",
                                                            _vm._g({}, on),
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  header.text
                                                                )
                                                              ),
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "pl-1",
                                                                  attrs: {
                                                                    color:
                                                                      "#113247"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-information"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.headerTooltips[
                                                        header.value
                                                      ]
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          : _c("span", { key: h.value }, [
                                              _vm._v(_vm._s(header.text))
                                            ])
                                      ]
                                    }
                                  }
                                }),
                                {
                                  key: "item.id",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getIndex(
                                                item,
                                                _vm.orderedLessons
                                              ) + 1
                                            )
                                        )
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "item.title",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "blue--text",
                                          attrs: {
                                            to:
                                              "/lesson/" +
                                              _vm.courseId +
                                              "/" +
                                              item.id
                                          }
                                        },
                                        [_vm._v(_vm._s(item.title))]
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.learningobjective",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      item.learningobjective
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                item.learningobjective.title
                                              )
                                            )
                                          ])
                                        : _c("span", [_vm._v("-")])
                                    ]
                                  }
                                },
                                {
                                  key: "item.isDisabled",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      item.isDisabled
                                        ? _c("span", [_vm._v(" Hidden ")])
                                        : _c("span", [_vm._v("Visible")])
                                    ]
                                  }
                                },
                                {
                                  key: "item.sort",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "lessons-actions-wrap" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                icon: "",
                                                small: "",
                                                disabled:
                                                  _vm.getIndex(
                                                    item,
                                                    _vm.orderedLessons
                                                  ) < 1
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.moveLesson(
                                                    _vm.getIndex(
                                                      item,
                                                      _vm.orderedLessons
                                                    ),
                                                    _vm.getIndex(
                                                      item,
                                                      _vm.orderedLessons
                                                    ) - 1
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-arrow-up")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                icon: "",
                                                small: "",
                                                disabled:
                                                  _vm.getIndex(
                                                    item,
                                                    _vm.orderedLessons
                                                  ) >
                                                  _vm.orderedLessons.length - 2
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.moveLesson(
                                                    _vm.getIndex(
                                                      item,
                                                      _vm.orderedLessons
                                                    ),
                                                    _vm.getIndex(
                                                      item,
                                                      _vm.orderedLessons
                                                    ) + 1
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-arrow-down")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.isPublished",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      item.isPublished
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "#757575" } },
                                                [_vm._v("mdi-file-table")]
                                              ),
                                              _vm._v(" Published ")
                                            ],
                                            1
                                          )
                                        : _c(
                                            "span",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "#757575" } },
                                                [
                                                  _vm._v(
                                                    "mdi-file-document-edit-outline"
                                                  )
                                                ]
                                              ),
                                              _vm._v(" Draft")
                                            ],
                                            1
                                          )
                                    ]
                                  }
                                },
                                {
                                  key: "item.action",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "lessons-actions-wrap" },
                                        [
                                          item.isDisabled
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    color: "green",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.DisableOREnalbeLesson(
                                                        item,
                                                        false
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { title: "Enable" }
                                                    },
                                                    [_vm._v("mdi-eye")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    color: "orange",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.DisableOREnalbeLesson(
                                                        item,
                                                        true
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        title: "Disable"
                                                      }
                                                    },
                                                    [_vm._v("mdi-eye-off")]
                                                  )
                                                ],
                                                1
                                              ),
                                          _vm.hideDelete == "false"
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    color: "red",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.LoadConfirmDialogForDeleteLesson(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { title: "Delete" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-trash-can-outline"
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "400px" },
          model: {
            value: _vm.showLBDialog,
            callback: function($$v) {
              _vm.showLBDialog = $$v
            },
            expression: "showLBDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-form",
                {
                  ref: "Objectiveform",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.validObjective,
                    callback: function($$v) {
                      _vm.validObjective = $$v
                    },
                    expression: "validObjective"
                  }
                },
                [
                  _c("v-card-title", { staticClass: "headline" }, [
                    _vm._v("Learning Objective")
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      counter: 50,
                                      rules: _vm.nameRules,
                                      label: "Title*",
                                      required: ""
                                    },
                                    model: {
                                      value:
                                        _vm.forms.createLearningObjective.title,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.forms.createLearningObjective,
                                          "title",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "forms.createLearningObjective.title"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("small", { staticClass: "info--text" }, [
                        _vm._v("*indicates required field")
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "", text: "" },
                          on: {
                            click: function($event) {
                              _vm.showLBDialog = false
                            }
                          }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _vm.forms.createLearningObjective.id > 0
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.updateLearningObjective }
                            },
                            [_vm._v(" Update ")]
                          )
                        : _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.validateLearningObjective }
                            },
                            [_vm._v(" Save ")]
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.showAddModuleToCourse,
            callback: function($$v) {
              _vm.showAddModuleToCourse = $$v
            },
            expression: "showAddModuleToCourse"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-form",
                {
                  ref: "AddModuleToCourseform",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.validLesson,
                    callback: function($$v) {
                      _vm.validLesson = $$v
                    },
                    expression: "validLesson"
                  }
                },
                [
                  _c("v-card-title", { staticClass: "headline" }, [
                    _vm._v("Add module to course")
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.primaryCourseList,
                                  rules: [
                                    function(v) {
                                      return !!v || "Course is required"
                                    }
                                  ],
                                  label: "Select course*",
                                  dense: "",
                                  required: ""
                                },
                                model: {
                                  value: _vm.forms.addModuleToCourse.id,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.forms.addModuleToCourse,
                                      "id",
                                      $$v
                                    )
                                  },
                                  expression: "forms.addModuleToCourse.id"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "", text: "" },
                          on: {
                            click: function($event) {
                              _vm.showAddModuleToCourse = false
                            }
                          }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.AddModuleToCourse }
                        },
                        [_vm._v(" Add module ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "800px" },
          model: {
            value: _vm.showLessonDialog,
            callback: function($$v) {
              _vm.showLessonDialog = $$v
            },
            expression: "showLessonDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-form",
                {
                  ref: "Lessonform",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.validLesson,
                    callback: function($$v) {
                      _vm.validLesson = $$v
                    },
                    expression: "validLesson"
                  }
                },
                [
                  _c("v-card-title", { staticClass: "headline" }, [
                    _vm._v("Lesson")
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      counter: 50,
                                      rules: _vm.nameRules,
                                      label: "Title*",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.forms.createLesson.title,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.forms.createLesson,
                                          "title",
                                          $$v
                                        )
                                      },
                                      expression: "forms.createLesson.title"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      items:
                                        _vm.availableLearingObjectivesForCourse,
                                      label: "Learning Objective*",
                                      required: ""
                                    },
                                    model: {
                                      value:
                                        _vm.forms.createLesson
                                          .createLearningObjective.id,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.forms.createLesson
                                            .createLearningObjective,
                                          "id",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "\n                    forms.createLesson.createLearningObjective.id\n                  "
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("small", { staticClass: "info--text" }, [
                        _vm._v(
                          "*Please ensure each mandatory field marked with an asterisk * is completed"
                        )
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "", text: "" },
                          on: {
                            click: function($event) {
                              _vm.showLessonDialog = false
                            }
                          }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _vm.forms.createLesson.id > 0
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.updateLearningObjective }
                            },
                            [_vm._v(" Update ")]
                          )
                        : _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.validateLesson }
                            },
                            [_vm._v(" Save ")]
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "450" },
          model: {
            value: _vm.confirmdialog.show,
            callback: function($$v) {
              _vm.$set(_vm.confirmdialog, "show", $$v)
            },
            expression: "confirmdialog.show"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.confirmdialog.HeaderText))
              ]),
              _c("v-card-text", [
                _vm._v(_vm._s(_vm.confirmdialog.ContentText))
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmdialog.show = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.confirmdialog.ButtonConfirmAction(
                            _vm.confirmdialog.customObject
                          )
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.confirmdialog.ButtonConfirmText))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "450" },
          model: {
            value: _vm.coursevalidationalert,
            callback: function($$v) {
              _vm.coursevalidationalert = $$v
            },
            expression: "coursevalidationalert"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", [
                _c("br"),
                _c("br"),
                _c("br"),
                !_vm.coursevalidationalertMsg
                  ? _c("h4", [
                      _vm._v(
                        " Intro header and Description are required fields "
                      )
                    ])
                  : _c("h4", [
                      _vm._v(" " + _vm._s(_vm.coursevalidationalertMsg) + " ")
                    ])
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.coursevalidationalert = false
                        }
                      }
                    },
                    [_vm._v("Got it")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }