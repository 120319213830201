<template>
  <v-container fluid>
    <div>
      <div class="page-sub-sec">
        <v-text-field
          v-model="searchUser"
          append-icon="mdi-magnify"
          label="Search"
          outlined
          dense
          hide-details
          class="shrink"
        />
        <v-btn
          v-if="IsDementiaAdmin"
          color="primary"
          @click="showAddModal"
        >
          <v-icon class="mr-2">mdi-plus</v-icon>Add
          Subscription</v-btn
        >
      </div>
      <v-data-table
        :items="orgProducts"
        :headers="computedUserHeaders"
        :search="searchUser"
        class="dark--text"
        :no-data-text="`No data available`"
        :loading="loading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:[`item.productName`]="{ item }">
          <router-link
            v-if="!(IsORGAdmin || IsORGViewer)"
            :to="`/organisations/${orgId}/${item.productId}`"
            class="blue--text"
            >{{ item.productName }}</router-link
          >
          <span v-if="IsORGAdmin || IsORGViewer">{{
            item.productName
          }}</span>
        </template>
        <template v-slot:[`item.accessCode`]="{ item }">
          {{ item.accessCode }}
        </template>
        <template v-slot:[`item.totalLicense`]="{ item }">
          <router-link
            :to="`/organisations/Users/${orgId}/${item.productId}`"
            class="blue--text"
            >{{ item.totalLicense }}</router-link
          >
        </template>
        <template v-slot:[`item.licence_remaining`]="{ item }">
          {{ item.licence_remaining }}
        </template>
        <template v-slot:[`item.usedLicense`]="{ item }">
          {{ item.usedLicense }}
        </template>
        <template v-slot:[`item.expirydateFormatted`]="{ item }">
          {{ item.expirydateFormatted }}
        </template>
        <template v-slot:[`item.userValidationRequired`]="{ item }">
          {{ item.userValidationRequired ? ' Yes' : ' No' }}
        </template>
        <template v-slot:[`item.productDisabled`]="{ item }">
          <v-btn
            v-if="!item.productDisabled"
            disabled
            color="green"
            icon
            small
            ><v-icon title="Enable">mdi-eye</v-icon></v-btn
          >
          <v-btn
            v-if="item.productDisabled"
            disabled
            color="red"
            icon
            small
            ><v-icon title="Enable">mdi-eye-off</v-icon></v-btn
          >
          {{ !item.productDisabled ? ' Visible' : ' Hidden' }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <div
            v-if="getLoginedUserId != item.id"
            class="lessons-actions-wrap"
          >
            <v-icon
              title="Edit"
              class="blue--text"
              @click="editItem(item)"
              >mdi-pencil</v-icon
            >

            <v-btn
              v-if="hideDelete == 'false'"
              icon
              color="red"
              small
              @click="LoadConfirmDialogForDeleteOrg(item)"
            >
              <v-icon title="Delete">mdi-trash-can-outline</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>

      <v-dialog v-model="showNewDialog" persistent max-width="600px">
        <v-card>
          <v-form ref="form" @submit="addSubscription">
            <v-card-title>
              <span v-if="!editSub" class="headline"
                >Add Subscription to Organisation</span
              >
              <span v-else class="headline">Edit Subscription</span>
            </v-card-title>
            <v-card-text>
              <v-row cols="12">
                <v-col sm="6">
                  <v-autocomplete
                    v-model="forms.selectedProduct"
                    :items="products"
                    item-text="name"
                    item-value="id"
                    :label="
                      forms.selectedProduct
                        ? editSub
                          ? forms.selectedProduct +
                            ' - ' +
                            editproductName
                          : 'ID-' + forms.selectedProduct
                        : 'Subscription Name*'
                    "
                    :disabled="editSub ? true : false"
                    :rules="[v => !!v || 'Select a Product']"
                    required
                    @change="event => selecteProduct(event)"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>
                          <span style="color:#0d3349;font-size:10px"
                            >{{ data.item.id }} -
                          </span>
                          {{ data.item.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col sm="6" style="margin-top: -10px;">
                  <v-row>
                    <v-col sm="2" style="    align-self: center;">
                      <span style=" color:#E0E0E0">
                        {{ orgName && orgName.slice(0, 3) }}
                      </span>
                    </v-col>

                    <v-col sm="10">
                      <v-text-field
                        v-model="forms.code"
                        label="Unique Subscription Code*"
                        :rules="[
                          v => !!v || 'Access Code is required',
                          v =>
                            /^[0-9a-zA-Z]+$/.test(v) ||
                            'Special characters and spaces are not allowed'
                        ]"
                        required
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row cols="12">
                <v-col sm="4">
                  <v-text-field
                    v-model="forms.noOfLicense"
                    label="Number of Licenses*"
                    type="number"
                    min="0"
                    :disabled="!IsDementiaAdmin"
                    :rules="[
                      v => !!v || 'Number of Licenses is required',
                      v =>
                        v > 0 || 'Please Enter a Valid License Count',
                      v =>
                        v < 2147483647 ||
                        'Enter valid Number of Licenses',
                      v => validateTotalLicence(v)
                    ]"
                    required
                  />
                </v-col>
                <v-col v-if="showNewDialog" sm="4">
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="forms.expirydate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="forms.expirydate"
                        :disabled="!IsDementiaAdmin"
                        :rules="[
                          v => !!v || 'Expiry Date is required'
                        ]"
                        label="Select Expiry Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="forms.expirydate"
                      scrollable
                      :min="
                        new Date(
                          Date.now() -
                            new Date().getTimezoneOffset() * 360000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="modal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(forms.expirydate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                  <!-- <v-datetime-picker
                    v-model="forms.expirydate"
                    label="Select Expiry Date*"
                    :text-field-props="{
                      value: forms.expirydate,
                      rules: [v => !!v || 'Expiry Date is required']
                    }"
                    :formatted-datetime="forms.expirydate"
                    :date-picker-props="{
                      min: new Date(
                        Date.now() -
                          new Date().getTimezoneOffset() * 360000
                      )
                        .toISOString()
                        .substr(0, 10)
                    }"
                  /> -->
                </v-col>
                <v-col sm="4">
                  <span class="">User Validation*</span>
                  <v-switch
                    v-model="forms.validation"
                    style="margin-top: 0px;"
                    inset
                    color="green"
                    vis
                    :label="
                      `${forms.validation ? 'Enabled' : 'Disabled'}`
                    "
                  />
                </v-col>
              </v-row>

              <div v-if="errors.length > 0">
                <v-icon style="color:red" @click="errors = []"
                  >mdi-close</v-icon
                >
                <div
                  v-for="(items, index) in errors"
                  :key="index"
                  class="pa-2 ma-1"
                >
                  <v-row>
                    <v-col>
                      <v-alert
                        dense
                        text
                        class="invite-learner v-alert"
                        type="error"
                        :value="true"
                        transition="slide-y-transition"
                        >{{ items.message }}</v-alert
                      >
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-card-text>
            <v-card-actions>
              <small class="info--text"
                >*Please ensure each mandatory field marked with an
                asterisk * is completed</small
              >
              <v-spacer />
              <v-btn depressed text @click="cancel">
                Cancel
              </v-btn>
              <v-btn color="primary" @click="addSubscription">
                {{ editSub ? 'Update' : 'Add' }} Subscription
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="confirmdialogUserdelete"
        persistent
        max-width="600"
      >
        <v-card>
          <v-card-title class="headline"
            >Remove Subscription From Organisation?</v-card-title
          >
          <v-card-text
            >Are you sure you want to remove .
            <span
              class="success--text"
              style="font-weight:bold;font-size:16px"
              >{{ subscriptionName }}</span
            >
            from the organsation
            <span
              class="success--text"
              style="font-weight:bold;font-size:16px"
              >{{ orgName }}</span
            >? This will remove access for
            {{ totalLicence }} learners.</v-card-text
          >
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="#113247 darken-1"
              text
              @click="confirmdialogUserdelete = false"
              >Cancel</v-btn
            >
            <v-btn
              class="actionButton"
              text
              @click="deleteSubscription(userdeleteId)"
              >REMOVE SUBSCRIPTION</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Constant } from '../../../config/constants';
import gql from 'graphql-tag';
import moment from 'moment';
import OrganizationStoreTypes from '../../../store/modules/organization/OrganizationStoreTypes';

const SUBSCRIPTION_QUERY = gql`
  query($id: ID!) {
    getOrgSubscriptions(id: $id) {
      data {
        accessCode
        expiryDate
        productName
        totalLicense
        usedLicense
        userValidationRequired
        productId
        orgProductId
        productDisabled
      }
      isSuccess
    }
  }
`;

const SUBSCRIPTION_LIST_MUTATION = gql`
  query FindUnmappedProducts($orgId: ID!) {
    findUnmappedProducts(orgId: $orgId) {
      data {
        id
        name
      }
      isSuccess
    }
  }
`;

const MAP_SUBSCRIPTION_MUTATION = gql`
  mutation($input: orgSubscriptionInput) {
    upsertOrgSubscription(input: $input) {
      statusCode
      statusMessage
      isSuccess
      error {
        code
        message
        id
      }
    }
  }
`;

const DELETE_SUBSCRIPTION = gql`
  mutation DeleteOrgProduct($input: deleteOrgProductInput) {
    deleteOrgProduct(input: $input) {
      orgProduct {
        id
      }
    }
  }
`;

const UPDATE_SUBSCRIPTION_DETAILS = gql`
  mutation($subId: ID!, $isDisabled: Boolean!) {
    updateSubscriptionDetail(
      input: { id: $subId, isDisabled: $isDisabled }
    ) {
      statusCode
      isSuccess
      statusMessage
    }
  }
`;

export default {
  name: 'SubscriptionsList',
  props: {
    orgName: {
      type: String,
      default: null
    }
  },
  data: () => ({
    system_roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER'
    },
    confirmdialogUserdelete: false,
    editSub: {},
    imageUrl:
      'https://cdn.pixabay.com/photo/2016/11/28/21/36/white-1866105_640.jpg',
    imageFile: [],
    userdeleteId: null,
    subscriptionName: null,
    alert: {
      type: 'success',
      show: true,
      text: 'test'
    },

    hideDelete: process.env.VUE_APP_HIDEDELETE_STATUS,
    errors: [],
    alertText: { text: '' },
    name: '',
    alertType: 'success',
    showNewDialog: false,
    searchUser: '',
    loading: false,
    editproductName: null,
    headers: [
      {
        text: 'Subscription Name',
        value: 'productName',
        width: '2%'
      },
      { text: 'Access Code', value: 'accessCode', width: '2%' },
      { text: 'Total Licences', value: 'totalLicense', width: '2%' },
      {
        text: 'Licences Remaining',
        value: 'licence_remaining',
        width: '2%'
      },
      { text: 'Total Learners', value: 'usedLicense', width: '2%' },
      {
        text: 'Expiry Date',
        value: 'expirydateFormatted',
        width: '1%'
      },
      {
        text: 'User Validation',
        value: 'userValidationRequired',
        width: '2%'
      },
      {
        text: 'Status',
        value: 'productDisabled',
        width: '1%'
      },
      {
        text: 'Action',
        value: 'action',
        sortable: false,
        width: '1%'
      }
    ],
    totalLicence: null,
    menu: false,
    modal: false,
    menu2: false,
    forms: {
      selectedProduct: null,
      code: null,
      validation: false,
      noOfLicense: null,
      expirydate: null
    }
  }),

  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating'
    ]),

    computedUserHeaders() {
      if (this.IsCMSAdmin)
        return this.headers.filter(t => t.value != 'visibility');
      else if (this.IsORGViewer)
        return this.headers.filter(t => t.value != 'action');
      else return this.headers;
    },
    IsDementiaAdmin() {
      if (
        this.getUser?.user?.role?.name ===
        this.system_roles.DEMENTIA_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsOrglevelRole() {
      const organizationRequiredRoles = [
        this.system_roles.ORG_ADMIN,
        this.system_roles.ORG_VIEWER,
        'LEARNER'
      ];
      if (
        this.forms.newRoleName &&
        organizationRequiredRoles.includes(this.forms.newRoleName)
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsCMSAdmin() {
      if (
        this.getUser?.user?.role?.name === this.system_roles.CMS_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsORGAdmin() {
      if (
        this.getUser?.user?.role?.name === this.system_roles.ORG_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsORGViewer() {
      if (
        this.getUser?.user?.role?.name ===
        this.system_roles.ORG_VIEWER
      ) {
        return true;
      } else {
        return false;
      }
    },
    getLoginedUserId() {
      return this.getUser?.user?.id;
    },
    orgId() {
      return parseInt(this.$route.params.orgId);
    }
  },
  created() {
    this.updateLicenseFromOrg('');
    this.Constant = Constant;
  },
  watch: {
    deep: true,
    'alert.show': function() {
      const self = this;
      self.$emit('alertObj', {
        alert: self.alert.show,
        alertType: self.alert.type,
        alertText: self.alert.text
      });
    }
  },
  methods: {
    getRoleDisplayName(roleName) {
      let displayRole = '';
      switch (roleName) {
        case Constant.roles.DEMENTIA_ADMIN:
          displayRole = Constant.roles.DEMENTIA_ADMIN_VIEW;
          break;
        case Constant.roles.CMS_ADMIN:
          displayRole = Constant.roles.CMS_ADMIN_VIEW;
          break;
        case Constant.roles.ORG_ADMIN:
          displayRole = Constant.roles.ORG_ADMIN_VIEW;
          break;
        case Constant.roles.ORG_VIEWER:
          displayRole = Constant.roles.ORG_VIEWER_VIEW;
          break;
        case Constant.roles.LEARNER:
          displayRole = Constant.roles.LEARNER_VIEW;
          break;
      }
      return displayRole;
    },
    addSubscription() {
      if (!this.$refs.form.validate()) return;
      if (!this.forms.expirydate) {
        this.errors = [{ message: 'Please Select Expiry Date' }];
        setTimeout(() => {
          this.errors = [];
        }, 4000);
        return false;
      }
      if (this.forms.noOfLicense > 2147483647) {
        this.errors = [
          { message: 'Please enter valid Number of license' }
        ];
        setTimeout(() => {
          this.errors = [];
        }, 4000);
        return false;
      }

      let payload = {
        org: this.orgId,
        product: parseInt(this.forms.selectedProduct),
        code: this.orgName.slice(0, 3) + '-' + this.forms.code,
        expirationDate: new Date(this.forms.expirydate),
        licenseCount: parseInt(this.forms.noOfLicense),
        isApprovalRequired: this.forms.validation
      };
      if (this.editSub) {
        payload['id'] = this.editSub;
      }
      const self = this;
      self.alert.show = false;
      this.$apollo
        .mutate({
          mutation: MAP_SUBSCRIPTION_MUTATION,
          variables: {
            input: payload
          },
          // Why is query not updating!!!
          // This shouldn't be necessary!!!
          update: (store, { data: { upsertOrgSubscription } }) => {
            if (upsertOrgSubscription?.statusCode == 'Error') {
              this.errors = upsertOrgSubscription.error;
              setTimeout(() => {
                this.errors = [];
              }, 4000);
            } else {
              this.$apollo.queries.orgProducts.refresh();
              this.$apollo.queries.products.refresh();
              self.alert.show = true;
              self.alert.text = 'Successfully Saved';
              self.alert.type = 'success';
              this.showNewDialog = false;
              this.callReloadMethodInOrgList();
              this.$refs.form.resetValidation();
              this.$refs.form.reset();
              setTimeout(() => {
                self.alert.show = false;
              }, 3000);
            }
          }
        })
        .catch(error => {
          self.alert.show = true;
          self.alert.text = error;
          self.alert.type = 'error';
          setTimeout(() => {
            self.alert.show = false;
          }, 3000);
        });
    },
    deleteSubscription(id) {
      let payload = {
        where: {
          id: id.toString()
        }
      };
      this.alert.show = false;
      this.$apollo
        .mutate({
          mutation: DELETE_SUBSCRIPTION,
          variables: {
            input: payload
          },
          // Why is query not updating!!!
          // This shouldn't be necessary!!!
          update: (store, { data: { upsertOrgSubscription } }) => {
            if (upsertOrgSubscription?.statusCode == 'Error') {
              this.errors = upsertOrgSubscription.error;
              this.alert.show = true;
            } else {
              this.$apollo.queries.orgProducts.refresh();
              this.$apollo.queries.products.refresh();
              this.confirmdialogUserdelete = false;
              this.alert.show = true;
              this.alert.text = 'Successfully Saved';
              this.alert.type = 'success';
              this.showNewDialog = false;
              this.$refs.form.resetValidation();
              this.$refs.form.reset();
              setTimeout(() => {
                this.alert.show = false;
              }, 3000);
            }
          }
        })
        .catch(error => {
          this.alert.show = true;
          this.alert.text = error;
          this.alert.type = 'error';
          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        });
    },
    getFile() {
      let fileUpload = document.getElementById('fileUpload');
      if (fileUpload != null) {
        fileUpload.click();
      }
    },
    onFilePicked(e) {
      const files = e;
      if (files?.name !== undefined) {
        this.imageName = files.name;
        if (this.imageName.lastIndexOf('.') <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files);
        fr.addEventListener('load', () => {
          this.imageUrl = fr.result;
          this.imageFile = files; // this is an image file that can be sent to server...
        });
      } else {
        this.imageName = '';
        this.imageFile = '';
        this.imageUrl =
          'https://cdn.pixabay.com/photo/2016/11/28/21/36/white-1866105_640.jpg';
      }
    },
    LoadConfirmDialogForDeleteOrg(item) {
      this.userdeleteId = item.orgProductId;
      this.subscriptionName = item.productName;
      this.totalLicence = item.usedLicense;
      this.confirmdialogUserdelete = true;
    },
    cancel() {
      this.$refs.form.reset();
      this.imageUrl =
        'https://cdn.pixabay.com/photo/2016/11/28/21/36/white-1866105_640.jpg';
      this.showNewDialog = false;
      this.editSub = null;
      this.errors = [];
      this.forms = {
        selectedProduct: null,
        code: null,
        validation: false,
        noOfLicense: null
      };
    },
    changeVisibility(item) {
      this.alert.show = false;
      this.$apollo
        .mutate({
          mutation: UPDATE_SUBSCRIPTION_DETAILS,
          variables: {
            subId: item.id,
            isDisabled: !item.isDisabled
          },
          // Why is query not updating!!!
          // This shouldn't be necessary!!!
          update: () => {
            this.$apollo.queries.products.refresh();
            this.alert.show = true;
            this.alert.text = 'Successfully Saved';
            this.alert.type = 'success';
            this.editName = false;
            setTimeout(() => {
              this.alert.show = false;
            }, 3000);
          }
        })
        .catch(error => {
          this.alert.show = true;
          this.alert.text = error;
          this.alert.type = 'error';
          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        });
    },
    showAddModal() {
      this.showNewDialog = true;
      this.editSub = null;
      this.forms = {
        selectedProduct: null,
        code: null,
        validation: false,
        noOfLicense: null
      };
    },
    editItem(item) {
      this.editSub = item.orgProductId;
      this.editproductName = item.productName;
      let code = item.accessCode.split('-');
      this.totalLicence = item.usedLicense;
      this.errors = [];
      this.forms = {
        selectedProduct: item.productId.toString(),
        code: code[1],
        validation: item.userValidationRequired,
        noOfLicense: item.totalLicense,
        expirydate: moment(item.expiryDate).format('YYYY-MM-DD')
      };
      this.showNewDialog = true;
    },
    selecteProduct(event) {
      let product = this.products.find(item => item.id == event);
      this.forms.code = product.name.slice(0, 3);
    },
    ...mapActions('organization', [
      OrganizationStoreTypes.Actions.UPDATE_LICENSE_FROM_ORG
    ]),
    validateTotalLicence(v) {
      const self = this;
      if (self.totalLicence && v < self.totalLicence) {
        return `There is a problem with you reducing the license count as your subscription currently has ${self.totalLicence} allocated to learners`;
      }
      return true;
    },
    callReloadMethodInOrgList: function() {
      this.$root.$emit('OrgListComponent');
    }
  },
  apollo: {
    orgProducts() {
      this.loading = true;
      return {
        query: SUBSCRIPTION_QUERY,
        fetchPolicy: 'cache-and-network',
        variables: { id: this.orgId },
        update: data => {
          this.loading = false;
          let items = data.getOrgSubscriptions.data;
          let data_items = items.map(element => {
            element.licence_remaining =
              element.totalLicense - element.usedLicense;
            element.expirydateFormatted = moment(
              element.expiryDate
            ).format('MM-DD-YYYY');
            return element;
          });
          return data_items;
        },
        error() {
          this.loading = false;
        },
        skip() {
          return !this.isAuthenticated;
        }
      };
    },
    products() {
      return {
        query: SUBSCRIPTION_LIST_MUTATION,
        fetchPolicy: 'cache-and-network',
        variables: {
          orgId: this.orgId
        },
        update: data => {
          return data.findUnmappedProducts.data;
        },
        skip() {
          return !this.isAuthenticated || !this.IsDementiaAdmin;
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.v-alert {
  left: unset !important;
}
</style>
