<template>
  <div class="editor-rich-text">
    <app-rich-text-editor v-model="content" />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import AppRichTextEditor from '@/components/editors/AppRichTextEditor';
import { Emit } from './emit.js';

const QUERY_FRAGMENT = gql`
  fragment ComponentContentListRichText on ComponentContentListRichText {
    id
    content
  }
`;

export default {
  name: 'ComponentContentListRichText',
  icon: 'mdi-text-box-outline',
  gqlQueryFragment: QUERY_FRAGMENT,
  text: 'Rich Text',
  components: {
    AppRichTextEditor
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    content: Emit('content')
  }
};
</script>

<style lang="scss">
.editor-rich-text {
  .editing-panel {
    display: none;
  }

  &:not(.editing) .app-rte-toolbar {
    display: none;
  }
}
</style>
