var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isInit && _vm.isAuthenticated
      ? _c("h1", [_vm._v(" Dementia Australia: Learning Management System ")])
      : _vm._e(),
    _c(
      "div",
      { staticStyle: { position: "relative", top: "40px" } },
      [!_vm.isInit ? _c("page-user-control") : _vm._e()],
      1
    ),
    _vm.isInit ? _c("div", [_vm._v("Initialising")]) : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }