<template>
  <div>
    <v-container>
      <v-row>
        <v-col sm="1">
          <h3 class="font-weight-black">
            {{ index }}
          </h3>
        </v-col>
        <v-col>
          <h2 class="indigo--text">Drag &amp; Drop</h2>
          <app-rich-text-editor v-model="local.quiz.question" />
          <h3 class="mt-6">Answers</h3>
          <table class="mb-6" style="border-spacing: 0;">
            <thead>
              <tr>
                <th></th>
                <th style="text-align: left; width: 50%;">
                  Source
                </th>
                <th style="text-align: left; width: 50%">Target</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody v-if="local.quiz">
              <tr
                v-for="(answer, indexs) in local.quiz.answers"
                :key="answer.id"
              >
                <td class="pr-4 pb-6 pt-4">{{ indexs + 1 }}</td>
                <td><v-text-field v-model="answer.source" /></td>
                <td><v-text-field v-model="answer.target" /></td>
                <td>
                  <v-btn
                    icon
                    color="primary"
                    @click="deleteAnswer(indexs)"
                    ><v-icon>mdi-minus-circle</v-icon></v-btn
                  >
                </td>
              </tr>
            </tbody>
            <tbody style="background-color: rgba(0,0,0,0.05);">
              <tr>
                <td></td>
                <td>
                  <v-text-field v-model="forms.newAnswer.source" />
                </td>
                <td>
                  <v-text-field v-model="forms.newAnswer.target" />
                </td>
                <td>
                  <v-btn
                    v-if="IsCMSAdmin"
                    icon
                    color="primary"
                    @click="addAnswer"
                    ><v-icon>mdi-plus-circle</v-icon></v-btn
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mb-3">
            <h3 class="mt-6">Incorrect Answer Text</h3>
            <v-text-field
              v-model="local.quiz.incorrectAnswerText"
              outlined
              dense
              hide-details
              class="shrink "
            />
          </div>
          <v-btn
            v-if="IsCMSAdmin"
            color="primary"
            x-small
            title="Remove the current quiz"
            @click="$emit('removethiscomponent', local.quiz)"
            >Remove</v-btn
          >
          <v-dialog
            v-model="dragDropValidation"
            persistent
            max-width="450"
          >
            <v-card>
              <v-card-text>
                <br />
                <br />
                <br />
                <h4>Question and answers are required fields</h4>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="red darken-1"
                  text
                  @click="dragDropValidation = false"
                  >Got it</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import AppRichTextEditor from '@/components/editors/AppRichTextEditor';
import clone from 'rfdc';
import { mapGetters } from 'vuex';

const cloner = clone();

const QUERY_FRAGMENT = gql`
  fragment ComponentQuizDragDrop on ComponentQuizDragDrop {
    id
    question
    incorrectAnswerText
    answers {
      __typename
      ... on ComponentQuizAnswerDragText {
        id
        source
        target
      }
    }
  }
`;

export default {
  name: 'ComponentQuizDragDrop',
  icon: 'mdi-drag-variant',
  gqlQueryFragment: QUERY_FRAGMENT,
  text: 'Drag & Drop',
  components: {
    AppRichTextEditor
  },
  props: {
    quiz: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    dragDropValidation: false,
    roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER'
    },
    local: {
      quiz: {}
    },
    forms: {
      newAnswer: {
        source: '',
        target: ''
      }
    }
  }),

  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating'
    ]),
    IsDementiaAdmin() {
      if (
        this.getUser?.user?.role?.name === this.roles.DEMENTIA_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsCMSAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.CMS_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGViewer() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_VIEWER) {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {
    this.$set(this.local, 'quiz', cloner(this.quiz));
  },
  methods: {
    addAnswer() {
      if (
        !this.local.quiz.question ||
        !this.forms.newAnswer.source ||
        !this.forms.newAnswer.target
      ) {
        this.dragDropValidation = true;
        return;
      }
      this.local.quiz.answers.push({
        source: this.forms.newAnswer.source,
        target: this.forms.newAnswer.target
      });
      this.forms.newAnswer.source = '';
      this.forms.newAnswer.target = '';
    },
    deleteAnswer(index) {
      this.local.quiz.answers.splice(index, 1);
    }
  }
};
</script>
