var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "grey lighten-5", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "mr-auto", attrs: { cols: "auto" } },
                [
                  _c("v-breadcrumbs", {
                    staticClass: "pa-0",
                    attrs: { items: _vm.breadcrumbs }
                  }),
                  _c("h1", [
                    _vm._v(" Profile:"),
                    _c("span", { staticClass: "success--text" }, [
                      _vm._v(_vm._s(_vm.user.firstName))
                    ])
                  ]),
                  _c("h3", { staticClass: "info--text" }, [
                    _vm._v(_vm._s(_vm.org.name))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    { attrs: { color: "basil", flat: "" } },
                    [
                      _c("v-card-title", [_c("h3", [_vm._v("Details")])]),
                      _c("v-card-text", [
                        _c("dl", [
                          _c("dt", [_vm._v("Email")]),
                          _c("dd", [_vm._v(_vm._s(_vm.user.email))]),
                          _vm.user.firstName
                            ? _c("div", [
                                _c("dt", [_vm._v("First name")]),
                                _c("dd", [_vm._v(_vm._s(_vm.user.firstName))])
                              ])
                            : _vm._e(),
                          _vm.user.lastName
                            ? _c("div", [
                                _c("dt", [_vm._v("Last name")]),
                                _c("dd", [_vm._v(_vm._s(_vm.user.lastName))])
                              ])
                            : _vm._e(),
                          _vm.user.mobile
                            ? _c("div", [
                                _c("dt", [_vm._v("Mobile")]),
                                _c("dd", [_vm._v(_vm._s(_vm.user.mobile))])
                              ])
                            : _vm._e()
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    {
                      staticClass: "mt-5",
                      attrs: { color: "basil", flat: "" }
                    },
                    [
                      _c("v-card-title", [
                        _c("h3", [_vm._v("Lessons Attempted")])
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _c("v-data-table", {
                            attrs: {
                              items: _vm.lessonResults,
                              headers: _vm.lessonHeaders,
                              "no-data-text":
                                "No attempted lessons are available",
                              "hide-default-footer": _vm.lessonsPaginationStatus()
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.title",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(" " + _vm._s(item.title) + " ")
                                    ]
                                  }
                                },
                                {
                                  key: "item.completed",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.completed
                                              ? "Completed"
                                              : "In Progress"
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.success",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.success + "/" + item.activities
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.at",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [_vm._v(" " + _vm._s(item.at) + " ")]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }