var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticClass: "mx-auto mt-10 p-2 ",
          attrs: { "max-width": "360", flat: "" }
        },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _vm.loading
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c("v-progress-linear", {
                          attrs: { indeterminate: "" }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              !_vm.isAuthenticated &&
              !_vm.forgotPassword &&
              !_vm.resetPassword &&
              !_vm.forgotPasswordMessage &&
              !_vm.mobilestoredisplay
                ? _c(
                    "v-card",
                    [
                      _c("div", { staticClass: "imageDiv" }, [
                        _c("img", {
                          staticClass: "imageCard",
                          attrs: {
                            "max-height": "70.85",
                            "max-width": "256",
                            src: require("../../images/DementiaLogo.png")
                          }
                        })
                      ]),
                      _c("v-card-title", { staticClass: "loginCardTitle  " }, [
                        _vm._v("Login")
                      ]),
                      _c(
                        "div",
                        { staticClass: "divCard" },
                        [
                          _c(
                            "v-form",
                            { staticClass: "px-1" },
                            [
                              _c("v-text-field", {
                                staticClass: "userInput",
                                attrs: {
                                  outlined: "",
                                  placeholder: "Username"
                                },
                                model: {
                                  value: _vm.username,
                                  callback: function($$v) {
                                    _vm.username = $$v
                                  },
                                  expression: "username"
                                }
                              }),
                              _c("v-text-field", {
                                staticClass: "userInput",
                                attrs: {
                                  type: "password",
                                  outlined: "",
                                  placeholder: "Password"
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            { staticClass: "pa-0" },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "justify-center align-center"
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "white", large: "" },
                                          on: { click: _vm.callAuthenticate }
                                        },
                                        [_vm._v("Login")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "justify-center align-center mt-4"
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: {
                                            color: "white",
                                            "text-decoration": "none",
                                            "font-size": "15px"
                                          },
                                          attrs: { href: "javascript:void(0)" },
                                          on: { click: _vm.forgotPasswordClick }
                                        },
                                        [_vm._v("Forgot Password?")]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("div", { staticClass: "loginFailed pl-2" }, [
                        _vm.isLoginFailed
                          ? _c("span", { staticClass: "red--text " }, [
                              _vm._v("Login failed!")
                            ])
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAuthenticated && _vm.getUser.user
                ? _c(
                    "v-card",
                    [
                      _c("v-card-title", { staticClass: "headline" }, [
                        _vm._v("Currently logged in as")
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _c("drawer-user-item", {
                            attrs: { user: _vm.getUser.user }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "error" },
                              on: { click: _vm.logout }
                            },
                            [_vm._v("Logout")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isAuthenticated && _vm.resetPassword
                ? _c("v-card", [
                    _c("div", { staticClass: "imageDiv" }, [
                      _c("img", {
                        staticClass: "imageCard",
                        attrs: {
                          "max-height": "70.85",
                          "max-width": "256",
                          src: require("../../images/DementiaLogo.png")
                        }
                      })
                    ]),
                    _c(
                      "div",
                      { staticClass: "divCard" },
                      [
                        _c(
                          "v-list-item",
                          [
                            _c(
                              "v-list-item-content",
                              { staticStyle: { color: "white" } },
                              [
                                _c("v-list-item-title", [
                                  _vm._v("Create New Password")
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-form",
                          { ref: "form", staticClass: "px-4" },
                          [
                            _c("v-text-field", {
                              staticClass: "userInput",
                              attrs: {
                                type: "password",
                                rules: _vm.passwordRules,
                                placeholder: "New Password"
                              },
                              model: {
                                value: _vm.newPassword,
                                callback: function($$v) {
                                  _vm.newPassword = $$v
                                },
                                expression: "newPassword"
                              }
                            }),
                            _c("v-text-field", {
                              staticClass: "userInput",
                              attrs: {
                                type: "password",
                                rules: [
                                  !!_vm.confirmPassword ||
                                    "Please enter confirm password",
                                  _vm.newPassword === _vm.confirmPassword ||
                                    "The password confirmation does not match."
                                ],
                                placeholder: "Confirm Password"
                              },
                              model: {
                                value: _vm.confirmPassword,
                                callback: function($$v) {
                                  _vm.confirmPassword = $$v
                                },
                                expression: "confirmPassword"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "pa-4" },
                          [
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "align-center" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { color: "white" },
                                            on: {
                                              click: _vm.resetPasswordSubmit
                                            }
                                          },
                                          [_vm._v("Submit")]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm.isResetFailed
                                      ? _c(
                                          "span",
                                          { staticClass: "red--text" },
                                          [_vm._v(_vm._s(_vm.resetFailedText))]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "justify-center align-center",
                                    staticStyle: {
                                      color: "white",
                                      "text-decoration": "none",
                                      "font-size": "15px"
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "Having trouble creating a password?"
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass:
                                      "justify-center align-center mt-1"
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "white",
                                          "text-decoration": "none",
                                          "font-size": "15px"
                                        }
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticStyle: {
                                              color: "white",
                                              "font-size": "15px"
                                            },
                                            attrs: {
                                              href:
                                                "https://dementialearning.org.au/technology/ask-annie/support/"
                                            }
                                          },
                                          [_vm._v(" Contact")]
                                        ),
                                        _vm._v(" us now. ")
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm.forgotPassword
                ? _c("v-card", [
                    _c(
                      "div",
                      { staticClass: "resetPasswordContainer" },
                      [
                        _c("div", { staticClass: "imageDiv" }, [
                          _c("img", {
                            staticClass: "imageCard",
                            attrs: {
                              "max-height": "70.85",
                              "max-width": "256",
                              src: require("../../images/DementiaLogo.png")
                            }
                          })
                        ]),
                        _vm.isResetFailed
                          ? _c(
                              "v-card-title",
                              { staticClass: "resetPasswordHeading" },
                              [_vm._v("Expired Reset Link")]
                            )
                          : _c(
                              "v-card-title",
                              { staticClass: "resetPasswordHeading" },
                              [_vm._v("Reset Password")]
                            ),
                        _vm.isResetFailed
                          ? _c(
                              "p",
                              { staticClass: "resetPasswordHeading pa-0 ml-4" },
                              [
                                _vm._v(
                                  " The reset link you clicked has expired.Please enter your details below to resend the link "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "v-form",
                          { staticClass: "px-4" },
                          [
                            _vm.isResetFailed
                              ? _c("v-text-field", {
                                  staticClass: "userInput",
                                  attrs: { label: "Registered email ID" },
                                  model: {
                                    value: _vm.emailAddress,
                                    callback: function($$v) {
                                      _vm.emailAddress = $$v
                                    },
                                    expression: "emailAddress"
                                  }
                                })
                              : _c("v-text-field", {
                                  staticClass: "userInput",
                                  attrs: { label: "Email Address" },
                                  model: {
                                    value: _vm.emailAddress,
                                    callback: function($$v) {
                                      _vm.emailAddress = $$v
                                    },
                                    expression: "emailAddress"
                                  }
                                })
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "pa-4" },
                          [
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  "justify-space-between": "",
                                  "align-center": ""
                                }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "white", large: "" },
                                    on: { click: _vm.sendResetPassword }
                                  },
                                  [_vm._v("Submit")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "white", large: "" },
                                    on: { click: _vm.cancelProcess }
                                  },
                                  [_vm._v("Cancel")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.isResetFailed
                          ? _c(
                              "v-col",
                              [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "justify-center align-center",
                                    staticStyle: {
                                      color: "white",
                                      "text-decoration": "none",
                                      "font-size": "15px"
                                    }
                                  },
                                  [
                                    _c("span", [_vm._v("Need some help? ")]),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "white",
                                          "text-decoration": "none",
                                          "font-size": "15px"
                                        }
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticStyle: {
                                              color: "white",
                                              "font-size": "15px"
                                            },
                                            attrs: {
                                              href:
                                                "https://dementialearning.org.au/technology/ask-annie/support/"
                                            }
                                          },
                                          [_vm._v(" Contact")]
                                        ),
                                        _vm._v(" us now. ")
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass:
                                      "justify-center align-center mt-1"
                                  },
                                  [
                                    _vm.forgotPasswordFailed
                                      ? _c(
                                          "span",
                                          { staticClass: "red--text" },
                                          [
                                            _vm._v(
                                              "The user is not Registered!"
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            )
                          : _c(
                              "v-col",
                              [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "justify-center align-center",
                                    staticStyle: {
                                      color: "white",
                                      "text-decoration": "none",
                                      "font-size": "15px"
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("Haven't received an email?")
                                    ])
                                  ]
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass:
                                      "justify-center align-center mt-1"
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "white",
                                          "text-decoration": "none",
                                          "font-size": "15px"
                                        }
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticStyle: {
                                              color: "white",
                                              "font-size": "15px"
                                            },
                                            attrs: {
                                              href:
                                                "https://dementialearning.org.au/technology/ask-annie/support/"
                                            }
                                          },
                                          [_vm._v(" Contact")]
                                        ),
                                        _vm._v(" us now. ")
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass:
                                      "justify-center align-center mt-1"
                                  },
                                  [
                                    _vm.forgotPasswordFailed
                                      ? _c(
                                          "span",
                                          { staticClass: "red--text" },
                                          [
                                            _vm._v(
                                              "The user is not Registered!"
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm.forgotPasswordMessage
                ? _c(
                    "v-card",
                    [
                      _c("v-card-text", [
                        _c("h3", { staticClass: "success--text" }, [
                          _vm._v(
                            " Reset password link sent to " +
                              _vm._s(_vm.emailAddress) +
                              " "
                          )
                        ])
                      ]),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        { staticClass: "align-center" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" }
                            },
                            [
                              _c("span", [
                                _vm._v("Haven't received an email? "),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "https://dementialearning.org.au/technology/ask-annie/support/"
                                    }
                                  },
                                  [_vm._v(" Contact")]
                                ),
                                _vm._v(" us now.")
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        {
          staticClass: "mx-auto mt-10 p-2",
          attrs: { "max-width": "360", flat: "" }
        },
        [
          !_vm.isAuthenticated && _vm.mobilestoredisplay
            ? _c("v-card", [
                _c(
                  "div",
                  { staticClass: "resetPasswordContainer" },
                  [
                    _c("div", { staticClass: "imageDiv" }, [
                      _c("img", {
                        staticClass: "imageCard",
                        attrs: {
                          "max-height": "70.85",
                          "max-width": "256",
                          src: require("../../images/DementiaLogo.png")
                        }
                      })
                    ]),
                    _c(
                      "v-container",
                      { attrs: { fluid: "" } },
                      [
                        _c(
                          "v-layout",
                          {
                            attrs: { "justify-center": "", "align-center": "" }
                          },
                          [
                            _c("v-flex", { attrs: { shrink: "" } }, [
                              _c(
                                "div",
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass: "resetPasswordHeading pa-1",
                                      staticStyle: { "font-size": "18px" }
                                    },
                                    [_vm._v(" You’re almost there! ")]
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pa-1" },
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-row", [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "white",
                                                  "font-size": "18px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "Your password has been set. "
                                                )
                                              ]
                                            )
                                          ]),
                                          _c("v-row", [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "mt-3",
                                                staticStyle: {
                                                  color: "white",
                                                  "font-size": "18px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "Log in with your email address and password in the Ask Annie app on your iOS or Android mobile device."
                                                )
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "resetPasswordHeading text-center justify-center pa-0 "
                                    },
                                    [_vm._v("Download the app now!")]
                                  ),
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "pa-0" },
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "align-center ml-1" },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "text-center" },
                                            [
                                              _c("span", [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: _vm.androidstoreurl
                                                    }
                                                  },
                                                  [
                                                    _c("v-img", {
                                                      attrs: {
                                                        "max-height": "90",
                                                        "max-width": "150",
                                                        src: require("../../images/GoogleAppStore.png")
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { staticClass: "text-center" },
                                            [
                                              _c("span", [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: _vm.iosstoreurl
                                                    }
                                                  },
                                                  [
                                                    _c("v-img", {
                                                      attrs: {
                                                        "max-height": "75",
                                                        "max-width": "128",
                                                        src: require("../../images/AppStoreImage.png")
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "justify-center align-center mt-2",
                                      staticStyle: {
                                        color: "white",
                                        "text-decoration": "none",
                                        "font-size": "16px"
                                      }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("Need some help? "),
                                        _c(
                                          "a",
                                          {
                                            staticStyle: {
                                              color: "white",
                                              "font-size": "16px"
                                            },
                                            attrs: {
                                              href:
                                                "https://dementialearning.org.au/technology/ask-annie/support/"
                                            }
                                          },
                                          [_vm._v(" Contact")]
                                        ),
                                        _vm._v(" us now.")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }