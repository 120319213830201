<template>
  <div>
    <div v-if="loading">
      <div class="text-center">
        <v-progress-linear indeterminate />
      </div>
    </div>
    <div class="d-flex justify-center">
      <v-alert
        dense
        text
        class="v-alert"
        :type="alert.type"
        :value="alert.show"
        transition="slide-y-transition"
        >{{ alert.text }}</v-alert
      >
    </div>
    <v-container fluid class="grey lighten-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
          <v-row>
            <h1 class="pa-2">Licenses:</h1>
            <h1 class="success--text pa-2">
              {{ subscriptionName }}
            </h1>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="page-sub-sec">
      <v-text-field
        v-model="licenseSearch"
        append-icon="mdi-magnify"
        label="Search"
        outlined
        dense
        hide-details
        class="shrink"
      />
    </div>
    <v-col>
      <!-- justify-space-between -->
      <v-row class="d-flex ">
        <div v-for="(lic, index) in userLicenses" :key="index">
          <v-col>
            <v-data-table
              :items="lic.userDetail"
              :headers="computedHeaders"
              :search="licenseSearch"
              :no-data-text="
                `No Users available for this Subscription`
              "
              hide-default-footer
              disable-pagination
            >
              <template v-slot:[`item.id`]="{ item }">
                {{ item.id }}
              </template>
              <template v-slot:[`item.firstName`]="{ item }">
                <div :class="!item.userProduct ? 'available' : ''">
                  {{
                    item.firstName ? item.firstName : 'Unknown User'
                  }}
                </div>
              </template>
              <template v-slot:item.action="{ item }">
                <div
                  v-if="item.userProduct"
                  class="lessons-actions-wrap"
                >
                  <v-btn
                    color="red "
                    icon
                    small
                    @click="
                      LoadConfirmDialogForDeleteUser(
                        item.firstName,
                        item.userProduct
                      )
                    "
                    ><v-icon title="Delete"
                      >mdi-trash-can-outline</v-icon
                    ></v-btn
                  >
                </div>
                <div v-else class="lessons-actions-wrap">
                  <v-btn color="red " icon small disabled
                    ><v-icon title="Delete"
                      >mdi-trash-can-outline</v-icon
                    ></v-btn
                  >
                </div>
              </template>
            </v-data-table>
          </v-col>
        </div>
      </v-row>
    </v-col>
    <v-dialog
      v-model="confirmdialogUserdelete"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="headline"
          >Remove User From Subscription?</v-card-title
        >
        <v-card-text
          >Are you sure you want to remove {{ LearnerName }} from the
          subscription {{ subscriptionName }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#113247 darken-1"
            text
            @click="confirmdialogUserdelete = false"
            >Cancel</v-btn
          >
          <v-btn
            class="actionButton"
            text
            @click="deleteUser(UserProductId)"
            >REMOVE USER</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import gql from 'graphql-tag';
const USER_TOTALLICENSEQUERY = gql`
  query OrgProducts($where: JSON) {
    orgProducts(where: $where) {
      licenseCount
      product {
        name
      }
    }
  }
`;

const USER_LICENSEQUERY = gql`
  query UserProducts($where: JSON) {
    userProducts(where: $where) {
      id
      product {
        id
        name
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

const DELETE_USERPRODUCT_MUTATION = gql`
  mutation($userProductId: ID!) {
    deleteUserProduct(input: { where: { id: $userProductId } }) {
      userProduct {
        id
      }
    }
  }
`;

export default {
  name: 'LicenseList',
  data: () => ({
    confirmdialogUserdelete: false,
    licenseSearch: '',
    loading: false,
    subscriptionName: '',
    LearnerName: '',
    UserProductId: '',
    totalLicense: 0,
    data: '',
    alert: {
      type: 'success',
      show: false,
      text: ''
    },
    users: [],
    userLicenses: [],
    resposeUser: [],
    roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER'
    },
    headers: [
      { text: 'License#', value: 'id' },
      { text: 'LName', value: 'firstName' },
      { text: 'Action', value: 'action', sortable: false }
    ]
  }),
  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating'
    ]),
    ...mapGetters('course', ['getOrgPath']),
    ...mapGetters('organization', ['getOrgLicensePath']),
    computedHeaders() {
      if (this.IsORGViewer)
        return this.headers.filter(t => t.value != 'action');
      else return this.headers;
    },
    IsDementiaAdmin() {
      if (
        this.getUser?.user?.role?.name === this.roles.DEMENTIA_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsCMSAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.CMS_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGViewer() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_VIEWER) {
        return true;
      } else {
        return false;
      }
    },
    breadcrumbs() {
      if (this.getOrgPath)
        return [{ text: 'Back', href: `#${this.getOrgPath}` }];

      if (this.getOrgLicensePath)
        return [{ text: 'Back', href: `#${this.getOrgLicensePath}` }];

      return [
        { text: 'Back', href: `#/organisations/${this.orgId}` }
      ];
    },
    subId() {
      return this.$route.params.subscriptionId;
    },
    orgId() {
      return this.$route.params.orgId;
    }
  },
  methods: {
    deleteUser: function(userProductId) {
      this.$apollo.mutate({
        mutation: DELETE_USERPRODUCT_MUTATION,
        variables: {
          userProductId: userProductId
        },
        update: () => {
          this.$apollo.queries.licenseUsers.refresh();

          this.alert.show = true;
          this.alert.text = 'Successfully Deleted';
          this.alert.type = 'success';
          this.editName = false;
          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        }
      });
      this.confirmdialogUserdelete = false;
    },

    LoadConfirmDialogForDeleteUser(name, userProduct) {
      this.LearnerName = name;
      this.UserProductId = userProduct;
      this.confirmdialogUserdelete = true;
    },

    formatUserDetails(users) {
      let user1 = [];
      let user2 = [];
      let user3 = [];
      let user4 = [];
      let firstArrayIndex = 0;
      let secondArrayIndex = 1;
      let thirdArrayIndex = 2;
      let fourthArrayIndex = 3;
      let userCount = users.length;
      for (let i = 0; i < this.totalLicense; i++) {
        if (users[i] !== null) {
          if (i === firstArrayIndex) {
            firstArrayIndex = firstArrayIndex + 4;
            if (i < userCount) {
              user1.push({
                id: i + 1,
                firstName:
                  users[i]?.firstName + ' ' + users[i]?.lastName,
                userProduct: users[i].userProduct
              });
            } else {
              user1.push({
                id: i + 1,
                firstName: 'Available',
                action: null
              });
            }
          }
          if (i === secondArrayIndex) {
            secondArrayIndex = secondArrayIndex + 4;
            if (i < userCount) {
              user2.push({
                id: i + 1,
                firstName:
                  users[i]?.firstName + ' ' + users[i]?.lastName,
                userProduct: users[i].userProduct
              });
            } else {
              user2.push({
                id: i + 1,
                firstName: 'Available',
                action: null
              });
            }
          }
          if (i === thirdArrayIndex) {
            thirdArrayIndex = thirdArrayIndex + 4;
            if (i < userCount) {
              user3.push({
                id: i + 1,
                firstName:
                  users[i]?.firstName + ' ' + users[i]?.lastName,
                userProduct: users[i].userProduct
              });
            } else {
              user3.push({
                id: i + 1,
                firstName: 'Available',
                action: null
              });
            }
          }
          if (i === fourthArrayIndex) {
            fourthArrayIndex = fourthArrayIndex + 4;
            if (i < userCount) {
              user4.push({
                id: i + 1,
                firstName:
                  users[i]?.firstName + ' ' + users[i]?.lastName,
                userProduct: users[i].userProduct
              });
            } else {
              user4.push({
                id: i + 1,
                firstName: 'Available',
                action: null
              });
            }
          }
        }
      }
      let userList = [
        { userDetail: user1 },
        { userDetail: user2 },
        { userDetail: user3 },
        { userDetail: user4 }
      ];
      this.userLicenses = userList;
      this.loading = false;
      return userList;
    }
  },
  apollo: {
    totalLicenseCount() {
      this.loading = true;
      return {
        query: USER_TOTALLICENSEQUERY,
        variables: {
          where: {
            product: this.subId,
            org: this.orgId
          }
        },
        fetchPolicy: 'network-only',
        update: data => {
          this.totalLicense = data.orgProducts[0].licenseCount;
          this.subscriptionName = data.orgProducts[0].product.name;
          this.formatUserDetails(this.resposeUser);
          return data;
        },
        error() {
          this.loading = false;
        }
      };
    },
    licenseUsers() {
      return {
        query: USER_LICENSEQUERY,
        variables: {
          where: {
            product: this.subId,
            org: this.orgId
          }
        },
        fetchPolicy: 'network-only',
        update: data => {
          //Get organization details by id and bind the form
          let users = [];
          data.userProducts.forEach(usrPdct => {
            if (usrPdct.user !== null) {
              users.push({
                firstName: usrPdct.user.firstName,
                lastName: usrPdct.user.lastName,
                userProduct: usrPdct.id
              });
            }
          });
          this.resposeUser = users;
          return this.formatUserDetails(users);
        }
      };
    }
  }
};
</script>
