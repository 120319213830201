import ComponentContentListRichText from './RichText';
import ComponentContentListPicture from './Picture';
import ComponentContentListVideo from './Video';
import ComponentContentListHeading from './Heading';

export const ContentTypes = {
  ComponentContentListRichText,
  ComponentContentListPicture,
  ComponentContentListVideo,
  ComponentContentListHeading
};

export const ContentHeaderTypes = {
  ComponentContentListPicture,
  ComponentContentListVideo
};

export const ContentListPicture = {
  ComponentContentListPicture
};

/*
 * Turn the list of Types into a list of spread
 * notations ("...ComponentQuizDragDrop") for including
 * in query body.
 */
export const ContentFragmentNames = Object.keys(ContentTypes)
  .map(k => `...${k}`)
  .join(' ');

export const ContentFragmentImage = Object.keys(ContentListPicture)
  .map(k => `...${k}`)
  .join(' ');

/*
 * Turn the gql fragments back into string for including
 * in query since you can't pass array of GraphQL AST as
 * compiled by gql tagged template literal.
 */
export const ContentFragmentStrings = Object.values(ContentTypes)
  .map(q => q?.gqlQueryFragment?.loc?.source?.body)
  .filter(q => !!q)
  .join(' ');

export default {
  types: ContentTypes,
  headerTypes: ContentHeaderTypes,
  names: ContentFragmentNames,
  strings: ContentFragmentStrings,
  images: ContentFragmentImage
};
