<template>
  <div>
    <v-container fluid class="grey lighten-5">
      <v-alert
        dense
        text
        :type="alert.type"
        :value="alert.show"
        transition="slide-y-transition"
        >{{ alert.text }}</v-alert
      >
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <h1>Modules</h1>
        </v-col>
      </v-row>
    </v-container>
    <div class="page-sub-sec">
      <v-text-field
        v-model="searchCourse"
        append-icon="mdi-magnify"
        label="Search"
        outlined
        dense
        hide-details
        class="shrink"
        @input="modulesData"
      />
      <v-btn
        v-if="IsCMSAdmin"
        color="primary"
        @click="showNewDialog = true"
      >
        <v-icon>mdi-plus</v-icon> New Module</v-btn
      >
    </div>
    <v-data-table
      :headers="computedCourseHeaders"
      :items="modules"
      :search="searchCourse"
      class="dark--text"
      :no-data-text="`No module available`"
      :hide-default-footer="totalCourses < 20 ? true : false"
      :loading="loading"
      loading-text="Loading... Please wait"
      :page-count="pageSize"
      :page="page"
      :options.sync="options"
      :server-items-length="totalCourses"
      :footer-props="{
        'items-per-page-options': [20, 30, 40, 50, 100, 500]
      }"
    >
      <template
        v-for="h in computedCourseHeaders"
        v-slot:[`header.${h.value}`]="{ header }"
      >
        <v-tooltip
          v-if="headerTooltips[h.value]"
          :key="h.value"
          bottom
        >
          <template v-slot:activator="{ on }">
            <span v-on="on"
              >{{ header.text
              }}<v-icon class="pl-1" color="#113247"
                >mdi-information</v-icon
              ></span
            >
          </template>
          <span>{{ headerTooltips[header.value] }}</span>
        </v-tooltip>
        <span v-else :key="h.value">{{ header.text }}</span>
      </template>
      <template v-slot:[`item.title`]="{ item }">
        <router-link :to="`/modules/${item.id}`" class="blue--text">{{
          item.title
        }}</router-link>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <router-link :to="`/modules/${item.id}`" class="blue--text">{{
          item.id
        }}</router-link>
      </template>
      <template v-slot:[`item.lessons`]="{ item }">
        {{ item.lessons }}
      </template>
      <template v-slot:[`item.activities`]="{ item }">
        <span>{{ item.activities }}</span>
      </template>
      <template v-slot:[`item.course`]="{ item }">
        <span>{{ item.course }}</span>
      </template>
      <template v-slot:[`item.isPaid`]="{ item }">
        <v-btn v-if="!item.isPaid" color="green" icon small
          ><v-icon color="#757575" title="Enable"
            >mdi-currency-usd-off</v-icon
          ></v-btn
        >
        <v-btn v-if="item.isPaid" color="red" icon small
          ><v-icon color="#757575" title="Enable"
            >mdi-currency-usd</v-icon
          ></v-btn
        >
        <span> {{ !item.isPaid ? ' Free' : ' Paid' }}</span>
      </template>
      <template v-slot:[`item.isDisabled`]="{ item }">
        <v-btn
          v-if="!item.isDisabled && IsCMSAdmin"
          color="green"
          icon
          small
          @click="enableOrDisableCourse(item.id, true)"
          ><v-icon title="Enable">mdi-eye</v-icon></v-btn
        >
        <v-btn
          v-if="item.isDisabled && IsCMSAdmin"
          color="red"
          icon
          small
          @click="enableOrDisableCourse(item.id, false)"
          ><v-icon title="Enable">mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          v-if="!item.isDisabled && !IsCMSAdmin"
          color="grey"
          icon
          small
          disabled
          ><v-icon title="Enable">mdi-eye</v-icon></v-btn
        >
        <v-btn
          v-if="item.isDisabled && !IsCMSAdmin"
          color="grey"
          icon
          small
          disabled
          ><v-icon title="Enable">mdi-eye-off</v-icon></v-btn
        >
        {{ !item.isDisabled ? ' Visible' : ' Hidden' }}
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="lessons-actions-wrap">
          <v-btn
            icon
            small
            @click="LoadDialogForModuleDuplication(item)"
            ><v-icon title="copy">mdi-content-copy</v-icon></v-btn
          >
          <!-- <v-btn
            v-if="item.isDisabled"
            color="green"
            icon
            small
            @click="enableOrDisableCourse(item.id, false)"
            ><v-icon title="Enable">mdi-eye</v-icon></v-btn
          >
          <v-btn
            v-else
            color="orange"
            icon
            small
            @click="enableOrDisableCourse(item.id, true)"
            ><v-icon title="Disable">mdi-eye-off</v-icon></v-btn
          > -->

          <v-btn
            v-if="hideDelete == 'false'"
            icon
            color="red"
            class="float-right"
            small
            @click="LoadConfirmDialogForDeleteCourse(item.id)"
          >
            <v-icon title="Delete">mdi-trash-can-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="showNewDialog" persistent max-width="600px">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title>
            <span class="headline">New Module</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="newCourseName"
              :counter="50"
              :rules="nameRules"
              label="Name*"
              required
            />
          </v-card-text>
          <v-card-actions>
            <small class="info--text"
              >*indicates required field</small
            >
            <v-spacer />
            <v-btn depressed text @click="showNewDialog = false">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="validateCourse">
              New module
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmdialogCoursedelete"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">Are you sure</v-card-title>
        <v-card-text>Remove Module?</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="confirmdialogCoursedelete = false"
            >Cancel</v-btn
          >
          <v-btn
            color="red darken-1"
            text
            @click="deleteCourse(coursedeleteId)"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCopyDialog" persistent max-width="600px">
      <v-card>
        <v-form ref="form" v-model="copyvalid" lazy-validation>
          <v-card-title>
            <span class="headline">Duplicate Module</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="copyModuleName"
              :counter="50"
              :rules="nameRules"
              label="Name*"
              required
            />
          </v-card-text>
          <v-card-text>
            <v-autocomplete
              v-model="copyToCourseId"
              :items="primaryCourseList"
              label="Select Course*"
              :rules="[v => !!v || 'Course is required']"
              dense
              required
            />
          </v-card-text>
          <v-card-actions>
            <small class="info--text"
              >Please ensure each mandatory field marked with an
              asterisk * is completed</small
            >
            <v-spacer />
            <v-btn depressed text @click="showCopyDialog = false">
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              :loading="copyLoading"
              @click="validateDuplicateModule()"
            >
              Duplicate Module
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';

const ENABLE_DISABLE_COURSE_MUTATION = gql`
  mutation($courseId: ID!, $isDisabled: Boolean!) {
    updateCourse(
      input: {
        where: { id: $courseId }
        data: { isDisabled: $isDisabled }
      }
    ) {
      course {
        title
        id
        isDisabled
        lessons {
          title
          activities {
            id
          }
        }
      }
    }
  }
`;

// const COURSES_QUERY = gql`
//   query {
//     courses {
//       title
//       id
//       isDisabled
//       lessons {
//         title
//         activities {
//           id
//         }
//       }
//     }
//   }
// `;

const COURSES_QUERY_NEW = gql`
  query(
    $pageNumber: Int!
    $pageSize: Int!
    $search: String!
    $sortBy: String
    $sortOrder: String
  ) {
    fetchCourseList(
      pageNumber: $pageNumber
      pageSize: $pageSize
      search: $search
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      isSuccess
      statusCode
      statusMessage
      data {
        id
        title
        lessons
        activities
        course
        isPaid
        isDisabled
      }
      total
    }
  }
`;

const CREATE_COURSE_MUTATION = gql`
  mutation($newCourseName: String!) {
    createCourse(input: { data: { title: $newCourseName , public: ${true}} }) {
      course {
        title
        id
        isDisabled
        lessons {
          title
        }
      }
    }
  }
`;
const DELETE_COURSE_MUTATION = gql`
  mutation($courseId: ID!) {
    deleteCourse(input: { where: { id: $courseId } }) {
      course {
        id
      }
    }
  }
`;

const MODULE_DUPLICATION_DEEPCLONE_MUTATION = gql`
  mutation($moduleID: ID!, $moduleName: String!, $courseID: ID!) {
    deepCopyModule(
      moduleID: $moduleID
      moduleName: $moduleName
      courseID: $courseID
    ) {
      course {
        title
        id
        isDisabled
        lessons {
          title
          activities {
            id
          }
        }
      }
    }
  }
`;

// To populate primarycourse select
const ALL_PRIMARYCOURSE_QUERY = gql`
  query {
    primaryCourses {
      id
      Title
    }
  }
`;

export default {
  name: 'CourseList',
  data: () => ({
    alert: {
      type: 'success',
      show: false,
      text: ''
    },
    modules: [],
    pageSize: 20,
    page: 1,
    options: {},
    totalCourses: null,
    valid: true,
    nameRules: [
      v => !!v || 'Name is required',
      v =>
        (v && v.length <= 50) ||
        'Name must be less than 50 characters'
    ],
    roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER'
    },

    loading: false,
    coursedeleteId: '',
    confirmdialogCoursedelete: false,
    searchCourse: '',
    showNewDialog: false,
    newCourseName: '',
    copyvalid: true,
    copyModuleName: '',
    copyModuleId: 0,
    showCopyDialog: false,
    copyLoading: false,
    copyToCourseId: 0,
    hideDelete: process.env.VUE_APP_HIDEDELETE_STATUS,
    headers: [
      { text: 'Title', value: 'title' },
      { text: 'Module Id', value: 'id' },
      { text: 'Lessons', value: 'lessons' },
      { text: 'Activities', value: 'activities', sortable: false },
      { text: 'Course', value: 'course', sortable: false },
      { text: 'Payment', value: 'isPaid', sortable: false },
      { text: 'Visibility', value: 'isDisabled', sortable: false },
      { text: 'Action', value: 'action', sortable: false }
    ],
    headerTooltips: {
      isDisabled:
        'Whether or not this module is visible within the mobile app',
      isPaid:
        'Is this module available for free to users, or is it locked and requiring payment'
    }
  }),
  apollo: {
    // courses() {
    //   return {
    //     query: COURSES_QUERY_NEW,
    //     fetchPolicy: 'network-only',
    //     variables: {
    //       pageNumber: 0,
    //       pageSize: 500,
    //       search: ''
    //     },
    //     update: data => {
    //       let items = data.fetchCourseList.data;

    //       return items;
    //     },
    //     skip() {
    //       return !this.isAuthenticated;
    //     }
    //   };
    // },
    primaryCourses() {
      return {
        query: ALL_PRIMARYCOURSE_QUERY
      };
    }
  },
  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating'
    ]),
    IsDementiaAdmin() {
      if (
        this.getUser?.user?.role?.name === this.roles.DEMENTIA_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsCMSAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.CMS_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGViewer() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_VIEWER) {
        return true;
      } else {
        return false;
      }
    },
    computedCourseHeaders() {
      if (!this.IsCMSAdmin)
        return this.headers.filter(t => t.text != 'Action');
      else return this.headers;
    },
    primaryCourseList() {
      return this.primaryCourses?.map(mod => ({
        text: mod.Title,
        value: mod.id
      }));
    }
  },
  watch: {
    options: {
      handler() {
        this.modulesData();
      }
    },
    deep: true
  },
  methods: {
    modulesData(pageNum = false) {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let sortDirection = null;
      let sort_var = null;
      let page_nuber = pageNum ? 0 : page ? page : this.page;
      this.loading = true;
      if (sortBy[0]) {
        sortDirection = sortDesc[0] ? 'desc' : 'asc';
        sort_var = sortBy[0];
      }
      this.$apollo
        .query({
          query: COURSES_QUERY_NEW,
          fetchPolicy: 'network-only',
          variables: {
            pageNumber: page_nuber,
            pageSize: itemsPerPage ? itemsPerPage : this.pageSize,
            search: this.searchCourse,
            sortBy: sort_var,
            sortOrder: sortDirection
          },
          skip() {
            return !this.isAuthenticated;
          }
        })
        .then(response => {
          let data = response.data.fetchCourseList.data;
          this.totalCourses = response.data.fetchCourseList.total;
          this.loading = false;
          this.modules = data;
        });
    },
    validateCourse() {
      if (!this.$refs.form.validate()) return;
      //if validation true call update user
      this.createNewCourse();
    },
    coursePaginationStatus() {
      if (this.courses && this.courses.length > 0) return false;
      else return true;
    },
    getActivitiesCount(lessons) {
      var totallength = 0;
      if (!lessons) return 0;

      lessons.forEach(l => {
        if (l.activities)
          totallength = totallength + l.activities.length;
      });
      return totallength;
    },
    enableOrDisableCourse(courseId, isDisabled) {
      this.$apollo.mutate({
        mutation: ENABLE_DISABLE_COURSE_MUTATION,
        variables: {
          courseId: courseId,
          isDisabled: isDisabled
        },
        update: (store, { data: { updateCourse } }) => {
          // Only update the store if there's a new course created
          if (!updateCourse.course) return;
          this.modulesData(true);
        }
      });
    },
    // enableOrDisableCourse(courseId, isDisabled) {
    //   this.$apollo.mutate({
    //     mutation: ENABLE_DISABLE_COURSE_MUTATION,
    //     variables: {
    //       courseId: courseId,
    //       isDisabled: isDisabled
    //     },
    //     update: (store, { data: { updateCourse } }) => {
    //       // Only update the store if there's a new course created
    //       if (!updateCourse.course) return;
    //       // Get the current store state
    //       const data = store.readQuery({
    //         query: COURSES_QUERY
    //       });
    //       // Push the new course onto the cache
    //       //data.courses.push(updateCourse.course);
    //       const _course = data.courses.find(
    //         link => link.id === courseId
    //       );
    //       _course.isDisabled = isDisabled;

    //       // Write the list back into the cache
    //       store.writeQuery({ query: COURSES_QUERY, data });
    //     }
    //   });
    // },
    LoadDialogForModuleDuplication(item) {
      this.copyModuleName = 'Copy of ' + item.title;
      this.copyModuleId = item.id;
      this.showCopyDialog = true;
      this.copyToCourseId = 0;
    },
    validateDuplicateModule() {
      if (!this.$refs.form.validate()) return;
      //form is valid so continue with duplication
      this.moduleDuplicationForPrimaryCourse();
    },
    moduleDuplicationForPrimaryCourse() {
      this.copyLoading = true;
      this.$apollo.mutate({
        mutation: MODULE_DUPLICATION_DEEPCLONE_MUTATION,
        variables: {
          moduleID: this.copyModuleId,
          moduleName: this.copyModuleName,
          courseID: this.copyToCourseId
        },
        update: (store, { data: { deepCopyModule } }) => {
          this.copyLoading = false;
          if (!deepCopyModule.course) return;
          this.modulesData(true);
          this.alert.show = true;
          this.alert.text = 'Successfully Added';
          this.alert.type = 'success';

          this.showCopyDialog = false;
          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        },
        error() {
          this.copyLoading = false;
          this.showCopyDialog = false;
        }
      });
    },

    createNewCourse() {
      if (!this.newCourseName) return;
      const newCourseName = this.newCourseName;
      this.newCourseName = '';
      this.$apollo.mutate({
        mutation: CREATE_COURSE_MUTATION,
        variables: {
          newCourseName
        },
        update: () => {
          // Only update the store if there's a new course created
          this.modulesData(true);
          // Get the current store state
          // const data = store.readQuery({
          //   query: COURSES_QUERY
          // });
          // Push the new course onto the cache
          // data.courses.push(createCourse.course);
          // // Write the list back into the cache
          // store.writeQuery({ query: COURSES_QUERY, data });
          this.alert.show = true;
          this.alert.text = 'Successfully Created';
          this.alert.type = 'success';

          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        }
      });

      this.showNewDialog = false;
    },
    LoadConfirmDialogForDeleteCourse(id) {
      this.coursedeleteId = id;
      this.confirmdialogCoursedelete = true;
    },
    deleteCourse: function(courseId) {
      this.$apollo.mutate({
        mutation: DELETE_COURSE_MUTATION,
        variables: {
          courseId: courseId
        },
        update: () => {
          this.modulesData(true);
          // Only update the store if there's a new course created
          // this.$apollo.queries.courses.refresh();
          // Get the current store state
          // const data = store.readQuery({
          //   query: COURSES_QUERY
          // });
          // Push the new course onto the cache
          // data.courses.push(createCourse.course);
          // // Write the list back into the cache
          // store.writeQuery({ query: COURSES_QUERY, data });
          this.alert.show = true;
          this.alert.text = 'Successfully Deleted';
          this.alert.type = 'success';

          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        }
      });
      this.confirmdialogCoursedelete = false;
    }
  }
};
</script>
