<template>
  <div>
    <v-card max-width="360" flat class="mx-auto mt-10 p-2 ">
      <v-card-text class="pa-0">
        <div v-if="loading">
          <div class="text-center">
            <v-progress-linear indeterminate />
          </div>
        </div>
        <v-card
          v-if="
            !isAuthenticated &&
              !forgotPassword &&
              !resetPassword &&
              !forgotPasswordMessage &&
              !mobilestoredisplay
          "
        >
          <div class="imageDiv">
            <img
              class="imageCard"
              max-height="70.85"
              max-width="256"
              src="../../images/DementiaLogo.png"
            />
          </div>
          <v-card-title class="loginCardTitle  ">Login</v-card-title>
          <div class="divCard">
            <v-form class="px-1">
              <v-text-field
                v-model="username"
                outlined
                placeholder="Username"
                class="userInput"
              />
              <v-text-field
                v-model="password"
                type="password"
                outlined
                placeholder="Password"
                class="userInput"
              />
            </v-form>
            <v-card-actions class="pa-0">
              <v-col>
                <v-row class="justify-center align-center">
                  <v-btn color="white" large @click="callAuthenticate"
                    >Login</v-btn
                  >
                </v-row>
                <v-row class="justify-center align-center mt-4">
                  <a
                    href="javascript:void(0)"
                    style="color: white ;text-decoration: none;font-size:15px"
                    @click="forgotPasswordClick"
                    >Forgot Password?</a
                  >
                </v-row>
              </v-col>
            </v-card-actions>
          </div>
          <div class="loginFailed pl-2">
            <span v-if="isLoginFailed" class="red--text "
              >Login failed!</span
            >
          </div>
        </v-card>
        <v-card v-if="isAuthenticated && getUser.user">
          <v-card-title class="headline"
            >Currently logged in as</v-card-title
          >
          <v-card-text
            ><drawer-user-item :user="getUser.user"
          /></v-card-text>
          <v-card-actions>
            <v-btn color="error" @click="logout">Logout</v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-if="!isAuthenticated && resetPassword">
          <div class="imageDiv">
            <img
              class="imageCard"
              max-height="70.85"
              max-width="256"
              src="../../images/DementiaLogo.png"
            />
          </div>
          <div class="divCard">
            <v-list-item>
              <v-list-item-content style="color:white">
                <v-list-item-title
                  >Create New Password</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-form ref="form" class="px-4">
              <v-text-field
                v-model="newPassword"
                type="password"
                :rules="passwordRules"
                placeholder="New Password"
                class="userInput"
              />
              <v-text-field
                v-model="confirmPassword"
                type="password"
                :rules="[
                  !!confirmPassword ||
                    'Please enter confirm password',
                  newPassword === confirmPassword ||
                    'The password confirmation does not match.'
                ]"
                placeholder="Confirm Password"
                class="userInput"
              />
            </v-form>

            <v-card-actions class="pa-4">
              <v-col>
                <v-row class="align-center">
                  <v-col cols="12" class="text-center">
                    <v-btn color="white" @click="resetPasswordSubmit"
                      >Submit</v-btn
                    >
                  </v-col>
                  <span v-if="isResetFailed" class="red--text">{{
                    resetFailedText
                  }}</span>
                </v-row>
                <v-row
                  class="justify-center align-center"
                  style="color: white ;text-decoration: none;font-size:15px"
                >
                  <span>Having trouble creating a password?</span>
                </v-row>
                <v-row class="justify-center align-center mt-1">
                  <span
                    style="color: white ;text-decoration: none;font-size:15px"
                  >
                    <a
                      href="https://dementialearning.org.au/technology/ask-annie/support/"
                      style="color: white ;font-size:15px"
                    >
                      Contact</a
                    >
                    us now.
                  </span>
                </v-row>
              </v-col>
            </v-card-actions>
          </div>
        </v-card>
        <v-card v-if="forgotPassword">
          <div class="resetPasswordContainer">
            <div class="imageDiv">
              <img
                class="imageCard"
                max-height="70.85"
                max-width="256"
                src="../../images/DementiaLogo.png"
              />
            </div>
            <v-card-title
              v-if="isResetFailed"
              class="resetPasswordHeading"
              >Expired Reset Link</v-card-title
            >
            <v-card-title v-else class="resetPasswordHeading"
              >Reset Password</v-card-title
            >
            <p
              v-if="isResetFailed"
              class="resetPasswordHeading pa-0 ml-4"
            >
              The reset link you clicked has expired.Please enter your
              details below to resend the link
            </p>
            <v-form class="px-4">
              <v-text-field
                v-if="isResetFailed"
                v-model="emailAddress"
                label="Registered email ID"
                class="userInput"
              />
              <v-text-field
                v-else
                v-model="emailAddress"
                label="Email Address"
                class="userInput"
              />
            </v-form>
            <v-card-actions class="pa-4">
              <v-layout justify-space-between align-center>
                <v-btn color="white" large @click="sendResetPassword"
                  >Submit</v-btn
                >
                <v-btn color="white" large @click="cancelProcess"
                  >Cancel</v-btn
                >
              </v-layout>
            </v-card-actions>

            <v-col v-if="isResetFailed">
              <v-row
                class="justify-center align-center"
                style="color: white ;text-decoration: none;font-size:15px"
              >
                <span>Need some help? </span>
                <span
                  style="color: white ;text-decoration: none;font-size:15px"
                >
                  <a
                    href="https://dementialearning.org.au/technology/ask-annie/support/"
                    style="color: white ;font-size:15px"
                  >
                    Contact</a
                  >
                  us now.
                </span>
              </v-row>
              <v-row class="justify-center align-center mt-1">
                <span v-if="forgotPasswordFailed" class="red--text"
                  >The user is not Registered!</span
                >
              </v-row>
            </v-col>
            <v-col v-else>
              <v-row
                class="justify-center align-center"
                style="color: white ;text-decoration: none;font-size:15px"
              >
                <span>Haven't received an email?</span>
              </v-row>
              <v-row class="justify-center align-center mt-1">
                <span
                  style="color: white ;text-decoration: none;font-size:15px"
                >
                  <a
                    href="https://dementialearning.org.au/technology/ask-annie/support/"
                    style="color: white ;font-size:15px"
                  >
                    Contact</a
                  >
                  us now.
                </span>
              </v-row>
              <v-row class="justify-center align-center mt-1">
                <span v-if="forgotPasswordFailed" class="red--text"
                  >The user is not Registered!</span
                >
              </v-row>
            </v-col>
          </div>
        </v-card>
        <v-card v-if="forgotPasswordMessage">
          <v-card-text>
            <h3 class="success--text">
              Reset password link sent to {{ emailAddress }}
            </h3>
          </v-card-text>
          <v-divider />

          <v-row class="align-center">
            <v-col cols="12" class="text-center">
              <span
                >Haven't received an email?
                <a
                  href="https://dementialearning.org.au/technology/ask-annie/support/"
                >
                  Contact</a
                >
                us now.</span
              >
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
    </v-card>
    <v-card max-width="360" flat class="mx-auto mt-10 p-2">
      <v-card v-if="!isAuthenticated && mobilestoredisplay">
        <div class="resetPasswordContainer">
          <div class="imageDiv">
            <img
              class="imageCard"
              max-height="70.85"
              max-width="256"
              src="../../images/DementiaLogo.png"
            />
          </div>
          <v-container fluid>
            <v-layout justify-center align-center>
              <v-flex shrink>
                <div>
                  <h3
                    class="resetPasswordHeading pa-1"
                    style="font-size:18px"
                  >
                    You’re almost there!
                  </h3>
                  <v-card-text class="pa-1">
                    <v-col>
                      <v-row>
                        <span style="color: white ;font-size:18px"
                          >Your password has been set.
                        </span>
                      </v-row>
                      <v-row>
                        <span
                          style="color: white ;font-size:18px"
                          class="mt-3"
                          >Log in with your email address and password
                          in the Ask Annie app on your iOS or Android
                          mobile device.</span
                        >
                      </v-row>
                    </v-col>
                  </v-card-text>

                  <v-card-title
                    class="resetPasswordHeading text-center justify-center pa-0 "
                    >Download the app now!</v-card-title
                  >
                  <v-card-actions class="pa-0">
                    <v-row class="align-center ml-1">
                      <v-col class="text-center">
                        <span
                          ><a :href="androidstoreurl">
                            <v-img
                              max-height="90"
                              max-width="150"
                              src="../../images/GoogleAppStore.png"
                            />
                          </a>
                        </span>
                      </v-col>
                      <v-col class="text-center">
                        <span
                          ><a :href="iosstoreurl"
                            ><v-img
                              max-height="75"
                              max-width="128"
                              src="../../images/AppStoreImage.png"/></a
                        ></span>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                  <v-row
                    class="justify-center align-center mt-2"
                    style="color: white ;text-decoration: none;font-size:16px"
                  >
                    <span
                      >Need some help?&nbsp;<a
                        href="https://dementialearning.org.au/technology/ask-annie/support/"
                        style="color: white ;font-size:16px"
                      >
                        Contact</a
                      >

                      us now.</span
                    >
                  </v-row>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import AuthTypes from './AuthTypes';
import DrawerUserItem from './DrawerUserItem';
import { mapGetters, mapActions } from 'vuex';
//import someImage from '../assets/someImage.png'

const FORGOT_PASSWORD = gql`
  mutation($email: String!, $deviceType: String!) {
    customforgotPassword(email: $email, deviceType: $deviceType) {
      ok
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation(
    $password: String!
    $passwordConfirmation: String!
    $code: String!
    $date: String!
  ) {
    customResetPassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      code: $code
      date: $date
    ) {
      jwt
    }
  }
`;

export default {
  name: 'DrawerUserList',
  components: { DrawerUserItem },
  props: {
    loginRedirect: {
      type: String,
      default: 'Dashboard'
    }
  },
  data: () => ({
    roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER'
    },
    username: '',
    password: '',
    forgotPassword: false,
    resetPassword: false,
    mobilestoredisplay: false,
    forgotPasswordFailed: false,
    emailAddress: '',
    userString: '',
    devicetype: '',
    loading: false,
    androidstoreurl: process.env.VUE_APP_ANDROID_STORE,
    iosstoreurl: process.env.VUE_APP_IOS_STORE,
    mobiledeeplink: process.env.VUE_APP_MOBILE_DEEPLINK,
    newPassword: '',
    confirmPassword: '',
    passwordRules: [
      value => !!value || 'Please enter a password',
      value => !!value || 'Please type password.',
      value =>
        (value && /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(value)) ||
        'Password must be a combination of at least one uppercase letter,one lowercase letter,one alphanumeric value',
      value => (value && value.length >= 6) || 'Minimum 6 characters'
    ],
    forgotPasswordMessage: false,
    date: '',
    isResetFailed: false,
    resetFailedText: ''
  }),
  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating',
      'isLoginFailed'
    ])
  },
  created() {
    this.init();
    if (this.$route.query.date) {
      let date = this.$route.query.date;
      let userString = this.$route.query.code;
      let devicetype = this.$route.query.deviceType;
      if (date && userString && devicetype) {
        this.userString = userString;
        this.date = date;
        this.devicetype = devicetype;
        this.resetPassword = true;
      } else {
        this.resetPassword = false;
      }
    }
  },
  methods: {
    ...mapActions('auth', [
      AuthTypes.Actions.LOGIN,
      AuthTypes.Actions.LOGOUT,
      AuthTypes.Actions.CANCEL_LOGIN,
      AuthTypes.Actions.AUTHENTICATE,
      AuthTypes.Actions.INIT
    ]),
    callAuthenticate() {
      this.loading = true;
      this.authenticate({
        apollo: this.$apollo,
        username: this.username.trim(),
        password: this.password
      }).then(() => {
        this.loading = false;

        if (this.isLoginFailed) {
          console.log(
            'Is Login Failed:' +
              JSON.stringify(this.isLoginFailed) +
              ' Incorrect username or password'
          );
          return;
        }

        if (this.isAuthenticated) {
          console.log(
            'Is authenticated:' + JSON.stringify(this.isAuthenticated)
          );
          if (
            this.getUser?.user?.role?.name ===
            this.roles.DEMENTIA_ADMIN
          ) {
            this.$router.push({ path: '/users' });
          } else if (
            this.getUser?.user?.role?.name === this.roles.CMS_ADMIN
          ) {
            this.$router.push({ path: '/subscriptions' });
          } else if (
            this.getUser?.user?.role?.name === this.roles.ORG_ADMIN ||
            this.getUser?.user?.role?.name === this.roles.ORG_VIEWER
          ) {
            //get the org id
            // var member = this.getUser?.user?.memberOf;
            // this.$router.push({
            //   path: '/organisations/' + member[0].id
            // });
            this.$router.push({
              path: '/users'
            });
          }
        } else {
          console.log(
            'Is unauthenticated:' +
              JSON.stringify(this.isUnauthenticated)
          );

          //this.$router.push({ path: '/login' });
        }
      });
    },

    sendResetPassword() {
      //step 1
      if (!this.emailAddress) return;
      this.$apollo
        .mutate({
          mutation: FORGOT_PASSWORD,
          variables: {
            email: this.emailAddress,
            deviceType: 'LMS'
          },
          update: () => {
            this.forgotPassword = false;
            this.forgotPasswordMessage = true;
            this.forgotPasswordFailed = false;
          }
        })
        .catch(error => {
          error = 'This email does not exist!';
          console.log(error);
          this.forgotPasswordFailed = true;
        });
    },
    resetPasswordSubmit() {
      if (!this.$refs.form.validate()) return;
      //step 2
      this.$apollo
        .mutate({
          mutation: RESET_PASSWORD,
          variables: {
            password: this.newPassword,
            passwordConfirmation: this.confirmPassword,
            code: this.userString,
            date: this.date
          },
          update: () => {
            this.resetPassword = false;
            this.forgotPassword = false;
            this.forgotPasswordMessage = false;
            this.isResetFailed = false;
            if (this.devicetype === 'LMS') {
              this.$router.push({ path: '/login' });
            } else {
              this.mobilestoredisplay = true;
            }
          }
        })
        .catch(e => {
          this.isResetFailed = true;
          this.forgotPassword = true;
          this.resetPassword = false;
          this.forgotPasswordMessage = false;
          this.resetFailedText = JSON.stringify(
            e.graphQLErrors[0].extensions.exception.data.message[0]
              .messages[0].message
          ).replace(/['"]+/g, '');
        });
    },
    forgotPasswordClick() {
      this.forgotPassword = true;
    },
    cancelProcess() {
      this.forgotPassword = false;
      this.forgotPasswordMessage = false;
      this.forgotPasswordFailed = false;
      this.mobilestoredisplay = false;
      this.emailAddress = '';
    }
  }
};
</script>
<style lang="scss" scoped>
.imageCard {
  background-color: #113247;
  margin-left: 10%;
  margin-top: 15%;
  margin-bottom: 5%;
}
.loginCardTitle,
.resetPasswordHeading,
.newPasswordHeading {
  color: white;
  background-color: #113247;
}
.divCard,
.imageDiv,
.resetPasswordContainer {
  background-color: #113247;
}
.loginFailed {
  background-color: #113247;
  text-align: center;
  padding: 10px;
}
</style>
