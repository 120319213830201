var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "info-head" },
        [
          _vm.isAuthenticated && _vm.getUser.user
            ? _c(
                "div",
                [
                  _c("drawer-user-item", { attrs: { user: _vm.getUser.user } }),
                  _c(
                    "v-menu",
                    {
                      attrs: { right: "", bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "ml-2",
                                        attrs: { icon: "" }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      { staticClass: "light--text" },
                                      [_vm._v("mdi-dots-vertical")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        501159789
                      )
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "light" },
                        [
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  _vm.lmsaboutdialogue = true
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "primary--text" },
                                [_vm._v("About")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            { on: { click: _vm.callLogout } },
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "primary--text" },
                                [_vm._v("Logout")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isUnauthenticated
            ? _c(
                "router-link",
                {
                  staticClass:
                    "light--text v-btn v-btn--flat v-btn--text theme--light elevation-0 v-size--default",
                  attrs: { to: "/login" }
                },
                [
                  _c("v-icon", { staticClass: "mr-2 light--text" }, [
                    _vm._v("mdi-account-plus")
                  ]),
                  _vm._v(" Login ")
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "450" },
          model: {
            value: _vm.logoutconfirmdialog,
            callback: function($$v) {
              _vm.logoutconfirmdialog = $$v
            },
            expression: "logoutconfirmdialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Please confirm you wish to logout.")
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.logoutconfirmdialog = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: { click: _vm.confirmLogout }
                    },
                    [_vm._v("Confirm")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "400" },
          model: {
            value: _vm.lmsaboutdialogue,
            callback: function($$v) {
              _vm.lmsaboutdialogue = $$v
            },
            expression: "lmsaboutdialogue"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "mx-auto", attrs: { "max-width": "400" } },
            [
              _c("v-card-title", [_vm._v(" LMS ")]),
              _c("v-card-text", [
                true
                  ? _c("div", [
                      _c("p", { staticClass: "text--primary" }, [
                        _vm._v(
                          " Environment: " + _vm._s(_vm.env.VUE_APP_ENV) + " "
                        )
                      ]),
                      _c("p", { staticClass: "text--primary" }, [
                        _vm._v(
                          " Build: " + _vm._s(_vm.env.VUE_APP_BUILD_DATE) + " "
                        )
                      ])
                    ])
                  : _vm._e()
              ]),
              _c(
                "v-layout",
                { attrs: { "justify-center": "" } },
                [
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              _vm.lmsaboutdialogue = false
                            }
                          }
                        },
                        [_vm._v("OK")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }