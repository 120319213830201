<template>
  <v-app>
    <v-navigation-drawer
      v-if="hasChrome && isAuthenticated && !this.$route.meta.layout"
      v-model="drawerState"
      app
      class="primary side-drawer"
    >
      <v-list class="secondary--text pa-0">
        <v-list-item link :to="`/`">
          <v-list-item-content class="logo-sec">
            <img src="./images/logo.svg" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="IsDementiaAdmin || IsORGAdmin || IsORGViewer"
          link
          :to="`/users/`"
        >
          <v-list-item-action>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              >User Management
              <span
                v-if="IsORGAdmin && usersCount"
                class="e-badge  pr-1 pl-1 e-badge-success e-badge-notification"
                >{{ usersCount }}</span
              ></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="IsORGAdmin || IsORGViewer"
          link
          :to="`/organisations/${OrgInfoId}`"
        >
          <v-list-item-action>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="IsDementiaAdmin"
          link
          :to="`/organisations/`"
        >
          <v-list-item-action>
            <v-icon>mdi-office-building</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Organisations</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="IsCMSAdmin || IsDementiaAdmin"
          link
          :to="`/subscriptions/`"
        >
          <v-list-item-action>
            <v-img
              max-height="30"
              max-width="25"
              src="./images/SubscriptionIcon.png"
            />
            <!-- <v-icon>mdi-office-building</v-icon> -->
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Subscriptions</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="IsCMSAdmin || IsDementiaAdmin"
          link
          :to="`/courses/`"
        >
          <v-list-item-action>
            <v-icon>mdi-notebook-multiple</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Courses</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="IsCMSAdmin || IsDementiaAdmin"
          link
          :to="`/modules/`"
        >
          <v-list-item-action>
            <v-icon>mdi-book-open-page-variant</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Modules</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- v-if="IsDementiaAdmin || IsORGAdmin" -->
      </v-list>
      <v-spacer />

      <!-- <drawer-user-list /> -->
    </v-navigation-drawer>
    <v-app-bar
      v-if="hasChrome && !this.$route.meta.layout"
      app
      :class="
        isAuthenticated
          ? 'primary light--text'
          : 'container light--text'
      "
    >
      <v-app-bar-nav-icon
        v-if="hasChrome && isAuthenticated"
        class="light--text"
        @click="toggleDrawer"
      />
      <v-toolbar-title v-if="hasChrome && isAuthenticated"
        >LMS</v-toolbar-title
      >
      <v-spacer v-if="hasChrome && isAuthenticated" />
      <drawer-user-list />
    </v-app-bar>
    <v-main class="grey lighten-5 primary--text">
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { DrawerUserList } from './modules';
import { mapGetters } from 'vuex';
import gql from 'graphql-tag';

const USER_QUERY_COUNT = gql`
  query GetAwaitingLearnersTotalCount {
    getAwaitingLearnersTotalCount {
      isSuccess
      data
    }
  }
`;

export default {
  name: 'App',
  components: { DrawerUserList },

  data: () => ({
    drawerState: null,
    hasChrome: true,
    user: {},
    Loginuser: {},
    roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER'
    }
  }),
  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated'
    ]),

    IsDementiaAdmin() {
      if (
        this.getUser?.user?.role?.name === this.roles.DEMENTIA_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsCMSAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.CMS_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGViewer() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_VIEWER) {
        return true;
      } else {
        return false;
      }
    },
    OrgInfoId() {
      if (
        this.getUser?.user?.role?.name === this.roles.ORG_ADMIN ||
        this.getUser?.user?.role?.name === this.roles.ORG_VIEWER
      ) {
        var member = this.getUser.user?.memberOf;
        if (member[0]?.id) {
          return member[0].id;
        } else {
          return 0;
        }
      } else return 0;
    }
  },
  apollo: {
    usersCount() {
      return {
        query: USER_QUERY_COUNT,
        fetchPolicy: 'cache-and-network',
        update: data => {
          return data?.getAwaitingLearnersTotalCount?.data;
        },
        skip() {
          return !this.isAuthenticated || !this.IsORGAdmin;
        }
      };
    }
  },
  //mounted() {
  // this.user = JSON.parse(localStorage.getItem('userdata'));
  // this.roles = roles;
  // console.log(roles);
  // },

  methods: {
    toggleDrawer() {
      this.drawerState = !this.drawerState;
    }
  }
};
</script>
<style scoped>
.container {
  background-color: #fafafa !important;
  box-shadow: none !important;
}
</style>
