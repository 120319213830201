import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { AuthStore as auth } from '../modules';
import { CourseStore as course } from './modules/course/index';
import { OrganizationStore as organization } from './modules/organization/index';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: auth,
    course: course,
    organization: organization
  },
  plugins: [createPersistedState()]
});
