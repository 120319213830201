<template>
  <div>
    <div v-if="this.$apollo.loading">
      <div class="text-center">
        <v-progress-linear indeterminate />
      </div>
    </div>
    <v-container fluid class="grey lighten-5">
      <v-alert
        dense
        text
        :type="alert.type"
        :value="alert.show"
        transition="slide-y-transition"
        >{{ alert.text }}</v-alert
      >
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
          <h1>
            Activity:
            <span class="success--text">{{ activity.title }}</span>
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="page-sub-sec">
            <v-chip v-if="activity.quiz" class="ma-2">
              Total Number of Quizzes: {{ activity.quiz.length }}
            </v-chip>
            <v-chip v-else class="ma-2">
              Total Number of Quizzes: 0
            </v-chip>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card outlined>
            <v-card-title><h2>Quiz</h2></v-card-title>
            <v-card-text>
              <div class="row">
                <v-col cols="12" sm="6">
                  <!--.
            | Component repeats for each quiz in the activity,
            | current restricted to one or zero. The component
            | is loaded by the name of the Strapi component e.g.
            | "ComponentQuizDragDrop" and will fail if it
            | unavailable.
            |
            | All components are loaded from QuizTypes import.
            |
            | "ref" property makes them available to extract info
            | from when the quiz is saved.
            |
            | :key is combination of type and id since Strapi component
            | ids increment per component type.
            `-->
                  <component
                    :is="quiz.__typename"
                    v-for="(quiz, index) in activity.quiz"
                    :key="
                      quiz.__typename +
                        (quiz.id ? quiz.id : Math.random())
                    "
                    ref="quizzes"
                    :quiz="quiz"
                    :index="index + 1 + '. '"
                    @removethiscomponent="removeComponent"
                  />
                </v-col>
              </div>
              <!-- <div v-if="!activity.quiz || activity.quiz.length < 1"> -->
              <div v-if="IsCMSAdmin">
                <v-btn
                  v-for="(quizType, name) in quizTypes"
                  :key="name"
                  class="mr-2"
                  @click="addQuiz(quizType.name)"
                >
                  <v-icon class="mr-2">{{ quizType.icon }}</v-icon>
                  {{ quizType.text }}
                </v-btn>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                v-if="IsCMSAdmin"
                color="primary"
                x-large
                @click="saveQuiz"
                >Save</v-btn
              >
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="quizvalidationalert"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-text>
          <br />
          <br />
          <br />
          <h4>Question and answers are required fields</h4>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="quizvalidationalert = false"
            >Got it</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import AppRichTextEditor from '@/components/editors/AppRichTextEditor';
import Quiz from '@/components/quiz-edit';
import Content from '@/components/content-edit';
import { mapGetters } from 'vuex';

const LESSON_QUERY = gql`
  query($lessonId: ID!) {
    lesson(id: $lessonId) {
      id
      title
    }
  }
`;

/*
 * Use dynamic lists of query type fragments to query
 * activities.
 */
const ACTIVITY_QUERY = gql`
  query($activityId: ID!, $lessonId: ID!, $courseId: ID!) {
    activity(id: $activityId) {
      id
      title
      lessons (where: { id: $lessonId}) {
        title
        courses (where: { id: $courseId}) {
          title
        }
      }
      quiz {
        __typename
        ${Quiz.names}
      }
    }
  }

  ${Quiz.strings}
`;

const ACTIVITY_MUTATION = gql`
  mutation($activityId: ID!, $activityTitle: String!) {
    updateActivity(
      input: {
        where: { id: $activityId }
        data: { title: $activityTitle }
      }
    ) {
      activity {
        id
        title
      }
    }
  }
`;

const QUIZ_MUTATION = gql`
  mutation(
    $activityId: ID!
    $quizList: [ActivitiesQuizDynamicZoneInput!]
  ) {
    updateActivity(
      input: {
        where: { id: $activityId }
        data: { quiz: $quizList }
      }
    ) {
      activity {
        id
        title
        quiz {
          __typename
          ${Quiz.names}
        }
      }
    }
  }
  ${Quiz.strings}
`;

const COURSE_TITLE_QUERY = gql`
  query($courseId: ID!) {
    course(id: $courseId) {
      title
    }
  }
`;

const SET_QUIZ_MUTATION = gql`
  mutation(
    $activityId: ID!
    $quiz: [ActivitiesQuizDynamicZoneInput!]!
  ) {
    updateActivity(
      input: { where: { id: $activityId }, data: { quiz: $quiz } }
    ) {
      activity {
        id
        quiz {
          __typename
          ${Quiz.names}
        }
      }
    }
  }
  ${Quiz.strings}
`;

export default {
  name: 'Activity',
  components: { AppRichTextEditor, ...Quiz.types, ...Content.types },
  data: () => ({
    alert: {
      type: 'success',
      show: false,
      text: ''
    },
    roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER'
    },
    answerHeaders: [
      { text: 'ID', value: 'id' },
      { text: 'Content', value: 'contents' },
      { text: 'Correct', value: 'correct' }
    ],
    lesson: '',
    loadedData: {},

    activity: false,
    local: {
      courseTitle: '',
      activityId: null,
      activityTitle: '',
      quizId: null,
      quizQuestion: '',
      quizAnswers: [],
      quiz: []
    },
    forms: {
      addQuiz: {
        type: ''
      }
    },
    quizvalidationalert: false,
    newAnswer: {
      text: '',
      correct: false
    },
    quizComponent: null,
    tmpComponentId: 0,
    quizTypes: Quiz.types,
    contentTypes: Content.types,
    courseCompletionFull: '',
    courseCompletionPartial: ''
  }),
  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating'
    ]),
    IsDementiaAdmin() {
      if (
        this.getUser?.user?.role?.name === this.roles.DEMENTIA_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsCMSAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.CMS_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGViewer() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_VIEWER) {
        return true;
      } else {
        return false;
      }
    },
    courseId() {
      return this.$route.params.courseId;
    },
    lessonId() {
      return this.$route.params.lessonId;
    },
    breadcrumbs() {
      if (this.activity) {
        let lesson = this.activity.lessons[0];
        let course = lesson.courses[0];
        // prettier-ignore
        return [
          { text: course.title, href: `#/modules/${this.courseId}` },
          { text: lesson.title, href: `#/lesson/${this.courseId}/${this.lessonId}` },
          { text: this.activity.title }
        ];
      } else {
        return [
          { text: 'Course', disabled: true },
          { text: 'Lesson', disabled: true },
          { text: 'Activity', disabled: true }
        ];
      }
    },
    answerTableData() {
      return this.activity.quiz?.[0]?.answers;
    }
  },
  beforeRouteLeave(to, from, next) {
    let edited = false;
    if (this.getUser?.user?.role?.name === this.roles.CMS_ADMIN) {
      if (this.activity.quiz.length > 0) {
        if (
          !this.objectsEqual(
            this.loadedData,
            this.$refs?.quizzes?.map(q => q?.local?.quiz)
          ) ||
          !this.objectsEqual(this.loadedData, this.activity.quiz)
        ) {
          edited = true;
        }
      }
    } else {
      edited = false;
    }
    if (edited && to.name != 'LoginHome') {
      if (
        confirm(
          'Some data are not saved, do you want to proceed without saving?'
        )
      ) {
        next();
      } else {
        return false;
      }
    } else {
      next();
    }
  },
  methods: {
    saveActivity() {
      this.$apollo.mutate({
        mutation: ACTIVITY_MUTATION,
        variables: {
          activityId: this.activity.id,
          activityTitle: this.local.activityTitle
        }

        // todo: catch errors, update processing state
      });
      this.alert.show = true;
      this.alert.text = 'Successfully Saved';
      this.alert.type = 'success';

      setTimeout(() => {
        this.alert.show = false;
      }, 3000);
    },
    arraysEqual(a1, a2) {
      return (
        a1 &&
        a2 &&
        a1.length === a2.length &&
        a1.every((o, idx) => this.objectsEqual(o, a2[idx]))
      );
    },
    objectsEqual(o1, o2) {
      return o1 &&
        typeof o1 === 'object' &&
        Object.keys(o1).length > 0
        ? Object.keys(o1).length === Object.keys(o2).length &&
            Object.keys(o1).every(p =>
              this.objectsEqual(o1[p], o2[p])
            )
        : o1 === o2;
    },
    saveQuiz() {
      var quizlist = this.$refs?.quizzes?.map(q => q?.local?.quiz);

      let quizerrors = quizlist.filter(function(e) {
        if (
          e.answers.length === 0 ||
          !e.question ||
          e.question === '<p></p>'
        ) {
          return e;
        }
      });
      if (quizerrors.length > 0) {
        this.quizvalidationalert = true;
        return;
      }

      this.$apollo.mutate({
        mutation: QUIZ_MUTATION,
        variables: {
          activityId: this.activity.id,
          quizList: this.$refs?.quizzes?.map(q => q?.local?.quiz)
        }
      });

      this.alert.show = true;
      this.alert.text = 'Successfully Saved';
      this.alert.type = 'success';

      setTimeout(() => {
        this.alert.show = false;
      }, 3000);
    },
    addQuiz(strapiComponent) {
      if (this.activity.quiz && this.activity.quiz.length > 0) {
        this.activity.quiz.push({
          __typename: strapiComponent,
          answers: [],
          incorrectAnswerText: null,
          question: null
        });
      } else {
        this.activity.quiz = [
          {
            __typename: strapiComponent,
            answers: [],
            incorrectAnswerText: null,
            question: null
          }
        ];
      }
      // this.$apollo.mutate({
      //   mutation: SET_QUIZ_MUTATION,
      //   variables: {
      //     activityId: this.activity.id,
      //     // quiz: [{ __typename: strapiComponent }]
      //     quiz: this.activity.quiz
      //   }
      // });
    },
    removeComponent: function(item) {
      var quizes = [];
      if (this.activity.quiz && this.activity.quiz.length > 0) {
        quizes = this.activity.quiz.filter(q => q.id != item.id);
        quizes = quizes.filter(q => q.id);
        this.activity.quiz = quizes;
        this.$apollo.mutate({
          mutation: SET_QUIZ_MUTATION,
          variables: {
            activityId: this.activity.id,
            quiz: quizes
          }
        });
      }
    }
  },
  apollo: {
    course() {
      return {
        query: COURSE_TITLE_QUERY,
        variables: {
          courseId: this.$route.params.courseId
        },
        update: data => {
          this.local.courseTitle = data.course.title;
          return data.course;
        }
      };
    },
    lesson() {
      return {
        query: LESSON_QUERY,
        variables: {
          lessonId: this.$route.params.lessonId
        },
        update: data => {
          return data.lesson;
        }
      };
    },
    activity() {
      return {
        query: ACTIVITY_QUERY,
        variables: {
          activityId: this.$route.params.activityId,
          lessonId: this.$route.params.lessonId,
          courseId: this.$route.params.courseId
        },
        update: data => {
          this.loadedData = data.activity.quiz;
          // Copy materials to local for editing
          this.local.activityId = data.activity?.id;
          this.local.activityTitle = data.activity?.title;
          return data.activity;
        }
      };
    }
  }
};
</script>
