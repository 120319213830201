var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex justify-center" },
        [
          _c(
            "v-alert",
            {
              staticClass: "v-alert",
              attrs: {
                dense: "",
                text: "",
                type: _vm.alert.type,
                value: _vm.alert.show,
                transition: "slide-y-transition"
              }
            },
            [_vm._v(_vm._s(_vm.alert.text))]
          )
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "grey lighten-5", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c("v-col", { staticClass: "mr-auto", attrs: { cols: "auto" } }, [
                _c("h1", [_vm._v("Subscriptions")])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "page-sub-sec" },
        [
          _c("v-text-field", {
            staticClass: "shrink",
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Search",
              outlined: "",
              dense: "",
              "hide-details": ""
            },
            on: { input: _vm.subscriptionData },
            model: {
              value: _vm.searchUser,
              callback: function($$v) {
                _vm.searchUser = $$v
              },
              expression: "searchUser"
            }
          }),
          _vm.IsCMSAdmin
            ? _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      _vm.showNewDialog = true
                    }
                  }
                },
                [
                  _c("v-icon", { staticClass: "mr-2" }, [_vm._v("mdi-plus")]),
                  _vm._v("Add Subscription")
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "dark--text",
        attrs: {
          items: _vm.subscriptions,
          headers: _vm.computedUserHeaders,
          search: _vm.searchUser,
          "no-data-text": "No data available",
          loading: this.$apollo.loading,
          "loading-text": "Loading... Please wait",
          "hide-default-footer": _vm.courseCurrentCount < 10 ? true : false,
          "page-count": _vm.pageSize,
          page: _vm.page,
          options: _vm.options,
          "server-items-length": _vm.courseCurrentCount,
          "footer-props": {
            "items-per-page-options": [10, 20, 30, 40, 50, 100, 500]
          }
        },
        on: {
          "update:options": function($event) {
            _vm.options = $event
          }
        },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.computedUserHeaders, function(h) {
              return {
                key: "header." + h.value,
                fn: function(ref) {
                  var header = ref.header
                  return [
                    _vm.headerTooltips[h.value]
                      ? _c(
                          "v-tooltip",
                          {
                            key: h.value,
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "span",
                                        _vm._g({}, on),
                                        [
                                          _vm._v(_vm._s(header.text)),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "pl-1",
                                              attrs: { color: "#113247" }
                                            },
                                            [_vm._v("mdi-information")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.headerTooltips[header.value]))
                            ])
                          ]
                        )
                      : _c("span", { key: h.value }, [
                          _vm._v(_vm._s(header.text))
                        ])
                  ]
                }
              }
            }),
            {
              key: "item.productName",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "router-link",
                    {
                      staticClass: "blue--text",
                      attrs: { to: "/subscriptions/" + item.productId }
                    },
                    [_vm._v(_vm._s(item.productName))]
                  )
                ]
              }
            },
            {
              key: "item.productId",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.productId) + " ")]
              }
            },
            {
              key: "item.inAppMonthlyProductId",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.inAppMonthlyProductId) + " ")]
              }
            },
            {
              key: "item.inAppYearlyProductId",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.inAppYearlyProductId) + " ")]
              }
            },
            {
              key: "item.courseCount",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.courseCount) + " ")]
              }
            },
            {
              key: "item.productAvailabilityName",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        item.productAvailabilityName
                          ? item.productAvailabilityName
                          : "Unavailable"
                      ) +
                      " "
                  )
                ]
              }
            },
            {
              key: "item.action",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm.getLoginedUserId != item.productId
                    ? _c(
                        "div",
                        { staticClass: "lessons-actions-wrap" },
                        [
                          !item.isDisabled
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "green",
                                    icon: "",
                                    small: "",
                                    disabled: !_vm.IsDementiaAdmin
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeVisibility(item)
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { title: "Enable" } }, [
                                    _vm._v("mdi-eye")
                                  ])
                                ],
                                1
                              )
                            : _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "orange",
                                    icon: "",
                                    small: "",
                                    disabled: !_vm.IsDementiaAdmin
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeVisibility(item)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { title: "Disable" } },
                                    [_vm._v("mdi-eye-off")]
                                  )
                                ],
                                1
                              ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                color: "light-blue",
                                small: ""
                              }
                            },
                            [
                              _c("v-icon", { attrs: { title: "Reinvite" } }, [
                                _vm._v("mdi-account-reactivate")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--small",
                              attrs: { to: "/users/" + item.productId }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "blue--text",
                                  attrs: { title: "Edit" }
                                },
                                [_vm._v("mdi-pencil")]
                              )
                            ],
                            1
                          ),
                          _vm.hideDelete == "false"
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", color: "red", small: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.LoadConfirmDialogForDeleteOrg(
                                        item.productId
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { title: "Delete" } }, [
                                    _vm._v("mdi-trash-can-outline")
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.isDisabled",
              fn: function(ref) {
                var item = ref.item
                return [
                  !item.isDisabled
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "green",
                            icon: "",
                            small: "",
                            disabled: !_vm.IsDementiaAdmin
                          },
                          on: {
                            click: function($event) {
                              return _vm.changeVisibility(item)
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { title: "Enable" } }, [
                            _vm._v("mdi-eye")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  item.isDisabled
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "red",
                            icon: "",
                            small: "",
                            disabled: !_vm.IsDementiaAdmin
                          },
                          on: {
                            click: function($event) {
                              return _vm.changeVisibility(item)
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { title: "Enable" } }, [
                            _vm._v("mdi-eye-off")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(
                    " " +
                      _vm._s(!item.isDisabled ? " Visible" : " Hidden") +
                      " "
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.showNewDialog,
            callback: function($$v) {
              _vm.showNewDialog = $$v
            },
            expression: "showNewDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-form",
                { ref: "form", on: { submit: _vm.createProduct } },
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v("Add Subscription")
                    ])
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Name*",
                          rules: [
                            function(v) {
                              return !!v || "Name is required"
                            }
                          ],
                          required: ""
                        },
                        model: {
                          value: _vm.forms.name,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "name", $$v)
                          },
                          expression: "forms.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("small", { staticClass: "info--text" }, [
                        _vm._v("*indicates required field")
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "", text: "" },
                          on: { click: _vm.cancel }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.createProduct }
                        },
                        [_vm._v(" Submit ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }