<template>
  <div class="black--text">
    <v-card-text
      class="pa-0 pb-3"
      v-if="summaryCount.employeeIDErrorCount > 0"
    >
      <v-card-text class="pa-0 font-weight-bold">
        Missing Employee ID:
      </v-card-text>
      The learners listed below had incorrect employee IDs provided.
      Most issues result in the learner account being created without
      an employee ID. Some issues prevent the learner account from
      being created altogether.
    </v-card-text>
    <v-card-text class="pa-0 pb-3" v-else
      >Some issues prevent the learner account from being created
      altogether.</v-card-text
    >
    <v-card-text
      class="pa-0 pb-3"
      v-if="summaryCount.employeeIDErrorCount > 0"
    >
      Employee IDs may be updated individually via User Management.
      Alternatively, learners without an employee ID will be prompted
      to input this data the next time they access the Ask Annie
      mobile app.
    </v-card-text>
    <v-card-text
      class="pa-0 font-weight-medium"
      v-if="summaryCount.successCount > 0"
    >
      <span class="green--text">{{ summaryCount.successCount }}</span>
      learner accounts were successfully created.
    </v-card-text>
    <v-card-text
      class="pa-0 font-weight-medium pb-3"
      v-if="summaryCount.accountNotCreatedCount > 0"
    >
      <span class="red--text">{{
        summaryCount.accountNotCreatedCount
      }}</span>
      learner accounts were not created.
    </v-card-text>

    <v-simple-table fixed-header height="300px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Learner Name
            </th>
            <th class="text-left">
              Learner Email
            </th>
            <th class="text-left">
              Issue Type
            </th>
            <th class="text-left">
              Account Created
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in summaryFailedList"
            :key="index"
            class="error-list-b"
            :class="index % 2 ? 'table-row-bg' : ''"
          >
            <td>
              <span v-if="item.data && item.data.name">{{
                item.data.name
              }}</span>
            </td>
            <td>
              <span v-if="item.data && item.data.email">{{
                item.data.email
              }}</span>
            </td>
            <td>{{ item.message }}</td>
            <td>{{ item.accountCreated }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: 'OrgLearnersErrorSummary',
  props: ['summaryFailedList', 'summaryCount']
};
</script>

<style lang="scss" scoped>
.table-row-bg {
  background: #f4f6f8;
}
.error-list-b td {
  border: none !important;
}
</style>
