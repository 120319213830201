var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info-head" },
    [
      _vm.user.avatar
        ? _c("v-img", { attrs: { src: _vm.user.avatar } })
        : _vm._e(),
      !_vm.user.avatar
        ? _c(
            "v-btn",
            { attrs: { color: "secondary", outlined: "", rounded: "" } },
            [
              _c("v-icon", { staticClass: "mr-2" }, [_vm._v("mdi-account")]),
              _c("span", [_vm._v(_vm._s(_vm.user.firstName))])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }