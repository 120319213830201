<template>
  <div>
    <div class="d-flex justify-center pb-1">
      <v-alert
        dense
        text
        class="invite-learner v-alert"
        :type="alertType"
        :value="alert"
        transition="slide-y-transition"
        >{{ alertText.text }}</v-alert
      >
    </div>

    <v-dialog v-model="alertBox" persistent max-width="730">
      <v-card>
        <v-card-title class="headline">{{
          alertBoxType
        }}</v-card-title>
        <v-card-text
          class="black--text pb-0"
          v-if="summaryCount.employeeIDNotRequiredItemsCount > 0"
        >
          Employee ID is not required for this organization.
        </v-card-text>
        <v-card-text
          ><OrgLearnersErrorSummary
            v-if="summaryFailedList.length > 0"
            :summaryFailedList="summaryFailedList"
            :summaryCount="summaryCount"
          />

          <v-card-text
            class="black--text pa-0 font-weight-medium pt-3"
            v-if="
              summaryCount.successCount > 0 &&
                uploadTotalRecords === summaryCount.successCount &&
                summaryFailedList.length === 0
            "
            ><span class="green--text">{{
              summaryCount.successCount
            }}</span>
            learner accounts were successfully created.</v-card-text
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" text @click="clearAlert">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid class="grey lighten-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
          <h1>
            Profile: <span class="success--text">{{ org.name }}</span>
          </h1>
        </v-col>
      </v-row>
    </v-container>

    <v-tabs v-model="tab">
      <v-tab
        v-for="tab in computedtabItems"
        :key="tab.id"
        @click="setActiveTab(tab.id)"
      >
        <v-img
          v-if="tab.text == 'Subscriptions'"
          class="mr-2"
          max-height="24"
          max-width="24"
          src="../../images/SubscriptionIconTab.png"
        />
        <v-icon v-else class="mr-2">{{ tab.icon }}</v-icon>
        {{ tab.text }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="tab in computedtabItems" :key="tab.id">
        <div v-if="tab.text == 'Subscriptions'">
          <subscriptions-list
            :org-name="org.name"
            @alertObj="getAlertObj"
          />
        </div>
        <div v-if="tab.text == 'Details'">
          <v-card color="basil" flat>
            <v-card-text>
              <div class="page-sub-sec">
                <div>
                  <v-chip class="ma-2" color="primary">
                    Total Viewers: {{ local.org.noofviewers }}
                  </v-chip>
                  <v-chip class="ma-2" color="primary">
                    Total Admins: {{ local.org.noofadmins }}
                  </v-chip>
                </div>
                <v-btn
                  v-if="IsDementiaAdmin"
                  color="warning"
                  @click="checkLicenseUpdate"
                  ><v-icon class="mr-2">mdi-update</v-icon>
                  Update</v-btn
                >
              </div>
              <v-container fluid>
                <v-form ref="orgform" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="local.org.newOrgName"
                        :rules="nameRules"
                        :counter="50"
                        label="Name*"
                        required
                        :disabled="IsORGAdmin ? true : false"
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="local.org.orgAddress"
                        label="Address*"
                        :rules="[v => !!v || 'Address is required']"
                        required
                        :disabled="IsORGAdmin ? true : false"
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="local.org.orgDomain"
                        label="Website*"
                        required
                        :rules="[v => !!v || 'Domain is required']"
                        :disabled="IsORGAdmin ? true : false"
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="local.org.code"
                        label="Organisation Code*"
                        :rules="[
                          v => !!v || 'Organisation Code is required'
                        ]"
                        required
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="local.org.orgCountry"
                        label="Country*"
                        :rules="[v => !!v || 'Country is required']"
                        required
                        :disabled="IsORGAdmin ? true : false"
                      />
                    </v-col>
                    <v-col cols="12" sm="4" class="pt-1">
                      <span class="caption">Employee ID Type*</span>
                      <v-select
                        v-model="EmployeeIDType"
                        :items="EmployeeIDTypes"
                        item-value="value"
                        item-text="label"
                        class="pt-0"
                        label="Select"
                        persistent-hint
                        :disabled="!IsDementiaAdmin"
                        single-line
                        :rules="[
                          v => !!v || 'Employee ID Type is required'
                        ]"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      v-if="
                        EmployeeIDType === 'AlphaNumeric' ||
                          EmployeeIDType === 'EmailAddress'
                      "
                    >
                      <v-text-field
                        v-model="MobileHelpMessage"
                        label="Mobile Help Message*"
                        :disabled="!IsDementiaAdmin"
                        :rules="[
                          v =>
                            !!v || 'Mobile help message is required'
                        ]"
                        required
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </div>
        <div v-if="tab.text == 'Courses'">
          <v-card color="basil" flat>
            <v-card-text>
              <div class="page-sub-sec">
                <v-text-field
                  v-model="courseSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  outlined
                  hide-details
                  dense
                  class="shrink"
                />
                <v-btn
                  v-if="IsDementiaAdmin"
                  color="success"
                  @click="showNewDialog = true"
                  >Add Course</v-btn
                >
              </div>
              <v-data-table
                :items="orderedCourses"
                :headers="computedCourseHeaders"
                :search="courseSearch"
                :no-data-text="`No course available`"
                :hide-default-footer="coursePaginationStatus()"
              >
                <template v-slot:[`item.Title`]="{ item }">
                  <span v-if="IsDementiaAdmin">
                    <router-link
                      :to="`/courses/${item.id}`"
                      class="blue--text"
                      >{{ item.Title }}</router-link
                    >
                  </span>
                  <span v-else>
                    {{ item.Title }}
                  </span>
                </template>
                <template v-slot:[`item.courses`]="{ item }">
                  <span v-if="item.courses">{{
                    item.courses.length
                  }}</span>
                  <span v-else>0</span>
                </template>
                <template v-slot:[`item.isDisabled`]="{ item }">
                  <div
                    v-if="getIsCourseBlockedByORG(item)"
                    class="warning--text"
                  >
                    Inactive
                  </div>
                  <div v-else class="success--text">
                    Active
                  </div>
                </template>
                <template v-slot:[`item.sort`]="{ item }">
                  <v-btn
                    icon
                    small
                    :disabled="getCourseIndex(item) < 1"
                    @click="
                      moveCourse(
                        getCourseIndex(item),
                        getCourseIndex(item) - 1
                      )
                    "
                    ><v-icon>mdi-arrow-up</v-icon></v-btn
                  >
                  <v-btn
                    icon
                    small
                    :disabled="
                      getCourseIndex(item) > orderedCourses.length - 2
                    "
                    @click="
                      moveCourse(
                        getCourseIndex(item),
                        getCourseIndex(item) + 1
                      )
                    "
                    ><v-icon>mdi-arrow-down</v-icon></v-btn
                  >
                </template>
                <!-- <template v-slot:[`item.orgCourseRefresh`]="{ item }">
                  <v-btn
                    v-if="IsORGAdmin"
                    icon
                    small
                    @click="
                      updateCourseDuration(item.id, getRefresh(item))
                    "
                    >{{
                      `${
                        item.orgCourseRefresh ? getRefresh(item) : '0'
                      } Days`
                    }}<v-icon title="Edit duration"
                      >mdi-pencil</v-icon
                    ></v-btn
                  >
                  <div v-else>
                    {{
                      `${
                        item.orgCourseRefresh ? getRefresh(item) : '0'
                      } Days`
                    }}
                  </div>
                </template> -->
                <template v-slot:[`item.action`]="{ item }">
                  <div class="lessons-actions-wrap">
                    <div
                      :style="
                        item.isDisabled ? 'cursor: pointer' : ''
                      "
                      :title="
                        item.isDisabled
                          ? 'Disabled on all organisations'
                          : ''
                      "
                    >
                      <v-btn
                        v-if="getIsCourseBlockedByORG(item)"
                        icon
                        color="green"
                        small
                        :disabled="item.isDisabled"
                        @click="
                          enableOrDisableCourse(item, true, org.id)
                        "
                        ><v-icon title="Enable"
                          >mdi-eye</v-icon
                        ></v-btn
                      >

                      <v-btn
                        v-else
                        icon
                        color="orange"
                        small
                        :disabled="item.isDisabled"
                        @click="
                          enableOrDisableCourse(item, false, org.id)
                        "
                        ><v-icon title="Disable"
                          >mdi-eye-off</v-icon
                        ></v-btn
                      >
                    </div>
                    <v-btn
                      v-if="hideDelete == 'false'"
                      icon
                      color="red"
                      class="float-right"
                      small
                      @click="LoadConfirmDialogForRemoveCourse(item)"
                      ><v-icon title="Delete"
                        >mdi-trash-can-outline</v-icon
                      ></v-btn
                    >
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </div>
        <div v-if="tab.text == 'Learners'">
          <v-card color="basil" flat>
            <v-card-text>
              <div class="page-sub-sec">
                <v-text-field
                  v-model="learnerSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  dense
                  outlined
                  hide-details
                  class="shrink"
                  @input="learnersData"
                />
                <div
                  v-if="IsDementiaAdmin || IsORGAdmin"
                  class="d-flex float-right"
                >
                  <v-btn
                    :class="
                      IsDementiaAdmin || IsORGAdmin ? 'mr-5' : ''
                    "
                    color="success"
                    :loading="learnerReportloader"
                    @click="generateLearnersReport"
                    :disabled="!isShowLearnersReport"
                    ><v-icon>mdi-file-excel</v-icon>Export Learner
                    Report</v-btn
                  >
                  <v-btn
                    v-if="IsDementiaAdmin || IsORGAdmin"
                    :href="defaultTemplateForLearnerUpload"
                    class="mr-5"
                    color="success"
                    ><v-icon>mdi-download</v-icon>Template</v-btn
                  >

                  <v-btn
                    v-if="IsDementiaAdmin || IsORGAdmin"
                    class="mr-0"
                    color="success"
                    @click="showNewDialogForUpload = true"
                    ><v-icon>mdi-file-upload</v-icon> Upload
                    Learners</v-btn
                  >
                  <!-- <v-btn color="success" @click="showNewDialog = true"
                    >Invite Learner</v-btn
                  > -->
                </div>
              </div>
              <v-data-table
                :items="learners"
                :headers="computedLearnerHeaders"
                :loading="loadingLearner"
                :search="learnerSearch"
                :no-data-text="`No learner available`"
                :hide-default-footer="
                  totalLearners < 20 ? true : false
                "
                :page-count="pageSize"
                :page="page"
                :options.sync="options"
                :server-items-length="totalLearners"
                :footer-props="{
                  'items-per-page-options': [20, 30, 40, 50, 100, 500]
                }"
              >
                <template v-slot:[`item.EmployeeID`]="{ item }">
                  {{ item.EmployeeID }}
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <router-link
                    :to="
                      `/organisations/Learners/${org.id}/${item.user_id}`
                    "
                    class="blue--text"
                    >{{ item.name }}</router-link
                  >
                </template>
                <template v-slot:[`item.email`]="{ item }">
                  {{ item.email }}
                </template>
                <template v-slot:[`item.completed`]="{ item }">
                  <span> {{ item.completed }}/{{ item.total }}</span>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <div class="lessons-actions-wrap">
                    <v-btn
                      v-if="item.blocked"
                      icon
                      color="green"
                      small
                      @click="
                        enableOrDisablelearner(item.user_id, false)
                      "
                      ><v-icon title="Enable">mdi-eye</v-icon></v-btn
                    >
                    <v-btn
                      v-else
                      icon
                      color="orange"
                      small
                      @click="
                        enableOrDisablelearner(item.user_id, true)
                      "
                      ><v-icon title="Disable"
                        >mdi-eye-off</v-icon
                      ></v-btn
                    >
                    <v-btn
                      v-if="hideDelete == 'false'"
                      icon
                      color="red"
                      class="float-right"
                      small
                      @click="LoadConfirmDialogForRemoveLearner(item)"
                      ><v-icon title="Delete"
                        >mdi-trash-can-outline</v-icon
                      ></v-btn
                    >
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </div>
        <div v-if="tab.text == 'Administrators'">
          <v-card color="basil" flat>
            <v-card-text>
              <div class="page-sub-sec">
                <v-row class="justify-end mr-2">
                  <div v-if="IsDementiaAdmin || IsORGAdmin">
                    <v-btn
                      color="success"
                      @click="showNewDialogForAdmin = true"
                      >Invite Admin</v-btn
                    >
                  </div>
                </v-row>
              </div>
              <v-data-table
                :items="org.admins"
                :headers="adminHeaders"
                :no-data-text="`No admin available`"
                :hide-default-footer="adminPaginationStatus()"
              >
                <template v-slot:[`item.username`]="{ item }">
                  <router-link
                    :to="
                      `/organisations/Learners/${org.id}/${item.id}`
                    "
                    class="blue--text"
                    >{{
                      (item.firstName ? item.firstName : '') +
                        ' ' +
                        (item.lastName ? item.lastName : '')
                    }}</router-link
                  >
                </template>
                <template v-slot:[`item.email`]="{ item }">
                  {{ item.email }}
                </template>
                <template v-slot:[`item.mobile`]="{ item }">
                  {{ item.mobile }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </div>
        <div v-if="tab.text == 'Viewers'">
          <v-card color="basil" flat>
            <v-card-text>
              <div class="page-sub-sec">
                <v-row class="justify-end mr-2">
                  <div v-if="IsDementiaAdmin || IsORGAdmin">
                    <v-btn
                      color="success"
                      @click="showNewDialogForViewers = true"
                      >Invite Viewers</v-btn
                    >
                  </div>
                </v-row>
              </div>
              <v-data-table
                :items="org.viewers"
                :headers="adminHeaders"
                :no-data-text="`No viewers available`"
                :hide-default-footer="viewerPaginationStatus()"
              >
                <template v-slot:[`item.username`]="{ item }">
                  <router-link
                    :to="
                      `/organisations/Learners/${org.id}/${item.id}`
                    "
                    class="blue--text"
                    >{{
                      (item.firstName ? item.firstName : '') +
                        ' ' +
                        (item.lastName ? item.lastName : '')
                    }}</router-link
                  >
                </template>
                <template v-slot:[`item.email`]="{ item }">
                  {{ item.email }}
                </template>
                <template v-slot:[`item.mobile`]="{ item }">
                  {{ item.mobile }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </div>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog v-model="showNewDialog" width="500">
      <v-card>
        <v-card-title v-if="slectedTab == 1" class="headline"
          >Add Course</v-card-title
        >
        <v-card-title v-if="slectedTab == 2" class="headline"
          >Invite Learner</v-card-title
        >
        <v-form v-if="slectedTab == 1" class="pa-6">
          <v-autocomplete
            v-model="forms.newCourseId"
            :items="availableCourses"
            label="Select From Available Course"
          />
        </v-form>
        <v-form v-if="slectedTab == 2" ref="form" class="pa-6">
          <v-text-field
            v-model="newLearner.email"
            label="Email*"
            :rules="[
              v => !!v || 'E-mail is required',
              v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
            ]"
            required
          />

          <v-text-field
            v-model="newLearner.firstName"
            :rules="[v => !!v || 'First name is required']"
            label="First name*"
            required
          />

          <v-text-field
            v-model="newLearner.lastName"
            label="Last name"
          />
        </v-form>
        <v-card-actions>
          <small class="info--text">*indicates required field</small>
          <v-spacer />
          <v-btn
            v-if="slectedTab == 2"
            depressed
            text
            @click="cancelInviteLearner"
          >
            Cancel
          </v-btn>
          <v-btn v-else depressed text @click="showNewDialog = false">
            Cancel
          </v-btn>
          <v-btn
            v-if="slectedTab == 1"
            color="primary"
            @click="addCourse"
          >
            Add Course
          </v-btn>
          <v-btn
            v-if="slectedTab == 2"
            color="primary"
            @click="addLearner"
          >
            Add Learner
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showNewDialogForAdmin"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-form ref="form">
          <v-card-title>
            <span class="headline">Invite Admin</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="newAdmin.newEmail"
              label="Email*"
              :rules="[
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
              ]"
              required
            />

            <v-text-field
              v-model="newAdmin.newFirstName"
              :rules="[v => !!v || 'First name is required']"
              label="First name*"
              required
            />

            <v-text-field
              v-model="newAdmin.newLastName"
              label="Last name"
            />

            <v-text-field
              v-model="newAdmin.newMobileNum"
              :rules="
                newAdmin.newMobileNum
                  ? [
                      v =>
                        /^\d+$/.test(v) ||
                        'Number is not a valid mobile number'
                    ]
                  : []
              "
              label="Mobile Number"
            />
          </v-card-text>
          <v-card-actions>
            <small class="info--text"
              >*Please ensure each mandatory field marked with an
              asterisk * is completed</small
            >
            <v-spacer />
            <v-btn depressed text @click="cancelInviteAdmin">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="createNewUser">
              Invite
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showNewDialogForViewers"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-form ref="form">
          <v-card-title>
            <span class="headline">Invite Viewers</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="newViewer.newEmail"
              label="Email*"
              :rules="[
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
              ]"
              required
            />

            <v-text-field
              v-model="newViewer.newFirstName"
              :rules="[v => !!v || 'First name is required']"
              label="First name*"
              required
            />

            <v-text-field
              v-model="newViewer.newLastName"
              label="Last name"
            />

            <v-text-field
              v-model="newViewer.newMobileNum"
              :rules="
                newViewer.newMobileNum
                  ? [
                      v =>
                        /^\d+$/.test(v) ||
                        'Number is not a valid mobile number'
                    ]
                  : []
              "
              label="Mobile Number"
            />
          </v-card-text>
          <v-card-actions>
            <small class="info--text"
              >*Please ensure each mandatory field marked with an
              asterisk * is completed</small
            >
            <v-spacer />
            <v-btn depressed text @click="cancelInviteViewer">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="createViewer">
              Invite
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showNewDialogForUpload" width="500">
      <v-card class="pa-3">
        <br />
        <v-form ref="form">
          <input
            id="inputExcelUpload"
            ref="file"
            accept=".csv"
            class="mr-5"
            color="primary"
            type="file"
          />
        </v-form>
        <br />
        {{ uploadSuccessMessage }}
        <v-card-actions>
          <v-spacer />
          <v-btn depressed color="primary" text @click="cancelUpload">
            Cancel
          </v-btn>
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="primary"
            @click="uploadExcel"
          >
            Upload New Learners
            <v-icon right dark>
              mdi-cloud-upload
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="licenseconfirmdialog"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline"
          >Update Organisation license's</v-card-title
        >
        <v-card-text
          >Please confirm the change in number of
          licenses</v-card-text
        >
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="licenseconfirmdialog = false"
            >Cancel</v-btn
          >
          <v-btn color="red darken-1" text @click="updateOrg"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editCourseDuration" persistent max-width="450">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h2>
            Course Refresh Duration
          </h2>
        </v-card-title>
        <v-slider
          v-model="courseRefresh"
          class="mt-7 ml-5 mr-5"
          thumb-color="blue"
          min="30"
          max="400"
          thumb-label="always"
          ><template v-slot:append>
            <v-text-field
              v-model="courseRefresh"
              min="30"
              max="10000"
              class="mt-0 pt-0"
              type="number"
              style="width: 60px"
            />Days
          </template></v-slider
        >
        <v-card-actions>
          <v-btn
            color="primary"
            small
            rounded
            class="mb-5"
            @click="editCourseDuration = false"
            >CANCEL</v-btn
          >
          <v-spacer />
          <v-btn
            color="primary"
            small
            rounded
            class="mb-5"
            @click="setCourseDuration"
            >SAVE</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="d-flex justify-center pt-2">
      <v-alert
        v-if="org.learners"
        :value="dialoglicensealert"
        prominent
        border="top"
        transition="scale-transition"
        max-width="600"
      >
        <v-row align="center">
          <v-col class="grow">
            There is a problem with you reducing the licence count as
            your organisation currently has
            {{ org.learners.length }} allocated to learners
          </v-col>
          <v-col class="shrink">
            <v-btn
              color="primary"
              @click="dialoglicensealert = false"
            >
              OK
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>
    <!-- alertconfirm -->
    <v-dialog v-model="confirmdialog.show" persistent max-width="450">
      <v-card>
        <v-card-title class="headline">{{
          confirmdialog.HeaderText
        }}</v-card-title>
        <v-card-text>{{ confirmdialog.ContentText }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="confirmdialog.show = false"
            >Cancel</v-btn
          >
          <v-btn
            color="red darken-1"
            text
            @click="
              confirmdialog.ButtonConfirmAction(
                confirmdialog.customObject
              )
            "
            >{{ confirmdialog.ButtonConfirmText }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';
import { Constant } from '../../config/constants';
import SubscriptionsList from './Subscriptions/SubscriptionsList';
import OrgLearnersErrorSummary from './OrgLearnersErrorSummary.vue';
import * as saveAs from 'file-saver';
const ORG_QUERY = gql`
  query($orgId: ID!) {
    orgMeasures(id: $orgId) {
      id
      name
      address
      country
      code
      domain
      primaryCourseOrder
      EmployeeIDType
      MobileHelpMessage
      primary_courses {
        id
        Title
        isDisabled
        org_Denied
        DisabledCourses
        courses {
          id
          title
        }
      }
      courseOrder
      courses {
        id
        title
        isDisabled
        org_Denied
        orgCourseRefresh
        lessons {
          id
          title
        }
      }
      admins {
        id
        username
        firstName
        lastName
        email
        mobile
      }
      learners {
        id
        username
        firstName
        lastName
        email
        totalLessons
        completedLessons
        courseCompletedPercentage
        completedCourses
        totalCourses
        blocked
      }
      viewers {
        id
        email
        firstName
        lastName
        username
        mobile
      }
    }
  }
`;

const UPDATE_COURSE_DURATION_MUTATION = gql`
  mutation($courseId: ID!, $orgId: ID!, $courseRefresh: ID!) {
    orgAdminUpdateCourse(
      input: {
        where: { id: $courseId }
        data: {
          orgCourseRefresh: {
            OrgId: $orgId
            CourseRefresh: $courseRefresh
          }
        }
      }
    ) {
      course {
        id
        title
        orgCourseRefresh
      }
    }
  }
`;
const UPDATE_ORG_MUTATION_FOR_DA = gql`
  mutation(
    $orgId: ID!
    $newOrgName: String!
    $orgAddress: String!
    $orgCountry: String!
    $orgDomain: String!
    $EmployeeIDType: ENUM_ORG_EMPLOYEEIDTYPE!
    $MobileHelpMessage: String!
  ) {
    updateOrg(
      input: {
        where: { id: $orgId }
        data: {
          name: $newOrgName
          address: $orgAddress
          country: $orgCountry
          domain: $orgDomain
          EmployeeIDType: $EmployeeIDType
          MobileHelpMessage: $MobileHelpMessage
        }
      }
    ) {
      org {
        id
        name
        address
        country
        domain
        no_license
        courseOrder
        primaryCourseOrder
        EmployeeIDType
        MobileHelpMessage
      }
    }
  }
`;

const UPDATE_ORG_MUTATION = gql`
  mutation(
    $orgId: ID!
    $newOrgName: String!
    $orgAddress: String!
    $orgCountry: String!
    $orgDomain: String!
  ) {
    updateOrg(
      input: {
        where: { id: $orgId }
        data: {
          name: $newOrgName
          address: $orgAddress
          country: $orgCountry
          domain: $orgDomain
        }
      }
    ) {
      org {
        id
        name
        address
        country
        domain
        no_license
        courseOrder
        primaryCourseOrder
      }
    }
  }
`;

// const UPDATE_COURSES_MUTATION = gql`
//   mutation($orgId: ID!, $primary_courses: [ID]) {
//     customUpdateOrg(
//       input: {
//         where: { id: $orgId }
//         data: { primary_courses: $primary_courses }
//       }
//     ) {
//       org {
//         id
//         name
//         primaryCourseOrder
//         primary_courses {
//           id
//           Title
//           isDisabled
//           org_Denied
//           DisabledCourses
//           courses {
//             id
//             title
//           }
//         }
//         courseOrder
//         learners {
//           id
//           username
//           email
//           totalLessons
//           completedLessons
//           courseCompletedPercentage
//           completedCourses
//           totalCourses
//         }
//         courses {
//           id
//           title
//           orgCourseRefresh
//           isDisabled
//           org_Denied
//           lessons {
//             id
//             title
//           }
//         }
//       }
//     }
//   }
// `;

// const UPDATE_ORG_PRIMARY_COURSES_MUTATION = gql`
//   mutation(
//     $orgId: ID!
//     $primary_courses: [ID]
//     $primaryCourseOrder: JSON
//   ) {
//     updateOrg(
//       input: {
//         where: { id: $orgId }
//         data: {
//           primary_courses: $primary_courses
//           primaryCourseOrder: $primaryCourseOrder
//         }
//       }
//     ) {
//       org {
//         id
//         name
//         primaryCourseOrder
//         primary_courses {
//           id
//           Title
//           isDisabled
//           org_Denied
//           DisabledCourses
//           courses {
//             id
//             title
//           }
//         }
//         courseOrder
//       }
//     }
//   }
// `;

const LEARNERS_QUERY = gql`
  query GetOrgUserList(
    $pageNumber: Int!
    $pageSize: Int!
    $search: String
    $sortBy: String
    $sortOrder: String
    $orgId: Int
  ) {
    getOrgUserList(
      pageNumber: $pageNumber
      pageSize: $pageSize
      search: $search
      sortBy: $sortBy
      sortOrder: $sortOrder
      orgId: $orgId
    ) {
      isSuccess
      statusCode
      statusMessage
      total
      EmployeeIDType
      data {
        user_id
        name
        email
        blocked
        total
        completed
        EmployeeID
      }
    }
  }
`;

const UPDATE_ORG_PRIMARY_COURSES_MUTATION = gql`
  mutation(
    $orgId: ID!
    $primary_courses: [ID]
    $primarycourseOrder: JSON
  ) {
    customUpdateOrg(
      input: {
        where: { id: $orgId }
        data: {
          primary_courses: $primary_courses
          primaryCourseOrder: $primarycourseOrder
        }
      }
    ) {
      org {
        id
        name
        primaryCourseOrder
        primary_courses {
          id
          Title
          isDisabled
          org_Denied
          DisabledCourses
          courses {
            id
            title
          }
        }
        courseOrder
        learners {
          id
          username
          email
          totalLessons
          completedLessons
          courseCompletedPercentage
          completedCourses
          totalCourses
        }
        courses {
          id
          title
          orgCourseRefresh
          isDisabled
          org_Denied
          lessons {
            id
            title
          }
        }
      }
    }
  }
`;

const ORG_COURSE_ORDER_MUTATION = gql`
  mutation($orgId: ID!, $primarycourseOrder: JSON) {
    customUpdateOrg(
      input: {
        where: { id: $orgId }
        data: { primaryCourseOrder: $primarycourseOrder }
      }
    ) {
      org {
        id
        name
        primaryCourseOrder
        primary_courses {
          id
          Title
          isDisabled
          org_Denied
          DisabledCourses
          courses {
            id
            title
          }
        }
        courseOrder
        learners {
          id
          username
          email
          totalLessons
          completedLessons
          courseCompletedPercentage
          completedCourses
          totalCourses
        }
        courses {
          id
          title
          orgCourseRefresh
          isDisabled
          org_Denied
          lessons {
            id
            title
          }
        }
      }
    }
  }
`;

// const UPDATE_ORG_COURSE_STATUS_MUTATION = gql`
//   mutation($orgId: ID!, $courseId: ID!) {
//     disableCourseForOrganization(courseID: $courseId, orgId: $orgId) {
//       course {
//         id
//         title
//         isDisabled
//         orgCourseRefresh
//         org_Denied
//         lessons {
//           id
//           title
//         }
//       }
//     }
//   }
// `;
const UPDATE_PRIMARY_COURSE_STATUS_FOR_ORG_MUTATION = gql`
  mutation($courseId: ID!, $org_Denied: JSON) {
    updatePrimaryCourse(
      input: {
        where: { id: $courseId }
        data: { org_Denied: $org_Denied }
      }
    ) {
      primaryCourse {
        id
        Title
        isDisabled
        org_Denied
        DisabledCourses
        courses {
          id
          title
        }
      }
    }
  }
`;

const ALL_COURSES_QUERY = gql`
  query {
    primaryCourses {
      id
      Title
      org_Denied
      isDisabled
    }
  }
`;

const ENABLE_DISABLE_ORG_LEARNERS_MUTATION = gql`
  mutation($userId: ID!, $isDisabled: Boolean) {
    updateUser(
      input: {
        where: { id: $userId }
        data: { blocked: $isDisabled }
      }
    ) {
      user {
        id
        email
        username
        blocked
      }
    }
  }
`;

const DELETE_USER = gql`
  mutation($userId: ID!) {
    deleteUser(input: { where: { id: $userId } }) {
      user {
        id
      }
    }
  }
`;
const INVITE_CREATE_NEW_USER = gql`
  mutation(
    $email: String!
    $roleName: String!
    $orgId: ID
    $firstName: String!
    $lastName: String
    $mobile: String
  ) {
    customRegister(
      input: {
        email: $email
        role: $roleName
        orgId: $orgId
        firstName: $firstName
        lastName: $lastName
        mobile: $mobile
      }
    ) {
      user {
        id
        username
        email
        confirmed
        blocked
      }
    }
  }
`;
const INVITE_NEW_USER = gql`
  mutation(
    $email: String!
    $roleName: String!
    $orgId: ID
    $firstName: String!
    $lastName: String
  ) {
    customRegister(
      input: {
        email: $email
        role: $roleName
        orgId: $orgId
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      user {
        id
        username
        email
        confirmed
        blocked
      }
    }
  }
`;
export default {
  name: 'OrgDetail',
  components: {
    SubscriptionsList,
    OrgLearnersErrorSummary
  },
  data: () => ({
    valid: true,
    searchSubscription: '',
    nameRules: [
      v => !!v || 'Name is required',
      v =>
        (v && v.length <= 50) ||
        'Name must be less than 50 characters'
    ],
    roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER'
    },

    hideDelete: process.env.VUE_APP_HIDEDELETE_STATUS,
    Loginuser: {},
    courseSearch: '',
    totalLearners: 0,
    loadingLearner: false,
    learnerSearch: '',
    confirmdialog: {
      show: false,
      HeaderText: '',
      ContentText: '',
      ButtonConfirmText: '',
      ButtonConfirmAction: '',
      customObject: null
    },
    editCourseDuration: false,
    courseRefresh: null,
    courseRefreshDuration: null,
    licenseconfirmdialog: false,
    oldnoOfLicense: '',
    switch1: true,
    showNewDialog: false,
    showNewDialogForUpload: false,
    showNewDialogForAdmin: false,
    showNewDialogForViewers: false,
    dialoglicensealert: false,
    tab: null,
    currentcourseId: null,
    alertBox: false,
    alertBoxText: { text: '' },
    alertBoxType: '',
    alertSuccessText: null,
    alertFailureText: null,
    isUploading: false,
    loading: false,
    uploadSuccessMessage: '',
    defaultTemplateForLearnerUpload:
      process.env.VUE_APP_DEFAULT_LEARNER_IMPORT_TEMPLATE ||
      'https://dementiastaticbucket.s3.us-east-2.amazonaws.com/excel-upload+for+add+learners.csv',
    tabItems: [
      { id: 0, text: 'Subscriptions', icon: 'mdi-file' },
      { id: 3, text: 'Learners', icon: 'mdi-book-account' },
      {
        id: 4,
        text: 'Administrators',
        icon: 'mdi-account-supervisor'
      },
      {
        id: 5,
        text: 'Viewers',
        icon: 'mdi-account-supervisor'
      },
      { id: 1, text: 'Details', icon: 'mdi-account-details' }
      // { id: 2, text: 'Courses', icon: 'mdi-school' }
    ],
    slectedTab: 0,
    lessonscompleted: 0,
    index: '',
    courseHeaders: [
      { text: 'Name', value: 'Title' },
      { text: 'Modules', value: 'courses', align: 'left' },
      { text: 'Status', value: 'isDisabled', align: 'left' },
      {
        text: 'Sort',
        value: 'sort',
        sortable: false,
        align: 'center'
      },
      {
        text: 'Action',
        value: 'action',
        sortable: false,
        align: 'left'
      }
    ],
    adminHeaders: [
      { text: 'Name', value: 'username' },
      { text: 'Email', value: 'email', align: 'left' },
      { text: 'Mobile', value: 'mobile', align: 'center' }
    ],
    learnerHeaders: [
      { text: 'Employee ID', value: 'EmployeeID' },
      { text: 'Name', value: 'name' },
      { text: 'Email', value: 'email' },
      {
        text: 'Lessons Completed',
        value: 'completed',
        sortable: false,
        align: 'center'
      },
      {
        text: 'Action',
        value: 'action',
        sortable: false,
        align: 'left'
      }
    ],
    learners: [],
    pageSize: 20,
    page: 1,
    options: {},
    org: {
      courseOrder: [],
      primaryCourseOrder: [],
      courses: [],
      learners: [],
      no_license: 0,
      primary_courses: []
    },
    allCourses: [],
    allLearners: [],
    local: {
      org: {}
    },
    forms: {
      newCourseId: null,
      newUserId: null
    },
    newLearner: {
      email: null,
      firstName: null,
      lastName: null
    },
    newAdmin: {
      email: null,
      firstName: null,
      lastName: null,
      mobile: null
    },
    newViewer: {
      email: null,
      firstName: null,
      lastName: null,
      mobile: null
    },
    alert: false,
    alertText: { text: '' },
    alertType: 'success',
    EmployeeIDTypes: [
      {
        value: 'AlphaNumeric',
        label: 'AlphaNumeric'
      },
      {
        value: 'EmailAddress',
        label: 'Email Address'
      },
      {
        value: 'None',
        label: 'None'
      }
    ],
    learnerReportloader: false,
    EmployeeIDType: 'None',
    MobileHelpMessage: '',
    learnersEmployeeIDType: '',
    summaryFailedList: [],
    oldSuccessList: [],
    summaryCount: {},
    uploadTotalRecords: 0,
    isShowLearnersReport: false
  }),
  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating'
    ]),
    computedLearnerHeaders() {
      const self = this;
      const EmpType = self.learnersEmployeeIDType;
      return self.learnerHeaders.filter(t => {
        return (
          (self.IsORGViewer ? t.text != 'Action' : true) &&
          (EmpType !== 'AlphaNumeric' && EmpType !== 'EmailAddress'
            ? t.text != 'Employee ID'
            : true)
        );
      });
    },
    computedCourseHeaders() {
      if (this.IsORGViewer || this.IsORGAdmin)
        return this.courseHeaders.filter(
          t => t.text != 'Action' && t.text != 'Sort'
        );
      else return this.courseHeaders;
    },
    computedtabItems() {
      if (this.IsDementiaAdmin || this.IsORGAdmin)
        return this.tabItems.filter(t => t.text != 'Courses');
      else return this.tabItems;
    },
    IsDementiaAdmin() {
      if (
        this.getUser?.user?.role?.name === this.roles.DEMENTIA_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsCMSAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.CMS_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGViewer() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_VIEWER) {
        return true;
      } else {
        return false;
      }
    },
    orgId() {
      return this.$route.params.orgId;
    },
    availableCourses() {
      if (!this.org.primary_courses)
        return this.allCourses.map(i => ({
          text: i.Title,
          value: i.id
        }));
      return this.allCourses
        .filter(
          i => !this.org.primary_courses.some(j => j.id == i.id)
        )
        .map(i => ({ text: i.Title, value: i.id }));
    },

    // orderedCourses() {
    //    //here we dealing with primary courses
    //   if (!this.org.courseOrder) return this.org.courses;
    //   if (this.org.courseOrder.length != this.org.courses.length)
    //     return this.org.courses;

    //   // Create index of courses
    //   let courses = this.org.courses.reduce((acc, i) => {
    //     acc[i.id] = i;
    //     return acc;
    //   }, {});

    //   // First add courses with order
    //   let orderedCourses = [];
    //   this.org.courseOrder.forEach(l => {
    //     orderedCourses.push(courses[l]);
    //     delete courses[l];
    //   });

    //   // Then add unordered courses
    //   //orderedCourses.push(...Object.values(courses));
    //   return orderedCourses;
    // },
    orderedCourses() {
      //here we dealing with primary courses
      if (!this.org.primaryCourseOrder)
        return this.org.primary_courses;
      if (
        this.org.primaryCourseOrder.length !=
        this.org.primary_courses.length
      )
        return this.org.primary_courses;

      // Create index of courses
      let primarycourses = this.org.primary_courses.reduce(
        (acc, i) => {
          acc[i.id] = i;
          return acc;
        },
        {}
      );

      // First add courses with order
      let orderedPrimaryCourses = [];
      this.org.primaryCourseOrder.forEach(l => {
        if (primarycourses[l]) {
          orderedPrimaryCourses.push(primarycourses[l]);
          delete primarycourses[l];
        }
      });

      // Then add unordered courses
      orderedPrimaryCourses.push(...Object.values(primarycourses));
      return orderedPrimaryCourses;
    },
    breadcrumbs() {
      if (this.IsORGAdmin || this.IsORGViewer) {
        return [{ text: ' ' }];
      } else {
        return [{ text: 'Back', href: `#/organisations` }];
      }
    }
  },
  watch: {
    options: {
      handler() {
        this.learnersData();
      }
    },
    deep: true
  },
  methods: {
    learnersData(pageNum = false) {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let sortDirection = null;
      let sort_var = null;
      let page_nuber = pageNum ? 0 : page ? page : this.page;
      this.loadingLearner = true;
      if (sortBy && sortBy[0]) {
        sortDirection = sortDesc[0] ? 'desc' : 'asc';
        sort_var = sortBy[0];
      }
      this.$apollo
        .query({
          query: LEARNERS_QUERY,
          fetchPolicy: 'network-only',
          variables: {
            pageNumber: page_nuber,
            pageSize: itemsPerPage ? itemsPerPage : this.pageSize,
            search: this.learnerSearch,
            sortBy: sort_var,
            sortOrder: sortDirection,
            orgId: parseInt(this.orgId)
          },
          skip() {
            return !this.isAuthenticated;
          }
        })
        .then(response => {
          this.learners = response.data.getOrgUserList.data;
          if (
            this.learners.length > 0 &&
            (pageNum === true || this.isShowLearnersReport === false)
          ) {
            this.isShowLearnersReport = true;
          }
          this.totalLearners = response.data.getOrgUserList.total;
          this.learnersEmployeeIDType =
            response.data.getOrgUserList.EmployeeIDType;
          this.loadingLearner = false;
        });
    },
    refetchOrgDetails() {
      this.$apollo.queries.org.refetch();
    },
    adminPaginationStatus() {
      if (this.org && this.org.admins && this.org.admins.length > 0)
        return false;
      else return true;
    },
    coursePaginationStatus() {
      if (this.orderedCourses && this.orderedCourses.length > 0)
        return false;
      else return true;
    },
    learnerPaginationStatus() {
      if (
        this.org &&
        this.org.learners &&
        this.org.learners.length > 0
      )
        return false;
      else return true;
    },
    viewerPaginationStatus() {
      if (this.org && this.org.viewers && this.org.viewers.length > 0)
        return false;
      else return true;
    },
    enableOrDisablelearner(learnerId, status) {
      const self = this;
      this.$apollo.mutate({
        mutation: ENABLE_DISABLE_ORG_LEARNERS_MUTATION,
        variables: {
          userId: learnerId,
          isDisabled: status
        },
        update: (store, { data: { updateUser } }) => {
          if (!updateUser.user) return;
          // Get the current store state
          const data = store.readQuery({
            query: ORG_QUERY,
            variables: {
              orgId: this.orgId
            }
          });

          const _learner = self.learners.find(
            learner => learner.user_id === learnerId
          );
          _learner.blocked = status;

          // Write the list back into the cache
          store.writeQuery({
            query: ORG_QUERY,
            variables: { orgId: this.orgId },
            data
          });
        }
      });
    },
    updateCourseDuration(courseId, duration) {
      this.currentcourseId = courseId;
      this.courseRefresh = duration;
      this.editCourseDuration = true;
    },
    setCourseDuration() {
      this.$apollo.mutate({
        mutation: UPDATE_COURSE_DURATION_MUTATION,
        variables: {
          courseId: parseInt(this.currentcourseId),
          courseRefresh: this.courseRefresh,
          orgId: this.orgId
        },
        update: (store, { data: { orgAdminUpdateCourse } }) => {
          if (!orgAdminUpdateCourse.course) return;
          const data = store.readQuery({
            query: ORG_QUERY,
            variables: {
              orgId: this.orgId
            }
          });
          var courses = data.orgMeasures.courses.filter(function(
            course
          ) {
            if (course.id == orgAdminUpdateCourse.course.id) {
              return course;
            }
          });
          if (courses.length > 0) {
            courses[0].orgCourseRefresh =
              orgAdminUpdateCourse.course.orgCourseRefresh;
          }
          store.writeQuery({
            query: ORG_QUERY,
            variables: {
              orgId: this.orgId
            },
            data
          });
        }
      });
      this.editCourseDuration = false;
    },
    clearAlert() {
      this.alertBox = false;
      this.alertBoxText = { text: '' };
      this.alertBoxType = '';
      this.alertSuccessText = null;
      this.alertFailureText = null;
    },
    uploadExcel() {
      if (!this.$refs.file.files[0]) return;
      this.loading = true;
      this.isUploading = true;
      let blob = this.$refs.file.files[0];
      if (blob.name.includes('.csv' || '.CSV')) {
        blob = blob.slice(0, blob.size, 'text/csv');
      }
      const uploadUrl =
        process.env.VUE_APP_BASE_API + '/orgAdmin/bulkUploadLearners';

      const headerOptions = {
        Authorization:
          'Bearer ' + localStorage.getItem(Constant.AUTH_TOKEN)
      };

      let data = new FormData();
      data.append('file', blob, blob.name);
      data.append('OrgId', this.orgId);
      fetch(uploadUrl, {
        method: 'POST',
        body: data,
        headers: headerOptions
      })
        .then(res => res.json())
        .then(res => {
          this.alertBoxType = res.message ? res.message : res.heading;
          this.summaryFailedList =
            res.summaryFailed && res.summaryFailed.length > 0
              ? res.summaryFailed
              : [];
          this.oldSuccessList =
            res.success && res.success.length > 0 ? res.success : [];
          this.summaryCount = res.summaryCount
            ? res.summaryCount
            : {};
          this.uploadTotalRecords = res.totalRecords
            ? res.totalRecords
            : 0;
          this.loading = false;
          this.alertBox = true;
          this.showNewDialogForUpload = false;
          this.learnersData(true);
          this.refetchOrgDetails();
          res.success
            ? res.success.length > 0
              ? (this.alertSuccessText = res.success)
              : null
            : null;

          res.failed
            ? res.failed.length > 0
              ? (this.alertFailureText = res.failed)
              : null
            : null;

          this.alertBoxText.text = ` ${
            this.alertSuccessText
              ? '<h4>Learners uploaded successfully:</h4>'
              : ''
          } ${
            this.alertSuccessText
              ? `<div>${this.alertSuccessText.join('<br/>')}</div>`
              : ''
          } ${
            this.alertFailureText
              ? '<h4>Learners upload failed :</h4>'
              : ''
          } ${
            this.alertFailureText
              ? `<div>${this.alertFailureText.join('<br/>')}</div>`
              : ''
          }`;
          this.cancelUpload();
        })
        .catch(err => {
          this.showNewDialogForUpload = false;
          this.cancelUpload();
          this.loading = false;
          this.alertBoxType = '';
          this.alertBox = true;
          this.alertBoxText.text = err.message;
        })
        .finally(() => {
          this.cancelUpload();
        });
    },
    getIsCourseBlockedByORG(item) {
      var orgid = this.org.id;
      if (!item.org_Denied || !Array.isArray(item.org_Denied))
        return false;
      if (item.org_Denied == 1) return false;
      var check = item.org_Denied.filter(function(elm) {
        if (elm == orgid) {
          return elm;
        }
      });
      if (check.length > 0) return true;
      else return false;
    },
    getRefresh(refreshitem) {
      if (!refreshitem.orgCourseRefresh) return 0;
      const Item = refreshitem.orgCourseRefresh.filter(
        r => r.OrgId === this.orgId
      );
      return Item.length > 0 ? Item[0].CourseRefresh : 0;
    },
    getCourseIndex(item) {
      if (!this.orderedCourses) return 0;
      return this.orderedCourses
        .filter(o => o != 'undefined')
        .map(function(x) {
          if (x) return x.id;
        })
        .indexOf(item.id);
    },
    setActiveTab(id) {
      this.slectedTab = id;
    },
    enableOrDisableCourse(item, status, orgId) {
      var orgdenielist = item.org_Denied;
      if (status === true) {
        //enable the module for course so pop moduleid from disabled list
        if (orgdenielist && orgdenielist.length > 0) {
          orgdenielist = orgdenielist.filter(function(id) {
            if (id != orgId) {
              return id;
            }
          });
        }
      } else {
        //enable the primary course so pop any org id from ord denied field of primary course entity
        if (orgdenielist && orgdenielist.length > 0) {
          orgdenielist.push(orgId);
        } else {
          orgdenielist = [orgId];
        }
      }
      this.$apollo.mutate({
        mutation: UPDATE_PRIMARY_COURSE_STATUS_FOR_ORG_MUTATION,
        variables: {
          courseId: item.id,
          org_Denied: orgdenielist
        },
        update: (store, { data: { updatePrimaryCourse } }) => {
          // Only update the store if there's a new course created
          if (!updatePrimaryCourse.primaryCourse) return;
          //Get the current store state
          const data = store.readQuery({
            query: ORG_QUERY,
            variables: {
              orgId: orgId
            }
          });
          // Push the new course onto the cache
          var course = data.orgMeasures.primary_courses.filter(
            function(course) {
              if (course.id == item.id) {
                return course;
              }
            }
          );
          if (course.length > 0) {
            course[0].org_Denied =
              updatePrimaryCourse.primaryCourse.org_Denied;
          }

          // Write the list back into the cache
          store.writeQuery({
            query: ORG_QUERY,
            variables: {
              orgId: orgId
            },
            data
          });
        }
      });
    },
    moveCourse(from, to) {
      const newOrder = this.orderedCourses.map(l => l.id);
      const [item] = newOrder.splice(from, 1);
      newOrder.splice(to, 0, item);
      this.$apollo.mutate({
        mutation: ORG_COURSE_ORDER_MUTATION,
        variables: {
          orgId: this.org.id,
          primarycourseOrder: newOrder
        }
      });
    },
    checkLicenseUpdate() {
      if (!this.$refs.orgform[0].validate()) return;
      this.updateOrg();
      // const oldnolic = parseInt(this.oldnoOfLicense);
      // const currentnolic = parseInt(this.local.org.noOfLicense);
      // const consumedlicense = parseInt(this.org.learners.length);
      // if (
      //   oldnolic < currentnolic ||
      //   consumedlicense <= currentnolic
      // ) {
      //   this.licenseconfirmdialog = true;
      // }
      // if (oldnolic == currentnolic) {
      //   this.updateOrg();
      // }
      // if (consumedlicense > currentnolic) {
      //   this.local.org.noOfLicense = oldnolic;
      //   this.dialoglicensealert = true;
      // }
    },
    updateOrg() {
      this.licenseconfirmdialog = false;
      this.MobileHelpMessage =
        this.EmployeeIDType !== 'None' ? this.MobileHelpMessage : '';
      this.$apollo.mutate({
        mutation: this.IsDementiaAdmin
          ? UPDATE_ORG_MUTATION_FOR_DA
          : UPDATE_ORG_MUTATION,
        variables: this.IsDementiaAdmin
          ? {
              orgId: this.org.id,
              newOrgName: this.local.org.newOrgName,
              orgAddress: this.local.org.orgAddress,
              orgCountry: this.local.org.orgCountry,
              orgDomain: this.local.org.orgDomain,
              EmployeeIDType: this.EmployeeIDType,
              MobileHelpMessage: this.MobileHelpMessage
              // noOfLicense: parseInt(this.local.org.noOfLicense)
            }
          : {
              orgId: this.org.id,
              newOrgName: this.local.org.newOrgName,
              orgAddress: this.local.org.orgAddress,
              orgCountry: this.local.org.orgCountry,
              orgDomain: this.local.org.orgDomain
            },
        update: (store, { data: { updateOrg } }) => {
          if (!updateOrg.org) return;
          // Get the current store state
          const data = store.readQuery({
            query: ORG_QUERY,
            variables: {
              orgId: this.orgId
            }
          });
          data.orgMeasures.name = updateOrg.org.name;
          data.orgMeasures.address = updateOrg.org.address;
          data.orgMeasures.country = updateOrg.org.country;
          data.orgMeasures.domain = updateOrg.org.domain;
          data.orgMeasures.EmployeeIDType =
            updateOrg.org.EmployeeIDType;
          data.orgMeasures.MobileHelpMessage =
            updateOrg.org.MobileHelpMessage;
          // data.orgMeasures.no_license = updateOrg.org.no_license;
          // Write the list back into the cache
          store.writeQuery({
            query: ORG_QUERY,
            variables: { orgId: this.orgId },
            data
          });
          this.learnersData();
          this.alertType = 'success';
          this.alert = true;
          this.alertText.text = 'Success';
          setTimeout(() => {
            this.alert = false;
            this.alertText.text = '';
          }, 3000);
        }
      });
    },
    addCourse() {
      const courseId = this.forms.newCourseId;
      if (!courseId) return;

      let courses = this.org.primary_courses.map(c => c.id);
      if (courses.includes(courseId)) return;
      courses.push(courseId);

      //primarycourseorder need to be updated
      if (
        this.org.primaryCourseOrder &&
        this.org.primaryCourseOrder.length > 0
      ) {
        this.org.primaryCourseOrder.push(courseId);
      } else {
        this.org.primaryCourseOrder = [courseId];
      }

      this.$apollo.mutate({
        mutation: UPDATE_ORG_PRIMARY_COURSES_MUTATION,
        variables: {
          orgId: this.org.id,
          primary_courses: courses,
          primaryCourseOrder: this.org.primaryCourseOrder
        },
        update: (store, { data: { customUpdateOrg } }) => {
          if (!customUpdateOrg.org.primary_courses) return;
          // Get the current store state
          const data = store.readQuery({
            query: ORG_QUERY,
            variables: {
              orgId: this.orgId
            }
          });
          data.orgMeasures.primary_courses =
            customUpdateOrg.org.primary_courses;
          data.orgMeasures.primaryCourseOrder =
            customUpdateOrg.org.primaryCourseOrder;
          // Write the list back into the cache
          store.writeQuery({
            query: ORG_QUERY,
            variables: { orgId: this.orgId },
            data
          });

          this.alert = true;
          this.alertType = 'success';
          this.alertText.text = 'Success';
          setTimeout(() => {
            this.alert = false;
            this.alertText.text = '';
          }, 3000);
        }
      });
      this.showNewDialog = false;
    },
    createNewUser() {
      if (!this.$refs.form.validate()) return;
      this.Constant = Constant;

      this.$apollo
        .mutate({
          mutation: INVITE_CREATE_NEW_USER,
          variables: {
            email: this.newAdmin.newEmail,
            roleName: Constant.roles.ORG_ADMIN,
            orgId: this.org.id,
            firstName: this.newAdmin.newFirstName,
            lastName: this.newAdmin.newLastName,
            mobile: this.newAdmin.newMobileNum
          },
          update: (store, { data: { customRegister } }) => {
            // Only update the store if there's a new user created
            if (!customRegister.user) return;
            this.refetchOrgDetails();
            this.$refs.form.reset();
            this.alertType = 'success';
            this.alert = true;
            this.alertText.text = 'Success';
            setTimeout(() => {
              this.alert = false;
              this.alertText.text = '';
            }, 3000);
          }
        })
        .catch(e => {
          this.alertType = 'error';
          this.alert = true;
          this.alertText.text = JSON.stringify(
            e.graphQLErrors[0].extensions.exception.data.message[0]
              .messages[0].message
          ).replace(/['"]+/g, '');
          setTimeout(() => {
            this.alert = false;
            this.alertText.text = '';
            this.alertType = 'success';
          }, 13000);
        });

      this.showNewDialogForAdmin = false;
    },
    createViewer() {
      if (!this.$refs.form.validate()) return;
      this.Constant = Constant;

      this.$apollo
        .mutate({
          mutation: INVITE_CREATE_NEW_USER,
          variables: {
            email: this.newViewer.newEmail,
            roleName: Constant.roles.ORG_VIEWER,
            firstName: this.newViewer.newFirstName,
            orgId: this.org.id,
            lastName: this.newViewer.newLastName,
            mobile: this.newViewer.newMobileNum
          },
          update: (store, { data: { customRegister } }) => {
            // Only update the store if there's a new user created
            if (!customRegister.user) return;
            this.refetchOrgDetails();
            this.$refs.form.reset();
            this.alertType = 'success';
            this.alert = true;
            this.alertText.text = 'Success';
            setTimeout(() => {
              this.alert = false;
              this.alertText.text = '';
            }, 3000);
          }
        })
        .catch(e => {
          this.alertType = 'error';
          this.alert = true;
          this.alertText.text = JSON.stringify(
            e.graphQLErrors[0].extensions.exception.data.message[0]
              .messages[0].message
          ).replace(/['"]+/g, '');
          setTimeout(() => {
            this.alert = false;
            this.alertText.text = '';
            this.alertType = 'success';
          }, 13000);
        });

      this.showNewDialogForViewers = false;
    },

    LoadConfirmDialogForRemoveCourse(item) {
      this.confirmdialog.HeaderText = 'Are you sure?';
      this.confirmdialog.ContentText =
        'Course will be removed from current organisation';
      this.confirmdialog.ButtonConfirmText = "Yes I'm sure";
      this.confirmdialog.show = true;
      this.confirmdialog.customObject = item;
      this.confirmdialog.ButtonConfirmAction = this.removeCourse;
    },
    removeCourse(item) {
      let courses = this.org.primary_courses
        .filter(c => c.id != item.id)
        .map(c => c.id);

      //primarycourseorder need to be updated
      if (
        this.org.primaryCourseOrder &&
        this.org.primaryCourseOrder.length > 0
      ) {
        this.org.primaryCourseOrder = this.org.primaryCourseOrder.filter(
          courseid => courseid != item.id
        );
      }

      this.$apollo.mutate({
        mutation: UPDATE_ORG_PRIMARY_COURSES_MUTATION,
        variables: {
          orgId: this.org.id,
          primary_courses: courses,
          primaryCourseOrder: this.org.primaryCourseOrder
        },
        update: (store, { data: { customUpdateOrg } }) => {
          if (!customUpdateOrg.org.primary_courses) return;
          // Get the current store state
          const data = store.readQuery({
            query: ORG_QUERY,
            variables: { orgId: this.orgId }
          });
          data.orgMeasures.primary_courses =
            customUpdateOrg.org.primary_courses;
          data.orgMeasures.primaryCourseOrder =
            customUpdateOrg.org.primaryCourseOrder;
          // Write the list back into the cache
          store.writeQuery({
            query: ORG_QUERY,
            variables: { orgId: this.orgId },
            data
          });
        }
      });
      this.confirmdialog.show = false;
    },
    addLearner() {
      if (!this.$refs.form.validate()) return;

      this.$apollo
        .mutate({
          mutation: INVITE_NEW_USER,
          variables: {
            email: this.newLearner.email,
            roleName: Constant.roles.LEARNER,
            orgId: this.orgId,
            firstName: this.newLearner.firstName,
            lastName: this.newLearner.lastName
          },
          update: (store, { data: { customRegister } }) => {
            // Only update the store if there's a new user created
            if (!customRegister.user) return;
            this.refetchOrgDetails();
            this.$refs.form.reset();
            this.alertType = 'success';
            this.alert = true;
            this.alertText.text = 'Success';
            setTimeout(() => {
              this.alert = false;
              this.alertText.text = '';
            }, 3000);
          }
        })
        .catch(e => {
          this.alertType = 'error';
          this.alert = true;
          this.alertText.text = JSON.stringify(
            e.graphQLErrors[0].extensions.exception.data.message[0]
              .messages[0].message
          ).replace(/['"]+/g, '');
          setTimeout(() => {
            this.alert = false;
            this.alertText.text = '';
          }, 13000);
        });

      this.showNewDialog = false;
    },
    LoadConfirmDialogForRemoveLearner(item) {
      this.confirmdialog.HeaderText = 'Are you sure?';
      this.confirmdialog.ContentText =
        'Learner will be removed from current organisation';
      this.confirmdialog.ButtonConfirmText = "Yes I'm sure";
      this.confirmdialog.show = true;
      this.confirmdialog.customObject = item;
      this.confirmdialog.ButtonConfirmAction = this.removeLearner;
    },
    removeLearner(item) {
      this.$apollo.mutate({
        mutation: DELETE_USER,
        variables: {
          userId: item.user_id
        },
        update: (store, { data: { deleteUser } }) => {
          if (!deleteUser) return;

          // Get the current store state
          const data = store.readQuery({
            query: ORG_QUERY,
            variables: { orgId: this.orgId }
          });
          //data.orgMeasures.courses = updateOrg.org.courses;
          // Pop the organizaton from the cache
          data.orgMeasures.learners = data.orgMeasures.learners.filter(
            function(e) {
              return e.id != item.user_id;
            }
          );
          //data.orgs.pop(deleteOrg);
          // Write the list back into the cache
          store.writeQuery({ query: ORG_QUERY, data });
        }
      });
      this.confirmdialog.show = false;
    },
    cancelUpload() {
      this.showNewDialogForUpload = false;
      document.getElementById('inputExcelUpload').value = '';
    },
    cancelInviteLearner() {
      this.showNewDialog = false;
      this.$refs.form.reset();
    },
    cancelInviteAdmin() {
      this.showNewDialogForAdmin = false;
      this.$refs.form.reset();
    },
    cancelInviteViewer() {
      this.showNewDialogForViewers = false;
      this.$refs.form.reset();
    },
    async reportNameGenerator(report) {
      var datetime = new Date();
      var month = datetime.getMonth() + 1;
      const reportName =
        report +
        datetime.getDate() +
        '_' +
        month +
        '_' +
        datetime.getFullYear() +
        '_' +
        datetime.toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        }) +
        '.xlsx';
      return reportName;
    },
    async generateLearnersReport() {
      const self = this;
      self.learnerReportloader = true;
      const orgId = this.orgId;
      var sheetname = await self.reportNameGenerator(
        'LearnerReport_'
      );
      const downloadUrl = `${process.env.VUE_APP_BASE_API}/generateLearnerReport`;
      fetch(downloadUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization:
            'Bearer ' + localStorage.getItem(Constant.AUTH_TOKEN)
        },
        body: JSON.stringify({ orgId })
      })
        .then(response => response.blob())
        .then(blob => {
          saveAs(blob, sheetname);
          self.learnerReportloader = false;
        })
        .catch(err => {
          self.learnerReportloader = false;
          console.log(err);
        });
    },
    getAlertObj(value) {
      const self = this;
      self.alert = value.alert;
      self.alertType = value.alertType;
      self.alertText.text = value.alertText;
    }
  },
  apollo: {
    org() {
      return {
        query: ORG_QUERY,
        variables: {
          orgId: this.orgId
        },
        fetchPolicy: 'network-only',
        update: data => {
          //Get organization details by id and bind the form
          this.local.org.orgId = data.orgMeasures.id;
          this.local.org.newOrgName = data.orgMeasures.name;
          this.local.org.orgAddress = data.orgMeasures.address;
          this.local.org.orgCountry = data.orgMeasures.country;
          this.local.org.orgDomain = data.orgMeasures.domain;
          this.local.org.noOfLicense = data.orgMeasures.no_license;
          this.local.org.code = data.orgMeasures.code;
          this.local.org.nooflearners =
            data.orgMeasures.learners.length;
          this.oldnoOfLicense = data.orgMeasures.no_license;
          this.local.org.noofviewers =
            data.orgMeasures.viewers.length;
          this.local.org.noofadmins = data.orgMeasures.admins.length;
          this.org.learners = data.orgMeasures.learners;
          this.EmployeeIDType = data.orgMeasures.EmployeeIDType;
          this.MobileHelpMessage = data.orgMeasures.MobileHelpMessage;

          return data.orgMeasures;
        }
      };
    },
    allCourses() {
      return {
        query: ALL_COURSES_QUERY,
        fetchPolicy: 'network-only',
        update: data =>
          data.primaryCourses?.filter(c => !c.isDisabled)
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.v-alert {
  left: unset !important;
}
</style>
