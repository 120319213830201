<template>
  <div v-if="IsDementiaAdmin">
    <v-container fluid class="grey lighten-5">
      <v-alert
        dense
        text
        :type="alert.type"
        :value="alert.show"
        transition="slide-y-transition"
        >{{ alert.text }}</v-alert
      >
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <h1>Organisations</h1>
        </v-col>
      </v-row>
    </v-container>
    <div class="page-sub-sec">
      <v-text-field
        v-model="orgSearch"
        append-icon="mdi-magnify"
        label="Search"
        outlined
        dense
        hide-details
        class="shrink"
      />
      <div class="d-flex float-right">
        <!-- <v-btn
          color="primary"
          class="mr-5"
          @click="downloadExcelForOrg"
          ><v-icon>mdi-download</v-icon> Export Learner Details</v-btn
        > -->
        <br />
        <v-btn color="primary" @click="clearform"
          ><v-icon>mdi-plus</v-icon> New Organisation</v-btn
        >
      </div>
    </div>
    <v-data-table
      :items="orgs"
      :headers="computedHeaders"
      :search="orgSearch"
      :no-data-text="`No organisation available`"
      :hide-default-footer="orgsCount < 10 ? true : false"
      :loading="this.$apollo.loading"
      loading-text="Loading... Please wait"
      :page-count="pageSize"
      :page="page"
      :options.sync="options"
      :server-items-length="orgsCount"
      :footer-props="{
        'items-per-page-options': [20, 30, 40, 50, 100, 500]
      }"
    >
      <template v-slot:item.name="{ item }">
        <router-link
          :to="`/organisations/${item.id}`"
          class="blue--text"
          >{{ item.name }}</router-link
        >
      </template>
      <template v-slot:item.code="{ item }">
        {{ item.code }}
      </template>
      <template v-slot:item.totalLicense="{ item }">
        <div>
          <p
            v-for="(license, lIndex) in item.org_products"
            :key="lIndex"
            class="mt-2 mb-2"
          >
            {{ license.product.name }}:
            <a
              class="a-tag-color-blue"
              href="javascript:void(0)"
              @click="goToLicenseList(item.id, license.product.id)"
            >
              {{ license.licenseCount }}</a
            >
          </p>
        </div>
      </template>
      <template v-slot:item.licenceRemaining="{ item }">
        <div>
          <p
            v-for="(license, lIndex) in item.org_products"
            :key="lIndex"
            class="mb-1"
          >
            {{ license.product.name }}: {{ license.licenceRemaining }}
          </p>
        </div>
      </template>
      <template v-slot:item.learners="{ item }">
        {{ item.learners.length }}
      </template>
      <template v-slot:item.admins="{ item }">
        {{ item.admins.length }}
      </template>
      <template v-slot:item.isDisabled="{ item }">
        <div v-if="item.isDisabled" class="gray--text">Inactive</div>
        <div v-else class="success--text">
          Active
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="lessons-actions-wrap">
          <v-btn
            v-if="hideDelete == 'false'"
            color="red"
            icon
            small
            @click="LoadConfirmDialogForDeleteOrg(item.id)"
            ><v-icon title="Delete"
              >mdi-trash-can-outline</v-icon
            ></v-btn
          >
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="showNewDialog" persistent max-width="600px">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title>
            <span class="headline">Add Organisation</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="newOrgName"
                  :counter="50"
                  label="Name*"
                  :rules="nameRules"
                  required
                  @change="generateOrgCode()"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="orgAddress"
                  label="Address*"
                  :rules="[v => !!v || 'Address is required']"
                  required
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="orgCountry"
                  label="Country*"
                  :rules="[v => !!v || 'Country is required']"
                  required
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="orgDomain"
                  label="Domain*"
                  :rules="[v => !!v || 'Domain is required']"
                  required
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="code"
                  label="Organisation Code*"
                  :rules="[
                    v => !!v || 'Organisation Code is required'
                  ]"
                  required
                />
              </v-col>
              <v-col
                v-if="IsDementiaAdmin"
                cols="12"
                sm="6"
                class="pt-1"
              >
                <span class="caption">Employee ID Type*</span>
                <v-select
                  v-model="EmployeeIDType"
                  :items="EmployeeIDTypes"
                  item-value="id"
                  item-text="label"
                  class="pt-0"
                  label="Select"
                  persistent-hint
                  single-line
                  :rules="[
                    v => !!v || 'Employee ID Type is required'
                  ]"
                />
              </v-col>
              <v-col
                v-if="EmployeeIDType !== 'None' && IsDementiaAdmin"
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="MobileHelpMessage"
                  label="Mobile Help Message*"
                  :rules="[
                    v => !!v || 'Mobile help message is required'
                  ]"
                  required
                />
              </v-col>
              <v-spacer />
            </v-row>
          </v-card-text>
          <v-card-actions>
            <small class="info--text"
              >*Please ensure each mandatory field marked with an
              asterisk * is completed</small
            >
            <v-spacer />
            <v-btn depressed text @click="cancelCreateOrg">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="createOrg">
              Create
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmdialogOrgdelete"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">Are you sure</v-card-title>
        <v-card-text
          >Please confirm you wish to remove the
          organisation?</v-card-text
        >
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="confirmdialogOrgdelete = false"
            >Cancel</v-btn
          >
          <v-btn
            color="red darken-1"
            text
            @click="deleteOrg(orgdeleteId)"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//changes made in this module
import gql from 'graphql-tag';
import { mapGetters, mapActions } from 'vuex';
import { AUTH_TOKEN } from '../../vue-apollo';
import * as saveAs from 'file-saver'; // for file download
import OrganizationStoreTypes from '../../store/modules/organization/OrganizationStoreTypes';
import CourseStoreTypes from '../../store/modules/course/CourseStoreTypes';

const ORGS_QUERY = gql`
  query($limit: Int, $first: Int, $sort: String, $where: JSON) {
    orgs(limit: $limit, start: $first, sort: $sort, where: $where) {
      id
      name
      address
      country
      domain
      license
      no_license
      code
      learners {
        username
      }
      isDisabled
      admins {
        username
        email
        mobile
      }
      user_products {
        product {
          id
        }
        user {
          id
        }
      }
      org_products {
        product {
          name
          id
        }
        licenseCount
      }
    }
  }
`;
const CREATE_ORG_MUTATION = gql`
  mutation(
    $newOrgName: String!
    $orgAddress: String!
    $orgCountry: String!
    $orgDomain: String!
    $code: String!
    $EmployeeIDType: ENUM_ORG_EMPLOYEEIDTYPE!
    $MobileHelpMessage: String!
  ) {
    createOrg(
      input: {
        data: {
          name: $newOrgName
          address: $orgAddress
          country: $orgCountry
          domain: $orgDomain
          code: $code
          EmployeeIDType: $EmployeeIDType
          MobileHelpMessage: $MobileHelpMessage
        }
      }
    ) {
      org {
        id
        name
        address
        country
        domain
        license
        no_license
        code
        EmployeeIDType
        MobileHelpMessage
        learners {
          username
        }
        isDisabled
        admins {
          username
          email
          mobile
        }
      }
    }
  }
`;
const DELETE_ORGANIZATION_MUTATION = gql`
  mutation($orgId: ID!) {
    deleteOrg(input: { where: { id: $orgId } }) {
      org {
        id
      }
    }
  }
`;

export default {
  name: 'OrgList',
  data: () => ({
    valid: true,
    nameRules: [
      v => !!v || 'Name is required',
      v =>
        (v && v.length <= 50) ||
        'Name must be less than 50 characters'
    ],
    orgs: [],
    pageSize: 20,
    page: 0,
    options: {},
    roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER'
    },
    hideDelete: process.env.VUE_APP_HIDEDELETE_STATUS,
    confirmdialogOrgdelete: false,
    orgdeleteId: '',
    orgSearch: '',
    data: '',
    showNewDialog: false,
    newOrgName: '',
    orgAddress: '',
    orgCountry: '',
    orgDomain: '',
    noOfLicense: 0,
    primaryAdminName: '',
    primaryAdminEmail: '',
    primaryAdminMobile: '',
    code: null,
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Organisation Code', value: 'code' },
      {
        text: 'Total Licenses',
        value: 'totalLicense',
        sortable: false
      },
      {
        text: 'Licenses Remaining',
        value: 'licenceRemaining',
        sortable: false
      },
      // { text: 'Licences', value: 'no_license' },
      { text: 'Learners', value: 'learners', sortable: false },
      { text: 'Administrator', value: 'admins', sortable: false },
      { text: 'Status', value: 'isDisabled' },
      { text: 'Action', value: 'action', sortable: false }
    ],
    licenseRules: [
      v => !!v || 'No of license is required',
      v => (v && !isNaN(v)) || 'license must be an integer value'
    ],
    alert: {
      type: 'success',
      show: false,
      text: ''
    },
    EmployeeIDType: 'None',
    EmployeeIDTypes: [
      {
        id: 'AlphaNumeric',
        label: 'AlphaNumeric'
      },
      {
        id: 'EmailAddress',
        label: 'Email Address'
      },
      {
        id: 'None',
        label: 'None'
      }
    ],
    MobileHelpMessage: '',
    awaitingSearch: false
  }),
  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating'
    ]),
    computedHeaders() {
      if (this.hideDelete == 'true')
        return this.headers.filter(t => t.text != 'Action');
      else return this.headers;
    },
    IsDementiaAdmin() {
      if (
        this.getUser?.user?.role.name === this.roles.DEMENTIA_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsCMSAdmin() {
      if (this.getUser?.user?.role.name === this.roles.CMS_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGAdmin() {
      if (this.getUser?.user?.role.name === this.roles.ORG_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGViewer() {
      if (this.getUser?.user?.role.name === this.roles.ORG_VIEWER) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    options: {
      handler() {
        this.orgsData();
      }
    },
    deep: true,
    orgSearch: function() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.orgsData();
          this.awaitingSearch = false;
        }, 500); // .5 sec delay
      }
      this.awaitingSearch = true;
    }
  },
  mounted() {
    this.$root.$on('OrgListComponent', () => {
      this.reloadOrgsList();
    });
  },
  methods: {
    organisationPaginationStatus: function() {
      if (this.orgs && this.orgs.length > 0) return false;
      else return true;
    },
    clearform: function() {
      this.showNewDialog = true;
      this.newOrgName = '';
      this.orgAddress = '';
      this.orgCountry = '';
      this.orgDomain = '';
      this.noOfLicense = '';
      this.EmployeeIDType = 'None';
      this.MobileHelpMessage = '';
    },
    createOrg() {
      if (!this.$refs.form.validate()) return;

      const newOrgName = this.newOrgName;
      const orgAddress = this.orgAddress;
      const orgCountry = this.orgCountry;
      const orgDomain = this.orgDomain;
      const noOfLicense = parseInt(this.noOfLicense);
      this.MobileHelpMessage =
        this.EmployeeIDType !== 'None' ? this.MobileHelpMessage : '';
      this.$apollo.mutate({
        mutation: CREATE_ORG_MUTATION,
        variables: {
          newOrgName,
          orgAddress,
          orgCountry,
          orgDomain,
          noOfLicense,
          code: this.code,
          EmployeeIDType: this.EmployeeIDType,
          MobileHelpMessage: this.MobileHelpMessage
        },
        // Why is query not updating!!!
        // This shouldn't be necessary!!!
        update: (store, { data: { createOrg } }) => {
          // Only update the store if there's a new course created
          if (!createOrg.org) return;
          // Get the current store state
          const data = store.readQuery({
            query: ORGS_QUERY
          });
          // Push the new course onto the cache
          data.orgs.push(createOrg.org);
          // Write the list back into the cache
          store.writeQuery({ query: ORGS_QUERY, data });
          this.alert.show = true;
          this.alert.text = 'Success';
          this.alert.type = 'success';

          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        }
      });
      this.showNewDialog = false;
    },
    deleteOrg: function(orgId) {
      this.$apollo.mutate({
        mutation: DELETE_ORGANIZATION_MUTATION,
        variables: {
          orgId: orgId
        },
        update: (store, { data: { deleteOrg } }) => {
          if (!deleteOrg) return;
          // Get the current store state
          this.orgsData(true);
          this.alert.show = true;
          this.alert.text = 'Organization deleted Successfully';
          this.alert.type = 'success';
        }
      });
      this.confirmdialogOrgdelete = false;
    },
    downloadExcelForOrg() {
      var datetime = new Date();
      var month = datetime.getMonth() + 1;
      //custom file name
      var sheetname =
        'DA_LearnerData_' +
        datetime.getDate() +
        '_' +
        month +
        '_' +
        datetime.getFullYear() +
        '_' +
        datetime.toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        }) +
        '.xlsx';
      const downloadUrl =
        process.env.VUE_APP_BASE_API + '/generateexcel';
      fetch(downloadUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN)
        }
      })
        .then(response => response.blob())
        .then(blob => saveAs(blob, sheetname))
        .catch(err => {
          // todo: handle server failure
          console.log(err);
        });
    },
    LoadConfirmDialogForDeleteOrg(id) {
      this.orgdeleteId = id;
      this.confirmdialogOrgdelete = true;
    },
    cancelCreateOrg() {
      this.$refs.form.reset();
      this.showNewDialog = false;
    },
    generateOrgCode() {
      if (this.newOrgName?.length > 2) {
        let newName = this.newOrgName.replace(/ /g, '');
        this.code =
          newName.slice(0, 3) +
          this.randomString(
            5,
            '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
          );
      }
    },
    randomString(length, chars) {
      var result = '';
      for (var i = length; i > 0; --i)
        result += chars[Math.floor(Math.random() * chars.length)];
      return result;
    },
    orgsData(flag = false, reloadCache = false) {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let payload = {};
      payload.first = flag
        ? 1
        : page
        ? page == 1
          ? 0
          : page * this.pageSize - this.pageSize
        : this.page;
      payload.limit = itemsPerPage ? itemsPerPage : this.pageSize;
      if (sortBy[0]) {
        payload.first = null;
        let sortDirection = sortDesc[0] ? 'desc' : 'asc';
        payload.sort = sortBy[0] + ':' + sortDirection;
      }
      if (this.orgSearch) {
        payload.first = null;
        payload.where = {
          name_contains: this.orgSearch
        };
      }
      this.$apollo
        .query({
          query: ORGS_QUERY,
          variables: payload,
          skip() {
            return !this.isAuthenticated;
          },
          fetchPolicy: reloadCache
            ? 'network-only'
            : 'client.watchQuery'
        })
        .then(response => {
          let data = response.data.orgs;
          // process data to get remaining licence
          data.map(v => {
            let mappingData = v.user_products.map(v => {
              return {
                pid: v.product.id
              };
            });

            const groupedData = mappingData.reduce(function(rv, x) {
              (rv[x['pid']] = rv[x['pid']] || []).push(x);
              return rv;
            }, {});

            let tmp_org_products = v.org_products.map(x => {
              const licenceRemaining =
                x.licenseCount -
                (groupedData[x.product.id]
                  ? groupedData[x.product.id].length
                  : 0);
              x.licenceRemaining = licenceRemaining;
              return x;
            });

            v.org_products = tmp_org_products;
            return v;
          });
          // process data to get remaining licence
          this.orgs = data;
        });
    },
    ...mapActions('organization', [
      OrganizationStoreTypes.Actions.UPDATE_LICENSE_FROM_ORG
    ]),
    ...mapActions('course', [
      CourseStoreTypes.Actions.UPDATE_COURSE_FROM_ORG
    ]),
    goToLicenseList(orgId, productId) {
      this.updateLicenseFromOrg('/organisations');
      this.$router.push(`/organisations/Users/${orgId}/${productId}`);
    },
    reloadOrgsList() {
      this.orgsData(false, true);
    }
  },
  created() {
    this.updateCourseFromOrg('');
  },
  apollo: {
    // orgs: {
    //   query: ORGS_QUERY,
    //   variables: {
    //     limit: 1,
    //     first: 0
    //   },
    //   fetchPolicy: 'cache-and-network',
    //   skip() {
    //     return !this.isAuthenticated;
    //   }
    // },
    orgsCount() {
      return {
        query: ORGS_QUERY,
        fetchPolicy: 'cache-and-network',
        update: data => {
          return data?.orgs?.length;
        },
        skip() {
          return !this.isAuthenticated;
        }
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.a-tag-color-blue {
  color: #2196f3;
}
</style>
