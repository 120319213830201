<template>
  <tiptap-vuetify
    v-model="contentLocal"
    class="app-rte-tiptap"
    :extensions="tiptapExtensions"
    :card-props="{ flat: true, outlined: true }"
    :toolbar-attributes="{ class: 'app-rte-toolbar' }"
  />
</template>

<script>
import {
  TiptapVuetify,
  Blockquote,
  Bold,
  Link,
  Italic,
  ListItem,
  BulletList,
  OrderedList
} from 'tiptap-vuetify';

export default {
  name: 'AppRichTextEditor',
  components: { TiptapVuetify },
  props: { value: { type: String, default: '' } },
  data: function() {
    return {
      tiptapExtensions: [
        Blockquote,
        Bold,
        Link,
        Italic,
        ListItem,
        BulletList,
        OrderedList
      ]
    };
  },
  computed: {
    contentLocal: {
      get() {
        return this.value;
      },
      // TipTap will set contentLocal, emit the change event, the parent
      // will mutate and then update the prop? Will that potentially
      // lead to instability if a change returns after user has added more
      // data?
      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>
