var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex justify-center" },
        [
          _c(
            "v-alert",
            {
              staticClass: "v-alert",
              attrs: {
                dense: "",
                text: "",
                type: _vm.alertType,
                value: _vm.alert,
                transition: "slide-y-transition"
              }
            },
            [_vm._v(_vm._s(_vm.alertText.text))]
          )
        ],
        1
      ),
      _vm.IsORGAdmin || (_vm.IsORGViewer && _vm.awaitingUsersCount)
        ? _c("div", [
            _vm._m(0),
            _c(
              "div",
              {
                staticClass: "pa-2 mb-4",
                staticStyle: { "background-color": "#e2f6ff" }
              },
              [
                _c("v-data-table", {
                  staticClass: "dark--text",
                  attrs: {
                    items: _vm.awaiting_users,
                    headers: _vm.awaitngHeader,
                    loading: this.$apollo.loading,
                    "loading-text": "Loading... Please wait",
                    "no-data-text": "No user available",
                    "hide-default-footer":
                      _vm.awaitingUsersCount < 10 ? true : false,
                    "page-count": _vm.awaiting_pageSize,
                    page: _vm.awaiting_page,
                    options: _vm.awaiting_options,
                    "server-items-length": _vm.awaitingUsersCount,
                    "footer-props": {
                      "items-per-page-options": [10, 20, 30, 40, 50, 100, 500]
                    }
                  },
                  on: {
                    "update:options": function($event) {
                      _vm.awaiting_options = $event
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item.EmployeeID",
                        fn: function(ref) {
                          var item = ref.item
                          return [_vm._v(" " + _vm._s(item.EmployeeID))]
                        }
                      },
                      {
                        key: "item.name",
                        fn: function(ref) {
                          var item = ref.item
                          return [_vm._v(" " + _vm._s(item.name))]
                        }
                      },
                      {
                        key: "item.productName",
                        fn: function(ref) {
                          var item = ref.item
                          return [_vm._v(" " + _vm._s(item.productName) + " ")]
                        }
                      },
                      {
                        key: "item.dateCalculated",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(" " + _vm._s(item.dateCalculated) + " ")
                          ]
                        }
                      },
                      {
                        key: "item.isUserRegistered",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            item.isUserRegistered
                              ? _c("div", { staticClass: "success--text" }, [
                                  _vm._v(" True ")
                                ])
                              : _c("div", { staticClass: "warning--text" }, [
                                  _vm._v(" False ")
                                ])
                          ]
                        }
                      },
                      {
                        key: "item.isBlocked",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            item.isBlocked
                              ? _c("div", { staticClass: "warning--text" }, [
                                  _vm._v(" Inactive ")
                                ])
                              : _c("div", { staticClass: "success--text" }, [
                                  _vm._v(" Active ")
                                ])
                          ]
                        }
                      },
                      {
                        key: "item.validationSlot",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "div",
                              { staticClass: "ml-2" },
                              [
                                item.isBlocked
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "ml-2",
                                        attrs: {
                                          color: "grey",
                                          icon: "",
                                          small: ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { right: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {},
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [_vm._v("mdi-check")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  "User is Blocked or Inactive "
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "v-btn",
                                      {
                                        staticClass: "ml-2",
                                        attrs: {
                                          color: "green",
                                          icon: "",
                                          small: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.approveValidation(item)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { title: "Approve" } },
                                          [_vm._v("mdi-check")]
                                        )
                                      ],
                                      1
                                    ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-3",
                                    attrs: {
                                      color: "red",
                                      icon: "",
                                      small: "",
                                      disabled: item.isBlocked
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.rejectValidation(item)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { title: "Reject" } },
                                      [_vm._v("mdi-close")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "page-sub-sec" },
        [
          _c("v-text-field", {
            staticClass: "shrink",
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Search",
              outlined: "",
              dense: "",
              "hide-details": ""
            },
            on: { input: _vm.usersData },
            model: {
              value: _vm.searchEnterpriseLearner,
              callback: function($$v) {
                _vm.searchEnterpriseLearner = $$v
              },
              expression: "searchEnterpriseLearner"
            }
          }),
          _c(
            "div",
            { staticClass: "mr-2" },
            [
              _vm.IsORGAdmin
                ? _c(
                    "v-btn",
                    {
                      staticClass: "success mr-2",
                      attrs: {
                        color: "primary",
                        loading: _vm.gradeReportloading,
                        disabled: !_vm.isShowLearnersReport
                      },
                      on: { click: _vm.generateLearnerReport }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-file-excel")
                      ]),
                      _vm._v("Export Learner Report")
                    ],
                    1
                  )
                : _vm._e(),
              false &&
              (_vm.IsDementiaAdmin || _vm.IsORGAdmin || _vm.IsORGViewer)
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", loading: _vm.reportloading },
                      on: { click: _vm.generateReport }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-file-excel")
                      ]),
                      _vm._v("Generate Report")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "dark--text",
        attrs: {
          items: _vm.users,
          headers: _vm.computedUserHeaders,
          search: _vm.searchEnterpriseLearner,
          "hide-default-footer": _vm.usersCount < 10 ? true : false,
          loading: _vm.loading,
          "loading-text": "Loading... Please wait",
          "no-data-text": "No user available",
          "page-count": _vm.pageSize,
          page: _vm.page,
          options: _vm.options,
          "server-items-length": _vm.usersCount,
          "footer-props": {
            "items-per-page-options": [50, 100, 500]
          }
        },
        on: {
          "update:options": function($event) {
            _vm.options = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.EmployeeID",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        item.EmployeeIDType == "AlphaNumeric" ||
                          item.EmployeeIDType == "EmailAddress"
                          ? item.EmployeeID
                          : ""
                      )
                  )
                ]
              }
            },
            {
              key: "item.name",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "router-link",
                    {
                      staticClass: "blue--text",
                      attrs: { to: "/users/" + item.id }
                    },
                    [_vm._v(" " + _vm._s(item.name))]
                  )
                ]
              }
            },
            {
              key: "item.email",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.email) + " ")]
              }
            },
            {
              key: "item.role.text",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.role.text) + " ")]
              }
            },
            {
              key: "item.orgName",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.orgName) + " ")]
              }
            },
            {
              key: "item.userActive",
              fn: function(ref) {
                var item = ref.item
                return [
                  !item.userActive
                    ? _c("div", { staticClass: "warning--text" }, [
                        _vm._v(" Inactive ")
                      ])
                    : _c("div", { staticClass: "success--text" }, [
                        _vm._v(" Active ")
                      ])
                ]
              }
            },
            {
              key: "item.subscription",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.subscription) + " ")]
              }
            },
            {
              key: "item.action",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm.getLoginedUserId != item.id
                    ? _c(
                        "div",
                        { staticClass: "lessons-actions-wrap" },
                        [
                          !item.userActive
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "green",
                                    icon: "",
                                    small: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.enableOrDisableUser(
                                        item.id,
                                        true
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { title: "Enable" } }, [
                                    _vm._v("mdi-eye")
                                  ])
                                ],
                                1
                              )
                            : _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "orange",
                                    icon: "",
                                    small: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.enableOrDisableUser(
                                        item.id,
                                        false
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { title: "Disable" } },
                                    [_vm._v("mdi-eye-off")]
                                  )
                                ],
                                1
                              ),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--small",
                              attrs: { to: "/users/" + item.id }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "blue--text",
                                  attrs: { title: "Edit" }
                                },
                                [_vm._v("mdi-pencil")]
                              )
                            ],
                            1
                          ),
                          _vm.hideDelete == "false"
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", color: "red", small: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.LoadConfirmDialogForDeleteOrg(
                                        item.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { title: "Delete" } }, [
                                    _vm._v("mdi-trash-can-outline")
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "expanded-item",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "td",
                    {
                      staticStyle: { background: "white", padding: "0px" },
                      attrs: { colspan: _vm.computedUserHeaders.length }
                    },
                    [
                      item.products && item.products.length > 0
                        ? _c("v-simple-table", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function() {
                                    return [
                                      _c("thead", [
                                        _c(
                                          "tr",
                                          {
                                            staticStyle: {
                                              background: "#E5E5E5"
                                            }
                                          },
                                          [
                                            _c(
                                              "th",
                                              { staticClass: "text-left" },
                                              [_vm._v(" Subscription Name ")]
                                            ),
                                            _c(
                                              "th",
                                              { staticClass: "text-left" },
                                              [_vm._v(" Status ")]
                                            )
                                          ]
                                        )
                                      ]),
                                      item.products && item.products.length > 0
                                        ? _c(
                                            "tbody",
                                            _vm._l(item.products, function(
                                              items
                                            ) {
                                              return _c(
                                                "tr",
                                                { key: items.productId },
                                                [
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(items.product)
                                                    )
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        items.productActive
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            }),
                                            0
                                          )
                                        : _c("tbody", [
                                            _c("tr", [
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-center",
                                                  staticStyle: {
                                                    color: "#ababab",
                                                    "font-size": ".875rem"
                                                  },
                                                  attrs: { colspan: "5" }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        "No data available"
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ])
                                          ])
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "450" },
          model: {
            value: _vm.confirmdialog.show,
            callback: function($$v) {
              _vm.$set(_vm.confirmdialog, "show", $$v)
            },
            expression: "confirmdialog.show"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.confirmdialog.HeaderText))
              ]),
              !_vm.confirmdialog.reject
                ? _c("v-card-text", [
                    _vm._v("want to Approve "),
                    _c("span", { staticClass: "success--text" }, [
                      _vm._v(_vm._s(_vm.confirmdialog.ContentText))
                    ]),
                    _vm._v(" validation")
                  ])
                : _vm._e(),
              _vm.confirmdialog.reject
                ? _c("v-card-text", [
                    _vm._v("want to Reject "),
                    _c("span", { staticClass: "warning--text" }, [
                      _vm._v(_vm._s(_vm.confirmdialog.ContentText))
                    ]),
                    _vm._v(" validation")
                  ])
                : _vm._e(),
              _vm.errors.length > 0
                ? _c(
                    "div",
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "pa-1 mt-4",
                          staticStyle: { color: "red" },
                          on: {
                            click: function($event) {
                              _vm.errors = []
                            }
                          }
                        },
                        [_vm._v("mdi-close")]
                      ),
                      _vm._l(_vm.errors, function(items, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "pa-2" },
                          [
                            _c(
                              "v-row",
                              [
                                _c("v-col", [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.length > 1
                                            ? index + 1 + "."
                                            : ""
                                        ) +
                                          " " +
                                          _vm._s(items.message)
                                      )
                                    ]
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmdialog.show = false
                          _vm.errors = []
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.confirmdialog.ButtonConfirmAction(
                            _vm.confirmdialog.customObject
                          )
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.confirmdialog.ButtonConfirmText))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "450" },
          model: {
            value: _vm.confirmdialogUserdelete,
            callback: function($$v) {
              _vm.confirmdialogUserdelete = $$v
            },
            expression: "confirmdialogUserdelete"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Are you sure")
              ]),
              _c("v-card-text", [
                _vm._v("Please confirm you wish to remove the selected user")
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmdialogUserdelete = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.deleteUser(_vm.userdeleteId)
                        }
                      }
                    },
                    [_vm._v("Confirm")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-sub-sec" }, [
      _c("h2", { staticClass: "pa-1" }, [_vm._v("Awaiting Validation")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }