import { render, staticRenderFns } from "./OrgDetail.vue?vue&type=template&id=5a19657d&scoped=true&"
import script from "./OrgDetail.vue?vue&type=script&lang=js&"
export * from "./OrgDetail.vue?vue&type=script&lang=js&"
import style0 from "./OrgDetail.vue?vue&type=style&index=0&id=5a19657d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a19657d",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAlert,VAutocomplete,VBreadcrumbs,VBtn,VCard,VCardActions,VCardText,VCardTitle,VChip,VCol,VContainer,VDataTable,VDialog,VForm,VIcon,VImg,VRow,VSelect,VSlider,VSpacer,VTab,VTabItem,VTabs,VTabsItems,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/var/app/current/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5a19657d')) {
      api.createRecord('5a19657d', component.options)
    } else {
      api.reload('5a19657d', component.options)
    }
    module.hot.accept("./OrgDetail.vue?vue&type=template&id=5a19657d&scoped=true&", function () {
      api.rerender('5a19657d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Organization/OrgDetail.vue"
export default component.exports