<template>
  <div class="overflow-y-scroll">
    <div v-if="loading">
      <div class="text-center">
        <v-progress-circular indeterminate color="black" />
      </div>
    </div>

    <v-container v-if="!loading" fluid class="grey lighten-5">
      <v-alert
        dense
        text
        :type="alert.type"
        :value="alert.show"
        transition="slide-y-transition"
        >{{ alert.text }}</v-alert
      >
      <div v-if="dataLoaded">
        <v-row no-gutters align="center">
          <v-col cols="auto" class="mr-auto">
            <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
            <h1>{{ course.title }}</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-card outlined>
              <v-form
                ref="form"
                v-model="validCourse"
                lazy-validation
              >
                <v-card-text>
                  <div class="section-devider no-bg">
                    <h2>Title</h2>
                    <v-text-field
                      v-model="local.title"
                      :counter="50"
                      :rules="nameRules"
                      prepend-icon="mdi-pencil"
                      placeholder="loading..."
                      required
                    />
                  </div>
                  <div v-if="IsCMSAdmin">
                    <div class="section-devider">
                      <h2>Text Color</h2>
                      <v-color-picker
                        v-model="textcolor"
                        hide-canvas
                        hide-mode-switch
                        mode="hexa"
                        show-swatches
                        swatches-max-height="300"
                      />
                    </div>
                    <div class="section-devider">
                      <h2>SubText Color</h2>
                      <v-color-picker
                        v-model="subtextColour"
                        hide-canvas
                        hide-mode-switch
                        mode="hexa"
                        show-swatches
                        swatches-max-height="300"
                      />
                    </div>
                    <div class="section-devider">
                      <h2>Background Color</h2>
                      <v-color-picker
                        v-model="tilecolor"
                        hide-canvas
                        hide-mode-switch
                        mode="hexa"
                        show-swatches
                        swatches-max-height="300"
                      />
                    </div>

                    <div class="section-devider">
                      <h2>Progress Fill color</h2>
                      <v-color-picker
                        v-model="progressfillColour"
                        hide-canvas
                        hide-mode-switch
                        mode="hexa"
                        show-swatches
                        swatches-max-height="300"
                      />
                    </div>
                  </div>
                  <div v-if="IsDementiaAdmin" class="section-devider">
                    <h2>Color Picker</h2>
                    <v-row
                      justify="space-around"
                      class="colorDisplayRow"
                    >
                      <v-col>
                        <v-row class="colorDisplayRow">
                          <h4>Text Color</h4>
                        </v-row>
                        <v-row class="colorDisplayRow">
                          <div
                            class="text-Color-Picker"
                            :style="{ 'background-color': textcolor }"
                          ></div>
                          <div class="text-display">
                            {{ textcolor }}
                          </div>
                        </v-row>
                      </v-col>
                      <v-col>
                        <v-row>
                          <h4>Subtext Color</h4>
                        </v-row>
                        <v-row>
                          <div
                            class="text-Color-Picker"
                            :style="{
                              'background-color': subtextColour
                            }"
                          ></div>
                          <div class="text-display">
                            {{ subtextColour }}
                          </div>
                        </v-row>
                      </v-col>
                      <v-col>
                        <v-row>
                          <h4>Background Color</h4>
                        </v-row>
                        <v-row>
                          <div
                            class="text-Color-Picker"
                            :style="{ 'background-color': tilecolor }"
                          ></div>
                          <div class="text-display">
                            {{ tilecolor }}
                          </div>
                        </v-row>
                      </v-col>
                      <v-col>
                        <v-row>
                          <h4>Progress Fill Color</h4>
                        </v-row>
                        <v-row>
                          <div
                            class="text-Color-Picker"
                            :style="{
                              'background-color': progressfillColour
                            }"
                          ></div>
                          <div class="text-display">
                            {{ progressfillColour }}
                          </div>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-btn
                    v-if="IsCMSAdmin"
                    color="primary"
                    x-large
                    @click="validateCourse"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-card>
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-col>
              <v-card outlined class="mb-5">
                <v-card-title>
                  <v-row>
                    <v-col>
                      <h2>Modules</h2>
                    </v-col>
                    <v-spacer />
                    <v-col class="text-end">
                      <v-btn
                        v-if="IsCMSAdmin"
                        color="primary"
                        rounded
                        small
                        @click="LoadCreateModule"
                        >NEW MODULE</v-btn
                      >
                    </v-col>
                    <v-col class="text-end">
                      <v-btn
                        v-if="IsCMSAdmin"
                        color="primary"
                        rounded
                        small
                        @click="LoadAddModule"
                        >ADD MODULE</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="searchmodule"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        dense
                        hide-details
                        class="shrink"
                      />
                    </v-col>
                  </v-row>
                  <v-data-table
                    :items="orderedModules"
                    :headers="computedModuleHeaders"
                    :search="searchmodule"
                    :loading="loadingCourses"
                    loading-text="Loading... Please wait"
                    :no-data-text="`No module available`"
                    :hide-default-footer="modulesPaginationStatus()"
                  >
                    <template
                      v-for="h in computedModuleHeaders"
                      v-slot:[`header.${h.value}`]="{ header }"
                    >
                      <v-tooltip
                        v-if="headerTooltips[h.value]"
                        :key="h.value"
                        bottom
                      >
                        <template v-slot:activator="{ on }">
                          <span v-on="on"
                            >{{ header.text
                            }}<v-icon class="pl-1" color="#113247"
                              >mdi-information</v-icon
                            ></span
                          >
                        </template>
                        <span>{{
                          headerTooltips[header.value]
                        }}</span>
                      </v-tooltip>
                      <span v-else :key="h.value">{{
                        header.text
                      }}</span>
                    </template>
                    <template v-slot:[`item.sl`]="{ item }">
                      <span>
                        {{ getIndex(item, orderedModules) + 1 }}</span
                      >
                    </template>
                    <template v-slot:[`item.title`]="{ item }">
                      <router-link
                        :to="`/courses/${courseListId}/${item.id}`"
                        class="blue--text"
                        >{{ item.title }}</router-link
                      >
                    </template>
                    <template v-slot:[`item.id`]="{ item }">
                      <span> {{ item.id }}</span>
                    </template>
                    <template v-slot:[`item.lessons`]="{ item }">
                      <span>{{ getLessonsCount(item.lessons) }}</span>
                    </template>
                    <template v-slot:[`item.activities`]="{ item }">
                      <span>{{
                        getActivitiesCount(item.lessons)
                      }}</span>
                    </template>

                    <template v-slot:[`item.isPaid`]="{ item }">
                      <v-btn
                        v-if="!item.isPaid"
                        color="grey"
                        icon
                        small
                        ><v-icon>mdi-currency-usd-off</v-icon></v-btn
                      >
                      <v-btn
                        v-if="item.isPaid"
                        color="grey"
                        icon
                        small
                        ><v-icon>mdi-currency-usd</v-icon></v-btn
                      >
                      {{ item.isPaid ? 'Paid' : 'Free' }}
                    </template>
                    <!-- v-if="isModuleDisabledForCourse(item.id)" -->
                    <!-- <template v-slot:[`item.isDisabled`]="{ item }">
                      <div
                        v-if="item.isDisabled"
                        class="warning--text"
                      >
                        Inactive
                      </div>
                      <div v-else class="success--text">
                        Active
                      </div>
                    </template> -->
                    <template v-slot:[`item.sort`]="{ item }">
                      <div class="lessons-actions-wrap">
                        <v-btn
                          icon
                          small
                          :disabled="
                            getIndex(item, orderedModules) < 1
                          "
                          @click="
                            moveModule(
                              getIndex(item, orderedModules),
                              getIndex(item, orderedModules) - 1
                            )
                          "
                          ><v-icon>mdi-arrow-up</v-icon></v-btn
                        >
                        <v-btn
                          icon
                          small
                          :disabled="
                            getIndex(item, orderedModules) >
                              orderedModules.length - 2
                          "
                          @click="
                            moveModule(
                              getIndex(item, orderedModules),
                              getIndex(item, orderedModules) + 1
                            )
                          "
                          ><v-icon>mdi-arrow-down</v-icon></v-btn
                        >
                      </div>
                    </template>
                    <template v-slot:[`item.isDisabled`]="{ item }">
                      <v-btn
                        v-if="
                          isModuleDisabledForCourse(item.id) &&
                            IsCMSAdmin
                        "
                        color="green"
                        icon
                        small
                        @click="
                          enableOrDisableModuleForCourse(
                            item.id,
                            false
                          )
                        "
                        ><v-icon title="Enable">mdi-eye</v-icon>
                        <span style="text-transform: none;"
                          >Visible</span
                        ></v-btn
                      >
                      <v-btn
                        v-if="
                          !isModuleDisabledForCourse(item.id) &&
                            IsCMSAdmin
                        "
                        color="orange"
                        icon
                        small
                        @click="
                          enableOrDisableModuleForCourse(
                            item.id,
                            true
                          )
                        "
                        ><v-icon title="Disable">mdi-eye-off</v-icon>
                        <span style="text-transform: none;"
                          >Hidden</span
                        ></v-btn
                      >
                      <v-btn
                        v-if="
                          isModuleDisabledForCourse(item.id) &&
                            !IsCMSAdmin
                        "
                        color="grey"
                        icon
                        small
                        disabled
                        ><v-icon title="Enable">mdi-eye</v-icon>
                        <span style="text-transform: none;"
                          >Visible</span
                        ></v-btn
                      >
                      <v-btn
                        v-if="
                          !isModuleDisabledForCourse(item.id) &&
                            !IsCMSAdmin
                        "
                        color="grey"
                        icon
                        small
                        disabled
                        ><v-icon title="Disable">mdi-eye-off</v-icon>
                        <span style="text-transform: none;"
                          >Hidden</span
                        ></v-btn
                      >
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                      <div class="lessons-actions-wrap">
                        <v-btn
                          icon
                          small
                          @click="
                            LoadDialogForModuleDuplication(item)
                          "
                          ><v-icon title="copy"
                            >mdi-content-copy</v-icon
                          ></v-btn
                        >
                        <!-- <v-btn
                          v-if="isModuleDisabledForCourse(item.id)"
                          color="green"
                          icon
                          small
                          @click="
                            enableOrDisableModuleForCourse(
                              item.id,
                              false
                            )
                          "
                          ><v-icon title="Enable"
                            >mdi-eye</v-icon
                          ></v-btn
                        >
                        <v-btn
                          v-else
                          color="orange"
                          icon
                          small
                          @click="
                            enableOrDisableModuleForCourse(
                              item.id,
                              true
                            )
                          "
                          ><v-icon title="Disable"
                            >mdi-eye-off</v-icon
                          ></v-btn
                        > -->
                        <v-btn
                          color="red"
                          icon
                          small
                          @click="
                            LoadConfirmDialogForDeleteModuleFromCourse(
                              item
                            )
                          "
                        >
                          <v-icon title="Delete"
                            >mdi-trash-can-outline</v-icon
                          ></v-btn
                        >
                      </div>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="v-data-table__wrapper ">
              <v-card outlined class="mb-5">
                <v-card-title>
                  <h2 class="pa-0">Course Details</h2>
                </v-card-title>
                <div class="lessons-actions-wrap">
                  <v-col class="v-data-table__wrapper ">
                    <div class="d-inline  mx-lg-auto">
                      <h4 class="pa-2">
                        Available to...
                      </h4>
                      <v-autocomplete
                        v-model="selectedProduct"
                        :items="getAvailableProducts"
                        class="pa-2"
                        style="width:80%"
                        :label="
                          selectedProduct
                            ? 'ID-' + selectedProduct
                            : 'Select Subscription'
                        "
                        :disabled="
                          IsCMSAdmin
                            ? courseProductChanged
                              ? false
                              : true
                            : true
                        "
                        @change="changeCourseProduct()"
                      >
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title>
                              <span
                                style="color:#0d3349;font-size:12px"
                                >{{ data.item.value }} -
                              </span>
                              {{ data.item.text }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </div>
                  </v-col>
                  <v-col class="v-data-table__wrapper">
                    <div class="d-inline  mx-sm-auto">
                      <div class="lessons-actions-wrap">
                        <h4 class="pa-2">
                          Visibility
                        </h4>
                        <v-tooltip right class="pa-2">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">
                              mdi-information
                            </v-icon>
                          </template>
                          <span
                            >Whether or not this course is visible
                            within the mobile app</span
                          >
                        </v-tooltip>
                      </div>
                      <v-switch
                        v-model="courseEnabled"
                        inset
                        :disabled="!IsCMSAdmin"
                        :label="
                          `${courseEnabled ? 'Visible' : 'Hidden'}`
                        "
                        color="success"
                        class="mx-3"
                      />
                    </div>
                  </v-col>
                </div>
                <v-col class="v-data-table__wrapper ">
                  <div class="d-inline  mx-lg-auto">
                    <h4 class="pa-1">
                      Notes
                    </h4>
                    <v-textarea
                      v-model="courseDetailNote"
                      :counter="500"
                      :disabled="!IsCMSAdmin"
                      class="pa-1"
                      outlined
                      dense
                    />
                  </div>
                  <v-card-actions
                    v-if="IsCMSAdmin"
                    class="float-right pa-0"
                  >
                    <v-btn
                      color="primary"
                      x-large
                      @click="validateCourseDetails"
                      >Save</v-btn
                    >
                  </v-card-actions>
                </v-col>
              </v-card>
            </v-col>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-dialog
      v-model="showNewModuleDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-form ref="form" v-model="validModule" lazy-validation>
          <v-card-title>
            <span class="headline">New Module</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="newModuleName"
              :counter="50"
              :rules="nameRules"
              label="Name*"
              required
            />
          </v-card-text>
          <v-card-actions>
            <small class="info--text"
              >Please ensure each mandatory field marked with an
              asterisk * is completed</small
            >
            <v-spacer />
            <v-btn
              depressed
              text
              @click="showNewModuleDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn color="primary" @click="createNewModule">
              New module
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showModuleAddDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-form ref="form1" v-model="validModuleAdd" lazy-validation>
          <v-card-title>
            <span class="headline">Add Module to this Course</span>
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              v-model="forms.addModule.id"
              :items="getUnmappedModuleList"
              :rules="[v => !!v || 'Module is required']"
              label="Select Modules*"
              dense
              required
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title>
                    <span style="color:#0d3349;font-size:10px"
                      >{{ data.item.value }} -
                    </span>
                    {{ data.item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <small class="info--text"
              >Please ensure each mandatory field marked with an
              asterisk * is completed</small
            >
            <v-spacer />
            <v-btn
              depressed
              text
              @click="showModuleAddDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn color="primary" @click="validateNewModule()">
              Add Module
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- alertconfirm -->
    <v-dialog v-model="confirmdialog.show" persistent max-width="450">
      <!-- <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Open Dialog
          </v-btn>
        </template> -->
      <v-card>
        <v-card-title class="headline">{{
          confirmdialog.HeaderText
        }}</v-card-title>
        <v-card-text>{{ confirmdialog.ContentText }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="confirmdialog.show = false"
            >Cancel</v-btn
          >
          <v-btn
            color="red darken-1"
            text
            @click="
              confirmdialog.ButtonConfirmAction(
                confirmdialog.customObject
              )
            "
            >{{ confirmdialog.ButtonConfirmText }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCopyDialog" persistent max-width="600px">
      <v-card>
        <v-form ref="form" v-model="copyvalid" lazy-validation>
          <v-card-title>
            <span class="headline">Duplicate Module</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="copyModuleName"
              :counter="50"
              :rules="nameRules"
              label="Name*"
              required
            />
          </v-card-text>
          <v-card-text>
            <v-autocomplete
              v-model="copyToCourseId"
              :items="primaryCourseList"
              label="Select Course"
              :rules="[v => !!v || 'Course is required']"
              dense
              required
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title>
                    <span style="color:#0d3349;font-size:10px"
                      >{{ data.item.value }} -
                    </span>
                    {{ data.item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <small class="info--text"
              >Please ensure each mandatory field marked with an
              asterisk * is completed</small
            >
            <v-spacer />
            <v-btn depressed text @click="showCopyDialog = false">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="validateDuplicateModule()">
              Duplicate Module
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapGetters, mapActions } from 'vuex';
import CourseStoreTypes from '../../store/modules/course/CourseStoreTypes';

const PRIMARY_COURSE_QUERY = gql`
  query($courseId: ID!) {
    primaryCourse(id: $courseId) {
      id
      Title
      tileColour
      titleTextColour
      subTextColour
      progressFillColour
      isDisabled
      notes
      ModuleOrder
      DisabledCourses
      courses {
        id
        title
        isDisabled
        isPaid
        lessons {
          title
          activities {
            id
          }
          isPublished
        }
      }
      product {
        id
      }
    }
  }
`;
const GET_UNMAPPED_MODULESLIST_QUERY = gql`
  query GetUnmappedCourses {
    getUnmappedCourses {
      data {
        title
        id
      }
    }
  }
`;
const AVAILABLE_PRODUCT_QUERY = gql`
  query Products($where: JSON) {
    products(where: $where) {
      id
      name
    }
  }
`;
const UPDATE_PRIMARY_COURSE_MUTATION = gql`
  mutation(
    $courseId: ID!
    $courseTitle: String!
    $tileColour: String!
    $titleTextColour: String!
    $subTextColour: String!
    $progressFillColour: String!
  ) {
    updatePrimaryCourse(
      input: {
        where: { id: $courseId }
        data: {
          Title: $courseTitle
          tileColour: $tileColour
          titleTextColour: $titleTextColour
          subTextColour: $subTextColour
          progressFillColour: $progressFillColour
        }
      }
    ) {
      primaryCourse {
        Title
        id
        isDisabled
        tileColour
        titleTextColour
        subTextColour
        progressFillColour
        notes
        ModuleOrder
        DisabledCourses
        product {
          id
        }
        courses {
          id
          title
          isDisabled
          isPaid
          lessons {
            title
            activities {
              id
            }
          }
        }
      }
    }
  }
`;

const UPDATE_PRIMARY_COURSE_DETAILS_MUTATION = gql`
  mutation(
    $courseId: ID!
    $isDisabled: Boolean!
    $selectedProduct: ID!
    $notes: String!
  ) {
    updatePrimaryCourse(
      input: {
        where: { id: $courseId }
        data: {
          isDisabled: $isDisabled
          product: $selectedProduct
          notes: $notes
        }
      }
    ) {
      primaryCourse {
        Title
        id
        isDisabled
        tileColour
        titleTextColour
        subTextColour
        progressFillColour
        notes
        ModuleOrder
        DisabledCourses
        courses {
          id
          title
          isDisabled
          isPaid
          lessons {
            title
            isPublished
            activities {
              id
            }
          }
        }
        product {
          id
        }
      }
    }
  }
`;

const CREATE_MODULE_MUTATION = gql`
  mutation($newModuleName: String!) {
    createCourse(input: { data: { title: $newModuleName , public: ${true}} }) {
      course {
        title
        id
        isDisabled
        lessons {
          title
          activities{
            id
          }
        }
      }
    }
  }
`;

const UPDATE_PRIMARY_COURSE_MODULES_MUTATION = gql`
  mutation($courseId: ID!, $courses: [ID], $moduleOrder: JSON) {
    updatePrimaryCourse(
      input: {
        where: { id: $courseId }
        data: { courses: $courses, ModuleOrder: $moduleOrder }
      }
    ) {
      primaryCourse {
        Title
        id
        isDisabled
        tileColour
        titleTextColour
        subTextColour
        progressFillColour
        notes
        ModuleOrder
        DisabledCourses
        product {
          id
        }
        courses {
          id
          title
          isDisabled
          isPaid
          lessons {
            isPublished
            title
            activities {
              id
            }
          }
        }
      }
    }
  }
`;
// To populate module select
// const ALL_MODULES_QUERY = gql`
//   query {
//     courses {
//       id
//       title
//       isDisabled
//       isPaid
//       lessons {
//         title
//       }
//     }
//   }
// `;

const SET_MODULES_ORDER_MUTATION = gql`
  mutation($courseId: ID!, $courses: JSON) {
    updatePrimaryCourse(
      input: {
        where: { id: $courseId }
        data: { ModuleOrder: $courses }
      }
    ) {
      primaryCourse {
        Title
        id
        isDisabled
        tileColour
        titleTextColour
        subTextColour
        progressFillColour
        notes
        ModuleOrder
        DisabledCourses
        product {
          id
        }
        courses {
          id
          title
          isDisabled
          isPaid
          lessons {
            isPublished
            title
            activities {
              id
            }
          }
        }
      }
    }
  }
`;

const ENABLE_DISABLE_MODULE_FOR_PRIMARYCOURSE_MUTATION = gql`
  mutation($courseId: ID!, $modules: JSON) {
    updatePrimaryCourse(
      input: {
        where: { id: $courseId }
        data: { DisabledCourses: $modules }
      }
    ) {
      primaryCourse {
        Title
        id
        isDisabled
        tileColour
        titleTextColour
        subTextColour
        progressFillColour
        notes
        ModuleOrder
        DisabledCourses
        product {
          id
        }
        courses {
          id
          title
          isDisabled
          isPaid
          lessons {
            title
            isPublished
            activities {
              id
            }
          }
        }
      }
    }
  }
`;

// To populate primarycourse select
const ALL_PRIMARYCOURSE_QUERY = gql`
  query {
    primaryCourses {
      id
      Title
    }
  }
`;

const MODULE_DUPLICATION_DEEPCLONE_MUTATION = gql`
  mutation($moduleID: ID!, $moduleName: String!, $courseID: ID!) {
    deepCopyModule(
      moduleID: $moduleID
      moduleName: $moduleName
      courseID: $courseID
    ) {
      course {
        title
        id
        isDisabled
        isPaid
        lessons {
          title
          activities {
            id
          }
        }
      }
    }
  }
`;

export default {
  name: 'PrimaryCourseDetail',
  data: () => ({
    alert: {
      type: 'success',
      show: false,
      text: ''
    },
    coursevalidationalert: false,
    validObjective: true,
    validCourse: true,
    validModule: true,
    validModuleAdd: true,
    copyvalid: true,
    nameRules: [
      v => !!v || 'Title is required',
      v =>
        (v && v.length <= 50) ||
        'Title must be less than 50 characters'
    ],
    textcolor: '',
    tilecolor: '',
    subtextColour: '',
    progressfillColour: '',
    courseEnabled: false,
    courseDetailNote: '',
    loadingCourses: true,
    dataLoaded: false,
    loading: true,
    roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER'
    },
    confirmdialog: {
      show: false,
      HeaderText: '',
      ContentText: '',
      ButtonConfirmText: '',
      ButtonConfirmAction: '',
      customObject: null
    },
    searchmodule: '',
    searchlesson: '',
    showLBDialog: false,
    showLessonDialog: false,
    showNewModuleDialog: false,
    showModuleAddDialog: false,
    showCopyDialog: false,
    newModuleName: '',
    copyModuleName: '',
    copyModuleId: 0,
    selectedProduct: 0,
    courseProductChanged: false,
    copyToCourseId: 0, //primarycourseid
    moduleHeaders: [
      { text: '', value: 'sl', width: '1%' },
      { text: 'Title', value: 'title', width: '2%' },
      { text: 'Module ID', value: 'id', width: '2%' },
      { text: 'Lessons', value: 'lessons', width: '1%' },
      { text: 'Activities', value: 'activities', width: '1%' },
      { text: 'Payment', value: 'isPaid', width: '2%' },
      // { text: 'Status', value: 'isDisabled', width: '100px' },
      {
        text: 'Sort',
        value: 'sort',
        sortable: false,
        width: '1%'
      },
      {
        text: 'Visibility',
        value: 'isDisabled',
        sortable: false,
        width: '2%'
      },
      {
        text: 'Action',
        value: 'action',
        sortable: false,
        width: '1%'
      }
    ],
    headerTooltips: {
      isDisabled:
        'Whether or not this module is visible within the mobile app',
      isPaid:
        'Is this module available for free to users, or is it locked and requiring payment'
    },
    primaryCourse: {},
    course: {
      title: ''
    },
    local: {
      title: ''
    },
    forms: {
      addModule: {
        id: ''
      },
      createModule: {
        id: '',
        title: ''
      }
    },
    modules: [],
    allModules: [],
    DisabledCourses: [],
    ModuleOrder: [],
    type: 'hex',
    hex: '#003349'
  }),
  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating'
    ]),
    ...mapGetters('course', ['getOrgPath']),
    IsDementiaAdmin() {
      if (
        this.getUser?.user?.role?.name === this.roles.DEMENTIA_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsCMSAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.CMS_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGAdmin() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGViewer() {
      if (this.getUser?.user?.role?.name === this.roles.ORG_VIEWER) {
        return true;
      } else {
        return false;
      }
    },

    courseId() {
      return this.$route.params.courseId;
    },

    breadcrumbs() {
      if (this.getOrgPath)
        return [{ text: 'Back', href: `#${this.getOrgPath}` }];

      return [{ text: 'Back', href: '#/courses' }];
    },
    courseListId() {
      return this.$route.params.courseId;
    },
    moduleList() {
      return this.getUnmappedCourses.data?.map(mod => ({
        text: mod.title,
        value: mod.id
      }));
    },
    computedModuleHeaders() {
      if (!this.IsCMSAdmin) {
        return this.moduleHeaders.filter(
          t => t.text != 'Action' && t.text != 'Sort'
        );
      }
      // else if (this.IsCMSAdmin) {
      //   return this.moduleHeaders.filter(t => t.text != 'Visibility');
      // }
      else return this.moduleHeaders;
    },
    orderedModules() {
      if (!this.primaryCourse.ModuleOrder) {
        return this.primaryCourse.courses;
      }

      if (
        this.primaryCourse.ModuleOrder.length !=
        this.primaryCourse.courses.length
      ) {
        return this.primaryCourse.courses;
      }
      // Create index of modules
      let modules = this.primaryCourse.courses.reduce((acc, i) => {
        acc[i.id] = i;
        return acc;
      }, {});
      // First add modules with order
      let orderedModules = [];
      this.primaryCourse.ModuleOrder.forEach(l => {
        if (modules[l]) {
          orderedModules.push(modules[l]);
          delete modules[l];
        }
      });
      // Then add unordered modules
      orderedModules.push(...Object.values(modules));

      return orderedModules;
    },
    primaryCourseList() {
      return this.primaryCourses?.map(mod => ({
        text: mod.Title,
        value: mod.id
      }));
    }
  },
  methods: {
    ...mapActions('course', [
      CourseStoreTypes.Actions.UPDATE_COURSE_FROM_ORG
    ]),
    modulesPaginationStatus() {
      if (this.orderedModules && this.orderedModules.length > 0)
        return false;
      else return true;
    },
    lessonPaginationStatus() {
      if (this.orderedLessons && this.orderedLessons.length > 0)
        return false;
      else return true;
    },
    isModuleDisabledForCourse(moduleid) {
      return this.DisabledCourses?.some(function(id) {
        return id === moduleid;
      });
    },
    getLessonsCount(lessons) {
      var totallength = 0;
      if (!lessons) return 0;

      lessons.forEach(l => {
        if (l.isPublished) totallength = totallength + 1;
      });
      return totallength;
    },
    getActivitiesCount(lessons) {
      var totallength = 0;
      if (!lessons) return 0;

      lessons.forEach(l => {
        if (l.activities)
          totallength = totallength + l.activities.length;
      });
      return totallength;
    },
    // getLessonsCount(courses) {
    //   var totallength = 0;
    //   if (!courses) return 0;

    //   courses.forEach(l => {
    //     if (l.lessons) totallength = totallength + l.lessons.length;
    //   });
    //   return totallength;
    // },
    LoadDialogForModuleDuplication(item) {
      this.copyModuleName = 'Copy of ' + item.title;
      this.copyModuleId = item.id;
      this.showCopyDialog = true;
      this.copyToCourseId = 0;
    },
    validateDuplicateModule() {
      if (!this.$refs.form.validate()) return;
      //form is valid so continue with duplication
      this.moduleDuplicationForPrimaryCourse();
    },
    moduleDuplicationForPrimaryCourse() {
      this.$apollo.mutate({
        mutation: MODULE_DUPLICATION_DEEPCLONE_MUTATION,
        variables: {
          moduleID: this.copyModuleId,
          moduleName: this.copyModuleName,
          courseID: this.copyToCourseId
        },
        update: (store, { data: { deepCopyModule } }) => {
          if (!deepCopyModule.course) return;
          if (this.copyToCourseId === this.courseId) {
            const data = store.readQuery({
              query: PRIMARY_COURSE_QUERY,
              variables: {
                courseId: this.courseId
              }
            });
            data.primaryCourse.courses.push(deepCopyModule.course);
            store.writeQuery({
              query: PRIMARY_COURSE_QUERY,
              variables: {
                courseId: this.courseId
              },
              data
            });
            this.alert.show = true;
            this.alert.text = 'Details Updated Successfully';
            this.alert.type = 'success';

            setTimeout(() => {
              this.alert.show = false;
            }, 3000);
          }
        }
      });
      this.showCopyDialog = false;
    },

    getIndex(item, items) {
      if (!items) return 0;
      if (!item) return 0;
      return items
        .filter(o => o != 'undefined')
        .map(function(x) {
          if (x) return x.id;
        })
        .indexOf(item.id);
    },
    updateModulesInPrimaryCourse(modules, moduleorder) {
      this.$apollo.mutate({
        mutation: UPDATE_PRIMARY_COURSE_MODULES_MUTATION,
        variables: {
          courseId: this.courseId,
          courses: modules,
          moduleOrder: moduleorder
        },
        update: (store, { data: { updatePrimaryCourse } }) => {
          if (!updatePrimaryCourse.primaryCourse) return;
          this.forms.addModule.id = null;
          // Get the current store state
          // const data = store.readQuery({
          //   query: PRIMARY_COURSE_QUERY,
          //   variables: {
          //     courseId: this.courseId
          //   }
          // });
          // data.primaryCourse = updatePrimaryCourse.primaryCourse;
          // // Write the list back into the cache
          // store.writeQuery({
          //   query: PRIMARY_COURSE_QUERY,
          //   variables: {
          //     courseId: this.courseId
          //   },
          //   data
          // });
          this.$apollo.queries.primaryCourse.refresh();
          this.$apollo.queries.getUnmappedModuleList.refresh();
          this.alert.show = true;
          this.alert.text = 'Successfully Updated';
          this.alert.type = 'success';
          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        }
      });
    },

    createNewModule() {
      if (!this.$refs.form.validate()) return;
      const newModuleName = this.newModuleName;
      this.newModuleName = '';
      this.$apollo.mutate({
        mutation: CREATE_MODULE_MUTATION,
        variables: {
          newModuleName
        },
        update: (store, { data: { createCourse } }) => {
          if (!createCourse.course) return;
          // update the primary
          let moduleids = [];
          if (this.modules && this.modules.length > 0) {
            moduleids = this.modules.map(c => c.id);
            moduleids.push(createCourse.course.id);
          } else {
            moduleids.push(createCourse.course.id);
          }
          //set module order
          //add new module to end of module order array
          if (this.ModuleOrder && this.ModuleOrder.length > 0) {
            this.ModuleOrder.push(createCourse.course.id);
          } else {
            this.ModuleOrder = [createCourse.course.id];
          }
          this.updateModulesInPrimaryCourse(
            moduleids,
            this.ModuleOrder
          );
        }
      });

      this.showNewModuleDialog = false;
    },
    validateCourse() {
      //valdate
      if (!this.$refs.form.validate()) return;
      this.updateCourse();
    },
    validateCourseDetails() {
      this.updateCourseDetails();
    },

    updateCourse() {
      if (!this.$refs.form.validate()) return;

      this.$apollo.mutate({
        mutation: UPDATE_PRIMARY_COURSE_MUTATION,
        variables: {
          courseId: this.courseId,
          courseTitle: this.local.title,
          tileColour: this.tilecolor,
          titleTextColour: this.textcolor,
          subTextColour: this.subtextColour,
          progressFillColour: this.progressfillColour
        },
        update: (store, { data: { updatePrimaryCourse } }) => {
          if (!updatePrimaryCourse.primaryCourse) return;
          // Get the current store state
          const data = store.readQuery({
            query: PRIMARY_COURSE_QUERY,
            variables: {
              courseId: this.courseId
            }
          });
          data.primaryCourse = updatePrimaryCourse.primaryCourse;
          // Write the list back into the cache
          store.writeQuery({
            query: PRIMARY_COURSE_QUERY,
            variables: {
              courseId: this.courseId
            },
            data
          });
          this.alert.show = true;
          this.alert.text = 'Successfully Updated';
          this.alert.type = 'success';

          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        }
      });
    },
    updateCourseDetails() {
      this.$apollo.mutate({
        mutation: UPDATE_PRIMARY_COURSE_DETAILS_MUTATION,
        variables: {
          courseId: this.courseId,
          isDisabled: !this.courseEnabled,
          selectedProduct: this.selectedProduct,
          notes: this.courseDetailNote
        },
        update: (store, { data: { updatePrimaryCourse } }) => {
          if (!updatePrimaryCourse.primaryCourse) return;
          // Get the current store state
          const data = store.readQuery({
            query: PRIMARY_COURSE_QUERY,
            variables: {
              courseId: this.courseId
            }
          });
          data.primaryCourse = updatePrimaryCourse.primaryCourse;
          // Write the list back into the cache
          store.writeQuery({
            query: PRIMARY_COURSE_QUERY,
            variables: {
              courseId: this.courseId
            },
            data
          });
          this.alert.show = true;
          this.alert.text = 'Course Details Saved Successfully';
          this.alert.type = 'success';

          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        }
      });
    },

    LoadCreateModule() {
      this.forms.createModule.id = 0;
      this.forms.createModule.title = '';
      this.showNewModuleDialog = true;
    },
    LoadAddModule() {
      this.showModuleAddDialog = true;
    },
    validateNewModule() {
      if (!this.$refs.form1.validate()) return;
      this.addNewModule();
    },
    addNewModule() {
      // update the primary
      if (this.forms.addModule.id == 0) return;
      let moduleids = [];
      if (this.modules && this.modules.length > 0) {
        moduleids = this.modules.map(c => c.id);
        moduleids.push(this.forms.addModule.id);
      } else {
        moduleids.push(this.forms.addModule.id);
      }
      //set module order
      //add new module to end of module order array
      if (this.ModuleOrder && this.ModuleOrder.length > 0) {
        this.ModuleOrder.push(this.forms.addModule.id);
      } else {
        this.ModuleOrder = [this.forms.addModule.id];
      }

      this.updateModulesInPrimaryCourse(moduleids, this.ModuleOrder);

      this.showModuleAddDialog = false;
    },
    validateLearningObjective() {
      if (!this.$refs.Objectiveform.validate()) return;
      this.createLearningObjective();
    },

    LoadConfirmDialogForDeleteModuleFromCourse(item) {
      this.confirmdialog.HeaderText = 'Are you sure?';
      this.confirmdialog.ContentText =
        'Deleting a module will remove all lessons and activities from this course';
      this.confirmdialog.ButtonConfirmText = "Yes I'm sure";
      this.confirmdialog.show = true;
      this.confirmdialog.customObject = item;
      this.confirmdialog.ButtonConfirmAction = this.DeleteModuleFromCourse;
    },
    async DeleteModuleFromCourse(item) {
      let moduleids = [];
      if (this.modules && this.modules.length > 0) {
        moduleids = this.modules.map(c => c.id);
        var filteredmoduleids = moduleids.filter(function(moduleid) {
          if (moduleid != item.id) {
            return moduleid;
          }
        });

        this.ModuleOrder = this.ModuleOrder.filter(function(
          moduleid
        ) {
          if (moduleid != item.id) {
            return moduleid;
          }
        });

        await this.updateModulesInPrimaryCourse(
          filteredmoduleids,
          this.ModuleOrder
        );
      }

      this.alert.show = true;
      this.alert.text = 'Module removed successfully';
      this.alert.type = 'success';

      setTimeout(() => {
        this.alert.show = false;
      }, 3000);

      this.confirmdialog.show = false;
    },

    moveModule(from, to) {
      const newOrder = this.orderedModules.map(l => l.id);
      const [item] = newOrder.splice(from, 1);
      newOrder.splice(to, 0, item);

      this.$apollo.mutate({
        mutation: SET_MODULES_ORDER_MUTATION,
        variables: {
          courseId: this.courseId,
          courses: newOrder
        }
      });
    },
    enableOrDisableModuleForCourse(moduleid, status) {
      var disabledmodulelist = this.DisabledCourses;
      if (status === true) {
        //disable the moduel for course so include in the disabled list array
        if (disabledmodulelist && disabledmodulelist.length > 0) {
          disabledmodulelist.push(moduleid);
        } else {
          disabledmodulelist = [moduleid];
        }
      } else {
        //enable the module for course so pop moduleid from disabled list
        if (disabledmodulelist && disabledmodulelist.length > 0) {
          disabledmodulelist = disabledmodulelist.filter(function(
            id
          ) {
            if (id != moduleid) {
              return id;
            }
          });
        }
      }

      this.$apollo
        .mutate({
          mutation: ENABLE_DISABLE_MODULE_FOR_PRIMARYCOURSE_MUTATION,
          variables: {
            courseId: this.courseId,
            modules: disabledmodulelist
          },
          update: (store, { data: { updatePrimaryCourse } }) => {
            if (!updatePrimaryCourse.primaryCourse) return;
            // Get the current store state
            const data = store.readQuery({
              query: PRIMARY_COURSE_QUERY,
              variables: {
                courseId: this.courseId
              }
            });
            data.DisabledCourses =
              updatePrimaryCourse.DisabledCourses;
            // Write the list back into the cache
            store.writeQuery({
              query: PRIMARY_COURSE_QUERY,
              variables: {
                courseId: this.courseId
              },
              data
            });
            this.alert.show = true;
            this.alert.text = 'Updated Successfully';
            this.alert.type = 'success';

            setTimeout(() => {
              this.alert.show = false;
            }, 3000);
          }
        })
        .catch(error => {
          this.alert.show = true;
          this.alert.text = error;
          this.alert.type = 'error';
          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        });
    },
    changeCourseProduct() {
      this.courseProductChanged = true;
    }
  },
  apollo: {
    // courses() {
    //   return {
    //     query: ALL_MODULES_QUERY
    //     //update: data => data.learningobjective
    //   };
    // },
    primaryCourse() {
      return {
        query: PRIMARY_COURSE_QUERY,
        variables: {
          courseId: this.$route.params.courseId
        },
        fetchPolicy: 'network-only',
        update: data => {
          if (!data.primaryCourse) {
            this.alert.show = true;
            this.alert.text =
              'Sorry, The requisition data cannot be loaded';
            this.alert.type = 'error';
            this.loadingCourses = false;
            this.loading = false;
            this.dataLoaded = false;
          } else {
            this.local.title = data.primaryCourse.Title || '';
            this.tilecolor =
              data.primaryCourse.tileColour || '#F4F6F8';
            this.textcolor =
              data.primaryCourse.titleTextColour || '#002744';
            this.subtextColour =
              data.primaryCourse.subTextColour || '#A6A6BD';
            this.progressfillColour =
              data.primaryCourse.progressFillColour || '#2CD5C4';
            this.courseEnabled = !data.primaryCourse.isDisabled;
            this.courseDetailNote = data?.primaryCourse?.notes
              ? data.primaryCourse.notes
              : '';
            this.selectedProduct = data.primaryCourse.product
              ? data.primaryCourse.product.id
              : 0;
            this.courseProductChanged =
              this.selectedProduct == 0 ? true : false;
            this.modules = data.primaryCourse.courses;
            this.DisabledCourses = data.primaryCourse.DisabledCourses;
            this.ModuleOrder = data.primaryCourse.ModuleOrder;
            this.loadingCourses = false;
            this.loading = false;
            this.dataLoaded = true;
          }
          return data.primaryCourse;
        },
        error() {
          this.alert.show = true;
          this.alert.text =
            'Sorry, The requisition data cannot be loaded';
          this.alert.type = 'error';
          this.dataLoaded = false;
          this.loading = false;
        }
      };
    },
    getAvailableProducts() {
      return {
        query: AVAILABLE_PRODUCT_QUERY,
        variables: {
          isDisabled: false
        },
        fetchPolicy: 'network-only',
        update: data => {
          this.avaliableProducts = data.products?.map(mod => ({
            text: mod.name,
            value: mod.id
          }));
          return this.avaliableProducts;
        }
      };
    },

    getUnmappedModuleList() {
      return {
        query: GET_UNMAPPED_MODULESLIST_QUERY,
        fetchPolicy: 'network-only',
        skip() {
          return !this.IsCMSAdmin;
        },
        update: data => {
          this.getAvailiableCourses = data.getUnmappedCourses.data?.map(
            mod => ({
              text: mod.title,
              value: mod.id
            })
          );
          return this.getAvailiableCourses;
        }
      };
    },

    primaryCourses() {
      return {
        query: ALL_PRIMARYCOURSE_QUERY,
        skip() {
          return !this.IsCMSAdmin;
        }
      };
    }
  }
};
</script>
