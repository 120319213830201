import ComponentQuizMultipleChoice from './MultipleChoice';
import ComponentQuizDragDrop from './DragDrop';
import ComponentQuizImageChoice from './ImageChoice';
import ComponentQuizOrderList from './OrderList';
import ComponentQuizSingleChoice from './SingleChoice';
// import ComponentQuizCategoryChoice from './CategoryChoice';

export const QuizTypes = {
  ComponentQuizMultipleChoice,
  ComponentQuizDragDrop,
  ComponentQuizImageChoice,
  ComponentQuizOrderList,
  ComponentQuizSingleChoice
  // ComponentQuizCategoryChoice
};

/*
 * Turn the list of QuizTypes into a list of spread
 * notations ("...ComponentQuizDragDrop") for including
 * in query body.
 */
export const QuizFragmentNames = Object.keys(QuizTypes)
  .map(k => `...${k}`)
  .join(' ');

/*
 * Turn the gql fragments back into string for including
 * in query since you can't pass array of GraphQL AST as
 * compiled by gql tagged template literal.
 */
export const QuizFragmentStrings = Object.values(QuizTypes)
  .map(q => q?.gqlQueryFragment?.loc?.source?.body)
  .filter(q => !!q)
  .join(' ');

export default {
  types: QuizTypes,
  names: QuizFragmentNames,
  strings: QuizFragmentStrings
};
