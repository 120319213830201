<template>
  <div class="editor-heading">
    <component :is="headingLevel">{{ text }}</component>
    <div class="editing-panel mt-4">
      <v-text-field v-model="text" label="Text" />
      <v-text-field v-model="level" label="Level" />
      <v-text-field v-model="anchor" label="Anchor" />
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { Emit } from './emit.js';

const QUERY_FRAGMENT = gql`
  fragment ComponentContentListHeading on ComponentContentListHeading {
    id
    text
    level
    anchor
  }
`;

export default {
  name: 'ComponentContentListHeading',
  icon: 'mdi-format-header-pound',
  gqlQueryFragment: QUERY_FRAGMENT,
  text: 'Heading',
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    text: Emit('text'),
    level: Emit('level'),
    anchor: Emit('anchor'),
    headingLevel() {
      return 'h' + (parseInt(this.level) || 2);
    }
  }
};
</script>

<style lang="scss">
.editor-heading {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 4px;
  padding: 1rem;

  &:not(.editing) .editing-panel {
    display: none;
  }
}
</style>
