var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.IsDementiaAdmin
    ? _c(
        "div",
        [
          _c(
            "v-container",
            { staticClass: "grey lighten-5", attrs: { fluid: "" } },
            [
              _c(
                "v-alert",
                {
                  attrs: {
                    dense: "",
                    text: "",
                    type: _vm.alert.type,
                    value: _vm.alert.show,
                    transition: "slide-y-transition"
                  }
                },
                [_vm._v(_vm._s(_vm.alert.text))]
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mr-auto", attrs: { cols: "auto" } },
                    [_c("h1", [_vm._v("Organisations")])]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "page-sub-sec" },
            [
              _c("v-text-field", {
                staticClass: "shrink",
                attrs: {
                  "append-icon": "mdi-magnify",
                  label: "Search",
                  outlined: "",
                  dense: "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.orgSearch,
                  callback: function($$v) {
                    _vm.orgSearch = $$v
                  },
                  expression: "orgSearch"
                }
              }),
              _c(
                "div",
                { staticClass: "d-flex float-right" },
                [
                  _c("br"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.clearform }
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-plus")]),
                      _vm._v(" New Organisation")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              items: _vm.orgs,
              headers: _vm.computedHeaders,
              search: _vm.orgSearch,
              "no-data-text": "No organisation available",
              "hide-default-footer": _vm.orgsCount < 10 ? true : false,
              loading: this.$apollo.loading,
              "loading-text": "Loading... Please wait",
              "page-count": _vm.pageSize,
              page: _vm.page,
              options: _vm.options,
              "server-items-length": _vm.orgsCount,
              "footer-props": {
                "items-per-page-options": [20, 30, 40, 50, 100, 500]
              }
            },
            on: {
              "update:options": function($event) {
                _vm.options = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.name",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "router-link",
                        {
                          staticClass: "blue--text",
                          attrs: { to: "/organisations/" + item.id }
                        },
                        [_vm._v(_vm._s(item.name))]
                      )
                    ]
                  }
                },
                {
                  key: "item.code",
                  fn: function(ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(item.code) + " ")]
                  }
                },
                {
                  key: "item.totalLicense",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "div",
                        _vm._l(item.org_products, function(license, lIndex) {
                          return _c(
                            "p",
                            { key: lIndex, staticClass: "mt-2 mb-2" },
                            [
                              _vm._v(" " + _vm._s(license.product.name) + ": "),
                              _c(
                                "a",
                                {
                                  staticClass: "a-tag-color-blue",
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      return _vm.goToLicenseList(
                                        item.id,
                                        license.product.id
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(license.licenseCount))]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  }
                },
                {
                  key: "item.licenceRemaining",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "div",
                        _vm._l(item.org_products, function(license, lIndex) {
                          return _c("p", { key: lIndex, staticClass: "mb-1" }, [
                            _vm._v(
                              " " +
                                _vm._s(license.product.name) +
                                ": " +
                                _vm._s(license.licenceRemaining) +
                                " "
                            )
                          ])
                        }),
                        0
                      )
                    ]
                  }
                },
                {
                  key: "item.learners",
                  fn: function(ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(item.learners.length) + " ")]
                  }
                },
                {
                  key: "item.admins",
                  fn: function(ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(item.admins.length) + " ")]
                  }
                },
                {
                  key: "item.isDisabled",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.isDisabled
                        ? _c("div", { staticClass: "gray--text" }, [
                            _vm._v("Inactive")
                          ])
                        : _c("div", { staticClass: "success--text" }, [
                            _vm._v(" Active ")
                          ])
                    ]
                  }
                },
                {
                  key: "item.action",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "div",
                        { staticClass: "lessons-actions-wrap" },
                        [
                          _vm.hideDelete == "false"
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { color: "red", icon: "", small: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.LoadConfirmDialogForDeleteOrg(
                                        item.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { title: "Delete" } }, [
                                    _vm._v("mdi-trash-can-outline")
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              3982528193
            )
          }),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "600px" },
              model: {
                value: _vm.showNewDialog,
                callback: function($$v) {
                  _vm.showNewDialog = $$v
                },
                expression: "showNewDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { "lazy-validation": "" },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c("v-card-title", [
                        _c("span", { staticClass: "headline" }, [
                          _vm._v("Add Organisation")
                        ])
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      counter: 50,
                                      label: "Name*",
                                      rules: _vm.nameRules,
                                      required: ""
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.generateOrgCode()
                                      }
                                    },
                                    model: {
                                      value: _vm.newOrgName,
                                      callback: function($$v) {
                                        _vm.newOrgName = $$v
                                      },
                                      expression: "newOrgName"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Address*",
                                      rules: [
                                        function(v) {
                                          return !!v || "Address is required"
                                        }
                                      ],
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.orgAddress,
                                      callback: function($$v) {
                                        _vm.orgAddress = $$v
                                      },
                                      expression: "orgAddress"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Country*",
                                      rules: [
                                        function(v) {
                                          return !!v || "Country is required"
                                        }
                                      ],
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.orgCountry,
                                      callback: function($$v) {
                                        _vm.orgCountry = $$v
                                      },
                                      expression: "orgCountry"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Domain*",
                                      rules: [
                                        function(v) {
                                          return !!v || "Domain is required"
                                        }
                                      ],
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.orgDomain,
                                      callback: function($$v) {
                                        _vm.orgDomain = $$v
                                      },
                                      expression: "orgDomain"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Organisation Code*",
                                      rules: [
                                        function(v) {
                                          return (
                                            !!v ||
                                            "Organisation Code is required"
                                          )
                                        }
                                      ],
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.code,
                                      callback: function($$v) {
                                        _vm.code = $$v
                                      },
                                      expression: "code"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.IsDementiaAdmin
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("span", { staticClass: "caption" }, [
                                        _vm._v("Employee ID Type*")
                                      ]),
                                      _c("v-select", {
                                        staticClass: "pt-0",
                                        attrs: {
                                          items: _vm.EmployeeIDTypes,
                                          "item-value": "id",
                                          "item-text": "label",
                                          label: "Select",
                                          "persistent-hint": "",
                                          "single-line": "",
                                          rules: [
                                            function(v) {
                                              return (
                                                !!v ||
                                                "Employee ID Type is required"
                                              )
                                            }
                                          ]
                                        },
                                        model: {
                                          value: _vm.EmployeeIDType,
                                          callback: function($$v) {
                                            _vm.EmployeeIDType = $$v
                                          },
                                          expression: "EmployeeIDType"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.EmployeeIDType !== "None" &&
                              _vm.IsDementiaAdmin
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Mobile Help Message*",
                                          rules: [
                                            function(v) {
                                              return (
                                                !!v ||
                                                "Mobile help message is required"
                                              )
                                            }
                                          ],
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.MobileHelpMessage,
                                          callback: function($$v) {
                                            _vm.MobileHelpMessage = $$v
                                          },
                                          expression: "MobileHelpMessage"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("v-spacer")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("small", { staticClass: "info--text" }, [
                            _vm._v(
                              "*Please ensure each mandatory field marked with an asterisk * is completed"
                            )
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { depressed: "", text: "" },
                              on: { click: _vm.cancelCreateOrg }
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.createOrg }
                            },
                            [_vm._v(" Create ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "450" },
              model: {
                value: _vm.confirmdialogOrgdelete,
                callback: function($$v) {
                  _vm.confirmdialogOrgdelete = $$v
                },
                expression: "confirmdialogOrgdelete"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "headline" }, [
                    _vm._v("Are you sure")
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      "Please confirm you wish to remove the organisation?"
                    )
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "green darken-1", text: "" },
                          on: {
                            click: function($event) {
                              _vm.confirmdialogOrgdelete = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "red darken-1", text: "" },
                          on: {
                            click: function($event) {
                              return _vm.deleteOrg(_vm.orgdeleteId)
                            }
                          }
                        },
                        [_vm._v("Confirm")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }