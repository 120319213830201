import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { Constant } from './config/constants';

// Load GraphQL fragment file and IntrospectionFragmentMatcher
// to support intersection types.
import {
  IntrospectionFragmentMatcher,
  InMemoryCache
} from 'apollo-cache-inmemory';
import introspectionQueryResultData from './graphql/fragmentTypes.json';
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

import {
  createApolloClient,
  restartWebsockets
} from 'vue-cli-plugin-apollo/graphql-client';

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
export const AUTH_TOKEN = Constant.AUTH_TOKEN;

// Http endpoint
var urlgQl = process.env.VUE_APP_BASE_API + '/graphql';
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || urlgQl;
// Export Apollo client so it can be called from plugins
// and Vuex modules
export const { apolloClient, wsClient } = createApolloClient({
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: null,
  // process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:1337/graphql',
  // LocalStorage token
  tokenName: Constant.AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: myLink

  // Override default cache
  // cache: myCache
  cache: new InMemoryCache({ fragmentMatcher }),

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  clientState: { resolvers: {}, defaults: {} }
});
apolloClient.wsClient = wsClient;

// Create vue apollo provider
export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $query: {
      // fetchPolicy: 'cache-and-network',
    }
  },
  errorHandler(error) {
    // eslint-disable-next-line no-console
    console.log(
      '%cError',
      'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
      error.message
    );
  }
});

// Manually call this when user log in
export async function onLogin(loginResponse) {
  if (typeof localStorage !== 'undefined' && loginResponse) {
    if (
      loginResponse.jwt &&
      loginResponse.user.role.name != Constant.roles.LEARNER
    ) {
      localStorage.setItem(Constant.AUTH_TOKEN, loginResponse.jwt);
      localStorage.setItem('UserId', loginResponse.user.id);
    }
    //if (loginResponse.user) {
    // localStorage.setItem(
    //   'userdata',
    //   JSON.stringify(loginResponse.user)
    // );
    // apolloClient.cache.writeData({
    //   data: {
    //     Loginuser: {
    //       __typename: 'user',
    //       id: loginResponse.user.id,
    //       username: loginResponse.user.username,
    //       rolename: loginResponse.user.role.name
    //     }
    //   }
    // });
    //}
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(
      '%cError on cache reset (login)',
      'color: orange;',
      e.message
    );
  }
}

// Manually call this when user log out
export async function onLogout() {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(Constant.AUTH_TOKEN);
    localStorage.removeItem('UserId');
    apolloClient.clearStore();
  }

  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(
      '%cError on cache reset (logout)',
      'color: orange;',
      e.message
    );
  }
}
