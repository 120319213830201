var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "overflow-y-scroll" },
    [
      _vm.loading
        ? _c("div", [
            _c(
              "div",
              { staticClass: "text-center" },
              [_c("v-progress-linear", { attrs: { indeterminate: "" } })],
              1
            )
          ])
        : _vm._e(),
      _c(
        "v-container",
        { staticClass: "grey lighten-5", attrs: { fluid: "" } },
        [
          _c(
            "v-alert",
            {
              attrs: {
                dense: "",
                text: "",
                type: _vm.alert.type,
                value: _vm.alert.show,
                transition: "slide-y-transition"
              }
            },
            [_vm._v(_vm._s(_vm.alert.text))]
          ),
          _c(
            "div",
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mr-auto", attrs: { cols: "auto" } },
                    [
                      _c("v-breadcrumbs", {
                        staticClass: "pa-0",
                        attrs: { items: _vm.breadcrumbs }
                      }),
                      !_vm.editName
                        ? _c(
                            "h1",
                            [
                              _vm._v(
                                " " + _vm._s(_vm.subscriptionDetails.name) + " "
                              ),
                              _vm.IsCMSAdmin
                                ? _c(
                                    "v-icon",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.editSubscriptionName()
                                        }
                                      }
                                    },
                                    [_vm._v("mdi-pencil")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.editName
                        ? _c(
                            "v-row",
                            [
                              _c("v-text-field", {
                                staticClass: "shrink pa-3",
                                staticStyle: {
                                  "font-size": "2em",
                                  "font-weight": "bold"
                                },
                                attrs: {
                                  autofocus: "",
                                  "hide-details": "",
                                  value: _vm.subscriptionDetails.name
                                },
                                model: {
                                  value: _vm.subscriptionName,
                                  callback: function($$v) {
                                    _vm.subscriptionName = $$v
                                  },
                                  expression: "subscriptionName"
                                }
                              }),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "pa-1",
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.updateSubscriptionName()
                                    }
                                  }
                                },
                                [_vm._v("mdi-check")]
                              ),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "pa-1",
                                  attrs: { color: "red" },
                                  on: {
                                    click: function($event) {
                                      return _vm.editSubscriptionName()
                                    }
                                  }
                                },
                                [_vm._v("mdi-close")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("available-courses"),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "mb-5", attrs: { outlined: "" } },
                        [
                          _c(
                            "div",
                            { staticClass: "pa-5 ma-0" },
                            [
                              _c(
                                "div",
                                { staticClass: "section-devider no-bg" },
                                [_c("h3", [_vm._v("Subscription Details")])]
                              ),
                              _c("h5", [
                                _vm._v("This subscription is available to")
                              ]),
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "pt-1",
                                  attrs: {
                                    disabled: _vm.IsDementiaAdmin,
                                    row: ""
                                  },
                                  model: {
                                    value: _vm.availability,
                                    callback: function($$v) {
                                      _vm.availability = $$v
                                    },
                                    expression: "availability"
                                  }
                                },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      value: 2,
                                      disabled:
                                        _vm.subscriptionDetails
                                          .product_availability == 2
                                    },
                                    on: { click: _vm.showModelEveryone },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c("div", [
                                              _c("strong", [_vm._v("Everyone")])
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _c("v-radio", {
                                    attrs: {
                                      value: 1,
                                      disabled:
                                        _vm.subscriptionDetails
                                          .product_availability == 2 ||
                                        _vm.subscriptionDetails
                                          .product_availability == 3
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c("div", [
                                              _c("strong", [_vm._v("Public")])
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _c("v-radio", {
                                    attrs: {
                                      value: 3,
                                      disabled:
                                        _vm.subscriptionDetails
                                          .product_availability == 2 ||
                                        _vm.subscriptionDetails
                                          .product_availability == 1
                                    },
                                    on: { click: _vm.showModel },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c("div", [
                                              _c("strong", [
                                                _vm._v("Enterprise")
                                              ])
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm.subscriptionDetails
                                    .product_availability == 2
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { right: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass: "pl-1",
                                                            attrs: {
                                                              color: "#113247"
                                                            }
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          "mdi-currency-usd"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2830284448
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "Contact Suyati support to change the availability of this subscription."
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c("v-row", { staticClass: "pl-2 pb-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-inline" },
                                  [
                                    _c("h4", { staticClass: "pa-1" }, [
                                      _vm._v("Subscription Icon")
                                    ]),
                                    _c(
                                      "v-row",
                                      { staticClass: "pl-3 pt-3" },
                                      [
                                        _c(
                                          "v-avatar",
                                          {
                                            staticClass: "ma-1",
                                            staticStyle: {
                                              border: "1px solid #E0E0E0"
                                            },
                                            attrs: { rounded: "", size: "29px" }
                                          },
                                          [
                                            _vm.imageUrl
                                              ? _c("img", {
                                                  attrs: {
                                                    alt: "Avatar",
                                                    src: _vm.imageUrl
                                                  }
                                                })
                                              : _vm._e()
                                          ]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "pa-3 ma-1 primary",
                                            staticStyle: {
                                              backgroundColor: "#113247"
                                            },
                                            attrs: {
                                              small: "",
                                              loading: _vm.isUploading,
                                              disabled: _vm.IsDementiaAdmin
                                            },
                                            on: { click: _vm.getFile }
                                          },
                                          [
                                            _c("v-file-input", {
                                              attrs: {
                                                id: "fileUpload",
                                                "hide-input": "",
                                                disabled: _vm.IsDementiaAdmin,
                                                label: "File input",
                                                filled: "",
                                                "prepend-icon": ""
                                              },
                                              on: { change: _vm.onFilePicked }
                                            }),
                                            _vm._v(" Upload Icon ")
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-inline  mx-lg-auto" },
                                  [
                                    _c("h4", { staticClass: "pa-1" }, [
                                      _vm._v("Visibility")
                                    ]),
                                    _c("v-switch", {
                                      staticStyle: {
                                        "font-size": "10px",
                                        "padding-left": "10px"
                                      },
                                      attrs: {
                                        inset: "",
                                        color: "green",
                                        disabled: _vm.IsDementiaAdmin,
                                        label:
                                          "" +
                                          (_vm.visibility
                                            ? "Visible"
                                            : "Hidden")
                                      },
                                      model: {
                                        value: _vm.visibility,
                                        callback: function($$v) {
                                          _vm.visibility = $$v
                                        },
                                        expression: "visibility"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _c("h4", { staticClass: "pa-1" }, [
                                _vm._v("Notes")
                              ]),
                              _c("v-textarea", {
                                staticStyle: {
                                  padding: "10px",
                                  background: "#FFFFFF"
                                },
                                attrs: {
                                  value: _vm.subscriptionDetails.notes,
                                  disabled: _vm.IsDementiaAdmin,
                                  outlined: "",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.notes,
                                  callback: function($$v) {
                                    _vm.notes = $$v
                                  },
                                  expression: "notes"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm.availability != 3
                        ? _c(
                            "v-card",
                            { staticClass: "mb-5", attrs: { outlined: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "pa-5 ma-0" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "section-devider no-bg" },
                                    [_c("h3", [_vm._v("In-App Advertising")])]
                                  ),
                                  _c("app-content-header-editor", {
                                    model: {
                                      value: _vm.advertisements,
                                      callback: function($$v) {
                                        _vm.advertisements = $$v
                                      },
                                      expression: "advertisements"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "mb-5" },
                                    [
                                      _c("h4", [_vm._v(" Advertisement ")]),
                                      _c("v-textarea", {
                                        staticStyle: { padding: "10px" },
                                        attrs: {
                                          value: _vm.subscriptionDetails.blurb,
                                          disabled: _vm.IsDementiaAdmin,
                                          outlined: "",
                                          dense: ""
                                        },
                                        model: {
                                          value: _vm.blurb,
                                          callback: function($$v) {
                                            _vm.blurb = $$v
                                          },
                                          expression: "blurb"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("v-row", { staticClass: "pl-2 pb-2" }, [
                                    _c(
                                      "div",
                                      { staticClass: "d-inline " },
                                      [
                                        _c("h4", [
                                          _vm._v(" Yearly Product Id* ")
                                        ]),
                                        _c("v-text-field", {
                                          staticStyle: {
                                            "font-size": "12px",
                                            "margin-top": "-15px",
                                            width: "200px"
                                          },
                                          attrs: {
                                            disabled: _vm.IsDementiaAdmin,
                                            "hide-details": "",
                                            rules: [
                                              function(v) {
                                                return (
                                                  !!v ||
                                                  "Enter In-App yearly product Id"
                                                )
                                              }
                                            ],
                                            value:
                                              _vm.subscriptionDetails
                                                .inAppYearlyProductId,
                                            width: "50px"
                                          },
                                          model: {
                                            value: _vm.price,
                                            callback: function($$v) {
                                              _vm.price = $$v
                                            },
                                            expression: "price"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "mb-5 mt-5" },
                                    [
                                      _c("h4", { staticClass: "pa-1" }, [
                                        _vm._v("In-App Auto Renew")
                                      ]),
                                      _c("v-switch", {
                                        staticStyle: {
                                          "font-size": "10px",
                                          "padding-left": "10px"
                                        },
                                        attrs: {
                                          inset: "",
                                          color: "green",
                                          disabled: _vm.IsDementiaAdmin,
                                          label:
                                            "" +
                                            (_vm.isAutoRenew
                                              ? "Enabled"
                                              : "Disabled")
                                        },
                                        model: {
                                          value: _vm.isAutoRenew,
                                          callback: function($$v) {
                                            _vm.isAutoRenew = $$v
                                          },
                                          expression: "isAutoRenew"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.IsCMSAdmin
                        ? _c(
                            "div",
                            { staticClass: "text-end pt-2" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", "x-large": "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.updateSubscription()
                                    }
                                  }
                                },
                                [_vm._v(" Save ")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.errors.length > 0
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-icon",
                            {
                              staticStyle: { color: "red" },
                              on: {
                                click: function($event) {
                                  _vm.errors = []
                                }
                              }
                            },
                            [_vm._v("mdi-close")]
                          ),
                          _vm._l(_vm.errors, function(items, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "pa-2 ma-1" },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c(
                                          "v-alert",
                                          {
                                            staticClass:
                                              "invite-learner v-alert",
                                            attrs: {
                                              dense: "",
                                              text: "",
                                              type: "error",
                                              value: true,
                                              transition: "slide-y-transition"
                                            }
                                          },
                                          [_vm._v(_vm._s(items.message))]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "600px" },
              model: {
                value: _vm.showNewDialog,
                callback: function($$v) {
                  _vm.showNewDialog = $$v
                },
                expression: "showNewDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-form",
                    { ref: "form" },
                    [
                      _c("v-card-title", [
                        _c("span", { staticClass: "headline" }, [
                          _vm._v("Set Subscription Availability")
                        ])
                      ]),
                      _c(
                        "v-card-text",
                        { staticStyle: { "font-size": "16px" } },
                        [
                          _vm._v(
                            " Are you sure you want to set the availability of "
                          ),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(_vm.subscriptionDetails.name))
                          ]),
                          _vm._v(" to Enterprise users? "),
                          _c("p", { staticClass: "pt-4" }, [
                            _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  " Once a subscription availability is set it can not be changed."
                                )
                              ]
                            ),
                            _vm._v(
                              " Reverting this change will require contacting Suyati support. "
                            )
                          ])
                        ]
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { depressed: "", text: "" },
                              on: { click: _vm.changeAvailability }
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.showModel }
                            },
                            [_vm._v(" Set Availability ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "600px" },
              model: {
                value: _vm.showNewDialogEveryone,
                callback: function($$v) {
                  _vm.showNewDialogEveryone = $$v
                },
                expression: "showNewDialogEveryone"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-form",
                    { ref: "form" },
                    [
                      _c("v-card-title", [
                        _c("span", { staticClass: "headline" }, [
                          _vm._v("Set Subscription Availability")
                        ])
                      ]),
                      _c(
                        "v-card-text",
                        { staticStyle: { "font-size": "16px" } },
                        [
                          _vm._v(
                            " Are you sure you want to set the availability of "
                          ),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(_vm.subscriptionDetails.name))
                          ]),
                          _vm._v(" to Everyone? "),
                          _c("p", { staticClass: "pt-4" }, [
                            _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  " Once a subscription availability is set it can not be changed."
                                )
                              ]
                            ),
                            _vm._v(
                              " Reverting this change will require contacting Suyati support. "
                            )
                          ])
                        ]
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { depressed: "", text: "" },
                              on: { click: _vm.changeAvailability }
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.showModelEveryone }
                            },
                            [_vm._v(" Set Availability ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }