var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { sm: "1" } }, [
                _c("h3", { staticClass: "font-weight-black" }, [
                  _vm._v(" " + _vm._s(_vm.index) + " ")
                ])
              ]),
              _c(
                "v-col",
                [
                  _c("h2", { staticClass: "indigo--text" }, [
                    _vm._v("Image Choice")
                  ]),
                  _c("h3", [_vm._v("Question")]),
                  _c("app-rich-text-editor", {
                    model: {
                      value: _vm.local.quiz.question,
                      callback: function($$v) {
                        _vm.$set(_vm.local.quiz, "question", $$v)
                      },
                      expression: "local.quiz.question"
                    }
                  }),
                  _c("h3", { staticClass: "mt-6 pb-1" }, [_vm._v("Answers")]),
                  _vm.IsCMSAdmin
                    ? _c("input", {
                        staticClass: "pb-1",
                        attrs: {
                          color: "primary",
                          type: "file",
                          accept: "image/*"
                        },
                        on: {
                          change: function($event) {
                            return _vm.uploadImage($event)
                          }
                        }
                      })
                    : _vm._e(),
                  _c(
                    "table",
                    {
                      staticClass: "mb-6",
                      staticStyle: { "border-spacing": "0" }
                    },
                    [
                      _c("thead", [
                        _c("tr", [
                          _c("th"),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                "text-align": "left",
                                width: "50%"
                              }
                            },
                            [_vm._v("Preview")]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "px-2",
                              staticStyle: {
                                "text-align": "left",
                                "white-space": "nowrap"
                              }
                            },
                            [_vm._v(" Correct Answer ")]
                          ),
                          _c("th", [_vm._v("Action")])
                        ])
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.local.quiz.answers, function(
                          answer,
                          indexs
                        ) {
                          return _c("tr", { key: answer.id }, [
                            _c("td", { staticClass: "pr-4 pb-6 pt-4" }, [
                              _vm._v(_vm._s(indexs + 1))
                            ]),
                            _c("td", [
                              _c("img", {
                                staticClass: "image",
                                attrs: {
                                  width: "200",
                                  height: "200",
                                  src: answer.image
                                }
                              })
                            ]),
                            _c(
                              "td",
                              { staticStyle: { "text-align": "center" } },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: answer.correct,
                                      expression: "answer.correct"
                                    }
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(answer.correct)
                                      ? _vm._i(answer.correct, null) > -1
                                      : answer.correct
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = answer.correct,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              answer,
                                              "correct",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              answer,
                                              "correct",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(answer, "correct", $$c)
                                      }
                                    }
                                  }
                                })
                              ]
                            ),
                            _c(
                              "td",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "", color: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteAnswer(indexs)
                                      }
                                    }
                                  },
                                  [_c("v-icon", [_vm._v("mdi-minus-circle")])],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        }),
                        0
                      ),
                      _c("tbody", {
                        staticStyle: { "background-color": "rgba(0,0,0,0.05)" }
                      })
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("h3", { staticClass: "mt-6" }, [
                        _vm._v("Incorrect Answer Text")
                      ]),
                      _c("v-text-field", {
                        staticClass: "shrink ",
                        attrs: { outlined: "", dense: "", "hide-details": "" },
                        model: {
                          value: _vm.local.quiz.incorrectAnswerText,
                          callback: function($$v) {
                            _vm.$set(_vm.local.quiz, "incorrectAnswerText", $$v)
                          },
                          expression: "local.quiz.incorrectAnswerText"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.IsCMSAdmin
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            "x-small": "",
                            title: "Remove the current quiz"
                          },
                          on: {
                            click: function($event) {
                              return _vm.$emit(
                                "removethiscomponent",
                                _vm.local.quiz
                              )
                            }
                          }
                        },
                        [_vm._v("Remove")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }