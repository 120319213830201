<template>
  <div>
    <div class="info-head">
      <div v-if="isAuthenticated && getUser.user">
        <drawer-user-item :user="getUser.user" />
        <v-menu right bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" class="ml-2" v-on="on">
              <v-icon class="light--text">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list class="light">
            <v-list-item @click="lmsaboutdialogue = true">
              <v-list-item-title class="primary--text"
                >About</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="callLogout">
              <v-list-item-title class="primary--text"
                >Logout</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <router-link
        v-if="isUnauthenticated"
        :to="`/login`"
        class="light--text v-btn v-btn--flat v-btn--text theme--light elevation-0 v-size--default"
      >
        <v-icon class="mr-2 light--text">mdi-account-plus</v-icon>
        Login
      </router-link>
    </div>
    <v-dialog
      v-model="logoutconfirmdialog"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline"
          >Please confirm you wish to logout.</v-card-title
        >
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="logoutconfirmdialog = false"
            >Cancel</v-btn
          >
          <v-btn color="red darken-1" text @click="confirmLogout"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="lmsaboutdialogue" persistent max-width="400">
      <v-card class="mx-auto" max-width="400">
        <v-card-title>
          LMS
        </v-card-title>
        <v-card-text>
          <div v-if="true">
            <p class="text--primary">
              Environment: {{ env.VUE_APP_ENV }}
            </p>
            <!-- <p class="text--primary">Mode: {{ mode }}</p> -->
            <!-- <p class="text--primary">Git: {{ env.VUE_APP_GIT_HASH }}</p> -->
            <p class="text--primary">
              Build: {{ env.VUE_APP_BUILD_DATE }}
            </p>
          </div>
        </v-card-text>
        <v-layout justify-center>
          <v-card-actions>
            <v-btn color="primary" @click="lmsaboutdialogue = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AuthTypes from './AuthTypes';
import DrawerUserItem from './DrawerUserItem';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DrawerUserList',
  components: { DrawerUserItem },
  data: () => ({
    logoutconfirmdialog: false,
    lmsaboutdialogue: false,
    username: '',
    password: ''
  }),
  computed: {
    mode() {
      return process.env.NODE_ENV;
    },
    env() {
      return process.env;
    },
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating'
    ]),
    showLoginDialog: {
      get: function() {
        return this.isAuthenticating;
      },
      // If dialog is dismissed by clicking outside
      // or ESC then cancel login.
      set: function(value) {
        if (!value) {
          this.cancelLogin();
        }
      }
    }
  },
  created() {
    this.init(this.$apollo);
  },
  methods: {
    confirmLogout() {
      this.logoutconfirmdialog = false;
      this.logout();
      this.$router.push('/login');
    },
    callLogout() {
      this.logoutconfirmdialog = true;
    },
    ...mapActions('auth', [
      AuthTypes.Actions.LOGIN,
      AuthTypes.Actions.LOGOUT,
      AuthTypes.Actions.CANCEL_LOGIN,
      AuthTypes.Actions.AUTHENTICATE,
      AuthTypes.Actions.INIT
    ]),
    callAuthenticate() {
      this.authenticate({
        apollo: this.$apollo,
        username: this.username,
        password: this.password
      });
    }
  }
};
</script>
