var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "editor-picture" }, [
    _c("div", { staticClass: "preview-frame" }, [
      _vm.getPreview()
        ? _c("img", { staticClass: "image", attrs: { src: _vm.getPreview() } })
        : _c("div", { staticClass: "message" }, [_vm._v("No image set")])
    ]),
    _c(
      "div",
      { staticClass: "editing-panel" },
      [
        _c("div", [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c("v-file-input", {
                ref: "uploadFile",
                attrs: {
                  "show-size": "",
                  label: "Upload image",
                  "prepend-icon": "mdi-camera",
                  loading: _vm.isUploading,
                  messages: _vm.form.uploadMessages,
                  "error-messages": _vm.form.uploadErrors,
                  "success-messages": _vm.form.uploadSuccess
                },
                on: { change: _vm.setUploadPreview },
                model: {
                  value: _vm.form.uploadFile,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "uploadFile", $$v)
                  },
                  expression: "form.uploadFile"
                }
              }),
              _c(
                "v-btn",
                {
                  attrs: {
                    icon: "",
                    disabled: !_vm.form.uploadFile,
                    color: "primary"
                  },
                  on: { click: _vm.uploadFile }
                },
                [_c("v-icon", [_vm._v("mdi-upload")])],
                1
              )
            ],
            1
          )
        ]),
        _c("v-text-field", {
          attrs: { "prepend-icon": "mdi-web", label: "Image KEY" },
          model: {
            value: _vm.value.key,
            callback: function($$v) {
              _vm.$set(_vm.value, "key", $$v)
            },
            expression: "value.key"
          }
        }),
        _c("v-text-field", {
          attrs: { "prepend-icon": "mdi-eye-off", label: "Alt text" },
          model: {
            value: _vm.value.alt,
            callback: function($$v) {
              _vm.$set(_vm.value, "alt", $$v)
            },
            expression: "value.alt"
          }
        }),
        _c("v-text-field", {
          attrs: { "prepend-icon": "mdi-subtitles", label: "Caption" },
          model: {
            value: _vm.value.caption,
            callback: function($$v) {
              _vm.$set(_vm.value, "caption", $$v)
            },
            expression: "value.caption"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }