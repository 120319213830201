var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "page-sub-sec" },
        [
          _c("v-text-field", {
            staticClass: "shrink",
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Search",
              outlined: "",
              dense: "",
              "hide-details": ""
            },
            on: { input: _vm.usersData },
            model: {
              value: _vm.searchEnterpriseUser,
              callback: function($$v) {
                _vm.searchEnterpriseUser = $$v
              },
              expression: "searchEnterpriseUser"
            }
          }),
          _c(
            "div",
            [
              _vm.IsDementiaAdmin || _vm.IsORGAdmin
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-5",
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.showNewDialog = true
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-account-plus")
                      ]),
                      _vm._v("Invite user")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "dark--text",
        attrs: {
          items: _vm.users,
          headers: _vm.computedUserHeaders,
          search: _vm.searchEnterpriseUser,
          loading: _vm.loading,
          "loading-text": "Loading... Please wait",
          "no-data-text": "No user available",
          "hide-default-footer": _vm.usersCount < 10 ? true : false,
          "page-count": _vm.pageSize,
          page: _vm.page,
          options: _vm.options,
          "server-items-length": _vm.usersCount,
          "footer-props": {
            "items-per-page-options": [50, 100, 500]
          }
        },
        on: {
          "update:options": function($event) {
            _vm.options = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.name",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "router-link",
                    {
                      staticClass: "blue--text",
                      attrs: { to: "/users/" + item.id }
                    },
                    [_vm._v(" " + _vm._s(item.name))]
                  )
                ]
              }
            },
            {
              key: "item.email",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.email) + " ")]
              }
            },
            {
              key: "item.role",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.role) + " ")]
              }
            },
            {
              key: "item.orgName",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.orgName) + " ")]
              }
            },
            {
              key: "item.userActive",
              fn: function(ref) {
                var item = ref.item
                return [
                  !item.userActive
                    ? _c("div", { staticClass: "warning--text" }, [
                        _vm._v(" Inactive ")
                      ])
                    : _c("div", { staticClass: "success--text" }, [
                        _vm._v(" Active ")
                      ])
                ]
              }
            },
            {
              key: "item.action",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm.getLoginedUserId != item.id
                    ? _c(
                        "div",
                        { staticClass: "lessons-actions-wrap" },
                        [
                          item.userActive
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "green",
                                    icon: "",
                                    small: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.enableOrDisableUser(
                                        item.id,
                                        false
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { title: "Enable" } }, [
                                    _vm._v("mdi-eye")
                                  ])
                                ],
                                1
                              )
                            : _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "orange",
                                    icon: "",
                                    small: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.enableOrDisableUser(
                                        item.id,
                                        true
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { title: "Disable" } },
                                    [_vm._v("mdi-eye-off")]
                                  )
                                ],
                                1
                              ),
                          _vm.IsDementiaAdmin || _vm.IsORGAdmin
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    icon: "",
                                    color: "light-blue",
                                    small: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.reInviteUser(
                                        item.id,
                                        item.username
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { title: "Reinvite" } },
                                    [_vm._v("mdi-account-reactivate")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--small",
                              attrs: { to: "/users/" + item.id }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "blue--text",
                                  attrs: { title: "Edit" }
                                },
                                [_vm._v("mdi-pencil")]
                              )
                            ],
                            1
                          ),
                          _vm.hideDelete == "false"
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", color: "red", small: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.LoadConfirmDialogForDeleteOrg(
                                        item.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { title: "Delete" } }, [
                                    _vm._v("mdi-trash-can-outline")
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.showNewDialog,
            callback: function($$v) {
              _vm.showNewDialog = $$v
            },
            expression: "showNewDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v("Invite user")
                    ])
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Email*",
                          rules: _vm.emailRules,
                          required: ""
                        },
                        model: {
                          value: _vm.forms.newEmail,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "newEmail", $$v)
                          },
                          expression: "forms.newEmail"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          rules: [
                            function(v) {
                              return !!v || "First name is required"
                            }
                          ],
                          label: "First name*",
                          required: ""
                        },
                        model: {
                          value: _vm.forms.newFirstName,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "newFirstName", $$v)
                          },
                          expression: "forms.newFirstName"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: { label: "Last name" },
                        model: {
                          value: _vm.forms.newLastName,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "newLastName", $$v)
                          },
                          expression: "forms.newLastName"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: { label: "Phone Number" },
                        model: {
                          value: _vm.forms.newPhoneNum,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "newPhoneNum", $$v)
                          },
                          expression: "forms.newPhoneNum"
                        }
                      }),
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.getRolesByRole,
                          rules: [
                            function(v) {
                              return !!v || "Role is required"
                            }
                          ],
                          "item-text": "text",
                          "item-value": "name",
                          label: "Select Role*",
                          required: ""
                        },
                        model: {
                          value: _vm.forms.newRoleName,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "newRoleName", $$v)
                          },
                          expression: "forms.newRoleName"
                        }
                      }),
                      _vm.IsDementiaAdmin && _vm.IsOrglevelRole
                        ? _c("v-autocomplete", {
                            attrs: {
                              items: _vm.orgs,
                              "item-text": "name",
                              "item-value": "id",
                              label: "Select Organisation*",
                              rules: [
                                function(v) {
                                  return !!v || "Organization is required"
                                }
                              ],
                              required: ""
                            },
                            model: {
                              value: _vm.forms.newOrgId,
                              callback: function($$v) {
                                _vm.$set(_vm.forms, "newOrgId", $$v)
                              },
                              expression: "forms.newOrgId"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("small", { staticClass: "info--text" }, [
                        _vm._v("*indicates required field")
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "", text: "" },
                          on: { click: _vm.cancel }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.createNewUser }
                        },
                        [_vm._v(" Invite ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "450" },
          model: {
            value: _vm.confirmdialogUserdelete,
            callback: function($$v) {
              _vm.confirmdialogUserdelete = $$v
            },
            expression: "confirmdialogUserdelete"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Are you sure")
              ]),
              _c("v-card-text", [
                _vm._v("Please confirm you wish to remove the selected user")
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmdialogUserdelete = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.deleteUser(_vm.userdeleteId)
                        }
                      }
                    },
                    [_vm._v("Confirm")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }