var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-content-list-editor" },
    [
      _vm._l(_vm.local.content, function(content, index) {
        return _c(
          "div",
          { key: content._vueKey, staticClass: "app-content-list-item" },
          [
            _c(content.__typename, {
              ref: "items",
              refInFor: true,
              tag: "component",
              class: _vm.editItem === content._vueKey ? "editing" : "",
              on: { input: _vm.update },
              model: {
                value: _vm.local.content[index],
                callback: function($$v) {
                  _vm.$set(_vm.local.content, index, $$v)
                },
                expression: "local.content[index]"
              }
            }),
            _vm.IsCMSAdmin
              ? _c(
                  "v-toolbar",
                  {
                    staticClass: "app-content-list-controls .rounded-b-md",
                    attrs: { flat: "", dense: "" }
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          icon: "",
                          outlined: _vm.editItem == content._vueKey
                        },
                        on: {
                          click: function($event) {
                            return _vm.edit(content._vueKey)
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { "aria-label": "Edit item settings" } },
                          [_vm._v("mdi-tools")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", disabled: index < 1 },
                        on: {
                          click: function($event) {
                            return _vm.move(index, index - 1)
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { "aria-label": "Move item up" } },
                          [_vm._v("mdi-arrow-up")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", disabled: index < 1 },
                        on: {
                          click: function($event) {
                            return _vm.move(index, 0)
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { "aria-label": "Move item to top" } },
                          [_vm._v("mdi-arrow-collapse-up")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          icon: "",
                          disabled: index > _vm.local.content.length - 2
                        },
                        on: {
                          click: function($event) {
                            return _vm.move(index, index + 1)
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { "aria-label": "Move item down" } },
                          [_vm._v("mdi-arrow-down")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          icon: "",
                          disabled: index > _vm.local.content.length - 2
                        },
                        on: {
                          click: function($event) {
                            return _vm.move(index, _vm.local.content.length)
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { "aria-label": "Move item to end" } },
                          [_vm._v("mdi-arrow-collapse-down")]
                        )
                      ],
                      1
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function($event) {
                            return _vm.del(index)
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { "aria-label": "Delete item" } },
                          [_vm._v("mdi-delete-outline")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      }),
      _vm.IsCMSAdmin
        ? _c(
            "v-toolbar",
            {
              staticClass: "app-content-list-actions",
              attrs: { flat: "", dense: "" }
            },
            [
              _c("v-icon", { staticClass: "plus" }, [_vm._v("mdi-plus")]),
              _vm._l(_vm.contentTypes, function(contentType, name) {
                return _c(
                  "v-btn",
                  {
                    key: name,
                    staticClass: "mr-2",
                    attrs: { depressed: "" },
                    on: {
                      click: function($event) {
                        return _vm.add(contentType.name)
                      }
                    }
                  },
                  [
                    _c("v-icon", { staticClass: "mr-2" }, [
                      _vm._v(_vm._s(contentType.icon))
                    ]),
                    _vm._v(" " + _vm._s(contentType.text) + " ")
                  ],
                  1
                )
              })
            ],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }