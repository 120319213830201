var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { attrs: { fluid: "" } }, [
    _c(
      "div",
      [
        _c(
          "div",
          { staticClass: "page-sub-sec" },
          [
            _c("v-text-field", {
              staticClass: "shrink",
              attrs: {
                "append-icon": "mdi-magnify",
                label: "Search",
                outlined: "",
                dense: "",
                "hide-details": ""
              },
              model: {
                value: _vm.searchUser,
                callback: function($$v) {
                  _vm.searchUser = $$v
                },
                expression: "searchUser"
              }
            }),
            _vm.IsDementiaAdmin
              ? _c(
                  "v-btn",
                  {
                    attrs: { color: "primary" },
                    on: { click: _vm.showAddModal }
                  },
                  [
                    _c("v-icon", { staticClass: "mr-2" }, [_vm._v("mdi-plus")]),
                    _vm._v("Add Subscription")
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _c("v-data-table", {
          staticClass: "dark--text",
          attrs: {
            items: _vm.orgProducts,
            headers: _vm.computedUserHeaders,
            search: _vm.searchUser,
            "no-data-text": "No data available",
            loading: _vm.loading,
            "loading-text": "Loading... Please wait"
          },
          scopedSlots: _vm._u(
            [
              {
                key: "item.productName",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    !(_vm.IsORGAdmin || _vm.IsORGViewer)
                      ? _c(
                          "router-link",
                          {
                            staticClass: "blue--text",
                            attrs: {
                              to:
                                "/organisations/" +
                                _vm.orgId +
                                "/" +
                                item.productId
                            }
                          },
                          [_vm._v(_vm._s(item.productName))]
                        )
                      : _vm._e(),
                    _vm.IsORGAdmin || _vm.IsORGViewer
                      ? _c("span", [_vm._v(_vm._s(item.productName))])
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.accessCode",
                fn: function(ref) {
                  var item = ref.item
                  return [_vm._v(" " + _vm._s(item.accessCode) + " ")]
                }
              },
              {
                key: "item.totalLicense",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass: "blue--text",
                        attrs: {
                          to:
                            "/organisations/Users/" +
                            _vm.orgId +
                            "/" +
                            item.productId
                        }
                      },
                      [_vm._v(_vm._s(item.totalLicense))]
                    )
                  ]
                }
              },
              {
                key: "item.licence_remaining",
                fn: function(ref) {
                  var item = ref.item
                  return [_vm._v(" " + _vm._s(item.licence_remaining) + " ")]
                }
              },
              {
                key: "item.usedLicense",
                fn: function(ref) {
                  var item = ref.item
                  return [_vm._v(" " + _vm._s(item.usedLicense) + " ")]
                }
              },
              {
                key: "item.expirydateFormatted",
                fn: function(ref) {
                  var item = ref.item
                  return [_vm._v(" " + _vm._s(item.expirydateFormatted) + " ")]
                }
              },
              {
                key: "item.userValidationRequired",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(item.userValidationRequired ? " Yes" : " No") +
                        " "
                    )
                  ]
                }
              },
              {
                key: "item.productDisabled",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    !item.productDisabled
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              disabled: "",
                              color: "green",
                              icon: "",
                              small: ""
                            }
                          },
                          [
                            _c("v-icon", { attrs: { title: "Enable" } }, [
                              _vm._v("mdi-eye")
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    item.productDisabled
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              disabled: "",
                              color: "red",
                              icon: "",
                              small: ""
                            }
                          },
                          [
                            _c("v-icon", { attrs: { title: "Enable" } }, [
                              _vm._v("mdi-eye-off")
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(
                      " " +
                        _vm._s(!item.productDisabled ? " Visible" : " Hidden") +
                        " "
                    )
                  ]
                }
              },
              {
                key: "item.action",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm.getLoginedUserId != item.id
                      ? _c(
                          "div",
                          { staticClass: "lessons-actions-wrap" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "blue--text",
                                attrs: { title: "Edit" },
                                on: {
                                  click: function($event) {
                                    return _vm.editItem(item)
                                  }
                                }
                              },
                              [_vm._v("mdi-pencil")]
                            ),
                            _vm.hideDelete == "false"
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      icon: "",
                                      color: "red",
                                      small: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.LoadConfirmDialogForDeleteOrg(
                                          item
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { title: "Delete" } },
                                      [_vm._v("mdi-trash-can-outline")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ],
            null,
            true
          )
        }),
        _c(
          "v-dialog",
          {
            attrs: { persistent: "", "max-width": "600px" },
            model: {
              value: _vm.showNewDialog,
              callback: function($$v) {
                _vm.showNewDialog = $$v
              },
              expression: "showNewDialog"
            }
          },
          [
            _c(
              "v-card",
              [
                _c(
                  "v-form",
                  { ref: "form", on: { submit: _vm.addSubscription } },
                  [
                    _c("v-card-title", [
                      !_vm.editSub
                        ? _c("span", { staticClass: "headline" }, [
                            _vm._v("Add Subscription to Organisation")
                          ])
                        : _c("span", { staticClass: "headline" }, [
                            _vm._v("Edit Subscription")
                          ])
                    ]),
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-row",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { sm: "6" } },
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    items: _vm.products,
                                    "item-text": "name",
                                    "item-value": "id",
                                    label: _vm.forms.selectedProduct
                                      ? _vm.editSub
                                        ? _vm.forms.selectedProduct +
                                          " - " +
                                          _vm.editproductName
                                        : "ID-" + _vm.forms.selectedProduct
                                      : "Subscription Name*",
                                    disabled: _vm.editSub ? true : false,
                                    rules: [
                                      function(v) {
                                        return !!v || "Select a Product"
                                      }
                                    ],
                                    required: ""
                                  },
                                  on: {
                                    change: function(event) {
                                      return _vm.selecteProduct(event)
                                    }
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "item",
                                      fn: function(data) {
                                        return [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#0d3349",
                                                      "font-size": "10px"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(data.item.id) +
                                                        " - "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(data.item.name) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.forms.selectedProduct,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.forms,
                                        "selectedProduct",
                                        $$v
                                      )
                                    },
                                    expression: "forms.selectedProduct"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticStyle: { "margin-top": "-10px" },
                                attrs: { sm: "6" }
                              },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticStyle: { "align-self": "center" },
                                        attrs: { sm: "2" }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#E0E0E0" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.orgName &&
                                                    _vm.orgName.slice(0, 3)
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { sm: "10" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Unique Subscription Code*",
                                            rules: [
                                              function(v) {
                                                return (
                                                  !!v ||
                                                  "Access Code is required"
                                                )
                                              },
                                              function(v) {
                                                return (
                                                  /^[0-9a-zA-Z]+$/.test(v) ||
                                                  "Special characters and spaces are not allowed"
                                                )
                                              }
                                            ],
                                            required: ""
                                          },
                                          model: {
                                            value: _vm.forms.code,
                                            callback: function($$v) {
                                              _vm.$set(_vm.forms, "code", $$v)
                                            },
                                            expression: "forms.code"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { sm: "4" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "Number of Licenses*",
                                    type: "number",
                                    min: "0",
                                    disabled: !_vm.IsDementiaAdmin,
                                    rules: [
                                      function(v) {
                                        return (
                                          !!v ||
                                          "Number of Licenses is required"
                                        )
                                      },
                                      function(v) {
                                        return (
                                          v > 0 ||
                                          "Please Enter a Valid License Count"
                                        )
                                      },
                                      function(v) {
                                        return (
                                          v < 2147483647 ||
                                          "Enter valid Number of Licenses"
                                        )
                                      },
                                      function(v) {
                                        return _vm.validateTotalLicence(v)
                                      }
                                    ],
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.forms.noOfLicense,
                                    callback: function($$v) {
                                      _vm.$set(_vm.forms, "noOfLicense", $$v)
                                    },
                                    expression: "forms.noOfLicense"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm.showNewDialog
                              ? _c(
                                  "v-col",
                                  { attrs: { sm: "4" } },
                                  [
                                    _c(
                                      "v-dialog",
                                      {
                                        ref: "dialog",
                                        attrs: {
                                          "return-value": _vm.forms.expirydate,
                                          persistent: "",
                                          width: "290px"
                                        },
                                        on: {
                                          "update:returnValue": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.forms,
                                              "expirydate",
                                              $event
                                            )
                                          },
                                          "update:return-value": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.forms,
                                              "expirydate",
                                              $event
                                            )
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            disabled: !_vm.IsDementiaAdmin,
                                                            rules: [
                                                              function(v) {
                                                                return (
                                                                  !!v ||
                                                                  "Expiry Date is required"
                                                                )
                                                              }
                                                            ],
                                                            label:
                                                              "Select Expiry Date",
                                                            "prepend-icon":
                                                              "mdi-calendar",
                                                            readonly: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.forms
                                                                .expirydate,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.forms,
                                                                "expirydate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "forms.expirydate"
                                                          }
                                                        },
                                                        "v-text-field",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2448507854
                                        ),
                                        model: {
                                          value: _vm.modal,
                                          callback: function($$v) {
                                            _vm.modal = $$v
                                          },
                                          expression: "modal"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-date-picker",
                                          {
                                            attrs: {
                                              scrollable: "",
                                              min: new Date(
                                                Date.now() -
                                                  new Date().getTimezoneOffset() *
                                                    360000
                                              )
                                                .toISOString()
                                                .substr(0, 10)
                                            },
                                            model: {
                                              value: _vm.forms.expirydate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.forms,
                                                  "expirydate",
                                                  $$v
                                                )
                                              },
                                              expression: "forms.expirydate"
                                            }
                                          },
                                          [
                                            _c("v-spacer"),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  text: "",
                                                  color: "primary"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    _vm.modal = false
                                                  }
                                                }
                                              },
                                              [_vm._v(" Cancel ")]
                                            ),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  text: "",
                                                  color: "primary"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.$refs.dialog.save(
                                                      _vm.forms.expirydate
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" OK ")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-col",
                              { attrs: { sm: "4" } },
                              [
                                _c("span", {}, [_vm._v("User Validation*")]),
                                _c("v-switch", {
                                  staticStyle: { "margin-top": "0px" },
                                  attrs: {
                                    inset: "",
                                    color: "green",
                                    vis: "",
                                    label:
                                      "" +
                                      (_vm.forms.validation
                                        ? "Enabled"
                                        : "Disabled")
                                  },
                                  model: {
                                    value: _vm.forms.validation,
                                    callback: function($$v) {
                                      _vm.$set(_vm.forms, "validation", $$v)
                                    },
                                    expression: "forms.validation"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.errors.length > 0
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: { color: "red" },
                                    on: {
                                      click: function($event) {
                                        _vm.errors = []
                                      }
                                    }
                                  },
                                  [_vm._v("mdi-close")]
                                ),
                                _vm._l(_vm.errors, function(items, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "pa-2 ma-1" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-alert",
                                                {
                                                  staticClass:
                                                    "invite-learner v-alert",
                                                  attrs: {
                                                    dense: "",
                                                    text: "",
                                                    type: "error",
                                                    value: true,
                                                    transition:
                                                      "slide-y-transition"
                                                  }
                                                },
                                                [_vm._v(_vm._s(items.message))]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      [
                        _c("small", { staticClass: "info--text" }, [
                          _vm._v(
                            "*Please ensure each mandatory field marked with an asterisk * is completed"
                          )
                        ]),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { depressed: "", text: "" },
                            on: { click: _vm.cancel }
                          },
                          [_vm._v(" Cancel ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary" },
                            on: { click: _vm.addSubscription }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.editSub ? "Update" : "Add") +
                                " Subscription "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "v-dialog",
          {
            attrs: { persistent: "", "max-width": "600" },
            model: {
              value: _vm.confirmdialogUserdelete,
              callback: function($$v) {
                _vm.confirmdialogUserdelete = $$v
              },
              expression: "confirmdialogUserdelete"
            }
          },
          [
            _c(
              "v-card",
              [
                _c("v-card-title", { staticClass: "headline" }, [
                  _vm._v("Remove Subscription From Organisation?")
                ]),
                _c("v-card-text", [
                  _vm._v("Are you sure you want to remove . "),
                  _c(
                    "span",
                    {
                      staticClass: "success--text",
                      staticStyle: {
                        "font-weight": "bold",
                        "font-size": "16px"
                      }
                    },
                    [_vm._v(_vm._s(_vm.subscriptionName))]
                  ),
                  _vm._v(" from the organsation "),
                  _c(
                    "span",
                    {
                      staticClass: "success--text",
                      staticStyle: {
                        "font-weight": "bold",
                        "font-size": "16px"
                      }
                    },
                    [_vm._v(_vm._s(_vm.orgName))]
                  ),
                  _vm._v(
                    "? This will remove access for " +
                      _vm._s(_vm.totalLicence) +
                      " learners."
                  )
                ]),
                _c(
                  "v-card-actions",
                  [
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "#113247 darken-1", text: "" },
                        on: {
                          click: function($event) {
                            _vm.confirmdialogUserdelete = false
                          }
                        }
                      },
                      [_vm._v("Cancel")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "actionButton",
                        attrs: { text: "" },
                        on: {
                          click: function($event) {
                            return _vm.deleteSubscription(_vm.userdeleteId)
                          }
                        }
                      },
                      [_vm._v("REMOVE SUBSCRIPTION")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }