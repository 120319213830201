var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex justify-center" },
        [
          _c(
            "v-alert",
            {
              staticClass: "v-alert",
              attrs: {
                dense: "",
                text: "",
                type: _vm.alertType,
                value: _vm.alert,
                transition: "slide-y-transition"
              }
            },
            [_vm._v(_vm._s(_vm.alertText.text))]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "page-sub-sec" },
        [
          _c("v-text-field", {
            staticClass: "shrink",
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Search",
              outlined: "",
              dense: "",
              "hide-details": ""
            },
            on: { input: _vm.usersData },
            model: {
              value: _vm.searchPublicLearner,
              callback: function($$v) {
                _vm.searchPublicLearner = $$v
              },
              expression: "searchPublicLearner"
            }
          }),
          _c(
            "div",
            { staticClass: "mr-2" },
            [
              _vm.IsDementiaAdmin
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      attrs: {
                        color: "primary",
                        loading: _vm.paymentreportloading
                      },
                      on: { click: _vm.generatePaymentReport }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-file-excel")
                      ]),
                      _vm._v("Generate Payment Report")
                    ],
                    1
                  )
                : _vm._e(),
              false && _vm.IsDementiaAdmin
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", loading: _vm.reportloading },
                      on: { click: _vm.generateReport }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-file-excel")
                      ]),
                      _vm._v("Generate Report")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "dark--text",
        attrs: {
          items: _vm.users,
          headers: _vm.headers,
          search: _vm.searchPublicLearner,
          "no-data-text": "No user available",
          "hide-default-footer": _vm.usersCount < 10 ? true : false,
          loading: this.$apollo.loading,
          "loading-text": "Loading... Please wait",
          expanded: _vm.expanded,
          "show-expand": "",
          "single-expand": "",
          "item-key": "id",
          "page-count": _vm.pageSize,
          page: _vm.page,
          options: _vm.options,
          "server-items-length": _vm.usersCount,
          "footer-props": {
            "items-per-page-options": [50, 100, 500]
          }
        },
        on: {
          "update:expanded": function($event) {
            _vm.expanded = $event
          },
          "update:options": function($event) {
            _vm.options = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.name",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "router-link",
                    {
                      staticClass: "blue--text",
                      attrs: { to: "/users/" + item.id }
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                ]
              }
            },
            {
              key: "item.email",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.email) + " ")]
              }
            },
            {
              key: "item.role.text",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.role.text) + " ")]
              }
            },
            {
              key: "item.blocked",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.blocked
                    ? _c("div", { staticClass: "warning--text" }, [
                        _vm._v(" Inactive ")
                      ])
                    : _c("div", { staticClass: "success--text" }, [
                        _vm._v(" Active ")
                      ])
                ]
              }
            },
            {
              key: "item.action",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm.getLoginedUserId != item.id
                    ? _c(
                        "div",
                        { staticClass: "lessons-actions-wrap" },
                        [
                          item.blocked
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "green",
                                    icon: "",
                                    small: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.enableOrDisableUser(
                                        item.id,
                                        false
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { title: "Enable" } }, [
                                    _vm._v("mdi-eye")
                                  ])
                                ],
                                1
                              )
                            : _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "orange",
                                    icon: "",
                                    small: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.enableOrDisableUser(
                                        item.id,
                                        true
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { title: "Disable" } },
                                    [_vm._v("mdi-eye-off")]
                                  )
                                ],
                                1
                              ),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--small",
                              attrs: { to: "/users/" + item.id }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "blue--text",
                                  attrs: { title: "Edit" }
                                },
                                [_vm._v("mdi-pencil")]
                              )
                            ],
                            1
                          ),
                          _vm.hideDelete == "false"
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", color: "red", small: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.LoadConfirmDialogForDeleteOrg(
                                        item.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { title: "Delete" } }, [
                                    _vm._v("mdi-trash-can-outline")
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.subscription",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.subscription) + " ")]
              }
            },
            {
              key: "expanded-item",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "td",
                    {
                      staticStyle: { background: "white", padding: "0px" },
                      attrs: { colspan: _vm.headers.length }
                    },
                    [
                      _c("v-simple-table", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function() {
                                return [
                                  _c("thead", [
                                    _c(
                                      "tr",
                                      {
                                        staticStyle: { background: "#E5E5E5" }
                                      },
                                      [
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(" Subscription Name ")
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(" Purchase Date ")
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(" Expiry Date ")
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(" Auto Renewal ")
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(" Status ")
                                        ])
                                      ]
                                    )
                                  ]),
                                  item.products && item.products.length > 0
                                    ? _c(
                                        "tbody",
                                        _vm._l(item.products, function(items) {
                                          return _c(
                                            "tr",
                                            { key: items.productId },
                                            [
                                              _c("td", [
                                                _vm._v(_vm._s(items.product))
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      items.purchaseDate &&
                                                        _vm.loadDate(
                                                          items.purchaseDate
                                                        )
                                                    ) +
                                                    " "
                                                )
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      items.expirationDate &&
                                                        _vm.loadDate(
                                                          items.expirationDate
                                                        )
                                                    ) +
                                                    " "
                                                )
                                              ]),
                                              _c("td", [
                                                items.autoRenewal
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "warning--text"
                                                      },
                                                      [_vm._v(" Yes ")]
                                                    )
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "success--text"
                                                      },
                                                      [_vm._v(" No ")]
                                                    )
                                              ]),
                                              _c("td", [
                                                items.productActive
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "success--text"
                                                      },
                                                      [_vm._v(" Active ")]
                                                    )
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "warning--text"
                                                      },
                                                      [_vm._v(" Inactive ")]
                                                    )
                                              ])
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    : _c("tbody", [
                                        _c("tr", [
                                          _c(
                                            "td",
                                            {
                                              staticClass: "text-center",
                                              staticStyle: {
                                                color: "#ababab",
                                                "font-size": ".875rem"
                                              },
                                              attrs: { colspan: "5" }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s("No data available") +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ])
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }