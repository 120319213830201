var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: "6" } },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-center" },
        [
          _c(
            "v-alert",
            {
              staticClass: "v-alert",
              attrs: {
                dense: "",
                text: "",
                type: _vm.alert.type,
                value: _vm.alert.show,
                transition: "slide-y-transition"
              }
            },
            [_vm._v(_vm._s(_vm.alert.text))]
          )
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-form",
            { ref: "form", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-card-text",
                [
                  _c("div", { staticClass: "section-devider no-bg" }, [
                    _c("h2", [_vm._v("Available Courses")])
                  ]),
                  _c(
                    "div",
                    { staticClass: "page-sub-sec" },
                    [
                      _c("v-text-field", {
                        staticClass: "shrink",
                        attrs: {
                          "append-icon": "mdi-magnify",
                          label: "Search",
                          outlined: "",
                          dense: "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.searchCourse,
                          callback: function($$v) {
                            _vm.searchCourse = $$v
                          },
                          expression: "searchCourse"
                        }
                      }),
                      _vm.IsCMSAdmin
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.showModel }
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v("mdi-file-plus")
                              ]),
                              _vm._v("Add Course")
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    staticClass: "dark--text",
                    attrs: {
                      items: _vm.courses,
                      search: _vm.searchCourse,
                      headers: _vm.computedHeaders,
                      "hide-default-footer":
                        _vm.courses.length <= 10 ? true : false,
                      loading: _vm.loadingCourses,
                      "loading-text": "Loading... Please wait",
                      "no-data-text": "No data available"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.Title",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "router-link",
                                {
                                  staticClass: "blue--text",
                                  attrs: { to: "/courses/" + item.id }
                                },
                                [_vm._v(_vm._s(item.Title))]
                              )
                            ]
                          }
                        },
                        {
                          key: "item.id",
                          fn: function(ref) {
                            var item = ref.item
                            return [_vm._v(" " + _vm._s(item.id) + " ")]
                          }
                        },
                        {
                          key: "item.modules",
                          fn: function(ref) {
                            var item = ref.item
                            return [_vm._v(" " + _vm._s(item.modules) + " ")]
                          }
                        },
                        {
                          key: "item.lessons",
                          fn: function(ref) {
                            var item = ref.item
                            return [_vm._v(" " + _vm._s(item.lessons) + " ")]
                          }
                        },
                        {
                          key: "item.isDisabled",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              !item.isDisabled
                                ? _c("p", { staticClass: "success--text" }, [
                                    _vm._v(" Active ")
                                  ])
                                : _vm._e(),
                              item.isDisabled
                                ? _c("p", { staticClass: "warning--text" }, [
                                    _vm._v(" Inactive ")
                                  ])
                                : _vm._e()
                            ]
                          }
                        },
                        _vm.IsCMSAdmin
                          ? {
                              key: "item.sort",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        small: "",
                                        disabled:
                                          _vm.getIndex(item, _vm.courses) < 1
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.moveCourse(
                                            _vm.getIndex(item, _vm.courses),
                                            _vm.getIndex(item, _vm.courses) - 1
                                          )
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-arrow-up")])],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        small: "",
                                        disabled:
                                          _vm.getIndex(item, _vm.courses) >
                                          _vm.courses.length - 2
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.moveCourse(
                                            _vm.getIndex(item, _vm.courses),
                                            _vm.getIndex(item, _vm.courses) + 1
                                          )
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-arrow-down")])],
                                    1
                                  )
                                ]
                              }
                            }
                          : null
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.IsCMSAdmin
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "600px" },
              model: {
                value: _vm.showModal,
                callback: function($$v) {
                  _vm.showModal = $$v
                },
                expression: "showModal"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-form",
                    { ref: "form" },
                    [
                      _c("v-card-title", [
                        _c("span", { staticClass: "headline" }, [
                          _vm._v("Add Course to subscription")
                        ])
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "8" } },
                        [
                          _c(
                            "v-row",
                            [
                              _vm.selectedCourse
                                ? _c(
                                    "v-col",
                                    {
                                      staticStyle: {
                                        "align-self": "center",
                                        "text-align": "center"
                                      },
                                      attrs: { sm: "2" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#5F6368",
                                            "font-size": "10px"
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.selectedCourse))]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-col",
                                { attrs: { sm: "9" } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      items: _vm.primaryCourseList,
                                      "item-text": "Title",
                                      "item-value": "id",
                                      label: "Select Course*",
                                      required: ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function(data) {
                                            return [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "#0d3349",
                                                          "font-size": "12px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(data.item.id) +
                                                            " - "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          data.item.Title
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1423689366
                                    ),
                                    model: {
                                      value: _vm.selectedCourse,
                                      callback: function($$v) {
                                        _vm.selectedCourse = $$v
                                      },
                                      expression: "selectedCourse"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("small", { staticClass: "info--text" }, [
                            _vm._v("*indicates required field")
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { depressed: "", text: "" },
                              on: { click: _vm.showModel }
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.addCourse }
                            },
                            [_vm._v(" Add Course ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }