<template>
  <div>
    <div v-if="loading">
      <div class="text-center">
        <v-progress-linear indeterminate />
      </div>
    </div>
    <v-alert
      dense
      text
      class="v-alert"
      :type="alert.type"
      :value="alert.show"
      transition="slide-y-transition"
      >{{ alert.text }}</v-alert
    >
    <v-container fluid class="grey lighten-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
          <h1>
            Profile:
            <span class="success--text">{{ user.firstName }}</span>
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-tabs v-model="tab">
            <v-tab
              v-for="tab in computedTabs"
              :key="tab.id"
              @click="setActiveTab(tab.id)"
            >
              <v-icon class="mr-2">{{ tab.icon }}</v-icon>
              {{ tab.text }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="tab in computedTabs" :key="tab.id">
              <div v-if="tab.text == 'Details'">
                <v-row>
                  <v-col cols="6">
                    <v-card color="basil" flat>
                      <v-card outlined>
                        <v-form ref="form">
                          <v-card-title>
                            <h2>User Details</h2>
                          </v-card-title>
                          <v-card-text>
                            <h3 v-if="employeeIDRequired">
                              Employee ID
                            </h3>
                            <v-text-field
                              v-if="employeeIDRequired"
                              v-model="EmployeeID"
                              :rules="[v => validateEmployeeID(v)]"
                              :disabled="
                                !IsDementiaAdmin && !IsORGAdmin
                              "
                              required
                            />
                            <h3>Email</h3>
                            <v-text-field
                              v-if="IsDementiaAdmin"
                              v-model="local.user.email"
                              :rules="emailRules"
                              disabled
                            />
                            <v-text-field
                              v-else
                              v-model="local.user.email"
                              disabled
                            />
                            <h3>First name</h3>
                            <v-text-field
                              v-model="local.user.firstName"
                              :rules="firstNameRules"
                              required
                            />
                            <h3>Last name</h3>
                            <v-text-field
                              v-model="local.user.lastName"
                              required
                            />

                            <h3 v-if="IsOrgHide">
                              Organisation
                            </h3>
                            <v-text-field
                              v-if="IsOrgHide"
                              v-model="local.user.orgName"
                              disabled
                              required
                            />

                            <v-row>
                              <v-col>
                                <h4>
                                  Resend password reset email
                                </h4>
                                <p>
                                  If a user has not received their
                                  password reset email, use this
                                  button to resend
                                </p>
                              </v-col>
                              <v-col>
                                <v-btn
                                  class="ma-2"
                                  :loading="resetloading"
                                  :disabled="resetloading"
                                  color="info"
                                  @click="sendResetPassword"
                                >
                                  Resend Email
                                </v-btn>
                                <v-btn
                                  outlined
                                  fab
                                  color="info"
                                  x-small
                                  class="ma-2 white--text"
                                  :disabled="
                                    resetURL != '' ? false : true
                                  "
                                  @click="copyInvite(2)"
                                >
                                  <v-icon title="copy to clipboard"
                                    >mdi-link</v-icon
                                  >
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                              v-if="IsDementiaAdmin || IsORGAdmin"
                              color="primary"
                              x-large
                              @click="validateUser"
                              >Save</v-btn
                            >
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-card>
                  </v-col>
                  <v-col v-if="!IsNotLearnerRole" cols="6">
                    <v-card outlined>
                      <v-card-title>
                        <v-row>
                          <v-col>
                            <h2>Subscriptions</h2>
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-text>
                        <div class="page-sub-sec">
                          <v-text-field
                            v-model="searchUserSubscription"
                            append-icon="mdi-magnify"
                            label="Search"
                            outlined
                            dense
                            hide-details
                            class="shrink"
                          />
                        </div>
                        <v-data-table
                          :items="userSubscription"
                          :headers="computedSubscriptionHeaders"
                          :search="searchUserSubscription"
                          :loading="loadingSubscriptions"
                          loading-text="Loading... Please wait"
                          :no-data-text="
                            `No subscriptions available for this user`
                          "
                          :hide-default-footer="
                            subscriptionPaginationStatus()
                          "
                        >
                          <template v-slot:[`item.name`]="{ item }">
                            {{ item.name }}
                          </template>
                          <template
                            v-slot:[`item.purchasedateFormatted`]="{
                              item
                            }"
                          >
                            {{
                              item.purchasedateFormatted &&
                                loadDate(item.purchasedateFormatted)
                            }}
                          </template>
                          <template
                            v-slot:[`item.expirydateFormatted`]="{
                              item
                            }"
                          >
                            {{
                              item.expirydateFormatted &&
                                loadDate(item.expirydateFormatted)
                            }}
                          </template>
                          <template
                            v-slot:[`item.AutoRenewal`]="{
                              item
                            }"
                          >
                            {{
                              item.AutoRenewal
                                ? item.AutoRenewal
                                : 'Yes'
                            }}
                          </template>
                          <template
                            v-slot:[`item.isDisabled`]="{ item }"
                          >
                            <div
                              v-if="item.product.isDisabled"
                              class="warning--text"
                            >
                              Inactive
                            </div>
                            <div v-else class="success--text">
                              Active
                            </div>
                          </template>
                          <template v-slot:[`item.action`]="{ item }">
                            <div class="lessons-actions-wrap">
                              <v-btn
                                v-if="hideDelete == 'false'"
                                color="red"
                                icon
                                small
                                @click="
                                  LoadConfirmDialogForDeleteSubscription(
                                    item.product.name,
                                    item.id
                                  )
                                "
                              >
                                <v-icon title="Delete"
                                  >mdi-trash-can-outline</v-icon
                                ></v-btn
                              >
                            </div>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
              <div v-if="tab.text == 'Course Progress'">
                <v-card color="basil" flat>
                  <div v-if="user_courses">
                    <v-card outlined>
                      <v-data-table
                        :headers="headers"
                        :items="courseProgress"
                        show-expand
                        :single-expand="true"
                        :expanded.sync="expanded"
                        item-key="pcourseId"
                        :no-data-text="`No course available`"
                        :hide-default-footer="
                          coursePaginationStatus()
                        "
                      >
                        <template
                          v-slot:[`item.pcourseName`]="{ item }"
                        >
                          {{ item.pcourseName }}
                        </template>
                        <template
                          v-slot:[`item.productName`]="{ item }"
                        >
                          {{ item.productName }}
                        </template>
                        <template
                          v-slot:[`item.totalLessons`]="{ item }"
                        >
                          <v-tooltip right class="pa-2 ">
                            <template
                              v-slot:activator="{ on, attrs }"
                            >
                              <v-icon
                                v-bind="attrs"
                                color="primary"
                                v-on="on"
                              >
                                mdi-information
                              </v-icon>
                            </template>
                            <span>
                              <span
                                >Course Id: {{ item.pcourseId }}</span
                              ><v-spacer />
                              <span
                                >Subscription Id:
                                {{ item.productId }}</span
                              >
                            </span>
                          </v-tooltip>
                          {{
                            item.lessons ? item.lessons.length : '0'
                          }}
                        </template>
                        <template
                          v-slot:[`item.lessonAttempted`]="{ item }"
                        >
                          {{ item.lessonCount }}
                        </template>
                        <template
                          v-slot:[`item.lessonCompleted`]="{ item }"
                        >
                          {{ item.lessonCompletedCount }}
                        </template>
                        <template
                          v-slot:[`item.percentCompletion`]="{ item }"
                        >
                          {{ item.percentageCompletion
                          }}<span>%</span>
                        </template>

                        <!-- Expand Buttons -->
                        <template
                          v-slot:[`item.data-table-expand`]="{
                            isExpanded,
                            expand
                          }"
                        >
                          <v-btn
                            v-if="!isExpanded"
                            color="blue"
                            icon
                            small
                            @click="expand(true)"
                            ><v-icon>mdi-chevron-down</v-icon></v-btn
                          >
                          <v-btn
                            v-if="isExpanded"
                            color="blue"
                            icon
                            small
                            @click="expand(false)"
                          >
                            <v-icon>
                              mdi-chevron-up
                            </v-icon>
                          </v-btn>
                        </template>

                        <!-- Expanded Data -->
                        <template
                          v-slot:expanded-item="{ headers, item }"
                        >
                          <td
                            :colspan="headers.length"
                            style="background: white;padding: 0px;"
                          >
                            <v-simple-table>
                              <template v-slot:default>
                                <thead style="background: #E5E5E5;">
                                  <tr>
                                    <th class="text-left">
                                      Module Title
                                    </th>
                                    <th class="text-left">
                                      Lesson Title
                                    </th>
                                    <th class="text-left">
                                      Availability
                                    </th>
                                    <th class="text-left">
                                      Last Attempt
                                    </th>
                                    <th class="text-left">
                                      Number of Attempts
                                    </th>
                                    <th class="text-left">
                                      Last Score
                                    </th>
                                    <th class="text-left">
                                      Status
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="items in item.lessons"
                                    :key="items.pcourseId"
                                  >
                                    <td>{{ items.courseName }}</td>
                                    <td>{{ items.lessonName }}</td>
                                    <td>
                                      <v-tooltip right class="pa-2">
                                        <template
                                          v-slot:activator="{
                                            on,
                                            attrs
                                          }"
                                        >
                                          <v-icon
                                            v-bind="attrs"
                                            color="primary"
                                            v-on="on"
                                          >
                                            mdi-information
                                          </v-icon>
                                        </template>
                                        <span>
                                          <span
                                            >Module Id:
                                            {{ items.courseId }}</span
                                          ><v-spacer />
                                          <span
                                            >Lesson Id:
                                            {{ items.lessonId }}</span
                                          >
                                        </span>
                                      </v-tooltip>
                                      {{ items.availability }}
                                    </td>
                                    <td>
                                      {{
                                        items.lastAttempt
                                          ? items.lastAttempt
                                          : 'Not Attempted'
                                      }}
                                    </td>
                                    <td>{{ items.totalAttemps }}</td>
                                    <td>
                                      {{
                                        items.totalAttemps > 0
                                          ? items.lastattempt_score +
                                            '/' +
                                            items.lastattempt_totalQuestions
                                          : ''
                                      }}
                                    </td>

                                    <td>
                                      <div
                                        :class="
                                          items.totalAttemps > 0
                                            ? 'success--text'
                                            : 'warning--text'
                                        "
                                      >
                                        {{ items.lessonStatus }}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </td>
                        </template>
                      </v-data-table>
                    </v-card>
                  </div>
                  <div v-else>
                    <v-card outlined>
                      {{ 'No Courses To Display' }}
                    </v-card>
                  </div>
                </v-card>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="confirmdialogSubscriptiondelete"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="headline"
          >Remove Subscription From User?</v-card-title
        >
        <v-card-text
          >Are you sure you want to remove subscription
          <span class="success--text">{{
            userSubscriptionName
          }}</span>
          from the user
          <span class="success--text">{{ ProductUserName }}</span>
          ??</v-card-text
        >
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#113247 darken-1"
            text
            @click="confirmdialogSubscriptiondelete = false"
            >Cancel</v-btn
          >
          <v-btn
            class="actionButton"
            text
            @click="deleteSubscription(UserProductId)"
            >REMOVE SUBSCRIPTION</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { Constant } from '../../config/constants';

const USER_QUERY = gql`
  query($userId: ID!) {
    user(id: $userId) {
      id
      username
      email
      mobile
      firstName
      lastName
      blocked
      isLogined
      EmployeeID
      adminOf {
        id
        name
      }
      viewerOf {
        id
        name
      }
      role {
        id
        name
      }
      memberOf {
        id
        name
        EmployeeIDType
        courses {
          id
          title
          lessons {
            id
            title
          }
        }
      }
    }
  }
`;
const USER_PRODUCTQUERY = gql`
  query UserProducts($where: JSON) {
    userProducts(where: $where) {
      id
      expirationDate
      purchaseDate
      product {
        id
        name
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
`;
const USER_COURSE_QUERY = gql`
  query($userId: ID!) {
    userDetails(id: $userId) {
      id
      email
      username
      mobile
      blocked
      memberOf {
        id
        name
        primary_courses {
          id
          Title
          courses {
            id
            title
            completed
            lessons {
              id
              title
              completed
              quizAttempts
            }
          }
        }
      }
    }
  }
`;
const COURSE_PROGRESSQUERY = gql`
  query($userId: ID) {
    courseProgress(userId: $userId) {
      isSuccess
      data {
        productId
        pcourseName
        productName
        pcourseId
        lessons {
          courseName
          courseId
          lessonName
          lessonId
          lastAttempt
          totalAttemps
          lastattempt_score
          lastattempt_totalQuestions
          availability
        }
      }
    }
  }
`;
// const ORG_QUERY = gql`
//   query {
//     orgs {
//       id
//       name
//     }
//   }
// `;
const UPDATE_USER_MUTATION = gql`
  mutation(
    $userId: ID!
    $userName: String!
    $userEmail: String!
    $status: Boolean!
    $mobile: String
    $firstName: String
    $lastName: String
    $isLogined: Boolean
    $EmployeeID: String
  ) {
    updateUser(
      input: {
        where: { id: $userId }
        data: {
          username: $userName
          email: $userEmail
          mobile: $mobile
          blocked: $status
          firstName: $firstName
          lastName: $lastName
          isLogined: $isLogined
          EmployeeID: $EmployeeID
        }
      }
    ) {
      user {
        id
        username
        email
        mobile
        blocked
        firstName
        lastName
        isLogined
        EmployeeID
      }
    }
  }
`;

const FORGOT_PASSWORD = gql`
  mutation($email: String!, $deviceType: String!) {
    customforgotPassword(email: $email, deviceType: $deviceType) {
      ok
      resetURL
    }
  }
`;
const DELETE_USERPRODUCT_MUTATION = gql`
  mutation($userProductId: ID!) {
    deleteUserProduct(input: { where: { id: $userProductId } }) {
      userProduct {
        id
      }
    }
  }
`;
export default {
  name: 'UserDetail',
  data: () => ({
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    firstNameRules: [v => !!v || 'First name is required'],
    valid: true,
    roles: {
      DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
      CMS_ADMIN: 'CMS_ADMIN',
      ORG_ADMIN: 'ORG_ADMIN',
      ORG_VIEWER: 'ORG_VIEWER',
      LEARNER: 'LEARNER'
    },
    alert: {
      type: 'success',
      show: false,
      text: ''
    },
    currentRole: '',
    loading: false,
    userSubscription: [],
    loadingSubscriptions: true,
    user: {},
    hideDelete: process.env.VUE_APP_HIDEDELETE_STATUS,
    confirmdialogSubscriptiondelete: '',
    userCourseProgress: [],
    ProductUserName: '',
    userSubscriptionName: '',
    local: {
      user: {
        email: '',
        isLogined: false,
        role: {
          name: ''
        }
      }
    },
    status_user: [
      { id: false, name: 'Active' },
      { id: true, name: 'In Active' }
    ],
    user_courses: [],
    tab: null,
    tabItems: [
      { id: 0, text: 'Details', icon: 'mdi-account-details' },
      { id: 1, text: 'Course Progress', icon: 'mdi-school' }
    ],
    subscriptionHeaders: [
      { text: 'Subscription', value: 'name', width: '25%' },
      {
        text: 'Purchase Date',
        value: 'purchasedateFormatted',
        sortable: false,
        width: '22%'
      },
      {
        text: 'Expiry Date',
        value: 'expirydateFormatted',
        sortable: false,
        width: '25%'
      },
      {
        text: 'Auto Renewal',
        value: 'AutoRenewal',
        sortable: false,
        width: '22%'
      },
      {
        text: 'Status',
        value: 'isDisabled',
        sortable: false,
        width: '15%'
      },
      {
        text: 'Actions',
        value: 'action',
        sortable: false,
        width: '15%'
      }
    ],
    expanded: [],
    searchUserSubscription: '',
    AutoRenewal: '',
    singleExpand: false,
    headers: [
      { text: 'Course', value: 'pcourseName' },
      { text: 'Subscription', value: 'productName' },
      { text: 'Total Lessons', value: 'totalLessons' },
      { text: 'Lessons Attempted', value: 'lessonAttempted' },
      { text: 'Lessons Completed', value: 'lessonCompleted' },
      { text: 'Percent Completion', value: 'percentCompletion' },

      { text: '', value: 'data-table-expand' }
    ],
    slectedTab: 0,
    inviteloading: false, //https://codepen.io/pen/?&editors=111
    resetloading: false,
    inviteURL: '',
    resetURL: '',
    orgID: '',
    EmployeeID: '',
    EmployeeIDError: false,
    EmployeeIDType: ''
  }),
  computed: {
    ...mapGetters('auth', [
      'getUser',
      'isAuthenticated',
      'isUnauthenticated',
      'isAuthenticating',
      'isUnauthenticating'
    ]),
    computedTabs() {
      if (
        this.currentRole === 'CMS_ADMIN' ||
        this.currentRole === 'DEMINTIA_ADMIN' ||
        this.currentRole === 'ORG_ADMIN' ||
        this.currentRole === 'ORG_VIEWER'
      ) {
        return this.tabItems.filter(t => t.text != 'Course Progress');
      } else return this.tabItems;
    },
    IsNotLearnerRole() {
      if (
        this.currentRole === 'CMS_ADMIN' ||
        this.currentRole === 'DEMINTIA_ADMIN' ||
        this.currentRole === 'ORG_ADMIN' ||
        this.currentRole === 'ORG_VIEWER'
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsOrgHide() {
      if (
        this.currentRole === 'CMS_ADMIN' ||
        this.currentRole === 'DEMINTIA_ADMIN' ||
        this.currentRole === 'PUBLIC_LEARNER'
      ) {
        return false;
      } else {
        return true;
      }
    },
    IsDementiaAdmin() {
      if (
        this.getUser?.user?.role.name === this.roles.DEMENTIA_ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsCMSAdmin() {
      if (this.getUser?.user?.role.name === this.roles.CMS_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGAdmin() {
      if (this.getUser?.user?.role.name === this.roles.ORG_ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    IsORGViewer() {
      if (this.getUser?.user?.role.name === this.roles.ORG_VIEWER) {
        return true;
      } else {
        return false;
      }
    },
    userId() {
      return this.$route.params.courseId;
    },
    breadcrumbs() {
      return [{ text: 'Back', href: '#/users' }];
    },
    computedSubscriptionHeaders() {
      if (this.hideDelete == 'true') {
        if (
          this.local.user.role != 'PUBLIC_LEARNER' &&
          this.local.user.role != 'LEARNER'
        ) {
          return this.subscriptionHeaders.filter(
            t =>
              t.text != 'Purchase Date' &&
              t.text != 'Expiry Date' &&
              t.text != 'Auto Renewal' &&
              t.text != 'Actions'
          );
        } else {
          return this.subscriptionHeaders.filter(
            t => t.text != 'Actions'
          );
        }
      } else {
        if (
          this.local.user.role != 'PUBLIC_LEARNER' &&
          this.local.user.role != 'LEARNER'
        ) {
          return this.subscriptionHeaders.filter(
            t =>
              t.text != 'Purchase Date' &&
              t.text != 'Expiry Date' &&
              t.text != 'Auto Renewal'
          );
        } else {
          return this.subscriptionHeaders.filter(
            t => t.text != 'Actions'
          );
        }
      }
    },
    LoggedInStatus() {
      return this.local.user.isLogined.toString();
    },
    employeeIDRequired() {
      const self = this;
      return self.EmployeeIDType === 'EmailAddress' ||
        self.EmployeeIDType === 'AlphaNumeric'
        ? true
        : false;
    }
  },
  watch: {
    EmployeeID(value) {
      const self = this;
      const userID = this.user.id;
      const orgID = this.orgID;
      if (!userID || !orgID || !self.employeeIDRequired) return true;
      const headerOptions = {
        Authorization:
          'Bearer ' + localStorage.getItem(Constant.AUTH_TOKEN)
      };
      const apiurl =
        process.env.VUE_APP_BASE_API +
        '/employeeIDDuplicateCheckProfile';
      let data = new FormData();
      data.append('orgID', orgID);
      data.append('userID', userID);
      data.append('employeeID', value);
      fetch(apiurl, {
        method: 'POST',
        headers: headerOptions,
        body: data
      })
        .then(res => res.json())
        .then(result => {
          self.EmployeeIDError = result.status ? false : true;
        })
        .catch(error => {
          this.showError = true;
          console.log('No Data: ' + error);
          return false;
        });
    }
  },
  methods: {
    deleteSubscription: function(userProductId) {
      this.$apollo.mutate({
        mutation: DELETE_USERPRODUCT_MUTATION,
        variables: {
          userProductId: userProductId
        },
        update: () => {
          this.$apollo.queries.userSubscription.refresh();

          this.alert.show = true;
          this.alert.text = 'Successfully deleted';
          this.alert.type = 'success';
          this.editName = false;
          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        }
      });
      this.confirmdialogSubscriptiondelete = false;
    },
    coursePaginationStatus() {
      if (this.user_courses && this.user_courses.length > 0)
        return false;
      else return true;
    },
    subscriptionPaginationStatus() {
      if (this.userSubscription && this.userSubscription.length > 0)
        return false;
      else return true;
    },
    copyInvite(mode) {
      if (mode === 1) {
        this.copyURLText(this.inviteURL).then(() => {
          this.alert.show = true;
          this.alert.text = 'Copied to clipboard!';
          setTimeout(() => {
            this.alert.show = false;
            this.alert.text = '';
          }, 3000);
        });
      } else {
        this.copyURLText(this.resetURL).then(() => {
          this.alert.show = true;
          this.alert.text = 'Copied to clipboard!';
          setTimeout(() => {
            this.alert.show = false;
            this.alert.text = '';
          }, 3000);
        });
      }
    },
    LoadConfirmDialogForDeleteSubscription(name, id) {
      this.userSubscriptionName = name;
      this.UserProductId = id;
      this.confirmdialogSubscriptiondelete = true;
    },
    copyURLText(contentText) {
      if (navigator.clipboard && window.isSecureContext) {
        return navigator.clipboard.writeText(contentText);
      } else {
        let textArea = document.createElement('textarea');
        textArea.value = contentText;
        textArea.style.position = 'fixed';
        textArea.style.left = '-999999px';
        textArea.style.top = '-999999px';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          document.execCommand('copy') ? res() : rej();
          textArea.remove();
        });
      }
    },
    validateUser() {
      if (!this.$refs.form[0].validate()) return;
      //if validation true call update user
      this.updateUser();
    },
    updateUser() {
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_MUTATION,
          variables: {
            userId: this.user.id,
            userName: this.local.user.email.toLowerCase(),
            userEmail: this.local.user.email.toLowerCase(),
            mobile: this.local.user.mobile,
            status: this.local.user.statusId,
            firstName: this.local.user.firstName,
            lastName: this.local.user.lastName,
            isLogined: this.local.user.isLogined,
            EmployeeID: this.EmployeeID
          }
          // todo: add an update method that checks if the modification
          //       is to the logged in user and updates the cache for "me"
          //       or use a "user" query instead of a "me" query if Admin
        })
        .then(user => {
          console.log(user);
          this.alert.show = true;
          this.alert.text = 'User Updated Successfully';
          setTimeout(() => {
            this.alert.show = false;
            this.alert.text = '';
          }, 10000);
        })
        .catch(e => {
          this.alert.show = true;
          this.alert.type = 'error';
          this.alert.text = JSON.stringify(
            e.graphQLErrors[0].extensions.exception.data.message[0]
              .messages[0].message
          ).replace(/['"]+/g, '');
          setTimeout(() => {
            this.alert.show = false;
            this.alert.text = '';
          }, 10000);
        });
    },
    setActiveTab(id) {
      this.slectedTab = id;
    },
    loadDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    getLessonStatus(item) {
      if (item.completed) {
        return 'Complete';
      } else if (!item.completed && item.quizAttempts > 0) {
        return 'In progress';
      } else if (!item.completed && item.quizAttempts === 0) {
        return 'Not yet started';
      }
    },
    sendResetPassword() {
      this.resetloading = true;
      this.resetURL = '';
      this.$apollo
        .mutate({
          mutation: FORGOT_PASSWORD,
          variables: {
            email: this.user.username,
            deviceType: 'LMS'
          },
          update: (store, { data: { customforgotPassword } }) => {
            this.resetURL = customforgotPassword.resetURL;
            this.resetloading = false;
            this.alert.show = true;
            this.alert.text = 'Password reset email sent!';
            this.alert.type = 'success';
            setTimeout(() => {
              this.alert.show = false;
              this.alert.text = '';
            }, 3000);
          }
        })
        .catch(e => {
          console.log(JSON.stringify(e.graphQLErrors[0]));
          this.resetURL = '';
          this.resetloading = false;
          this.alert = true;
          this.alert.show = true;
          this.alert.text = 'Network failure! please try again';
          this.alert.type = 'error';
          setTimeout(() => {
            this.alert.show = false;
            this.alert.text = '';
          }, 3000);
        });
    },
    validateEmployeeID(value) {
      const self = this;
      if (!self.EmployeeIDType) return true;
      if (!value) return 'Employee ID is required';
      const validateRule = {
        EmailAddress: /^\S+@\S+\.\S+$/,
        AlphaNumeric: /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9-!#$%*-?]+$/
      };
      if (
        self.EmployeeIDType === 'AlphaNumeric' &&
        !value.match(validateRule[self.EmployeeIDType])
      )
        return 'Employee ID should be alphanumeric';
      else if (
        self.EmployeeIDType === 'EmailAddress' &&
        !value.match(validateRule[self.EmployeeIDType])
      )
        return 'Employee ID should be a valid email address';
      else if (self.EmployeeIDError)
        return `Employee ID "${value}" already exist`;

      return true;
    }
  },
  apollo: {
    user() {
      this.loading = true;
      return {
        query: USER_QUERY,
        fetchPolicy: 'cache-and-network',
        variables: {
          userId: this.$route.params.userId
        },
        update: data => {
          this.local.user.username = data.user.username;
          this.local.user.email = data.user.email;
          this.local.user.statusId = data.user.blocked ? true : false;
          this.local.user.mobile = data.user.mobile;
          this.local.user.firstName = data.user.firstName;
          this.local.user.lastName = data.user.lastName;
          this.local.user.role = data.user.role.name;
          if (data.user.role.name == 'ORG_ADMIN') {
            this.local.user.orgName = data.user?.adminOf?.[0]?.name
              ? data.user.adminOf[0].name
              : 'Nil';
          } else if (data.user.role.name == 'ORG_VIEWER') {
            this.local.user.orgName = data.user?.viewerOf?.[0]?.name
              ? data.user.viewerOf[0].name
              : 'Nil';
          } else {
            this.local.user.orgName = data.user?.memberOf?.[0]?.name
              ? data.user.memberOf[0].name
              : 'Nil';
          }
          this.local.user.isLogined = data.user.isLogined
            ? data.user.isLogined
            : false;

          this.local.user.status = data.user.blocked
            ? 'Inactive'
            : 'Active';
          if (data.user.role.name === this.roles.ORG_ADMIN) {
            this.local.user.organisation = data.user.adminOf[0]
              ? data.user.adminOf[0].name
              : null;
          } else if (data.user.role.name === this.roles.ORG_VIEWER) {
            this.local.user.organisation = data.user.viewerOf[0]
              ? data.user.viewerOf[0].name
              : null;
          } else {
            this.local.user.organisation = data.user.memberOf[0]
              ? data.user.memberOf[0].name
              : null;
          }
          this.EmployeeID = data.user.EmployeeID;
          this.EmployeeIDType = data.user.memberOf[0]
            ? data.user.memberOf[0].EmployeeIDType
            : 'None';
          this.orgID = data.user.memberOf[0]
            ? data.user.memberOf[0].id
            : '';
          // this.local.user.organisationId = this.local.user.organisation = data
          //   .user.memberOf[0]
          //   ? data.user.memberOf[0].id
          //   : null;
          this.currentRole = data.user.role.name;
          this.loading = false;
          return data.user;
        }
      };
    },
    userSubscription() {
      return {
        query: USER_PRODUCTQUERY,
        variables: {
          where: {
            user: this.$route.params.userId
          }
        },
        fetchPolicy: 'network-only',
        update: data => {
          this.loadingSubscriptions = false;

          return data.userProducts.map(usrPrdcts => {
            this.userSubscriptionName = usrPrdcts.product.name || '';
            let ProductUserName =
              usrPrdcts.user.firstName +
              ' ' +
              usrPrdcts.user.lastName;
            usrPrdcts.name = usrPrdcts.product.name || '';
            this.ProductUserName = ProductUserName;
            usrPrdcts.expirydateFormatted = usrPrdcts.expirationDate;
            usrPrdcts.purchasedateFormatted = usrPrdcts.purchaseDate;

            return usrPrdcts;
          });
        }
      };
    },
    courseProgress() {
      return {
        query: COURSE_PROGRESSQUERY,
        variables: {
          userId: this.$route.params.userId
        },
        update: data => {
          if (data.courseProgress.data) {
            return data.courseProgress.data.map(crsPrgs => {
              let count = 0;
              let completedCount = 0;
              crsPrgs.percentageCompletion = 0;
              crsPrgs.lessonCount = 0;
              crsPrgs.lessonCompletedCount = 0;

              crsPrgs.lessons.map(lessonMapping => {
                if (lessonMapping.totalAttemps > 0) {
                  count = count + 1;
                  crsPrgs.lessonCount = count;
                  if (
                    lessonMapping.lastattempt_score ==
                    lessonMapping.lastattempt_totalQuestions
                  ) {
                    lessonMapping.lessonStatus = 'Complete';
                    completedCount = completedCount + 1;
                    crsPrgs.lessonCompletedCount = completedCount;
                  } else {
                    lessonMapping.lessonStatus = 'In Progress';
                  }
                } else {
                  lessonMapping.lessonStatus = 'Not yet started';
                }
              });
              if (
                crsPrgs.lessonCompletedCount > 0 &&
                crsPrgs.lessons.length > 0
              ) {
                let percentCompletion =
                  (crsPrgs.lessonCompletedCount /
                    crsPrgs.lessons.length) *
                  100;
                crsPrgs.percentageCompletion = percentCompletion
                  ? percentCompletion.toFixed(0)
                  : 0;
              }
              return crsPrgs;
            });
          }
        },
        error() {
          this.loading = false;
          this.dataLoaded = false;
          this.alert.show = true;
          this.alert.text =
            'Sorry, The requisition data cannot be loaded';
          this.alert.type = 'error';
          this.editName = false;
          // setTimeout(() => {
          //   this.alert.show = false;
          // }, 3000);
        }
      };
    },

    courses() {
      return {
        query: USER_COURSE_QUERY,
        skip() {
          return this.local.user.role != this.roles.LEARNER;
        },
        variables: {
          userId: this.$route.params.userId
        },

        update: data => {
          this.user_courses = data.userDetails.memberOf
            ? data.userDetails.memberOf[0]
              ? data.userDetails.memberOf[0].primary_courses
              : []
            : [];
        }
      };
    }
  }
};
</script>
