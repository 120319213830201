var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("div", [
            _c(
              "div",
              { staticClass: "text-center" },
              [_c("v-progress-linear", { attrs: { indeterminate: "" } })],
              1
            )
          ])
        : _vm._e(),
      _c(
        "v-alert",
        {
          staticClass: "v-alert",
          attrs: {
            dense: "",
            text: "",
            type: _vm.alert.type,
            value: _vm.alert.show,
            transition: "slide-y-transition"
          }
        },
        [_vm._v(_vm._s(_vm.alert.text))]
      ),
      _c(
        "v-container",
        { staticClass: "grey lighten-5", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "mr-auto", attrs: { cols: "auto" } },
                [
                  _c("v-breadcrumbs", {
                    staticClass: "pa-0",
                    attrs: { items: _vm.breadcrumbs }
                  }),
                  _c("h1", [
                    _vm._v(" Profile: "),
                    _c("span", { staticClass: "success--text" }, [
                      _vm._v(_vm._s(_vm.user.firstName))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-tabs",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    _vm._l(_vm.computedTabs, function(tab) {
                      return _c(
                        "v-tab",
                        {
                          key: tab.id,
                          on: {
                            click: function($event) {
                              return _vm.setActiveTab(tab.id)
                            }
                          }
                        },
                        [
                          _c("v-icon", { staticClass: "mr-2" }, [
                            _vm._v(_vm._s(tab.icon))
                          ]),
                          _vm._v(" " + _vm._s(tab.text) + " ")
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    _vm._l(_vm.computedTabs, function(tab) {
                      return _c("v-tab-item", { key: tab.id }, [
                        tab.text == "Details"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            attrs: { color: "basil", flat: "" }
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              { attrs: { outlined: "" } },
                                              [
                                                _c(
                                                  "v-form",
                                                  {
                                                    ref: "form",
                                                    refInFor: true
                                                  },
                                                  [
                                                    _c("v-card-title", [
                                                      _c("h2", [
                                                        _vm._v("User Details")
                                                      ])
                                                    ]),
                                                    _c(
                                                      "v-card-text",
                                                      [
                                                        _vm.employeeIDRequired
                                                          ? _c("h3", [
                                                              _vm._v(
                                                                " Employee ID "
                                                              )
                                                            ])
                                                          : _vm._e(),
                                                        _vm.employeeIDRequired
                                                          ? _c("v-text-field", {
                                                              attrs: {
                                                                disabled: "",
                                                                required: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.local.user
                                                                    .EmployeeID,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.local
                                                                      .user,
                                                                    "EmployeeID",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "local.user.EmployeeID"
                                                              }
                                                            })
                                                          : _vm._e(),
                                                        _c("h3", [
                                                          _vm._v("Email")
                                                        ]),
                                                        _vm.IsDementiaAdmin
                                                          ? _c("v-text-field", {
                                                              attrs: {
                                                                rules:
                                                                  _vm.emailRules,
                                                                disabled: "",
                                                                required: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.local.user
                                                                    .email,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.local
                                                                      .user,
                                                                    "email",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "local.user.email"
                                                              }
                                                            })
                                                          : _c("v-text-field", {
                                                              attrs: {
                                                                disabled: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.local.user
                                                                    .email,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.local
                                                                      .user,
                                                                    "email",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "local.user.email"
                                                              }
                                                            }),
                                                        _c("h3", [
                                                          _vm._v("First name")
                                                        ]),
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            rules:
                                                              _vm.firstNameRules,
                                                            disabled: "",
                                                            required: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.local.user
                                                                .firstName,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.local.user,
                                                                "firstName",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "local.user.firstName"
                                                          }
                                                        }),
                                                        _c("h3", [
                                                          _vm._v("Last name")
                                                        ]),
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            disabled: "",
                                                            required: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.local.user
                                                                .lastName,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.local.user,
                                                                "lastName",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "local.user.lastName"
                                                          }
                                                        }),
                                                        _vm.IsOrgHide
                                                          ? _c("h3", [
                                                              _vm._v(
                                                                " Organisation "
                                                              )
                                                            ])
                                                          : _vm._e(),
                                                        _vm.IsOrgHide
                                                          ? _c("v-text-field", {
                                                              attrs: {
                                                                disabled: "",
                                                                required: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.local.user
                                                                    .organisation,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.local
                                                                      .user,
                                                                    "organisation",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "local.user.organisation"
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    !_vm.IsNotLearnerRole
                                      ? _c(
                                          "v-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "v-card",
                                              { attrs: { outlined: "" } },
                                              [
                                                _c(
                                                  "v-card-title",
                                                  [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c("v-col", [
                                                          _c("h2", [
                                                            _vm._v(
                                                              "Subscriptions"
                                                            )
                                                          ])
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-card-text",
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "page-sub-sec"
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          staticClass: "shrink",
                                                          attrs: {
                                                            "append-icon":
                                                              "mdi-magnify",
                                                            label: "Search",
                                                            outlined: "",
                                                            dense: "",
                                                            "hide-details": ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.searchUserSubscription,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.searchUserSubscription = $$v
                                                            },
                                                            expression:
                                                              "searchUserSubscription"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c("v-data-table", {
                                                      attrs: {
                                                        items:
                                                          _vm.userSubscription,
                                                        headers:
                                                          _vm.computedSubscriptionHeaders,
                                                        search:
                                                          _vm.searchUserSubscription,
                                                        loading:
                                                          _vm.loadingSubscriptions,
                                                        "loading-text":
                                                          "Loading... Please wait",
                                                        "no-data-text":
                                                          "No subscriptions available for this user",
                                                        "hide-default-footer": _vm.subscriptionPaginationStatus()
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "item.name",
                                                            fn: function(ref) {
                                                              var item =
                                                                ref.item
                                                              return [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.name
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            }
                                                          },
                                                          {
                                                            key:
                                                              "item.purchasedateFormatted",
                                                            fn: function(ref) {
                                                              var item =
                                                                ref.item
                                                              return [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.purchasedateFormatted &&
                                                                        _vm.loadDate(
                                                                          item.purchasedateFormatted
                                                                        )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            }
                                                          },
                                                          {
                                                            key:
                                                              "item.expirydateFormatted",
                                                            fn: function(ref) {
                                                              var item =
                                                                ref.item
                                                              return [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.expirydateFormatted &&
                                                                        _vm.loadDate(
                                                                          item.expirydateFormatted
                                                                        )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            }
                                                          },
                                                          {
                                                            key:
                                                              "item.AutoRenewal",
                                                            fn: function(ref) {
                                                              var item =
                                                                ref.item
                                                              return [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.AutoRenewal
                                                                        ? item.AutoRenewal
                                                                        : "Yes"
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            }
                                                          },
                                                          {
                                                            key:
                                                              "item.isDisabled",
                                                            fn: function(ref) {
                                                              var item =
                                                                ref.item
                                                              return [
                                                                item.product
                                                                  .isDisabled
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "warning--text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Inactive "
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "success--text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Active "
                                                                        )
                                                                      ]
                                                                    )
                                                              ]
                                                            }
                                                          },
                                                          {
                                                            key: "item.action",
                                                            fn: function(ref) {
                                                              var item =
                                                                ref.item
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "lessons-actions-wrap"
                                                                  },
                                                                  [
                                                                    _vm.hideDelete ==
                                                                    "false"
                                                                      ? _c(
                                                                          "v-btn",
                                                                          {
                                                                            attrs: {
                                                                              color:
                                                                                "red",
                                                                              icon:
                                                                                "",
                                                                              small:
                                                                                ""
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.LoadConfirmDialogForDeleteSubscription(
                                                                                  item
                                                                                    .product
                                                                                    .name,
                                                                                  item.id
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                attrs: {
                                                                                  title:
                                                                                    "Delete"
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "mdi-trash-can-outline"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e()
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        tab.text == "Course Progress"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-card",
                                  { attrs: { color: "basil", flat: "" } },
                                  [
                                    _vm.user_courses
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "v-card",
                                              { attrs: { outlined: "" } },
                                              [
                                                _c("v-data-table", {
                                                  attrs: {
                                                    headers: _vm.headers,
                                                    items: _vm.courseProgress,
                                                    "show-expand": "",
                                                    "single-expand": true,
                                                    expanded: _vm.expanded,
                                                    "item-key": "pcourseId",
                                                    "no-data-text":
                                                      "No course available",
                                                    "hide-default-footer": _vm.coursePaginationStatus()
                                                  },
                                                  on: {
                                                    "update:expanded": function(
                                                      $event
                                                    ) {
                                                      _vm.expanded = $event
                                                    }
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "item.pcourseName",
                                                        fn: function(ref) {
                                                          var item = ref.item
                                                          return [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.pcourseName
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "item.productName",
                                                        fn: function(ref) {
                                                          var item = ref.item
                                                          return [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.productName
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key:
                                                          "item.totalLessons",
                                                        fn: function(ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                staticClass:
                                                                  "pa-2 ",
                                                                attrs: {
                                                                  right: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        var attrs =
                                                                          ref.attrs
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  attrs: {
                                                                                    color:
                                                                                      "primary"
                                                                                  }
                                                                                },
                                                                                "v-icon",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                " mdi-information "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Course Id: " +
                                                                          _vm._s(
                                                                            item.pcourseId
                                                                          )
                                                                      )
                                                                    ]),
                                                                    _c(
                                                                      "v-spacer"
                                                                    ),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Subscription Id: " +
                                                                          _vm._s(
                                                                            item.productId
                                                                          )
                                                                      )
                                                                    ])
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.lessons
                                                                    ? item
                                                                        .lessons
                                                                        .length
                                                                    : "0"
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key:
                                                          "item.lessonAttempted",
                                                        fn: function(ref) {
                                                          var item = ref.item
                                                          return [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.lessonCount
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key:
                                                          "item.lessonCompleted",
                                                        fn: function(ref) {
                                                          var item = ref.item
                                                          return [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.lessonCompletedCount
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key:
                                                          "item.percentCompletion",
                                                        fn: function(ref) {
                                                          var item = ref.item
                                                          return [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.percentageCompletion
                                                                )
                                                            ),
                                                            _c("span", [
                                                              _vm._v("%")
                                                            ])
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key:
                                                          "item.data-table-expand",
                                                        fn: function(ref) {
                                                          var isExpanded =
                                                            ref.isExpanded
                                                          var expand =
                                                            ref.expand
                                                          return [
                                                            !isExpanded
                                                              ? _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "blue",
                                                                      icon: "",
                                                                      small: ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return expand(
                                                                          true
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-chevron-down"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            isExpanded
                                                              ? _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "blue",
                                                                      icon: "",
                                                                      small: ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return expand(
                                                                          false
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          " mdi-chevron-up "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "expanded-item",
                                                        fn: function(ref) {
                                                          var headers =
                                                            ref.headers
                                                          var item = ref.item
                                                          return [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticStyle: {
                                                                  background:
                                                                    "white",
                                                                  padding: "0px"
                                                                },
                                                                attrs: {
                                                                  colspan:
                                                                    headers.length
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-simple-table",
                                                                  {
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "default",
                                                                          fn: function() {
                                                                            return [
                                                                              _c(
                                                                                "thead",
                                                                                {
                                                                                  staticStyle: {
                                                                                    background:
                                                                                      "#E5E5E5"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "tr",
                                                                                    [
                                                                                      _c(
                                                                                        "th",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-left"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " Module Title "
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-left"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " Lesson Title "
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-left"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " Availability "
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-left"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " Last Attempt "
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-left"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " Number of Attempts "
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-left"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " Last Score "
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-left"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " Status "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "tbody",
                                                                                _vm._l(
                                                                                  item.lessons,
                                                                                  function(
                                                                                    items
                                                                                  ) {
                                                                                    return _c(
                                                                                      "tr",
                                                                                      {
                                                                                        key:
                                                                                          items.pcourseId
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "td",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                items.courseName
                                                                                              )
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "td",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                items.lessonName
                                                                                              )
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "td",
                                                                                          [
                                                                                            _c(
                                                                                              "v-tooltip",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "pa-2",
                                                                                                attrs: {
                                                                                                  right:
                                                                                                    ""
                                                                                                },
                                                                                                scopedSlots: _vm._u(
                                                                                                  [
                                                                                                    {
                                                                                                      key:
                                                                                                        "activator",
                                                                                                      fn: function(
                                                                                                        ref
                                                                                                      ) {
                                                                                                        var on =
                                                                                                          ref.on
                                                                                                        var attrs =
                                                                                                          ref.attrs
                                                                                                        return [
                                                                                                          _c(
                                                                                                            "v-icon",
                                                                                                            _vm._g(
                                                                                                              _vm._b(
                                                                                                                {
                                                                                                                  attrs: {
                                                                                                                    color:
                                                                                                                      "primary"
                                                                                                                  }
                                                                                                                },
                                                                                                                "v-icon",
                                                                                                                attrs,
                                                                                                                false
                                                                                                              ),
                                                                                                              on
                                                                                                            ),
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                " mdi-information "
                                                                                                              )
                                                                                                            ]
                                                                                                          )
                                                                                                        ]
                                                                                                      }
                                                                                                    }
                                                                                                  ],
                                                                                                  null,
                                                                                                  true
                                                                                                )
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "span",
                                                                                                  [
                                                                                                    _c(
                                                                                                      "span",
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "Module Id: " +
                                                                                                            _vm._s(
                                                                                                              items.courseId
                                                                                                            )
                                                                                                        )
                                                                                                      ]
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "v-spacer"
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "span",
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "Lesson Id: " +
                                                                                                            _vm._s(
                                                                                                              items.lessonId
                                                                                                            )
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              ]
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " " +
                                                                                                _vm._s(
                                                                                                  items.availability
                                                                                                ) +
                                                                                                " "
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                        _c(
                                                                                          "td",
                                                                                          [
                                                                                            _vm._v(
                                                                                              " " +
                                                                                                _vm._s(
                                                                                                  items.lastAttempt
                                                                                                    ? items.lastAttempt
                                                                                                    : "Not Attempted"
                                                                                                ) +
                                                                                                " "
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "td",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                items.totalAttemps
                                                                                              )
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "td",
                                                                                          [
                                                                                            _vm._v(
                                                                                              " " +
                                                                                                _vm._s(
                                                                                                  items.totalAttemps >
                                                                                                    0
                                                                                                    ? items.lastattempt_score +
                                                                                                        "/" +
                                                                                                        items.lastattempt_totalQuestions
                                                                                                    : ""
                                                                                                ) +
                                                                                                " "
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "td",
                                                                                          [
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                class:
                                                                                                  items.totalAttemps >
                                                                                                  0
                                                                                                    ? "success--text"
                                                                                                    : "warning--text"
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      items.lessonStatus
                                                                                                    ) +
                                                                                                    " "
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                0
                                                                              )
                                                                            ]
                                                                          },
                                                                          proxy: true
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          [
                                            _c(
                                              "v-card",
                                              { attrs: { outlined: "" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      "No Courses To Display"
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.confirmdialogSubscriptiondelete,
            callback: function($$v) {
              _vm.confirmdialogSubscriptiondelete = $$v
            },
            expression: "confirmdialogSubscriptiondelete"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Remove Subscription From User?")
              ]),
              _c("v-card-text", [
                _vm._v("Are you sure you want to remove subscription "),
                _c("span", { staticClass: "success--text" }, [
                  _vm._v(_vm._s(_vm.userSubscriptionName))
                ]),
                _vm._v(" from the user "),
                _c("span", { staticClass: "success--text" }, [
                  _vm._v(_vm._s(_vm.ProductUserName))
                ]),
                _vm._v(" ??")
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "#113247 darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmdialogSubscriptiondelete = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "actionButton",
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          return _vm.deleteSubscription(_vm.UserProductId)
                        }
                      }
                    },
                    [_vm._v("REMOVE SUBSCRIPTION")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }