/*
 * Auth Mock
 * ---------
 * This is a mock authentication method that implements the API used for
 * specific methods (e.g. Keycloak).
 *
 * It supports a single user and authentication state for the session.
 */
import AuthTypes from './AuthTypes';
const { AuthState, Mutations } = AuthTypes;
//import gql from 'graphql-tag';
import {
  onLogin,
  onLogout,
  AUTH_TOKEN
  //apolloClient
} from '../../vue-apollo';

const SettledPromise = new Promise(ok => {
  ok();
});

function snakeToPascalCase(name) {
  return name
    .toLowerCase()
    .replace(/(^|_)([\w])/g, (match, p1, p2) => p2.toUpperCase());
}

// I mentioned this in a PR to Reubs so I chucked this in.
// Not sure if it's a good idea but worth discussing.
function mapEnumToIsFunctions(stateEnum, stateVar) {
  const isFuncs = {};
  // Creates a function for each value of the Enum which
  // is called "isStateKey" and checks if the stateVar is
  // equal to it.
  Object.keys(stateEnum).forEach(stateKey => {
    isFuncs['is' + snakeToPascalCase(stateKey)] = state =>
      state[stateVar] === stateEnum[stateKey];
  });
  return isFuncs;
}

// From https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
// atob has know unicode problems
// https://developer.mozilla.org/en-US/docs/Glossary/Base64#The_Unicode_Problem
const parseJwt = token => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const roles = {
  DEMENTIA_ADMIN: 'DEMINTIA_ADMIN',
  CMS_ADMIN: 'CMS_ADMIN',
  ORG_ADMIN: 'ORG_ADMIN',
  ORG_VIEWER: 'ORG_VIEWER'
};

// var LOGIN_MUTATION = gql`
//   mutation($input: UsersPermissionsLoginInput!) {
//     login(input: $input) {
//       jwt
//       user {
//         id
//         username
//         email
//         role {
//           id
//           name
//           description
//           type
//         }
//       }
//     }
//   }
// `;

// var ME_QUERY = gql`
//   query {
//     me {
//       id
//       username
//       email
//       role {
//         id
//         name
//         description
//         type
//       }
//     }
//   }
// `;

// const USER_QUERY = gql`
//   query($userId: ID!) {
//     user(id: $userId) {
//       id
//       username
//       memberOf {
//         id
//         name
//       }
//     }
//   }
// `;

export default {
  namespaced: true,
  userdetails: {},
  state: {
    authState: AuthState.INIT,
    user: {}
  },
  getters: {
    ...mapEnumToIsFunctions(AuthState, 'authState'),
    getUser(state) {
      return state;
    }
  },
  mutations: {
    setAuthState(state, authState) {
      state.authState = authState;
    },
    setUser(state, user) {
      state.user = user;
    }
  },
  actions: {
    init({ commit, state }) {
      // Check local storage for token
      let authToken = parseJwt(localStorage.getItem(AUTH_TOKEN));
      if (!authToken) {
        commit(Mutations.SET_AUTH_STATE, AuthState.UNAUTHENTICATED);
        return;
      }

      // Remove it if it's expired
      // - For some reason expiry is in seconds instead of millis
      // Something's going wrong - maybe this?
      // let nowInSeconds = parseInt(new Date().valueOf() / 1000);
      // if (authToken.exp < nowInSeconds) {
      //   localStorage.removeItem(AUTH_TOKEN);
      //   commit(Mutations.SET_AUTH_STATE, AuthState.UNAUTHENTICATED);
      //   return;
      // }

      // Query server for user info
      const userid = localStorage.getItem('UserId');
      const meurl = process.env.VUE_APP_BASE_API + '/users/' + userid;
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN)
        }
      };
      fetch(meurl, requestOptions)
        .then(response => response.json())
        .then(data => {
          state.user = {
            id: data?.id,
            email: data?.email,
            name: data?.username,
            firstName: data?.firstName,
            lastName: data?.lastName,
            role: {
              id: data?.role.id,
              name: data?.role.name,
              type: data?.role.type
            },
            memberOf: []
          };
          if (state.user.role.name === roles.ORG_ADMIN) {
            state.user.memberOf = data?.adminOf;
          } else if (state.user.role.name === roles.ORG_VIEWER) {
            state.user.memberOf = data?.viewerOf;
          }
          // Set "Authenticated" state
          commit(Mutations.SET_AUTH_STATE, AuthState.AUTHENTICATED);
          commit(Mutations.SET_USER, state.user);
        });

      // apolloClient
      //   .mutate({
      //     mutation: ME_QUERY,
      //     update: (store, meData) => {
      //       state.user = {
      //         id: meData.data.me?.id,
      //         email: meData.data.me?.email,
      //         name: meData.data.me?.username,
      //         role: meData.data.me.role.name
      //       };
      //       commit(Mutations.SET_AUTH_STATE, AuthState.AUTHENTICATED);
      //     }
      //   })
      //   .catch(error => {
      //     console.log('Auth init:' + error);
      //     // localStorage.removeItem(AUTH_TOKEN);
      //     commit(Mutations.SET_AUTH_STATE, AuthState.UNAUTHENTICATED);
      //   });
    },
    // Starts the login process, intent to login
    login({ commit }) {
      commit(Mutations.SET_AUTH_STATE, AuthState.AUTHENTICATING);
    },
    logout({ commit }) {
      // Send logout mutation
      // - There is no logout mutation!
      // - Is there a danger of jwt re-use?

      // Set "Unauthenticated" state
      commit(Mutations.SET_AUTH_STATE, AuthState.UNAUTHENTICATED);

      // call onLogout vue-apollo method
      onLogout();

      // Remove User object
      commit(Mutations.SET_USER, null);

      return SettledPromise;
    },
    // Called by login dialog
    async authenticate({ commit, state }, data) {
      // Send login mutation
      // return apolloClient
      //   .mutate({
      //     mutation: LOGIN_MUTATION,
      //     variables: {
      //       input: {
      //         identifier: data.username,
      //         password: data.password
      //       }
      //     },
      //     update: (store, updateData) => {
      //       // Call onLogin vue-apollo method
      //       onLogin(updateData?.data?.login);
      //       //updateData?.data?.login?.jwt
      //       // Add User object to state
      //       debugger;
      //       //check role is orgAdmin/orgviewer
      //       if (
      //         updateData?.data?.login?.user.role.name ===
      //           'ORG_ADMIN' ||
      //         updateData?.data?.login?.user.role.name === 'ORG_VIEWER'
      //       ) {
      //         console.log(JSON.stringify(this.userdetails));
      //       }

      //       state.user = {
      //         id: updateData?.data?.login?.user?.id,
      //         email: updateData?.data?.login?.user?.email,
      //         name: updateData?.data?.login?.user?.username,
      //         role: {
      //           id: updateData?.data?.login?.user.role.id,
      //           name: updateData?.data?.login?.user.role.name,
      //           type: updateData?.data?.login?.user.role.type
      //         }
      //       };
      //       // Set "Authenticated" state
      //       commit(Mutations.SET_AUTH_STATE, AuthState.AUTHENTICATED);
      //     }
      //   })
      //   .catch(error => {
      //     commit(Mutations.SET_AUTH_STATE, AuthState.UNAUTHENTICATED);
      //     console.log('Authenticate:' + error);
      //   });

      // Simple POST request with a JSON body using fetch
      const loginurl = process.env.VUE_APP_BASE_API + '/auth/local';
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          identifier: data.username,
          password: data.password
        })
      };
      return fetch(loginurl, requestOptions)
        .then(response => response.json())
        .then(data => {
          //this.postId = data.id

          if ('error' in data) {
            commit(Mutations.SET_AUTH_STATE, AuthState.LOGIN_FAILED);
            commit(Mutations.SET_USER, null);
          } else {
            onLogin(data);
            state.user = {
              id: data?.user?.id,
              email: data?.user?.email,
              name: data?.user?.username,
              firstName: data?.user?.firstName,
              lastName: data?.user?.lastName,
              role: {
                id: data?.user.role.id,
                name: data?.user.role.name,
                type: data?.user.role.type
              },
              memberOf: []
            };
            if (state.user.role.name === roles.ORG_ADMIN) {
              state.user.memberOf = data?.user?.adminOf;
              // Set "Authenticated" state
              commit(
                Mutations.SET_AUTH_STATE,
                AuthState.AUTHENTICATED
              );
              commit(Mutations.SET_USER, state.user);
            } else if (state.user.role.name === roles.ORG_VIEWER) {
              state.user.memberOf = data?.user?.viewerOf;
              // Set "Authenticated" state
              commit(
                Mutations.SET_AUTH_STATE,
                AuthState.AUTHENTICATED
              );
              commit(Mutations.SET_USER, state.user);
            } else if (
              state.user.role.name === roles.DEMENTIA_ADMIN
            ) {
              // Set "Authenticated" state
              commit(
                Mutations.SET_AUTH_STATE,
                AuthState.AUTHENTICATED
              );
              commit(Mutations.SET_USER, state.user);
            } else if (state.user.role.name === roles.CMS_ADMIN) {
              // Set "Authenticated" state
              commit(
                Mutations.SET_AUTH_STATE,
                AuthState.AUTHENTICATED
              );
              commit(Mutations.SET_USER, state.user);
            } else {
              //LEARNER
              commit(
                Mutations.SET_AUTH_STATE,
                AuthState.LOGIN_FAILED
              );
              commit(Mutations.SET_USER, null);
            }
          }
        })
        .catch(error => {
          commit(Mutations.SET_AUTH_STATE, AuthState.UNAUTHENTICATED);
          commit(Mutations.SET_USER, null);
          console.log('Authenticate:' + error);
        });
    },

    // Called by cancelling login dialog
    cancelLogin({ commit }) {
      commit(Mutations.SET_AUTH_STATE, AuthState.UNAUTHENTICATED);
      commit(Mutations.SET_USER, null);
      return SettledPromise;
    }
  }
};
